import { createEvent } from 'react-event-hook';
import { TFace } from 'types/building/Face';

export interface CornerDragEvent {
  position: { x: number; y: number };
  floorId: string;
  wall: TFace;
  previousWall: TFace;
  buildingId: string;
}

export interface CornerDragEndEvent {
  buildingId: string;
  cornerId: string;
}

export interface CornerSelectEvent {
  buildingId: string;
  floorId: string;
  wallId: string;
  cornerId: string;
}

export const { useCornerDragListener, emitCornerDrag } = createEvent('corner-drag')<CornerDragEvent>();

export const { useCornerDragEndListener, emitCornerDragEnd } = createEvent('corner-drag-end')<CornerDragEndEvent>();

export const { useCornerSelectionListener, emitCornerSelection } = createEvent('corner-selection')<CornerSelectEvent>();
