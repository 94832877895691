import { useThree } from '@react-three/fiber';
import { EObjectName, EVegetationType, getClippingPlanes, VEGETATION_TYPE_SIZE } from 'cityview';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BufferGeometry, DoubleSide, Material, Mesh, MeshStandardMaterial, Vector2 } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { EApplicationTool } from 'types/applicationPath';
import { XYZ } from 'types/location/coordinates';
import { ETerrainWidth, TTerrain } from 'types/terrain';
import { useSnapshot } from 'valtio';
import { TVegetationModels } from '../../hooks/useVegetationModels';
import store from '../../store';
import { addVegetation, createVegetationObject } from '../../store/mutations/landscape';
import { convertUuidToAngle } from './utils';

interface TemporaryVegetationProps {
  vegetationModels: TVegetationModels;
  terrain: TTerrain;
  terrainWidth: ETerrainWidth;
  visible: boolean;
}

const TemporaryVegetation = (props: TemporaryVegetationProps) => {
  const { vegetationModels, terrain, terrainWidth, visible } = props;

  const mouseRef = useRef<Vector2>(new Vector2(-100000, -100000));
  const meshRef = useRef<Mesh<BufferGeometry, Material>>(null);

  const [temporaryId, setTemporaryId] = useState(generateUUID());

  const { addVegetationType } = useSnapshot(store.landscape);

  const { gl, camera, raycaster, scene } = useThree();

  const { vegetationMaterial } = useMemo(() => {
    const vegetationMaterial = vegetationModels[EVegetationType.BIRCH].material.clone() as MeshStandardMaterial;
    vegetationMaterial.side = DoubleSide;
    vegetationMaterial.clippingPlanes = getClippingPlanes(terrainWidth);

    return { vegetationMaterial };
  }, [terrainWidth]);

  useEffect(() => {
    const canvas = gl.domElement;

    const handleMouseUp = (e: MouseEvent) => {
      if (meshRef.current) meshRef.current.visible = true;

      const { tool } = store.general.applicationPath;
      if (tool !== EApplicationTool.VEGETATION_ADD) return;

      const mouseUp = new Vector2((e.clientX / window.innerWidth) * 2 - 1, -(e.clientY / window.innerHeight) * 2 + 1);

      const mouseTravel = new Vector2().subVectors(mouseUp, mouseRef.current).length();

      if (mouseTravel > 0.003) return;

      raycaster.setFromCamera(mouseUp, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      const intersectsWithoutTempTree = intersects.filter(
        (intersect) =>
          intersect.object.name !== EObjectName.TEMPORARY_VEGETATION &&
          intersect.object.name !== EObjectName.TERRAIN_WIREFRAME,
      );

      if (intersectsWithoutTempTree[0] && intersectsWithoutTempTree[0].object.name === EObjectName.TERRAIN_SURFACE) {
        const point = intersectsWithoutTempTree[0].point;

        const vegetation = createVegetationObject({
          position: [point.x, point.y, point.z],
          height: VEGETATION_TYPE_SIZE[store.landscape.addVegetationType][1],
          diameter: VEGETATION_TYPE_SIZE[store.landscape.addVegetationType][0],
          type: store.landscape.addVegetationType,
        });

        addVegetation({
          ...vegetation,
          id: temporaryId,
        });

        setTemporaryId(generateUUID());
      }
    };

    const handleMouseDown = (e: MouseEvent) => {
      mouseRef.current.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouseRef.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
      if (meshRef.current) meshRef.current.visible = false;
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (store.general.applicationPath.tool !== EApplicationTool.VEGETATION_ADD || !meshRef?.current || !terrain)
        return;

      const hoverPosition = new Vector2(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1,
      );

      raycaster.setFromCamera(hoverPosition, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      const intersectsWithoutTempTree = intersects.filter(
        (intersect) =>
          intersect.object.name !== EObjectName.TEMPORARY_VEGETATION &&
          intersect.object.name !== EObjectName.TERRAIN_WIREFRAME,
      );

      if (intersectsWithoutTempTree[0] && intersectsWithoutTempTree[0].object.name === EObjectName.TERRAIN_SURFACE) {
        const point = intersectsWithoutTempTree[0].point;
        meshRef.current.position.set(point.x, point.y, point.z);
      } else {
        meshRef.current.position.set(-100000, -100000, -100000);
      }
    };

    canvas.addEventListener('pointerdown', handleMouseDown);
    canvas.addEventListener('pointerup', handleMouseUp);
    canvas.addEventListener('mousemove', handleMouseMove);

    return () => {
      canvas.removeEventListener('pointerdown', handleMouseDown);
      canvas.removeEventListener('pointerup', handleMouseUp);
      canvas.removeEventListener('mousemove', handleMouseMove);
    };
  }, [camera, raycaster, scene, temporaryId]);

  const { rotation, scale } = useMemo(() => {
    const rotation: XYZ = [0, 0, convertUuidToAngle(temporaryId)];
    const scale = VEGETATION_TYPE_SIZE[addVegetationType];

    return {
      rotation,
      scale: [scale[0], scale[0], scale[1]] as XYZ,
    };
  }, [addVegetationType, temporaryId]);

  if (!visible) return null;

  return (
    <mesh
      name={EObjectName.TEMPORARY_VEGETATION}
      ref={meshRef}
      castShadow={true}
      receiveShadow={true}
      material={vegetationMaterial}
      rotation={rotation}
      scale={scale}
      position={[-100000, -100000, -100000]}
      geometry={vegetationModels[addVegetationType].geometry}
    />
  );
};

export default TemporaryVegetation;
