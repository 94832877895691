import * as turf from '@turf/turf';
import cloneDeep from 'lodash/cloneDeep';
import { TFootprint } from 'types/building/Building';
import { XY } from 'types/location/coordinates';
import { distanceBetweenPoints } from '../components/Building/utils';
import { isValidBuildingFootprint } from './footprintValidation';
import { getBufferedPolygonCoordinates } from './vector';

export const bufferFootprint = (footprint: TFootprint, buffer: number, validate?: boolean): TFootprint => {
  const polygonCoordinates = [...footprint, footprint[0]];

  const bufferedCoordinates = getBufferedPolygonCoordinates(polygonCoordinates, buffer);

  const simplifiedFootprint = simplifyFootprint(bufferedCoordinates, 1);

  if (validate) {
    const { isValid, errors } = isValidBuildingFootprint(simplifiedFootprint);
    if (!isValid) {
      throw new Error(`bufferFootprint: Invalid footprint has errors: ${errors.join(', ')}`);
    }
  }

  return simplifiedFootprint;
};

export const extrudeFootprintWall = (footprint: TFootprint, sideIndex: number, diff = [0, 0]): TFootprint => {
  const newFootprint = cloneDeep(footprint);

  const indexOne = (sideIndex + 1) % newFootprint.length;
  const prevIndex = (indexOne - 1 + newFootprint.length) % newFootprint.length;
  newFootprint.splice(indexOne, 0, [newFootprint[prevIndex][0] + diff[0], newFootprint[prevIndex][1] + diff[1]]);

  const indexTwo = (indexOne + 1) % newFootprint.length;
  newFootprint.splice(indexTwo, 0, [newFootprint[indexTwo][0] + diff[0], newFootprint[indexTwo][1] + diff[1]]);

  return newFootprint;
};

export const areTwoFootprintsIdentical = (footprintA: TFootprint, footprintB: TFootprint): boolean => {
  if (footprintA.length !== footprintB.length) return false;

  const areDifferent = footprintA.some((coordinatesA, index) => {
    const coordinatesB = footprintB[index];

    return coordinatesA[0] !== coordinatesB[0] || coordinatesA[1] !== coordinatesB[1];
  });

  return !areDifferent;
};

export const simplifyFootprint = (
  footprint: TFootprint,
  tolerance: number,
  coordinatesType: 'local' | 'latLng' | undefined = 'local',
): TFootprint => {
  let prevPoint: XY = footprint[0];
  let point: XY = [0, 0];

  const newPoints: XY[] = [prevPoint];

  for (let i = 1; i < footprint.length; i++) {
    point = footprint[i];

    const distance =
      coordinatesType === 'local'
        ? distanceBetweenPoints(point, prevPoint)
        : turf.distance(point, prevPoint, { units: 'meters' });

    if (distance > tolerance) {
      newPoints.push(point);
      prevPoint = point;
    }
  }

  return newPoints;
};
