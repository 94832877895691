import { EObjectType, store } from 'cityview';
import {
  EApplicationMode,
  EApplicationOverrideTool,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  IApplicationPath,
} from 'types/applicationPath';
import { IBufferConstructionSettings } from 'types/building/BuildingBuffers';
import { snapshot } from 'valtio';
import { clearAllSelections, clearBuildingChildrenSelection, deselectType } from './selections';

export const setCityViewBufferConstructionSettings = (settings: IBufferConstructionSettings) => {
  store.buffers.bufferConstructionSettings = settings;
};

export const setCityViewClippingEnabled = (active: boolean) => {
  store.settings.clipping.active = active;
};

export const setCityViewClippingConstant = (constant: number) => {
  store.settings.clipping.constant = constant;
};

export const setCityViewClippingAngle = (angle: number) => {
  store.settings.clipping.angle = angle;
};

export const setCityViewCornerSnappingEnabled = (active: boolean) => {
  store.settings.cornerSnapping.active = active;
};

export const setCityViewCornerSnappingThreshold = (threshold: number) => {
  store.settings.cornerSnapping.threshold = threshold;
};

export const setOffsetTerrainActive = (active: boolean) => {
  store.settings.offsetTerrain.active = active;
};

export const setOffsetTerrainHeightFromTerrain = (height: number | null) => {
  store.settings.offsetTerrain.heightFromTerrain = height;
};

export const setOverrideTool = (overrideTool: EApplicationOverrideTool) => {
  store.general.overrideTool = overrideTool;
};

export const setApplicationPath = (path: IApplicationPath) => {
  const currentPath = snapshot(store.general.applicationPath);
  store.general.applicationPath.step = path.step;
  store.general.applicationPath.topic = path.topic;
  store.general.applicationPath.mode = path.mode;
  store.general.applicationPath.tool = path.tool;
  store.general.applicationPath.selectionFilter = path.selectionFilter;

  triggerPathChangeEffects(currentPath, path);
};

const triggerPathChangeEffects = (currentPath: IApplicationPath, newPath: IApplicationPath) => {
  const stepHasChanged = currentPath.step !== newPath.step;
  const topicHasChanged = currentPath.topic !== newPath.topic;
  const modeHasChanged = currentPath.mode !== newPath.mode;
  const toolHasChanged = currentPath.tool !== newPath.tool;
  const filterHasChanged = currentPath.selectionFilter !== newPath.selectionFilter;

  if (
    (stepHasChanged || topicHasChanged || filterHasChanged) &&
    (currentPath.step === EApplicationStep.DESIGN || currentPath.step === EApplicationStep.LOCATION)
  ) {
    clearAllSelections();
  }

  if (modeHasChanged && currentPath.topic === EApplicationTopic.FORM && newPath.mode === EApplicationMode.VIEW) {
    clearBuildingChildrenSelection();
    deselectType(EObjectType.PARKING_LOT);
    deselectType(EObjectType.VEGETATION);
  }

  if (modeHasChanged && currentPath.topic === EApplicationTopic.TERRAIN && currentPath.mode === EApplicationMode.EDIT) {
    deselectType(EObjectType.TERRAIN_POINT);
  }

  if (modeHasChanged && currentPath.topic === EApplicationTopic.FORM && currentPath.mode === EApplicationMode.BUFFER) {
    deselectType(EObjectType.BUFFER);
  }

  if (modeHasChanged && currentPath.topic === EApplicationTopic.FORM && newPath.mode === EApplicationMode.BUFFER) {
    deselectType(EObjectType.TRANSFORM_CORNER);
    deselectType(EObjectType.TRANSFORM_WALL);
    deselectType(EObjectType.FLOOR);
  }

  if (modeHasChanged && currentPath.topic === EApplicationTopic.FORM && newPath.mode === EApplicationMode.BUFFER) {
    deselectType(EObjectType.PARKING_LOT);
    deselectType(EObjectType.VEGETATION);
    clearBuildingChildrenSelection();
  }

  if (toolHasChanged && currentPath.topic === EApplicationTopic.FORM && currentPath.tool === EApplicationTool.MODIFY) {
    deselectType(EObjectType.TRANSFORM_CORNER);
    deselectType(EObjectType.TRANSFORM_WALL);
  }

  if (
    toolHasChanged &&
    currentPath.topic === EApplicationTopic.FORM &&
    currentPath.mode === EApplicationMode.EDIT &&
    currentPath.tool === EApplicationTool.SELECT
  ) {
    deselectType(EObjectType.FLOOR);
  }

  if (
    toolHasChanged &&
    currentPath.topic === EApplicationTopic.LANDSCAPE &&
    currentPath.mode === EApplicationMode.EDIT &&
    currentPath.tool === EApplicationTool.VEGETATION_SELECT
  ) {
    deselectType(EObjectType.VEGETATION);
  }
};
