import { IBuilding } from 'types/building/Building';
import { EFaceType, IWallFace, TFace } from 'types/building/Face';
import { IFloor } from 'types/building/Floor';
import buildingStore from '../buildingStore';
import { getFloorById } from './floor';

export const getFloorWalls = (buildingId: string, floorId: string): IWallFace[] | undefined => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  const floorWalls = floor.faces.filter((face) => face.type === EFaceType.WALL);
  if (!floorWalls.length) return;

  return floorWalls as IWallFace[];
};

export const getBuildingFloorWallById = (buildingId: string, floorId: string, wallId: string): IWallFace | undefined =>
  getFloorWalls(buildingId, floorId)?.find((wall) => wall.id === wallId && wall.type === EFaceType.WALL);

export const getBuildingFloorWall = (floor: IFloor, faceId: string): IWallFace | undefined => {
  const floorWall = floor.faces.find((face) => face.id === faceId && face.type === EFaceType.WALL);
  if (!floorWall) return;

  return floorWall as IWallFace;
};

export const getWallLength = (wall: IWallFace): number => {
  const {
    coordinates: [[x1, y1], [x2, y2]],
  } = wall;

  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getNextWall = (walls: IWallFace[], wall: IWallFace) => {
  if (wall.order === walls.length) {
    return walls.find((item) => item.order === 1) as IWallFace;
  } else {
    return walls.find((item) => item.order === wall.order + 1) as IWallFace;
  }
};

export const getPreviousWall = (walls: IWallFace[], wall: IWallFace) => {
  if (wall.order === 1) {
    return walls.find((item) => item.order === walls.length) as IWallFace;
  } else {
    return walls.find((item) => item.order === wall.order - 1) as IWallFace;
  }
};

export const getFaceById = (faceId: string): TFace | undefined => {
  const buildings: IBuilding[] = Object.values(buildingStore.value.projectBuildings);

  for (const building of buildings) {
    const floors = Object.values(building.floors);
    for (const floor of floors) {
      const face = floor.faces.find((face) => face.id === faceId);
      if (face) return face;
    }
  }
};

export const getFloorWallsByFaceId = (faceId: string) => {
  const buildings: IBuilding[] = Object.values(buildingStore.value.projectBuildings);
  for (const building of buildings) {
    const floors = Object.values(building.floors);
    for (const floor of floors) {
      const face = floor.faces.find((face) => face.id === faceId);
      if (face && face.type === EFaceType.WALL)
        return {
          buildingId: building.id,
          floorId: floor.id,
          walls: floor.faces.filter((face) => face.type === EFaceType.WALL),
          wall: face,
        };
    }
  }
};
