import chroma from 'chroma-js';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { Sky as SkyImpl } from 'three-stdlib';
import { ILngLat } from "types/location/coordinates";
import store from '../../store';
import { getSunCoordinates } from '../../utils/sun';

export interface EnvironmentProps {
  showSky?: boolean;
  coordinates: ILngLat;
}

const { daylightMonth, daylightMinutes } = store.animations;

const BaseEnvironment = (props: EnvironmentProps) => {
  const { coordinates, showSky } = props;

  const lightRef = useRef<THREE.DirectionalLight>(null);
  const ambientRef = useRef<THREE.AmbientLight>(null);

  const skyRef = useRef<SkyImpl>(null);

  const animateLight = () => {
    if (!lightRef.current) return;

    const progress = daylightMinutes.calculateProgress();

    const position = getSunCoordinates(
      Math.round(daylightMonth.getValue()),
      Math.round(daylightMinutes.getValue()),
      coordinates.lat,
      coordinates.lng,
      500,
    );

    lightRef.current.position.set(position.x, position.z, position.y);

    const sunArc = Math.sin(progress * Math.PI);
    const colorArc = Math.pow(sunArc, 0.04);
    const colorCoef = 1 - Math.max(1 - sunArc - 0.6, 0) / 0.4;
    lightRef.current.intensity = 0.05 + 3 * colorArc;
    lightRef.current.color = new THREE.Color(
      chroma.rgb(255, progress < 0.5 ? 150 + 105 * colorCoef : 89 + 166 * colorCoef, 255 * colorCoef).hex(),
    );

    if (!ambientRef.current) return;
    ambientRef.current.intensity = 0.01 + 1.2 * colorArc;

    if (!skyRef.current) return;

    const sunPosition = getSunCoordinates(
      daylightMonth.getValue(),
      Math.round(daylightMinutes.getValue()),
      coordinates.lat,
      coordinates.lng,
      500,
    );

    // @ts-ignore
    skyRef.current.material.uniforms.sunPosition.value = [sunPosition.x, sunPosition.z, sunPosition.y];
  };

  useEffect(() => {
    daylightMonth.onChange(() => {
      animateLight();
    });

    daylightMinutes.onChange(() => {
      animateLight();
    });

    animateLight();
  }, [coordinates, showSky]);

  return (
    <>
      <directionalLight
        ref={lightRef}
        intensity={0.65}
        color='#FFFFFF'
        castShadow={true}
        shadow-mapSize-height={2048 * 2}
        shadow-mapSize-width={2048 * 2}
        shadow-bias={-0.001}
        shadow-camera-near={0}
        shadow-camera-far={1000}
        shadow-camera-top={500}
        shadow-camera-right={500}
        shadow-camera-left={-500}
        shadow-camera-bottom={-500}
      />
      <ambientLight ref={ambientRef} args={[0xffffff]} intensity={0.25} />
      <hemisphereLight groundColor='#FAFAFA' intensity={0.1} />
      {/*{showSky && (*/}
      {/*  <Sky*/}
      {/*    ref={skyRef}*/}
      {/*    distance={450000}*/}
      {/*    azimuth={180}*/}
      {/*    mieCoefficient={0.005}*/}
      {/*    mieDirectionalG={0.7}*/}
      {/*    turbidity={10}*/}
      {/*    rayleigh={3}*/}
      {/*    material-uniforms-up-value={[0, 0, 1]}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};

const Environment = React.memo(BaseEnvironment);

export default Environment;
