import { useThree } from '@react-three/fiber';
import { EObjectName } from 'cityview';
import { useEffect, useRef } from 'react';
import { Vector2 } from 'three';
import { EApplicationOverrideTool, EApplicationTool, EApplicationTopic } from 'types/applicationPath';
import store from '../../store';
import { setOverrideTool } from '../../store/mutations/general';
import { clearAllSelections } from '../../store/mutations/selections';

const RaycasterListener = () => {
  const { gl, scene, raycaster } = useThree();

  const mouseRef = useRef<Vector2>(new Vector2(-100000, -100000));

  useEffect(() => {
    const canvas = gl.domElement;

    const handlePointerDown = (e: MouseEvent) => {
      mouseRef.current.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouseRef.current.y = -(e.clientY / window.innerHeight) * 2 + 1;
    };

    const handlePointerUp = (e: MouseEvent) => {
      const mouseUp = new Vector2((e.clientX / window.innerWidth) * 2 - 1, -(e.clientY / window.innerHeight) * 2 + 1);
      const mouseTravel = new Vector2().subVectors(mouseUp, mouseRef.current).length();
      if (mouseTravel > 0.003) return;
      if (store.general.applicationPath.tool === EApplicationTool.TERRAIN_RECTANGLE_SELECT) return;

      const intersects = raycaster
        .intersectObjects(scene.children, true)
        .filter(
          (intersect) =>
            intersect.object.name &&
            !['XYZE', 'X', 'Y', 'Z', EObjectName.TERRAIN_WIREFRAME].includes(intersect.object.name),
        );

      if (intersects.length) {
        const firstIntersect = intersects[0];

        const terrainWidth = store.settings.terrain.width;
        const halfTerrainWidth = terrainWidth / 2;
        const objectName = firstIntersect.object.name as EObjectName;

        if ([EObjectName.ACTIVE_VEGETATION, EObjectName.RESTORABLE_VEGETATION].includes(objectName)) {
          if (store.general.applicationPath.topic === EApplicationTopic.LANDSCAPE) {
            if (
              Math.abs(firstIntersect.point.x) > halfTerrainWidth &&
              Math.abs(firstIntersect.point.y) > halfTerrainWidth
            ) {
              setOverrideTool(EApplicationOverrideTool.NONE);
              clearAllSelections();
            }
          } else {
            setOverrideTool(EApplicationOverrideTool.NONE);
            clearAllSelections();
          }
        } else if (
          [EObjectName.TERRAIN_SURFACE, EObjectName.GROUND_BOTTOM, EObjectName.EXISTING_BUILDING].includes(objectName)
        ) {
          setOverrideTool(EApplicationOverrideTool.NONE);
          clearAllSelections();
        }
      } else if (!store.mouse.isMouseOnGizmoDown) {
        setOverrideTool(EApplicationOverrideTool.NONE);
        clearAllSelections();
      }
    };

    canvas.addEventListener('pointerdown', handlePointerDown);
    canvas.addEventListener('pointerup', handlePointerUp);

    return () => {
      canvas.removeEventListener('pointerdown', handlePointerDown);
      canvas.removeEventListener('pointerup', handlePointerUp);
    };
  }, []);

  return null;
};

export default RaycasterListener;
