import { createEvent } from 'react-event-hook';

interface BuildingMoveEvent {
  buildingId: string;
}

interface HoverEvent {
  segmentIds?: string[];
  faceIds?: string[];
  floorIds?: string[];
  buildingId?: string;
}

export const { useBuildingMoveListener, emitBuildingMove } = createEvent('building-move')<BuildingMoveEvent>();

export const { useFacadeChangeListener, emitFacadeChange } = createEvent('facade-change')<void>();

export const { useRoofChangeListener, emitRoofChange } = createEvent('roof-change')<void>();

export const { useSegmentHoverListener, emitSegmentHover } = createEvent('segment-hover')<HoverEvent>();
export const { useSegmentHoverEndListener, emitSegmentHoverEnd } = createEvent('segment-hover-end')();
