import chroma from 'chroma-js';

export const housePalette = {
  teal: {
    100: '#98B4C3',
    200: '#7299AC',
    300: '#507687',
    400: '#3C5967',
    500: '#263840',
  },
  violet: {
    100: '#9152CB',
    200: '#7D38BC',
    300: '#662E9B',
    400: '#53267E',
    500: '#3E1C5E',
  },
  yellow: {
    100: '#FBD960',
    200: '#FAD038',
    300: '#F9C80E',
    400: '#DBAD06',
    500: '#B38D05',
  },
  blue: {
    100: '#50A3E2',
    200: '#2286D3',
    300: '#1A659E',
    400: '#114369',
    500: '#002E52',
  },
  red: {
    100: '#FFC3AD',
    200: '#FF9670',
    300: '#FF6B35',
    400: '#F54100',
    500: '#B83100',
  },
};

export enum EDefaultFacadePalette {
  STANDARD_WHITE = '#F7F7F7',
  STANDARD_GREY = '#EBEBEB',
  BEIGE_BRIGHT = '#D9D1C7',
  BEIGE_MID = '#B3AAA1',
  BEIGE_DARK = '#998073',
  GREY_BRIGHT = '#B6B6B6',
  GREY_MID = '#A5A5A5',
  GREY_DARK = '#8D8D8D',
  ANTHRACITE = '#403F3F',
  YELLOW_GRAY = '#B3B2A8',
  DARK_GREEN = '#3B3F2F',
  BLUE_GREY_MID = '#7A7E81',
  BLUE_GREY_DARK = '#5D6263',
  STANDARD_GLASS = '#c3d7db', // Glass color is lower case. All solid colors are upper case
}

export const canvasTheme = {
  housePalette,
  terrainColor: '#ECEFF7',
  gridViewBackgroundColor: '#222222',
  gridLineColor: '#bfbfbf',
  gridLineColorDark: '#8f8f8f',
  transformControlColor: '#0000ff',
  buildingBufferColorDark: '#888888',
  buildingBufferColorLight: '#ebebeb',
  constructionLineColor: '#00b2ff',
  asphaltColor: chroma('#B7C0C2').darken(0.8).hex(),
  parkingLot: {
    outdoorLotLineColor: chroma('#dae5ff').brighten(1).hex(),
    entranceMarkerColor: '#1778ff',
    parkingOvergroundColor: '#bfd4ff',
    parkingUndergroundColor: '#7b8eb7',
    parkingUsageColor: '#7ba0fc',
  },
  building: {
    colors: {
      defaultSurface: '#dae5ff',
      existingCorner: '#1778ff',
      existingCornerHover: '#ffff82',
      existingCornerSelected: '#ffff00',
      potentialCorner: '#4e5154',
      potentialCornerHover: '#1778ff',
      demolishedBuilding: '#ffd65c',
      existingBuilding: '#eceff7',
      terrainEdit: '#c4d5fd',
      facade: {
        palette: [
          EDefaultFacadePalette.STANDARD_WHITE,
          EDefaultFacadePalette.STANDARD_GREY,
          '#EDE6DF',
          EDefaultFacadePalette.BEIGE_BRIGHT,
          EDefaultFacadePalette.BEIGE_MID,
          EDefaultFacadePalette.BEIGE_DARK,
          '#594C3E',
          '#9E4B3F',
          EDefaultFacadePalette.GREY_BRIGHT,
          EDefaultFacadePalette.GREY_MID,
          EDefaultFacadePalette.GREY_DARK,
          EDefaultFacadePalette.BLUE_GREY_MID,
          EDefaultFacadePalette.BLUE_GREY_DARK,
          EDefaultFacadePalette.ANTHRACITE,
          EDefaultFacadePalette.YELLOW_GRAY,
          '#6B705C',
        ],
        wall: chroma.rgb(0.925 * 255, 0.937 * 255, 0.969 * 255).hex(),
        ugWall: chroma
          .rgb(0.925 * 255, 0.937 * 255, 0.969 * 255)
          .darken(0.5)
          .hex(),
      },
    },
    defaultWallOpacity: 0.9,
    floorOpacity: 1,
  },
} as const;

// const library = {
//   tileThemes: [
//     {
//       name: 'My theme',
//       tilePresets: [],
//     },
//   ],
//   materialThemes: [
//     {
//       name: 'My theme',
//       materials: Record<string, ISegmentMaterial>,
//       wallMaterials: [],
//       wallMaterials: [],
//       roofMaterials: [],
//       wallMaterials: [],
//       roofMaterials: [],
//       wallMaterials: [],
//       roofMaterials: [],
//       roofMaterials: [],
//     },
//   ],
// };
