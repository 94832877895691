import { generateFullPaths } from 'utils/router';

interface IShopPaths {
  SHOP: {
    ROOT: string;
    PROJECT: {
      ROOT: string;
      FULL_PRODUCT: string;
      SEPARATE_PRODUCTS: string;
    };
    INTEGRATIONS: {
      ROOT: string;
      OVERVIEW: {
        ROOT: string;
      };
      FPRE: {
        ROOT: string;
        FULL_PRODUCT: string;
        SEPARATE_PRODUCTS: string;
      };
      KEEVALUE: {
        ROOT: string;
        FULL_PRODUCT: string;
      };
    };
  };
  BILLING_DETAILS: {
    ROOT: string;
  };
  CHECKOUT: {
    ROOT: string;
  };
}

const SHOP_PATHS: IShopPaths = {
  SHOP: {
    ROOT: 'shop',
    PROJECT: {
      ROOT: 'project',
      FULL_PRODUCT: 'full-product',
      SEPARATE_PRODUCTS: 'separate-products',
    },
    INTEGRATIONS: {
      ROOT: 'integrations',
      OVERVIEW: {
        ROOT: 'overview',
      },
      FPRE: {
        ROOT: 'fpre',
        FULL_PRODUCT: 'full-product',
        SEPARATE_PRODUCTS: 'separate-products',
      },
      KEEVALUE: {
        ROOT: 'keevalue',
        FULL_PRODUCT: 'full-product',
      },
    },
  },
  BILLING_DETAILS: {
    ROOT: 'billing-details',
  },
  CHECKOUT: {
    ROOT: 'checkout',
  },
};

const FULL_SHOP_PATHS = generateFullPaths(SHOP_PATHS);

export { SHOP_PATHS, FULL_SHOP_PATHS };
