import { EObjectType } from 'cityview';
import { terrainStore } from 'cityview/store/index';
import { EApplicationOverrideTool } from 'types/applicationPath';
import { IBufferConstructionSettings } from 'types/building/BuildingBuffers';
import { TTerrain } from 'types/terrain';
import { Nullable } from 'types/util';
import store from '../store';
import { getBuildingFacadeSegments } from './building';

export const getBufferConstructionSettings = (): Nullable<IBufferConstructionSettings> =>
  store.buffers.bufferConstructionSettings;

export const getSelectedObjectIdsByType = (type: EObjectType): string[] =>
  Object.entries(store.selections.selectedObjects).reduce((acc, [id, objectType]) => {
    if (objectType === type) acc.push(id);
    return acc;
  }, [] as string[]);

export const isObjectSelected = (id: string): boolean => !!store.selections.selectedObjects[id];

export const hasSelectedObjectsOfType = (type: EObjectType): boolean =>
  Object.values(store.selections.selectedObjects).includes(type);

export const isAnyObjectSelected = (objects: string[]): boolean => objects.some((id) => isObjectSelected(id));

export const isOnlySelectedObjectOfType = (id: string, type: EObjectType): boolean => {
  if (!isObjectSelected(id)) return false;
  return store.selections.selectedObjects[id] === type && !hasSelectedObjectsOfType(type);
};

export const areAllObjectsSelected = (ids: string[]): boolean => ids.every((id) => isObjectSelected(id));

export const hasSelectedFacadeSegmentsInBuilding = (buildingId: string): boolean => {
  const buildingSegments = getBuildingFacadeSegments(buildingId);
  return isAnyObjectSelected(buildingSegments.map((segment) => segment.id));
};

export const getIsFacadeSettingsPickerActive = (): boolean => {
  return [
    EApplicationOverrideTool.FACADE_WALL_LAYOUT_SETTINGS_PICKER,
    EApplicationOverrideTool.FACADE_MATERIAL_SETTINGS_PICKER,
    EApplicationOverrideTool.FACADE_BALCONY_SETTINGS_PICKER,
  ].includes(store.general.overrideTool);
};

export const getTerrain = (): TTerrain | undefined => terrainStore.value.originalTerrain.terrainData;
