import { useSnapshot } from 'valtio';
import { derivedStore } from '../store/store';

const useObjectSelections = () => {
  const { selectedObjects } = useSnapshot(derivedStore);

  return selectedObjects;
};

export default useObjectSelections;
