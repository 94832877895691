import { OrthographicCamera } from '@react-three/drei';
import { useFrame, useThree } from '@react-three/fiber';
import * as React from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { Group, Matrix4, Object3D, OrthographicCamera as ThreeOrthographicCamera, Quaternion, Vector3 } from 'three';

import Hud from './Hud';

interface GizmoHelperContext {
  tweenCamera: (direction: Vector3) => void;
}

const Context = React.createContext<GizmoHelperContext>({} as GizmoHelperContext);

export const useGizmoContext = () => useContext<GizmoHelperContext>(Context);

const dummy = new Object3D();
const matrix = new Matrix4();
const targetRotation = new Quaternion();
const target = new Vector3();
const targetPosition = new Vector3();

type ControlsProto = { update(): void; target: Vector3 };

export type GizmoHelperProps = JSX.IntrinsicElements['group'] & {
  alignment?:
    | 'top-left'
    | 'top-right'
    | 'bottom-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'center-right'
    | 'center-left'
    | 'center-center'
    | 'top-center';
  margin?: [number, number];
  renderPriority?: number;
  autoClear?: boolean;
  onUpdate?: () => void; // update controls during animation
  // TODO: in a new major state.controls should be the only means of consuming controls, the
  // onTarget prop can then be removed!
  onTarget?: () => Vector3; // return the target to rotate around
};

const GizmoHelper = (props: GizmoHelperProps) => {
  const { alignment = 'bottom-right', margin = [80, 80], renderPriority = 1, onTarget, children } = props;

  const size = useThree((state) => state.size);
  const mainCamera = useThree((state) => state.camera);
  // @ts-ignore
  const defaultControls = useThree((state) => state.controls) as ControlsProto;
  const invalidate = useThree((state) => state.invalidate);
  const gizmoRef = useRef<Group>(null);
  const virtualCam = useRef<ThreeOrthographicCamera>(null);

  const animating = useRef(false);
  const radius = useRef(0);
  const focusPoint = useRef(new Vector3(0, 0, 0));
  const defaultUp = useRef(new Vector3(0, 0, 0));

  useEffect(() => {
    defaultUp.current.copy(mainCamera.up);
  }, [mainCamera]);

  const tweenCamera = useCallback(
    (direction: Vector3) => {
      animating.current = true;
      if (defaultControls || onTarget) focusPoint.current = defaultControls?.target || onTarget?.();
      radius.current = mainCamera.position.distanceTo(target);

      targetPosition.copy(direction).multiplyScalar(radius.current).add(target);
      dummy.lookAt(targetPosition);
      targetRotation.copy(dummy.quaternion);

      // If the direction is up/down, we'll make sure the camera is always oriented parallel to North
      if (direction.x === 0 && direction.y === 0 && direction.z === 1) {
        targetRotation.copy(new Quaternion(0, 0, 0, 1));
      } else if (direction.x === 0 && direction.y === 0 && direction.z === -1) {
        targetRotation.copy(new Quaternion(0, 1, 0, 0));
      }

      mainCamera.position
        .set(0, 0, 1)
        .applyQuaternion(targetRotation)
        .multiplyScalar(radius.current)
        .add(focusPoint.current);
      mainCamera.quaternion.copy(targetRotation);

      invalidate();
    },
    [defaultControls, mainCamera, onTarget, invalidate],
  );

  useFrame(() => {
    if (virtualCam.current && gizmoRef.current) {
      // Sync Gizmo with main camera orientation
      matrix.copy(mainCamera.matrix).invert();
      gizmoRef.current?.quaternion.setFromRotationMatrix(matrix);
    }
  });

  const gizmoHelperContext = useMemo(() => ({ tweenCamera }), [tweenCamera]);

  // Position gizmo component within scene
  const [marginX, marginY] = margin;
  const x = alignment.endsWith('-center')
    ? 0
    : alignment.endsWith('-left')
      ? -size.width / 2 + marginX
      : size.width / 2 - marginX;
  const y = alignment.startsWith('center-')
    ? 0
    : alignment.startsWith('top-')
      ? size.height / 2 - marginY
      : -size.height / 2 + marginY;

  return (
    <Hud renderPriority={renderPriority}>
      <Context.Provider value={gizmoHelperContext}>
        <OrthographicCamera makeDefault ref={virtualCam} position={[0, 0, 200]} />
        <group ref={gizmoRef} position={[x, y, 0]}>
          {children}
        </group>
      </Context.Provider>
    </Hud>
  );
};

export default GizmoHelper;
