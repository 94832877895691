import { ProjectIdRequest } from 'api/dtos/general/request';
import {
  GetKeeValueCalculationSettingsResponse,
  GetKeeValueSubscriptionAccessResponse,
  StartNewKeeValueCalculationResponse,
  StartNewKeeValueSubscriptionIntegrationResponse,
} from 'api/dtos/kee-value/response';
import { backendApi } from 'state/services/backend/api';
import { EBackendTag } from 'state/services/backend/types';
import { RequestMethod } from 'types/request';

export const keeValueApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    getKeeValueSubscriptionAccess: builder.query<GetKeeValueSubscriptionAccessResponse, void>({
      query: () => ({
        url: '/api/kee-value/subscription',
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.KEE_VALUE_SUBSCRIPTION_ACCESS],
    }),
    startNewKeeValueSubscriptionIntegration: builder.mutation<StartNewKeeValueSubscriptionIntegrationResponse, void>({
      query: () => ({
        url: '/api/kee-value/subscription',
        method: RequestMethod.POST,
      }),
    }),
    disconnectKeeValueSubscription: builder.mutation<void, void>({
      query: () => ({
        url: '/api/kee-value/subscription/disconnect',
        method: RequestMethod.POST,
      }),
      invalidatesTags: [EBackendTag.KEE_VALUE_SUBSCRIPTION_ACCESS],
    }),
    getKeeValueCalculationSettings: builder.query<GetKeeValueCalculationSettingsResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/kee-value/project/${projectId}/calculation-settings`,
        method: RequestMethod.GET,
      }),
      providesTags: [EBackendTag.KEE_VALUE_CALCULATION_SETTINGS],
    }),
    startNewKeeValueCalculation: builder.mutation<StartNewKeeValueCalculationResponse, ProjectIdRequest>({
      query: ({ projectId }) => ({
        url: `/api/kee-value/project/${projectId}/start-calculation`,
        method: RequestMethod.POST,
      }),
    }),
  }),
});

export const {
  useGetKeeValueSubscriptionAccessQuery,
  useStartNewKeeValueSubscriptionIntegrationMutation,
  useDisconnectKeeValueSubscriptionMutation,
  useGetKeeValueCalculationSettingsQuery,
  useStartNewKeeValueCalculationMutation,
} = keeValueApiSlice;

export const useGetKeeValueCalculationSettingsQueryState =
  keeValueApiSlice.endpoints.getKeeValueCalculationSettings.useQueryState;
