import * as THREE from 'three';

interface GridHelperProps {
  width: number;
  cellSize: number;
}

const FlatGridHelper = (props: GridHelperProps) => {
  const { width, cellSize } = props;
  const rows = [];
  const columns = [];

  for (let i = 0; i <= width / 2; i += cellSize) {
    rows.push(i);
    columns.push(i);
  }

  for (let i = -cellSize; i >= -width / 2; i -= cellSize) {
    rows.push(i);
    columns.push(i);
  }

  const geometry = new THREE.BoxGeometry(width, 0.25, 0);

  return (
    <>
      {rows.map((rowY) => (
        <mesh name={'flatGridRow'} geometry={geometry} position={[0, rowY, 0]} visible={true}>
          <meshStandardMaterial color={'#cfcfcf'} attach={'material'} transparent={true} />
        </mesh>
      ))}

      {columns.map((columnX) => (
        <mesh
          name={'flatGridColumn'}
          geometry={geometry}
          position={[columnX, 0, 0]}
          rotation={[0, 0, Math.PI / 2]}
          visible={true}
        >
          <meshStandardMaterial color={'#cfcfcf'} attach={'material'} transparent={true} />
        </mesh>
      ))}
    </>
  );
};

export default FlatGridHelper;
