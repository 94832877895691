import { TransformControls } from '@react-three/drei';
import { useGesture } from '@use-gesture/react';
import { emitBuildingMove } from 'cityview';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { TransformControls as TransformControlsImpl } from 'three-stdlib';
import { XY } from "types/location/coordinates";
import { subscribeKey } from 'valtio/utils';
import store from '../../store';
import { setIsMouseDown, setIsMouseEditing } from '../../store/mutations/mouse';
import { getBuilding, getBuildingHeight, getBuildingZ } from '../../store/selectors/building';
import {
  getBuildingCenter,
  getBuildingTransformBoxSize,
  rotateAroundOrigin,
  setTransformControlsColor,
} from '../Building/utils';
import { IBuilding } from 'types/building/Building';

const floorPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

const onMeshUpdate = (self: THREE.Mesh) => (self.rotation.order = 'ZXY');

interface BuildingTransformerProps {
  building: IBuilding;
  handleUpdatePosition: (position: THREE.Vector3) => void;
  handleUpdateRotation: (rotation: THREE.Euler) => void;
  handleElevationChange: (diff: number) => void;
  showMesh?: boolean;
  intersectObject: THREE.Group | THREE.Mesh | null;
}

const BuildingTransformer = (props: BuildingTransformerProps) => {
  const { building, handleUpdatePosition, handleUpdateRotation, handleElevationChange, intersectObject } = props;

  const buildingId = building.id;

  // TODO: This component needs refactoring

  const objectRef = useRef<THREE.Mesh>(null!);
  const elevationControlsRef = useRef<TransformControlsImpl>(null!);
  const rotationControlsRef = useRef<TransformControlsImpl>(null!);
  const isElevationTransformActiveRef = useRef<boolean>(false);
  const isRotationTransformActiveRef = useRef<boolean>(false);
  const isDraggingTransformActiveRef = useRef<boolean>(false);
  const objectStartPointRef = useRef<THREE.Vector3>(new THREE.Vector3());
  const planeIntersectPointRef = useRef<THREE.Vector3>(new THREE.Vector3());
  const planeIntersectStartPointRef = useRef<THREE.Vector3>(new THREE.Vector3());
  const isIntersectingObjectRef = useRef<boolean>(false);
  const intersectionRayRef = useRef<THREE.Raycaster>(new THREE.Raycaster());
  const rotationRef = useRef<THREE.Euler>(new THREE.Euler());

  const buildingCenterRef = useRef<XY>([0, 0]);
  const buildingZRef = useRef<number>(0);
  const buildingHeightRef = useRef<number>(0);

  /*
   * Reset the controller positions when component re-renders with new building data
   * Fixes the building flickering after position/rotation change
   */
  if (!isDraggingTransformActiveRef.current && intersectObject) {
    intersectObject.position.set(0, 0, 0);
    intersectObject.rotation.set(0, 0, 0);
    if (objectRef.current) {
      rotationRef.current.set(0, 0, objectRef.current.rotation.z);
    }
  }

  const bind = useGesture(
    {
      onDrag: ({ active, timeStamp, event }) => {
        if (
          active &&
          objectRef.current &&
          !isElevationTransformActiveRef.current &&
          !isRotationTransformActiveRef.current &&
          isIntersectingObjectRef.current &&
          !store.mouse.isPersistingChanges
        ) {
          // @ts-ignore
          event.ray.intersectPlane(floorPlane, planeIntersectPointRef.current);
          objectRef.current.position.set(
            objectStartPointRef.current.x - (planeIntersectStartPointRef.current.x - planeIntersectPointRef.current.x),
            objectStartPointRef.current.y - (planeIntersectStartPointRef.current.y - planeIntersectPointRef.current.y),
            objectStartPointRef.current.z,
          );
        }

        onPositionChange();

        return timeStamp;
      },
      onDragStart: ({ event, cancel }) => {
        if (
          !store.mouse.isPersistingChanges &&
          !isElevationTransformActiveRef.current &&
          !isRotationTransformActiveRef.current &&
          intersectObject
        ) {
          handleCacheBuildingData();
          setIsMouseDown(true);
          setIsMouseEditing(true);
          isDraggingTransformActiveRef.current = true;

          // @ts-ignore
          const { origin, direction } = event.ray;
          intersectionRayRef.current.set(origin, direction);
          isIntersectingObjectRef.current = !!intersectionRayRef.current.intersectObject(intersectObject, true).length;

          if (!isIntersectingObjectRef.current) {
            setIsMouseDown(false);
            cancel();
            return;
          }

          if (objectRef.current) {
            objectStartPointRef.current.set(
              objectRef.current.position.x,
              objectRef.current.position.y,
              objectRef.current.position.z,
            );
            // @ts-ignore
            event.ray.intersectPlane(floorPlane, planeIntersectStartPointRef.current);
          }
        }
      },
      onDragEnd: () => {
        if (
          !store.mouse.isPersistingChanges &&
          !isElevationTransformActiveRef.current &&
          !isRotationTransformActiveRef.current
        ) {
          isDraggingTransformActiveRef.current = false;
          handleUpdateBuilding();
          setIsMouseDown(false);
          setIsMouseEditing(false);
          isIntersectingObjectRef.current = false;
        }
        emitBuildingMove({ buildingId });
      },
    },
    {},
  );

  const handleUpdateBuilding = () => {
    if (objectRef.current && intersectObject && store.mouse.isMouseDown) {
      handleUpdatePosition(objectRef.current.position);
    }
  };

  const handleCacheBuildingData = () => {
    const building = getBuilding(buildingId);
    if (!building) return;

    buildingCenterRef.current = getBuildingCenter(building);
    buildingHeightRef.current = getBuildingHeight(building);
    buildingZRef.current = objectRef.current.position.z;
    rotationRef.current = new THREE.Euler(0, 0, objectRef.current.rotation.z);
  };

  const onPositionChange = () => {
    if (
      objectRef.current &&
      intersectObject &&
      store.mouse.isMouseDown &&
      !isElevationTransformActiveRef.current &&
      !isRotationTransformActiveRef.current
    ) {
      intersectObject.position.set(
        objectRef.current.position.x - buildingCenterRef.current[0],
        objectRef.current.position.y - buildingCenterRef.current[1],
        0,
      );
    }
  };

  const onElevationMouseDown = () => {
    // @ts-ignore
    rotationControlsRef.current.enabled = false;
    isDraggingTransformActiveRef.current = false;

    setIsMouseDown(true);
    isElevationTransformActiveRef.current = true;
    handleCacheBuildingData();
  };

  const onElevationMouseUp = () => {
    if (objectRef.current && intersectObject && store.mouse.isMouseDown) {
      handleElevationChange(objectRef.current.position.z - buildingZRef.current);
    }

    setIsMouseDown(false);

    if (rotationControlsRef.current) {
      // @ts-ignore
      rotationControlsRef.current.enabled = true;
    }

    emitBuildingMove({ buildingId });
  };

  const onElevationChange = () => {
    if (
      objectRef.current &&
      intersectObject &&
      store.mouse.isMouseDown &&
      buildingHeightRef.current &&
      !isDraggingTransformActiveRef.current &&
      !isRotationTransformActiveRef.current
    ) {
      intersectObject.position.set(0, 0, objectRef.current.position.z - buildingZRef.current);
    }
  };

  useEffect(() => {
    if (!elevationControlsRef.current) return;

    const { current: control } = elevationControlsRef;

    setTransformControlsColor(elevationControlsRef.current);

    const callback = (event: THREE.Event) => {
      const value = 'value' in event && !!event.value;

      if (value) {
        onElevationMouseDown();
      } else {
        onElevationMouseUp();
      }

      isElevationTransformActiveRef.current = value;
      setIsMouseDown(value);
    };

    control.addEventListener('dragging-changed', callback);

    return () => {
      control.removeEventListener('dragging-changed', callback);
    };
  }, [building]);

  const onRotationMouseDown = () => {
    // @ts-ignore
    elevationControlsRef.current.enabled = false;
    isDraggingTransformActiveRef.current = false;

    setIsMouseDown(true);
    setIsMouseEditing(true);
    isRotationTransformActiveRef.current = true;
    handleCacheBuildingData();
  };

  const onRotationMouseUp = () => {
    if (objectRef.current && intersectObject && store.mouse.isMouseDown) {
      const rotationDiff = new THREE.Euler(0, 0, objectRef.current.rotation.z - rotationRef.current.z);
      handleUpdateRotation(rotationDiff);

      setIsMouseDown(false);
      setIsMouseEditing(false);
    }

    // @ts-ignore
    elevationControlsRef.current.enabled = true;
  };

  const onRotationChange = () => {
    if (objectRef.current && intersectObject && store.mouse.isMouseDown && !isDraggingTransformActiveRef.current) {
      const rotationDifference = objectRef.current.rotation.z - rotationRef.current.z;

      const building = getBuilding(buildingId);
      if (!building) return;

      const buildingCenter = getBuildingCenter(building);
      if (!buildingCenter) return;

      const [centerX, centerY] = buildingCenter;
      const [bx, by] = rotateAroundOrigin([centerX, centerY], rotationDifference);

      intersectObject.rotation.set(0, 0, rotationDifference);
      intersectObject.position.set(centerX - bx, centerY - by, 0);
    }
  };

  useEffect(() => {
    if (!rotationControlsRef.current) return;
    const { current: control } = rotationControlsRef;

    setTransformControlsColor(rotationControlsRef.current);

    const callback = (event: THREE.Event) => {
      const value = 'value' in event && !!event.value;

      if (value) {
        onRotationMouseDown();
      } else {
        onRotationMouseUp();
      }

      isRotationTransformActiveRef.current = value;
      setIsMouseDown(value);
    };

    control.addEventListener('dragging-changed', callback);

    return () => {
      control.removeEventListener('dragging-changed', callback);
    };
  }, [building]);

  const updateObjectMesh = () => {
    if (!intersectObject) return;

    const building = getBuilding(buildingId);
    if (!building) return;

    const buildingCenter = getBuildingCenter(building);
    const buildingHeight = getBuildingHeight(building);
    const buildingZ = getBuildingZ(building);
    const transformerSize = getBuildingTransformBoxSize(building, buildingCenter);

    const bb = new THREE.Box3().setFromObject(intersectObject);
    bb.applyMatrix4(intersectObject.matrixWorld);

    objectRef.current.position.set(buildingCenter[0], buildingCenter[1], buildingZ + buildingHeight / 2);
    objectRef.current.scale.set(transformerSize[0], transformerSize[1], buildingHeight);
    objectRef.current.rotation.set(0, 0, intersectObject.rotation.z);
  };

  useEffect(() => {
    store.mouse.isPersistingChanges = false;
    updateObjectMesh();
  }, [building, intersectObject]);

  useEffect(() => {
    const unsubscribe = subscribeKey(store.mouse, 'isPersistingChanges', (state) => {
      // @ts-ignore
      elevationControlsRef.current.enabled = !state;
      // @ts-ignore
      rotationControlsRef.current.enabled = !state;
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {/* @ts-ignore */}
      <mesh name='buildingTransformer' {...bind()} ref={objectRef} onUpdate={onMeshUpdate} visible={false}>
        <boxGeometry args={[1, 1, 1]} />
      </mesh>
      <TransformControls
        ref={elevationControlsRef}
        object={objectRef}
        mode='translate'
        onChange={onElevationChange}
        showX={false}
        showY={false}
      />
      <TransformControls
        ref={rotationControlsRef}
        object={objectRef}
        mode='rotate'
        onChange={onRotationChange}
        showX={false}
        showY={false}
      />
    </>
  );
};

export default BuildingTransformer;
