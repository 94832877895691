import { XY } from "types/location/coordinates";

export enum EBufferType {
  KG = 'KG',
  GG = 'GG',
  GA = 'GA',
  SA = 'SA',
}

export enum EBufferDistances {
  KG = 5,
  GG = 10,
  GA = 10,
  SA = 3.5,
}

export enum EAdditionalDistances {
  NO = 'no',
  MLZ = 'mlz',
  MHZ = 'mhz',
  MLZ_MHZ = 'mlz/mhz',
}

export enum EAdditionalDistanceBuffer {
  KG = 'KG',
  GG = 'GG',
  KGandGG = 'KG/GG',
}

export interface IBufferObject {
  id: string;
  geometry: [number, number][];
  order: number;
  bufferType: EBufferType;
  bufferDistance: number;
}

export interface IBufferLineSegment {
  order: number;
  coordinates: XY[];
}

export interface IBufferConstructionSettings {
  additionalDistance?: EAdditionalDistances;
  additionalDistanceByHeight?: EAdditionalDistanceBuffer;
  additionalDistanceByLength?: EAdditionalDistanceBuffer;
  maxGgWithDistance?: number | null;
  maxKgWithDistance?: number | null;
  mhz?: number | null;
  mhzFrom?: number | null;
  mlz?: number | null;
  mlzFrom?: number | null;
  smallBuffer?: number;
  largeBuffer?: number;
  buildingBuffer?: number;
  streetBuffer?: number;
}
