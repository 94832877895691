import { Canvas, Props } from '@react-three/fiber';
import chroma from 'chroma-js';
import merge from 'lodash/merge';
import React, { useEffect } from 'react';
import * as THREE from 'three';
import { IApplicationPath } from "types/applicationPath";
import store from '../../store';

import loaderStore, { IExternalLoadingState } from '../../store/loaderStore';
import { setApplicationPath } from '../../store/mutations/general';
import { ICityViewGeneral, ICityViewSettings, ITexts } from '../../store/store';
import RaycasterListener from '../RaycasterListener';
import styles from './Canvas.module.css';

interface IStoreData {
  general?: Partial<ICityViewGeneral>;
  settings?: Partial<ICityViewSettings>;
  texts?: ITexts;
}

// Resets the coordinate system for Three.js
THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1);

interface BaseCanvasProps extends Props {
  storeData?: IStoreData;
  externalLoadingState?: IExternalLoadingState;
  applicationPath: IApplicationPath;
}

const { daylightMinutes } = store.animations;

const BaseCanvas = React.forwardRef((props: BaseCanvasProps, ref: React.Ref<HTMLCanvasElement>) => {
  const { className, color, storeData, applicationPath, children, externalLoadingState, ...otherProps } = props;

  const colorRef = React.useRef<THREE.Color>(null);
  const bgColor = color || '#dbeff8';

  useEffect(() => {
    if (externalLoadingState) merge(loaderStore.external, externalLoadingState);
  }, [externalLoadingState]);

  useEffect(() => {
    if (storeData) merge(store, storeData);
  }, [storeData]);

  useEffect(() => {
    setApplicationPath(applicationPath);
  }, [applicationPath]);

  useEffect(() => {
    const { removeListener } = daylightMinutes.onChange(() => {
      if (!colorRef.current || color) return;
      const progress = daylightMinutes.calculateProgress();
      colorRef.current
        .set(bgColor)
        .lerp(
          new THREE.Color(chroma(bgColor).darken(2.5).set('hsl.h', 20).hex()),
          1 - Math.pow(Math.sin(progress * Math.PI), 0.3),
        );
    });

    return () => {
      removeListener();
    };
  }, [color]);

  return (
    <Canvas className={['cityview-canvas', styles.Canvas, className].join(' ')} {...otherProps} ref={ref}>
      <color ref={colorRef} attach='background' args={[bgColor]} />
      <RaycasterListener />
      {children}
    </Canvas>
  );
});

export default BaseCanvas;
