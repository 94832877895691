import { TFace } from './Face';
import { EBalconyDividers, ESegmentOpening, EWallLayout, TSegmentWallPartMaterials } from './FaceSegment';
import { EFloorType } from './Floor';
import { EPVActivationState } from './Pv';

export interface IBuildingWall {
  id: string;
  direction: number;
  directionName: EFaceDirection;
  faces: TFace[];
  segmentIds: string[];
  openingsOnWall: ESegmentOpening[];
  selectedSegmentCount: number;
  totalSegmentCount: number;
}

export interface IFloorFacade {
  floorId: string;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  floorNumber: number;
  floorType: EFloorType;
  openingsOnFloor: ESegmentOpening[];
  facadeFaces: IFacadeFace[];
  segmentIds: string[];
}

export interface IFacadeFace {
  totalSegmentsCount: number;
  selectedSegmentsCount: number;
  direction: number;
  directionName: EFaceDirection;
  face: TFace;
  openingsOnFacade: ESegmentOpening[];
  segmentIds: string[];
}

export interface IFacadeVariant {
  opening: ESegmentOpening;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  wallLayoutGroups: IWallLayoutGroup[];
  segmentIds: string[];
}

export interface IWallLayoutGroup {
  wallLayout: EWallLayout;
  selectedSegmentCount: number;
  totalSegmentCount: number;
  segmentIds: string[];
}

export interface ISelectedFacadeProperties {
  wallLayouts: EWallLayout[];
  hasBalcony: boolean | null;
  balconyWidth: number | null;
  balconyDividers: EBalconyDividers | null;
  openings: ESegmentOpening[];
  wallMaterials: TSegmentWallPartMaterials;
  topHeights: number[];
  bottomHeights: number[];
  sidesWidths: number[];
  pvIsActive: EPVActivationState;
}

export enum EBuildingPart {
  FLAT_ROOF_FLOOR = 'flatRoofFloor',
  ROOF_EDGE = 'roofEdge',
  GABLED_ROOF = 'gabledRoof',
  TERRACE_FLOOR = 'terraceFloor',
  BALCONY_FLOOR = 'balconyFloor',
  TERRACE_RAILING = 'terraceRailing',
  BALCONY_RAILING = 'balconyRailing',
  WINDOW_RAILING = 'windowRailing',
  WINDOW = 'window',
  WALL = 'wall',
}

export enum EBuildingPartWithoutWallAndWindow {
  FLAT_ROOF_FLOOR = 'flatRoofFloor',
  ROOF_EDGE = 'roofEdge',
  GABLED_ROOF = 'gabledRoof',
  TERRACE_FLOOR = 'terraceFloor',
  BALCONY_FLOOR = 'balconyFloor',
  TERRACE_RAILING = 'terraceRailing',
  BALCONY_RAILING = 'balconyRailing',
  WINDOW_RAILING = 'windowRailing',
}

export enum EFaceDirection {
  NORTH = 'N',
  NORTH_EAST = 'NE',
  EAST = 'E',
  SOUTH_EAST = 'SE',
  SOUTH = 'S',
  SOUTH_WEST = 'SW',
  WEST = 'W',
  NORTH_WEST = 'NW',
}

export enum EBuildingFacadePreset {
  MULTI_FAMILY_1 = 'multiFamily1',
  MULTI_FAMILY_2 = 'multiFamily2',
  MULTI_FAMILY_3 = 'multiFamily3',
  MULTI_FAMILY_4 = 'multiFamily4',
  MULTI_FAMILY_5 = 'multiFamily5',
  MULTI_FAMILY_6 = 'multiFamily6',
  MULTI_FAMILY_7 = 'multiFamily7',
  MULTI_FAMILY_8 = 'multiFamily8',
  COMMERCIAL_INDUSTRIAL_1 = 'commercialIndustrial1',
  COMMERCIAL_INDUSTRIAL_2 = 'commercialIndustrial2',
  COMMERCIAL_INDUSTRIAL_3 = 'commercialIndustrial3',
  COMMERCIAL_INDUSTRIAL_4 = 'commercialIndustrial4',
  COMMERCIAL_INDUSTRIAL_5 = 'commercialIndustrial5',
  COMMERCIAL_INDUSTRIAL_6 = 'commercialIndustrial6',
  COMMERCIAL_INDUSTRIAL_7 = 'commercialIndustrial7',
  COMMERCIAL_INDUSTRIAL_8 = 'commercialIndustrial8',
  SINGLE_FAMILY_1 = 'singleFamily1',
  SINGLE_FAMILY_2 = 'singleFamily2',
  SINGLE_FAMILY_3 = 'singleFamily3',
  SINGLE_FAMILY_4 = 'singleFamily4',
  SINGLE_FAMILY_5 = 'singleFamily5',
  SINGLE_FAMILY_6 = 'singleFamily6',
  SINGLE_FAMILY_7 = 'singleFamily7',
  SINGLE_FAMILY_8 = 'singleFamily8',
  MULTI_FAMILY_AND_OFFICE_1 = 'multiFamilyAndOffice1',
  MULTI_FAMILY_AND_OFFICE_2 = 'multiFamilyAndOffice2',
  MULTI_FAMILY_AND_OFFICE_3 = 'multiFamilyAndOffice3',
  MULTI_FAMILY_AND_OFFICE_4 = 'multiFamilyAndOffice4',
  MULTI_FAMILY_AND_OFFICE_5 = 'multiFamilyAndOffice5',
  MULTI_FAMILY_AND_OFFICE_6 = 'multiFamilyAndOffice6',
  MULTI_FAMILY_AND_OFFICE_7 = 'multiFamilyAndOffice7',
  MULTI_FAMILY_AND_OFFICE_8 = 'multiFamilyAndOffice8',
}

export interface ISegmentListItem {
  totalSegmentCount: number;
  selectedSegmentCount: number;
  segmentIds: string[];
}
