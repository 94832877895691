import { TransformControls } from '@react-three/drei';
import { useGesture } from '@use-gesture/react';

import { setTransformControlsColor } from 'cityview/components/Building/utils';
import { findGroundHeightEstimation } from 'cityview/components/Terrain/utils';
import store from 'cityview/store';
import { setIsMouseDown, setIsMouseEditing } from 'cityview/store/mutations/mouse';
import { derivedTerrainStore } from 'cityview/store/terrainStore';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { TransformControls as TransformControlsImpl } from 'three-stdlib';

const floorPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);

const onMeshUpdate = (self: THREE.Mesh) => (self.rotation.order = 'ZXY');

interface ParkingLotTransformerProps {
  handleUpdatePosition?: (position: THREE.Vector3) => void;
  handleUpdateRotation?: (rotation: THREE.Euler) => void;
  showMeshWhileDragging?: boolean;
  color?: string;
  intersectObject: THREE.Group | THREE.Mesh | null;
  geometry?: THREE.BufferGeometry;
  showElevationControls?: boolean;
}

const ParkingLotTransformer = (props: ParkingLotTransformerProps) => {
  const {
    handleUpdatePosition,
    handleUpdateRotation,
    intersectObject,
    geometry,
    color,
    showElevationControls = true,
  } = props;

  const objectRef = useRef<THREE.Mesh>(null!);
  const elevationControlsRef = useRef<TransformControlsImpl>(null!);
  const rotationControlsRef = useRef<TransformControlsImpl>(null!);
  const isElevationTransformActiveRef = useRef<boolean>(false);
  const isRotationTransformActiveRef = useRef<boolean>(false);
  const planeIntersectPointRef = useRef<THREE.Vector3>(new THREE.Vector3());

  const planeIntersectStartPointRef = useRef<THREE.Vector3>(new THREE.Vector3());
  const objectStartPointRef = useRef<THREE.Vector3>(new THREE.Vector3());

  const bind = useGesture(
    {
      onDrag: ({ active, timeStamp, event }) => {
        if (
          active &&
          !isElevationTransformActiveRef.current &&
          !isRotationTransformActiveRef.current &&
          intersectObject &&
          objectRef.current
        ) {
          // @ts-ignore
          event.ray!.intersectPlane(floorPlane, planeIntersectPointRef.current);

          const newX =
            objectStartPointRef.current.x - (planeIntersectStartPointRef.current.x - planeIntersectPointRef.current.x);
          const newY =
            objectStartPointRef.current.y - (planeIntersectStartPointRef.current.y - planeIntersectPointRef.current.y);

          objectRef.current.position.x = newX;
          objectRef.current.position.y = newY;

          if (!showElevationControls) {
            const { mergedModifiedTerrain } = derivedTerrainStore;
            if (mergedModifiedTerrain) {
              objectRef.current.position.z = findGroundHeightEstimation([newX, newY], mergedModifiedTerrain);
            }
          }
        }

        onPositionChange();

        return timeStamp;
      },
      onDragStart: ({ event }) => {
        if (
          !isElevationTransformActiveRef.current &&
          !isRotationTransformActiveRef.current &&
          intersectObject &&
          objectStartPointRef.current
        ) {
          setIsMouseDown(true);
          setIsMouseEditing(true);

          // @ts-ignore
          event.ray.intersectPlane(floorPlane, planeIntersectStartPointRef.current);

          objectStartPointRef.current.set(
            intersectObject.position.x,
            intersectObject.position.y,
            intersectObject.position.z,
          );
        }
      },
      onDragEnd: () => {
        if (!isElevationTransformActiveRef.current && !isRotationTransformActiveRef.current && intersectObject) {
          onPositionChange();
          setIsMouseDown(false);
          setIsMouseEditing(false);

          handleUpdatePosition?.(intersectObject.position);
        }
      },
    },
    {},
  );

  const onElevationMouseDown = () => {
    // @ts-ignore
    rotationControlsRef.current.enabled = false;

    setIsMouseDown(true);
    isElevationTransformActiveRef.current = true;
  };

  const onPositionChange = () => {
    if (
      objectRef.current &&
      intersectObject &&
      store.mouse.isMouseDown &&
      !isElevationTransformActiveRef.current &&
      !isRotationTransformActiveRef.current
    ) {
      intersectObject.position.set(
        objectRef.current.position.x,
        objectRef.current.position.y,
        objectRef.current.position.z,
      );
    }
  };

  const onElevationMouseUp = () => {
    if (intersectObject && store.mouse.isMouseDown) {
      handleUpdatePosition?.(intersectObject.position);
    }

    setIsMouseDown(false);

    if (rotationControlsRef.current) {
      // @ts-ignore
      rotationControlsRef.current.enabled = true;
    }
  };

  const onElevationChange = () => {
    if (objectRef.current && intersectObject && store.mouse.isMouseDown && !isRotationTransformActiveRef.current) {
      intersectObject.position.z = objectRef.current.position.z;
    }
  };

  const onRotationMouseDown = () => {
    // @ts-ignore
    if (elevationControlsRef.current) elevationControlsRef.current.enabled = false;

    setIsMouseDown(true);
    setIsMouseEditing(true);
    isRotationTransformActiveRef.current = true;
  };

  const onRotationChange = () => {
    if (intersectObject && objectRef.current) {
      const { x, y, z } = objectRef.current.rotation;
      intersectObject.rotation.set(x, y, z);
    }
  };

  const onRotationMouseUp = () => {
    if (intersectObject && store.mouse.isMouseDown) {
      setIsMouseDown(false);
      setIsMouseEditing(false);

      handleUpdateRotation?.(intersectObject.rotation);
    }

    // @ts-ignore
    if (elevationControlsRef.current) elevationControlsRef.current.enabled = true;
  };

  useEffect(() => {
    if (!rotationControlsRef.current) return;
    const { current: control } = rotationControlsRef;

    setTransformControlsColor(rotationControlsRef.current);

    const callback = (event: THREE.Event) => {
      const value = 'value' in event && !!event.value;

      if (value) {
        onRotationMouseDown();
      } else {
        onRotationMouseUp();
      }

      isRotationTransformActiveRef.current = value;
      setIsMouseDown(value);
    };

    control.addEventListener('dragging-changed', callback);

    return () => control.removeEventListener('dragging-changed', callback);
  }, []);

  useEffect(() => {
    if (!elevationControlsRef.current) return;

    const { current: control } = elevationControlsRef;

    setTransformControlsColor(elevationControlsRef.current);

    const callback = (event: THREE.Event) => {
      const value = 'value' in event && !!event.value;

      if (value) {
        onElevationMouseDown();
      } else {
        onElevationMouseUp();
      }

      isElevationTransformActiveRef.current = value;
      setIsMouseDown(value);
    };

    control.addEventListener('dragging-changed', callback);

    return () => control.removeEventListener('dragging-changed', callback);
  }, []);

  const updateObjectMesh = () => {
    if (!intersectObject) return;

    let z = intersectObject.position.z;
    if (!showElevationControls) {
      const { mergedModifiedTerrain } = derivedTerrainStore;
      if (mergedModifiedTerrain) {
        objectRef.current.position.z = findGroundHeightEstimation(
          [intersectObject.position.x, intersectObject.position.y],
          mergedModifiedTerrain,
        );
      }
    }

    objectRef.current.position.set(intersectObject.position.x, intersectObject.position.y, z);
    objectRef.current.rotation.set(0, 0, intersectObject.rotation.z);
  };

  useEffect(() => {
    store.mouse.isPersistingChanges = false;
    updateObjectMesh();
  }, [intersectObject]);

  return (
    <>
      {/* @ts-ignore */}
      <mesh
        name='buildingTransformer'
        {...bind()}
        ref={objectRef}
        geometry={geometry}
        onUpdate={onMeshUpdate}
        visible={false}
      >
        <meshStandardMaterial color={color} />
      </mesh>
      {showElevationControls && (
        <TransformControls
          ref={elevationControlsRef}
          object={objectRef}
          onChange={onElevationChange}
          mode='translate'
          showX={false}
          showY={false}
        />
      )}
      <TransformControls
        ref={rotationControlsRef}
        object={objectRef}
        onChange={onRotationChange}
        mode='rotate'
        showX={false}
        showY={false}
      />
    </>
  );
};

export default ParkingLotTransformer;
