import booleanConcave from '@turf/boolean-concave';
import * as turf from '@turf/turf';
import { Feature, MultiPolygon, Polygon } from 'geojson';
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { generateUUID } from 'three/src/math/MathUtils';
import { IBuilding, TFootprint } from 'types/building/Building';
import { EBufferDistances, EBufferType, IBufferObject } from 'types/building/BuildingBuffers';
import { XY } from 'types/location/coordinates';
import { distanceBetweenPoints, getBuildingFootprint, getEgFootprint } from '../components/Building/utils';
import { getBuildingHeight, getBuildingHeights } from '../store/selectors/building';
import { getTerrain } from '../store/selectors/general';

import { getCalculatedBufferDistance } from './bufferCalculations';
import { distanceToLineSegment } from './footprintValidation';

const infinity = 10e6;

const isConvex = (polygon: number[][]): boolean => {
  try {
    return !booleanConcave(turf.polygon([polygon]));
  } catch (e) {
    return false;
  }
};

export const isFootprintCoordinate = (footprint: number[][], coordinate: number[]): boolean =>
  footprint.some(
    (corner) =>
      Math.abs(Math.sqrt(Math.pow(corner[0] - coordinate[0], 2) + Math.pow(corner[1] - coordinate[1], 2))) < 0.2,
  );

const shiftPolygonCoordinates = (polygon: XY[], footprint: TFootprint): XY[] => {
  let newPolygon = cloneDeep(polygon);
  if (isFootprintCoordinate(footprint, newPolygon[0])) return newPolygon;
  newPolygon = newPolygon.slice(0, -1);
  while (!isFootprintCoordinate(footprint, newPolygon[0])) {
    const shift = newPolygon.shift();
    if (shift) {
      newPolygon.push(shift);
    }
  }
  newPolygon.push(newPolygon[0]);
  return newPolygon;
};

export const faceNormal = (firstCorner: number[], secondCorner: number[]): XY => {
  const faceVector = [secondCorner[1] - firstCorner[1], -(secondCorner[0] - firstCorner[0])];
  const length = Math.sqrt(Math.pow(faceVector[0], 2) + Math.pow(faceVector[1], 2));
  return [faceVector[0] / length, faceVector[1] / length];
};

const sortedBufferArray = (footprint: XY[], bufferArray: IBufferObject[]): IBufferObject[] => {
  const sortedBufferArray: IBufferObject[] = [];
  for (let i = 0; i < footprint.length - 1; i++) {
    const firstCorner = footprint[i];
    const secondCorner = footprint[i + 1];
    const bufferObject = bufferArray.find(
      (buffer) =>
        distanceBetweenPoints(buffer.geometry[0], firstCorner) < 0.1 &&
        distanceBetweenPoints(buffer.geometry[1], secondCorner) < 0.1,
    );
    if (bufferObject) {
      sortedBufferArray.push(bufferObject);
    }
  }
  return sortedBufferArray;
};

const offsetPolygonMap = (bufferArray: IBufferObject[], footprint: TFootprint): XY[] => {
  const footprintWithoutLast = footprint.slice(0, footprint.length - 1);
  let offsetArray: number[] = [];

  if (!bufferArray || bufferArray.length === 0) {
    offsetArray = Array(footprintWithoutLast.length).fill(EBufferDistances.KG);
    return buffersOffsetPolygon(footprintWithoutLast, offsetArray);
  }

  if (isConvex(footprint)) {
    const sortedBuffers = sortedBufferArray(footprint, bufferArray);
    sortedBuffers.forEach((buffer) => {
      offsetArray.push(buffer.bufferDistance);
    });
    if (offsetArray.length < footprintWithoutLast.length) {
      offsetArray = Array(footprintWithoutLast.length).fill(EBufferDistances.KG);
    }
    return buffersOffsetPolygon(footprintWithoutLast, offsetArray);
  }

  offsetArray = new Array(footprintWithoutLast.length).fill(null);
  bufferArray.forEach((buffer) => {
    const firstCorner = buffer.geometry[0];
    const secondCorner = buffer.geometry[1];

    const firstFootprintCorner = footprintWithoutLast.find(
      (corner) => distanceBetweenPoints(corner, firstCorner) < 0.1,
    );
    const secondFootprintCorner = footprintWithoutLast.find(
      (corner) => distanceBetweenPoints(corner, secondCorner) < 0.1,
    );

    if (firstFootprintCorner && secondFootprintCorner) {
      const firstCornerIndex = footprintWithoutLast.indexOf(firstFootprintCorner);
      const secondCornerIndex = footprintWithoutLast.indexOf(secondFootprintCorner);
      const indexDiff = secondCornerIndex - firstCornerIndex;
      if (indexDiff < 0) {
        for (let i = firstCornerIndex; i < footprintWithoutLast.length; i++) {
          offsetArray[i] = buffer.bufferDistance;
        }
        for (let i = 0; i < secondCornerIndex; i++) {
          offsetArray[i] = buffer.bufferDistance;
        }
      } else {
        for (let i = firstCornerIndex; i < indexDiff + firstCornerIndex; i++) {
          offsetArray[i] = buffer.bufferDistance;
        }
      }
    }
  });

  if (offsetArray.length < footprintWithoutLast.length || offsetArray.some((element) => !element)) {
    offsetArray = Array(footprintWithoutLast.length).fill(EBufferDistances.KG);
  }
  return buffersOffsetPolygon(footprintWithoutLast, offsetArray);
};

export const getBufferArray = (building: IBuilding, newValue?: EBufferType): IBufferObject[] => {
  const footprint = getEgFootprint(building);
  if (!footprint) return [];
  const simplifiedFootprint = removePointsOnEdges(footprint);
  const bufferPolygon = getBuildingBuffer(building, simplifiedFootprint);
  const bufferFootprintCoordinates = bufferPolygon.filter((coordinate) =>
    isFootprintCoordinate(simplifiedFootprint, coordinate),
  );
  const currentBufferArray = building.buffers;
  const terrain = getTerrain();
  const buildingHeight = terrain ? getBuildingHeights(building.id, terrain).totalHeight : getBuildingHeight(building);

  const buffers: IBufferObject[] = [];
  for (let i = 0; i < bufferFootprintCoordinates.length - 1; i++) {
    const firstCorner = clone(bufferFootprintCoordinates[i]);
    const secondCorner = clone(bufferFootprintCoordinates[i + 1]);
    buffers.push(findBufferObject(firstCorner, secondCorner, currentBufferArray, buildingHeight, newValue));
  }
  findBufferObjectOrder(buffers);

  return buffers;
};

const findBufferObjectOrder = (buffers: IBufferObject[]): void => {
  for (let i = 1; i <= buffers.length; i++) {
    const orderExists = buffers.some((buffer) => buffer.order === i);
    if (!orderExists) {
      const obj = buffers.find((buffer) => buffer.order === 0);
      if (obj) obj.order = i;
    }
  }
};

const findBufferObject = (
  firstCorner: XY,
  secondCorner: XY,
  currentBufferArray: IBufferObject[],
  buildingHeight: number,
  newValue?: EBufferType,
): IBufferObject => {
  const currentBufferObject = currentBufferArray.find(
    (buffer) =>
      distanceBetweenPoints(buffer.geometry[0], firstCorner) < 0.1 &&
      distanceBetweenPoints(buffer.geometry[1], secondCorner) < 0.1,
  );
  if (currentBufferObject) {
    return {
      ...currentBufferObject,
      bufferType: newValue || currentBufferObject.bufferType,
      bufferDistance: getCalculatedBufferDistance(
        [firstCorner, secondCorner],
        newValue || currentBufferObject.bufferType,
        buildingHeight,
      ),
    };
  } else {
    const bufferObjectFirstMatching = currentBufferArray.find(
      (buffer) => distanceBetweenPoints(buffer.geometry[0], firstCorner) < 0.1,
    );
    const bufferObjectSecondMatching = currentBufferArray.find(
      (buffer) => distanceBetweenPoints(buffer.geometry[1], secondCorner) < 0.1,
    );
    if (!bufferObjectFirstMatching && !bufferObjectSecondMatching)
      return {
        id: generateUUID(),
        geometry: [firstCorner, secondCorner],
        order: 0,
        bufferType: newValue || EBufferType.KG,
        bufferDistance: getCalculatedBufferDistance(
          [firstCorner, secondCorner],
          newValue || EBufferType.KG,
          buildingHeight,
        ),
      };
    if (!bufferObjectFirstMatching && !!bufferObjectSecondMatching)
      return {
        id: generateUUID(),
        geometry: [firstCorner, secondCorner],
        order: 0,
        bufferType: newValue || bufferObjectSecondMatching.bufferType,
        bufferDistance: getCalculatedBufferDistance(
          [firstCorner, secondCorner],
          newValue || bufferObjectSecondMatching.bufferType,
          buildingHeight,
        ),
      };
    if (!!bufferObjectFirstMatching && !bufferObjectSecondMatching)
      return {
        id: generateUUID(),
        geometry: [firstCorner, secondCorner],
        order: 0,
        bufferType: newValue || bufferObjectFirstMatching.bufferType,
        bufferDistance: getCalculatedBufferDistance(
          [firstCorner, secondCorner],
          newValue || bufferObjectFirstMatching.bufferType,
          buildingHeight,
        ),
      };
    if (!!bufferObjectFirstMatching && !!bufferObjectSecondMatching) {
      if (newValue) {
        return {
          id: generateUUID(),
          geometry: [firstCorner, secondCorner],
          order: 0,
          bufferType: newValue,
          bufferDistance: getCalculatedBufferDistance([firstCorner, secondCorner], newValue, buildingHeight),
        };
      } else {
        const distanceFirst = getCalculatedBufferDistance(
          [firstCorner, secondCorner],
          bufferObjectFirstMatching.bufferType,
          buildingHeight,
        );
        const distanceSecond = getCalculatedBufferDistance(
          [firstCorner, secondCorner],
          bufferObjectSecondMatching.bufferType,
          buildingHeight,
        );
        return {
          id: generateUUID(),
          geometry: [firstCorner, secondCorner],
          order: 0,
          bufferType:
            distanceFirst > distanceSecond
              ? bufferObjectFirstMatching.bufferType
              : bufferObjectSecondMatching.bufferType,
          bufferDistance: distanceFirst > distanceSecond ? distanceFirst : distanceSecond,
        };
      }
    }
  }

  return {
    id: generateUUID(),
    geometry: [firstCorner, secondCorner],
    order: 0,
    bufferType: EBufferType.KG,
    bufferDistance: getCalculatedBufferDistance([firstCorner, secondCorner], EBufferType.KG, buildingHeight),
  };
};

export const removePointsOnEdges = (polygon: XY[]): XY[] => {
  if (!polygon.length) return [];

  if (!isEqual(polygon[0], polygon[polygon.length - 1])) {
    polygon.push(polygon[0]);
  }
  const threshold = 0.2;
  const simplifiedFootprint: XY[] = turf
    .simplify(turf.polygon([polygon]), { tolerance: 0.5 })
    .geometry.coordinates[0].map((pos) => [pos[0], pos[1]]);
  let newFootprint: XY[] = cloneDeep(simplifiedFootprint);
  const footprintWithoutLast = simplifiedFootprint.slice(0, simplifiedFootprint.length - 1);
  for (let i = 0; i < footprintWithoutLast.length; i++) {
    const currentPoint = footprintWithoutLast[i % footprintWithoutLast.length];
    const middlePoint = footprintWithoutLast[(i + 1) % footprintWithoutLast.length];
    const nextPoint = footprintWithoutLast[(i + 2) % footprintWithoutLast.length];
    const d = distanceToLineSegment(middlePoint, [currentPoint, nextPoint]);
    if (d < threshold) {
      newFootprint = newFootprint.filter((point) => !isEqual(point, middlePoint));
    }
  }
  if (!isEqual(newFootprint[0], newFootprint[newFootprint.length - 1])) {
    newFootprint.push(newFootprint[0]);
  }
  return newFootprint;
};

export const getBuildingBuffer = (building: IBuilding, unionFootprint: XY[]): XY[] => {
  const buffer = offsetPolygonMap(building.buffers, unionFootprint);
  const bufferPolygon: Feature<Polygon | MultiPolygon> = turf.polygon([buffer]);
  const simplifiedBuffer = turf.simplify(bufferPolygon, { tolerance: 0.1, highQuality: true });
  const buildingBuffer: XY[] = simplifiedBuffer.geometry.coordinates[0].map(([x, y]) => [Number(x), Number(y)]);
  return shiftPolygonCoordinates(buildingBuffer, unionFootprint);
};

export const buffersOffsetPolygon = (polygon: XY[], offsets: number[]): XY[] => {
  const lines = offsetPolygonLines(polygon, offsets);
  const newPolygon = linesToPolygon(polygon, lines);
  let returnPolygon = newPolygon;
  try {
    returnPolygon = removeKinks(newPolygon);
  } catch (e) {
    console.log(e);
  }
  return returnPolygon;
};

const offsetPolygonLines = (polygon: XY[], offsets: number[]): [XY, XY][] =>
  polygon.map((coordinates, index) => {
    // Get the current vertex and the next vertex
    const [x1, y1] = coordinates;
    const [x2, y2] = polygon[(index + 1) % polygon.length];

    // Calculate the difference between the x and y coordinates
    let dx = x2 - x1;
    let dy = y2 - y1;

    // Normalize the differences to get the unit vector
    const length = Math.sqrt(dx ** 2 + dy ** 2);
    dx /= length;
    dy /= length;

    // Offset the vertices by the specified amount
    const offset1: XY = [x1 + offsets[index] * dy, y1 - offsets[index] * dx];
    const offset2: XY = [x2 + offsets[index] * dy, y2 - offsets[index] * dx];

    return [offset1, offset2];
  });

const linesToPolygon = (polygon: XY[], lines: [XY, XY][]): XY[] => {
  const points: XY[] = [];

  let lastCorner = [Infinity, Infinity];
  for (let i = 0; i < polygon.length; i++) {
    const [l1, l2] = [lines[i][0], lines[i][1]];
    if (l1[0] === lastCorner[0] && l1[1] === lastCorner[1]) {
      points.push(l2);
    } else {
      points.push(polygon[i], l1, l2);
    }
    lastCorner = l2;
  }
  return points;
};

const removeKinks = (polygon: XY[]): XY[] => {
  const clonedPolygon = cloneDeep(polygon);
  clonedPolygon.push(polygon[0]);
  const initialPolygon = turf.polygon([clonedPolygon]);
  const unkinkedPolygon = turf.unkinkPolygon(initialPolygon);
  unkinkedPolygon.features.sort((a, b) => turf.area(b) - turf.area(a));
  return unkinkedPolygon.features[0].geometry.coordinates[0].map((c): [number, number] => [c[0], c[1]]);
};

export const offsetPolygon = (polygon: XY[], offsets: number[] | number): XY[] => {
  if (!polygon.length || (Array.isArray(offsets) && polygon.length !== offsets.length)) return [];

  const lines: XY[][] = [];
  for (let i = 0; i < polygon.length - 1; i += 1) {
    const fc = polygon[i];
    const sc = polygon[i + 1];
    const normal = faceNormal(fc, sc);
    const offset = typeof offsets === 'number' ? offsets : offsets[i];
    const nfc: XY = [fc[0] + normal[0] * offset, fc[1] + normal[1] * offset];
    const nsc: XY = [sc[0] + normal[0] * offset, sc[1] + normal[1] * offset];
    lines.push([nfc, nsc]);
  }
  const corners: XY[] = [];
  for (let i = 0; i < lines.length; i += 1) {
    const fl = lines[i];
    const sl = i === lines.length - 1 ? lines[0] : lines[i + 1];
    const l1: XY = findLineEquation(fl);
    const l2: XY = findLineEquation(sl);
    const intersect = findLineIntersection(l1, l2);
    corners.push(intersect);
  }
  const cleanCorners: XY[] = [corners[corners.length - 1], ...corners];
  while (
    cleanCorners[cleanCorners.length - 1][0] === cleanCorners[cleanCorners.length - 2][0] &&
    cleanCorners[cleanCorners.length - 1][1] === cleanCorners[cleanCorners.length - 2][1]
  ) {
    cleanCorners.pop();
  }
  if (cleanCorners.length === 4) cleanCorners.push(cleanCorners[0]);
  return cleanCorners;
};

const findLineEquation = (line: XY[]): XY => {
  const fc: XY = line[0],
    sc: XY = line[1];
  const isVertical: boolean = Math.abs(sc[0] - fc[0]) < 10e-6;
  const slope: number = isVertical ? infinity : (sc[1] - fc[1]) / (sc[0] - fc[0]);
  const c: number = slope === infinity ? fc[0] : fc[1] - slope * fc[0];
  return [slope, c];
};

const findLineIntersection = (l1: XY, l2: XY): XY => {
  let x, y;
  if (l1[0] === infinity) {
    x = l1[1];
    y = l2[0] * x + l2[1];
  } else if (l2[0] === infinity) {
    x = l2[1];
    y = l1[0] * x + l1[1];
  } else {
    x = (l2[1] - l1[1]) / (l1[0] - l2[0]);
    y = l1[0] * x + l1[1];
  }
  return [x, y];
};
