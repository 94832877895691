import { Box } from '@mui/material';
import ModalContentWrapper from 'components/display/ModalContentWrapper';
import ShopWrapper from 'components/features/ShopModal/components/ShopWrapper';
import BillingDetailsView from 'components/features/ShopModal/views/BillingDetailsView';
import CheckoutView from 'components/features/ShopModal/views/CheckoutView';
import ShopView from 'components/features/ShopModal/views/ShopView';
import ShopIntegrationsView from 'components/features/ShopModal/views/ShopView/views/ShopIntegrationsView';
import ShopIntegrationsFpreView from 'components/features/ShopModal/views/ShopView/views/ShopIntegrationsView/views/ShopIntegrationsFpreView';
import ShopIntegrationsFpreFullProductView from 'components/features/ShopModal/views/ShopView/views/ShopIntegrationsView/views/ShopIntegrationsFpreView/views/ShopIntegrationsFpreFullProductView';
import ShopIntegrationsFpreSeparateProductsView from 'components/features/ShopModal/views/ShopView/views/ShopIntegrationsView/views/ShopIntegrationsFpreView/views/ShopIntegrationsFpreSeparateProductsView';
import ShopIntegrationsKeeValueView from 'components/features/ShopModal/views/ShopView/views/ShopIntegrationsView/views/ShopIntegrationsKeeValueView';
import ShopProjectView from 'components/features/ShopModal/views/ShopView/views/ShopProjectView';
import ShopProjectFullProductView from 'components/features/ShopModal/views/ShopView/views/ShopProjectView/views/ShopProjectFullProductView';
import ShopProjectSeparateProductsView from 'components/features/ShopModal/views/ShopView/views/ShopProjectView/views/ShopProjectSeparateProductsView';
import React from 'react';
import { createMemoryRouter, Navigate } from 'react-router-dom';
import NavigateLast from 'routes/components/NavigateLast';
import { SHOP_PATHS } from 'routes/routers/shop/shopPaths';

const shopRouter = createMemoryRouter([
  {
    element: <ShopWrapper />,
    children: [
      {
        index: true,
        element: <Navigate replace to={SHOP_PATHS.SHOP.ROOT} />,
      },
      {
        path: SHOP_PATHS.SHOP.ROOT,
        element: <ShopView />,
        children: [
          {
            index: true,
            element: <NavigateLast initialView={SHOP_PATHS.SHOP.PROJECT.ROOT} path={['shop']} />,
          },
          {
            path: SHOP_PATHS.SHOP.PROJECT.ROOT,
            element: <ShopProjectView />,
            children: [
              {
                index: true,
                element: <NavigateLast initialView={SHOP_PATHS.SHOP.PROJECT.FULL_PRODUCT} path={['shop', 'project']} />,
              },
              {
                path: SHOP_PATHS.SHOP.PROJECT.FULL_PRODUCT,
                element: <ShopProjectFullProductView />,
              },
              {
                path: SHOP_PATHS.SHOP.PROJECT.SEPARATE_PRODUCTS,
                element: <ShopProjectSeparateProductsView />,
              },
            ],
          },
          {
            path: SHOP_PATHS.SHOP.INTEGRATIONS.ROOT,
            element: <ShopIntegrationsView />,
            children: [
              {
                index: true,
                element: (
                  <NavigateLast initialView={SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.ROOT} path={['shop', 'integrations']} />
                ),
              },
              // {
              //   path: SHOP_PATHS.SHOP.INTEGRATIONS.OVERVIEW.ROOT,
              //   element: <ShopIntegrationsOverviewView />,
              // },
              {
                path: SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.ROOT,
                element: <ShopIntegrationsFpreView />,
                children: [
                  {
                    index: true,
                    element: (
                      <NavigateLast
                        initialView={SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.FULL_PRODUCT}
                        path={['shop', 'integrations', 'fpre']}
                      />
                    ),
                  },
                  {
                    path: SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.FULL_PRODUCT,
                    element: <ShopIntegrationsFpreFullProductView />,
                  },
                  {
                    path: SHOP_PATHS.SHOP.INTEGRATIONS.FPRE.SEPARATE_PRODUCTS,
                    element: <ShopIntegrationsFpreSeparateProductsView />,
                  },
                ],
              },
              {
                path: SHOP_PATHS.SHOP.INTEGRATIONS.KEEVALUE.ROOT,
                element: <ShopIntegrationsKeeValueView />,
              },
            ],
          },
        ],
      },
      {
        path: SHOP_PATHS.BILLING_DETAILS.ROOT,
        element: <BillingDetailsView />,
      },
      {
        path: SHOP_PATHS.CHECKOUT.ROOT,
        element: <CheckoutView />,
      },
      {
        path: '*',
        element: (
          <ModalContentWrapper>
            <Box sx={{ p: 3 }}>Not found</Box>
          </ModalContentWrapper>
        ),
      },
    ],
  },
]);

export default shopRouter;
