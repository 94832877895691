import { extend } from '@react-three/fiber';
import { ELoadingState, EObjectName } from 'cityview';
import TerrainRectangleSelection from 'cityview/components/Terrain/ModifiedTerrain/TerrainPoints/TerrainRectangleSelection';
import { useTerrainModifiedListener } from 'cityview/events/terrain';
import { Feature, MultiPolygon } from 'geojson';
import React, { useEffect, useMemo } from 'react';
import * as THREE from 'three';
import { EApplicationMode, EApplicationTool, EApplicationTopic } from 'types/applicationPath';
import { IConstructionLine } from 'types/gis/ConstructionLine';
import { ILngLat } from 'types/location/coordinates';
import { ETerrainImageType, ITerrainWithProperties } from 'types/terrain';
import { useSnapshot } from 'valtio';
import { useApplicationPath } from '../../hooks';
import useTerrainGeometry from '../../hooks/useTerrainGeometry';
import useTerrainTexture from '../../hooks/useTerrainTexture';
import store from '../../store';

import loaderStore from '../../store/loaderStore';
import FlatGridHelper from '../FlatGridHelper';
import OffsetTerrain from '../OffsetTerrain';
import TerrainPoints from './ModifiedTerrain/TerrainPoints/TerrainPoints';
import { getPlotSurfaceCoordinates, updateVertexElevation, updateWireframeVertexElevation } from './utils';

extend(THREE.ShadowMaterial);

export interface TerrainProps {
  center: ILngLat;
  constructionLines?: IConstructionLine[];
  flattenTerrain?: boolean;
  gridCellWidth?: number;
  gridRotationAngle?: number;
  helplines?: Feature[];
  mapboxApiKey: string;
  plotPolygon?: ILngLat[];
  landscapeFeatures?: Feature<MultiPolygon>[];
  showGrid?: boolean;
  showTerrain?: boolean;
  showTexture?: boolean;
  terrainWithProperties: ITerrainWithProperties;
  type?: ETerrainImageType;
}

export interface BaseTerrainProps extends TerrainProps {
  roadmapTexture?: THREE.CanvasTexture;
  satelliteTexture?: THREE.CanvasTexture;
  cadastreTexture?: THREE.CanvasTexture;
}

const BaseTerrain = (props: BaseTerrainProps) => {
  const {
    flattenTerrain = false,
    gridCellWidth,
    showTerrain = true,
    terrainWithProperties,
    plotPolygon,
    center,
  } = props;

  const { terrain, properties } = terrainWithProperties;
  const { terrainWidth } = properties;

  const terrainMeshRef = React.useRef<THREE.Mesh>(null);
  const terrainWireframeRef = React.useRef<THREE.LineSegments>(null);
  const wireframeGeometryRef = React.useRef<THREE.WireframeGeometry>(null);

  const [wireframeIndices, setWireframeIndices] = React.useState<Map<string, number[]>>();

  const { active: showOffsetTerrain } = useSnapshot(store.settings.offsetTerrain);
  const { topic, mode, tool } = useApplicationPath();

  const { bottomGeometry, overlayGeometry, pointIndices } = useTerrainGeometry({ properties, terrain });

  const plotSurfaceCoordinates = useMemo(() => getPlotSurfaceCoordinates(plotPolygon, center), [plotPolygon, center]);
  const texture = useTerrainTexture({ ...props, plotCoordinates: plotSurfaceCoordinates });

  useEffect(() => {
    loaderStore.internal.terrain.loadingState = ELoadingState.SUCCESS;
  }, []);

  useTerrainModifiedListener(({ changedPoints }) => {
    if (!terrainMeshRef.current || !changedPoints || !pointIndices) return;

    updateVertexElevation(terrainMeshRef.current.geometry, changedPoints, pointIndices);
    if (terrainWireframeRef.current && wireframeIndices) {
      updateWireframeVertexElevation(
        terrainWireframeRef.current.geometry as THREE.WireframeGeometry,
        changedPoints,
        wireframeIndices,
      );
    }
  });

  const updateWireFrameIndices = () => {
    if (wireframeGeometryRef.current) {
      // create a map of all the indices of a xy coordinate in the position attribute of the geometry
      const indexMap = new Map<string, number[]>();

      const vertices = wireframeGeometryRef.current.attributes.position.array;

      for (let i = 0; i < vertices.length; i += 3) {
        const key = `${vertices[i]},${vertices[i + 1]}`;
        if (indexMap.has(key)) {
          indexMap.get(key)?.push(i);
        } else {
          indexMap.set(key, [i]);
        }
      }

      setWireframeIndices(indexMap);
    }
  };

  useEffect(() => {
    if (wireframeGeometryRef.current) {
      updateWireFrameIndices();
    }
  }, [overlayGeometry]);

  return (
    <group position={[0, 0, mode === EApplicationMode.BUFFER ? -100 : 0]}>
      {showTerrain && (
        <>
          <mesh
            name={EObjectName.TERRAIN_SURFACE}
            ref={terrainMeshRef}
            receiveShadow={!flattenTerrain && showTerrain}
            castShadow={!flattenTerrain && showTerrain}
            geometry={overlayGeometry}
            onUpdate={(self) => self.geometry.computeVertexNormals()}
            onPointerOver={(e) => e.stopPropagation()}
            onPointerOut={(e) => e.stopPropagation()}
            scale={flattenTerrain || mode === EApplicationMode.BUFFER || !showTerrain ? [1, 1, 0.01] : [1, 1, 1]}
          >
            <meshStandardMaterial
              attach='material'
              transparent={topic === EApplicationTopic.PARKING_LOTS}
              opacity={0.6}
              side={topic === EApplicationTopic.PARKING_LOTS ? THREE.DoubleSide : THREE.FrontSide}
              onUpdate={(self) => {
                self.needsUpdate = true;
              }}
              clipShadows={true}
              map={texture}
            />
          </mesh>

          <lineSegments
            ref={terrainWireframeRef}
            name={EObjectName.TERRAIN_WIREFRAME}
            visible={topic === EApplicationTopic.TERRAIN && mode === EApplicationMode.EDIT}
          >
            {overlayGeometry && (
              <wireframeGeometry
                ref={wireframeGeometryRef}
                args={[overlayGeometry]}
                onUpdate={updateWireFrameIndices}
              />
            )}
            <lineBasicMaterial linewidth={10} color='#636363' />
          </lineSegments>

          <mesh
            name={EObjectName.GROUND_BOTTOM}
            geometry={bottomGeometry}
            onUpdate={(self) => self.geometry.computeVertexNormals()}
            onPointerOver={(e) => e.stopPropagation()}
            onPointerOut={(e) => e.stopPropagation()}
            renderOrder={2}
          >
            <meshStandardMaterial
              attach='material'
              color={'#f3f3f3'}
              transparent={topic === EApplicationTopic.PARKING_LOTS}
              opacity={0.6}
              side={THREE.DoubleSide}
              onUpdate={(self) => {
                self.needsUpdate = true;
              }}
            />
          </mesh>

          {topic === EApplicationTopic.TERRAIN && mode === EApplicationMode.EDIT && plotSurfaceCoordinates && (
            <TerrainPoints />
          )}

          {showOffsetTerrain && plotSurfaceCoordinates && <OffsetTerrain plotCoordinates={plotSurfaceCoordinates} />}
        </>
      )}

      {!showTerrain && gridCellWidth && <FlatGridHelper width={terrainWidth} cellSize={gridCellWidth} />}
      {tool === EApplicationTool.TERRAIN_RECTANGLE_SELECT && <TerrainRectangleSelection />}
    </group>
  );
};

export default BaseTerrain;
