import { areVectorsSimilar } from 'cityview/components/Building/Face/FaceSegment/interactions';
import { XYZ } from 'types/location/coordinates';

const edgeToString = (p1: XYZ, p2: XYZ): string => {
  return JSON.stringify([p1, p2].sort((a, b) => a[0] - b[0] || a[1] - b[1] || a[2] - b[2]));
};

export const mergeTrianglesIntoPolygon3d = (triangles: XYZ[][]): XYZ[] => {
  const edgeCount: { [key: string]: number } = {};

  // Add edges of each triangle
  triangles.forEach((triangle) => {
    const [p1, p2, p3] = triangle;
    const edges = [edgeToString(p1, p2), edgeToString(p2, p3), edgeToString(p3, p1)];

    edges.forEach((edge) => {
      if (edgeCount[edge]) {
        edgeCount[edge] += 1;
      } else {
        edgeCount[edge] = 1;
      }
    });
  });

  // Identify boundary edges (those that appear only once)
  const boundaryEdges: [XYZ, XYZ][] = [];

  triangles.forEach((triangle) => {
    const [p1, p2, p3] = triangle;
    const edges = [
      [p1, p2],
      [p2, p3],
      [p3, p1],
    ];

    edges.forEach((edge) => {
      const edgeStr = edgeToString(edge[0], edge[1]);
      if (edgeCount[edgeStr] === 1) {
        boundaryEdges.push(edge as [XYZ, XYZ]);
      }
    });
  });

  // Sort boundary edges to form a continuous loop
  const polygon: XYZ[] = [];
  let currentPoint = boundaryEdges[0][0];
  polygon.push(currentPoint);

  while (boundaryEdges.length > 0) {
    for (let i = 0; i < boundaryEdges.length; i++) {
      const [p1, p2] = boundaryEdges[i];

      if (areVectorsSimilar(currentPoint, p1, 1e-4)) {
        currentPoint = p2;
        polygon.push(currentPoint);
        boundaryEdges.splice(i, 1);
        break;
      } else if (areVectorsSimilar(currentPoint, p2, 1e-4)) {
        currentPoint = p1;
        polygon.push(currentPoint);
        boundaryEdges.splice(i, 1);
        break;
      }
    }
  }

  return polygon;
};
