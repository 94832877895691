import { alpha, Box, styled } from '@mui/material';
import { Html } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';

const CursorTooltipWrapper = styled(Html)({
  display: 'none',
  pointerEvents: 'none',
  userSelect: 'none',
});

const CursorTooltipContent = styled(Box)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.paper, 0.95),
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: 6,
  padding: theme.spacing(0.5, 0.75),
  width: 'max-content',
  maxWidth: 280,
}));

interface TooltipProps {
  children: React.ReactNode;
  xOffset?: number;
  yOffset?: number;
}

const CursorTooltip = (props: TooltipProps) => {
  const { children, xOffset = 15, yOffset = 15 } = props;

  const { gl } = useThree();
  const htmlRef = useRef<HTMLDivElement>(null);
  const mousePositionRef = useRef<[number, number]>([0, 0]);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      if (htmlRef.current && mousePositionRef.current) {
        htmlRef.current.style.display = 'block';
        mousePositionRef.current = [event.offsetX + xOffset, event.offsetY + yOffset];
      }
    };

    gl.domElement?.addEventListener('mousemove', handleMouseMove);

    return () => {
      if (htmlRef.current) {
        htmlRef.current.style.display = 'none';
      }
      gl.domElement?.removeEventListener('mousemove', handleMouseMove);
    };
  }, [gl.domElement]);

  return (
    <CursorTooltipWrapper ref={htmlRef} calculatePosition={() => mousePositionRef.current}>
      <CursorTooltipContent>{children}</CursorTooltipContent>
    </CursorTooltipWrapper>
  );
};

export default CursorTooltip;
