import { alpha, Box, Button, Theme, Typography } from '@mui/material';
import useModals from 'hooks/useModals';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CircleArrowUp } from 'theme/icons';
import { EModal } from 'types/enums/Modal';

const styles = {
  subscriptionButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    border: 1,
    borderColor: 'grey.300',
    borderRadius: 2,
    p: 3,
    pr: 4,
    mt: 6,
    width: '100%',
    '&:hover': {
      border: 1,
      borderColor: 'blue.300',
      backgroundColor: (theme: Theme) => alpha(theme.palette.blue[500], 0.05),
    },
  },
};

const ShopIntegrationsKeeValueView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setModal } = useModals();

  const handleOpenSubscriptionModal = () => {
    dispatch(
      setModal({
        modal: EModal.KEE_VALUE_SETTINGS,
        open: true,
      }),
    );
  };

  return (
    <Box sx={{ p: 6 }}>
      <Box sx={{ mb: 6 }}>
        <Typography variant='h5' component='h2' fontWeight={500} gutterBottom>
          {t('shop:views.shop.integrations.keeValue.title')}
        </Typography>
        <Typography variant='body2' color='textSecondary' sx={{ mb: 6 }}>
          {t('shop:views.shop.integrations.keeValue.description')}
        </Typography>
      </Box>
      <Box variant='outlined' component={Button} onClick={handleOpenSubscriptionModal} sx={styles.subscriptionButton}>
        <CircleArrowUp sx={{ color: 'blue.500', mr: 3 }} />
        <Typography variant='body2' fontWeight={500} textAlign='left' sx={{ fontSize: 12, color: 'blue.500' }}>
          {t('shop:views.shop.integrations.keeValue.connectAccount.title')}
        </Typography>
      </Box>
    </Box>
  );
};

export default ShopIntegrationsKeeValueView;
