import * as THREE from 'three';
import { BufferGeometry } from 'three';
import { ICoreType, TFootprint } from 'types/building/Building';
import { IBufferLineSegment } from 'types/building/BuildingBuffers';
import { TFace } from 'types/building/Face';
import { XY, XYZ } from 'types/location/coordinates';
import { distanceBetweenPoints, LineSegment2D } from '../components/Building/utils';

export const getFloorSurfaceGeometry = (footprint: TFootprint, z: number, height: number): THREE.BufferGeometry => {
  const polygon = footprint.map((coordinate) => new THREE.Vector2(coordinate[0], coordinate[1]));
  const faces = THREE.ShapeUtils.triangulateShape(polygon, []);

  const topPositions = faces.reduce((lastArray: number[], face: number[]) => {
    const v1 = footprint[face[0]];
    const v2 = footprint[face[1]];
    const v3 = footprint[face[2]];

    const safetyBuffer = 0.01;
    const floorZ = z + safetyBuffer;
    const ceilingZ = z + height;

    return [
      ...lastArray,
      ...[...[...v1, floorZ], ...[...v2, floorZ], ...[...v3, floorZ]],
      ...[...[...v1, ceilingZ], ...[...v2, ceilingZ], ...[...v3, ceilingZ]],
    ];
  }, [] as number[]);

  const topGeometry = new THREE.BufferGeometry();
  topGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(topPositions), 3));

  return topGeometry;
};

export const getBufferSurfaceGeometry = (segment: IBufferLineSegment): BufferGeometry => {
  const polygon = segment.coordinates.map((coordinate) => new THREE.Vector2(coordinate[0], coordinate[1]));
  const faces = THREE.ShapeUtils.triangulateShape(polygon, []);

  const positions = faces.reduce((lastArray: number[], face: number[]) => {
    const v1 = segment.coordinates[face[0]];
    const v2 = segment.coordinates[face[1]];
    const v3 = segment.coordinates[face[2]];

    return [...lastArray, ...[...[...v1, 0], ...[...v2, 0], ...[...v3, 0]]];
  }, [] as number[]);

  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(positions), 3));

  return geometry;
};

export const getWallNormal = (coordinates: TFace['coordinates']): XY => {
  const [[x1, y1], [x2, y2]] = coordinates;
  const dy = y2 - y1;
  const dx = x2 - x1;
  const d = Math.sqrt(Math.pow(dy, 2) + Math.pow(dx, 2));

  return [dy / d, -dx / d];
};

export const getWallGeometricParameters = (
  coordinates: TFace['coordinates'] | XY[],
  z: number,
  height: number,
): {
  direction: number;
  midpoint: XYZ;
  iconMidpoint: XYZ;
  normal: XY;
  intercept: number;
} => {
  const [[x1, y1], [x2, y2]] = coordinates;

  const midpoint: XYZ = [(x1 + x2) / 2, (y1 + y2) / 2, z + height / 2];
  const dy = y2 - y1;
  const dx = x2 - x1;
  const direction = Math.atan2(dy, dx) + Math.PI / 2;

  let normal: XY = [dy, -dx];
  const dNormal = Math.sqrt(Math.pow(normal[0], 2) + Math.pow(normal[1], 2));
  normal = [normal[0] / dNormal, normal[1] / dNormal];

  const intercept = -normal[0] * x1 - normal[1] * y1;

  const iconMidpoint: XYZ = [midpoint[0] + normal[0] * 0.05, midpoint[1] + normal[1] * 0.05, midpoint[2]];

  return {
    direction,
    midpoint,
    iconMidpoint,
    normal,
    intercept,
  };
};

interface ICoreProjection {
  [key: number]: {
    firstProjection: XY;
    secondProjection: XY;
  };
}

// interface ICorridorProjection {
//   [key: number]: {
//     firstProjection: XY;
//     secondProjection: XY;
//     thirdProjection: XY;
//     fourthProjection: XY;
//   };
// }

export const getCoreWallGeometry = (
  footprint: XY[],
  z: number,
  height: number,
  core: ICoreType,
  nrOfCores: number,
): {
  coreGeometries: THREE.BufferGeometry[];
  coreFootprints: XY[][];
} => {
  const { area, length } = core;
  const width = area / length;

  const longFootprintLine =
    distanceBetweenPoints(footprint[0], footprint[1]) >= distanceBetweenPoints(footprint[1], footprint[2])
      ? new LineSegment2D(footprint[0], footprint[1])
      : new LineSegment2D(footprint[1], footprint[2]);
  const shortFootprintLine =
    distanceBetweenPoints(footprint[0], footprint[1]) < distanceBetweenPoints(footprint[1], footprint[2])
      ? new LineSegment2D(footprint[0], footprint[1])
      : new LineSegment2D(footprint[1], footprint[2]);

  const isNarrowBuilding = shortFootprintLine.length < 13;

  const lengthToCoverByCore = longFootprintLine.length / nrOfCores;
  const lengthWithoutCore = isNarrowBuilding ? lengthToCoverByCore - length : lengthToCoverByCore - width;
  const coreSide = isNarrowBuilding ? length : width;
  const halfOppositeCoreSide = isNarrowBuilding ? width / 2 : length / 2;

  const coreProjections: ICoreProjection = {};
  for (let i = 1; i <= nrOfCores; i++) {
    coreProjections[i] = {
      firstProjection: longFootprintLine.pointOnLineByDistance(lengthWithoutCore / 2 + (i - 1) * lengthToCoverByCore),
      secondProjection: longFootprintLine.pointOnLineByDistance(
        lengthWithoutCore / 2 + (i - 1) * lengthToCoverByCore + coreSide,
      ),
    };
  }

  const coreFootprints: XY[][] = [];
  Object.values(coreProjections).forEach((coreProjection) => {
    const coreFootprint = [
      longFootprintLine.pointOnReverseNormalByPointAndDistance(
        coreProjection.firstProjection,
        shortFootprintLine.length / 2 - halfOppositeCoreSide,
      ),
      longFootprintLine.pointOnReverseNormalByPointAndDistance(
        coreProjection.secondProjection,
        shortFootprintLine.length / 2 - halfOppositeCoreSide,
      ),
      longFootprintLine.pointOnReverseNormalByPointAndDistance(
        coreProjection.secondProjection,
        shortFootprintLine.length / 2 + halfOppositeCoreSide,
      ),
      longFootprintLine.pointOnReverseNormalByPointAndDistance(
        coreProjection.firstProjection,
        shortFootprintLine.length / 2 + halfOppositeCoreSide,
      ),
      longFootprintLine.pointOnReverseNormalByPointAndDistance(
        coreProjection.firstProjection,
        shortFootprintLine.length / 2 - halfOppositeCoreSide,
      ),
    ];
    coreFootprints.push(coreFootprint);
  });

  const coreGeometries: THREE.BufferGeometry[] = [];
  coreFootprints.forEach((coreFootprint) => {
    for (let i = 0; i < coreFootprint.length - 1; i++) {
      const coreF1 = [
        ...[...coreFootprint[i], z],
        ...[...coreFootprint[i + 1], z],
        ...[...coreFootprint[i + 1], z + height],
      ];
      const coreF2 = [
        ...[...coreFootprint[i + 1], z + height],
        ...[...coreFootprint[i], z + height],
        ...[...coreFootprint[i], z],
      ];
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([...coreF1, ...coreF2]), 3));
      coreGeometries.push(geometry);
    }
    const topGeometry = getFloorSurfaceGeometry(coreFootprint, z, height - 0.1);
    coreGeometries.push(topGeometry);
  });

  return { coreGeometries, coreFootprints };
};

export const getCorridorWallGeometry = (
  footprint: XY[],
  z: number,
  height: number,
  core: ICoreType,
  nrOfCores: number,
  corridorArea: number,
  corridorWidth: number,
): THREE.BufferGeometry[] => {
  const shortFootprintLine =
    distanceBetweenPoints(footprint[0], footprint[1]) < distanceBetweenPoints(footprint[1], footprint[2])
      ? new LineSegment2D(footprint[0], footprint[1])
      : new LineSegment2D(footprint[1], footprint[2]);
  const isNarrowBuilding = shortFootprintLine.length < 13;

  const { coreFootprints } = getCoreWallGeometry(footprint, z, height, core, nrOfCores);

  const corridorTotalLength = corridorArea / corridorWidth;
  const corridorSegmentLength = corridorTotalLength / nrOfCores / 2;

  const corridorFootprints: XY[][] = [];
  coreFootprints.forEach((coreFootprint) => {
    const coreLines = [];
    for (let i = 0; i < coreFootprint.length - 1; i++) {
      coreLines.push(new LineSegment2D(coreFootprint[i], coreFootprint[i + 1]));
    }
    isNarrowBuilding ? coreLines.sort((a, b) => a.length - b.length) : coreLines.sort((a, b) => b.length - a.length);
    const longCoreLine1 = coreLines[0];
    const longCoreLine2 = coreLines[1];

    const firstCorridorPointOnCoreLine1 = longCoreLine1.pointOnLineByDistance(
      longCoreLine1.length / 2 - corridorWidth / 2,
    );
    const secondCorridorPointOnCoreLine1 = longCoreLine1.pointOnLineByDistance(
      longCoreLine1.length / 2 + corridorWidth / 2,
    );

    const firstCorridorPointOnCoreLine2 = longCoreLine2.pointOnLineByDistance(
      longCoreLine2.length / 2 - corridorWidth / 2,
    );
    const secondCorridorPointOnCoreLine2 = longCoreLine2.pointOnLineByDistance(
      longCoreLine2.length / 2 + corridorWidth / 2,
    );

    const corridorSegmentFootprint1 = [
      firstCorridorPointOnCoreLine1,
      longCoreLine1.pointOnNormalByPointAndDistance(firstCorridorPointOnCoreLine1, corridorSegmentLength),
      longCoreLine1.pointOnNormalByPointAndDistance(secondCorridorPointOnCoreLine1, corridorSegmentLength),
      secondCorridorPointOnCoreLine1,
      firstCorridorPointOnCoreLine1,
    ];
    const corridorSegmentFootprint2 = [
      firstCorridorPointOnCoreLine2,
      longCoreLine2.pointOnNormalByPointAndDistance(firstCorridorPointOnCoreLine2, corridorSegmentLength),
      longCoreLine2.pointOnNormalByPointAndDistance(secondCorridorPointOnCoreLine2, corridorSegmentLength),
      secondCorridorPointOnCoreLine2,
      firstCorridorPointOnCoreLine2,
    ];
    corridorFootprints.push(corridorSegmentFootprint1);
    corridorFootprints.push(corridorSegmentFootprint2);
  });

  const corridorGeometries: THREE.BufferGeometry[] = [];
  corridorFootprints.forEach((corridorFootprint) => {
    for (let i = 0; i < corridorFootprint.length - 1; i++) {
      const corridorF1 = [
        ...[...corridorFootprint[i], z],
        ...[...corridorFootprint[i + 1], z],
        ...[...corridorFootprint[i + 1], z + height],
      ];
      const corridorF2 = [
        ...[...corridorFootprint[i + 1], z + height],
        ...[...corridorFootprint[i], z + height],
        ...[...corridorFootprint[i], z],
      ];
      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array([...corridorF1, ...corridorF2]), 3));
      corridorGeometries.push(geometry);
    }
    const topGeometry = getFloorSurfaceGeometry(corridorFootprint, z, height - 0.1);
    corridorGeometries.push(topGeometry);
  });

  return corridorGeometries;
};

export const getPotentialCorner = (wall: TFace, height: number): XYZ => {
  const {
    coordinates: [[x1, y1], [x2, y2]],
  } = wall;
  const x = (x1 + x2) / 2;
  const y = (y1 + y2) / 2;
  return [x, y, height];
};
