import * as THREE from 'three';
import { mergeVertices } from 'three/examples/jsm/utils/BufferGeometryUtils';
import { XYZ } from 'types/location/coordinates';
import { TTerrain } from 'types/terrain';

class TerrainHelper {
  private readonly terrain: TTerrain;
  private lowestPoint: XYZ = [0, 0, 0];
  private highestPoint: XYZ = [0, 0, 0];

  private bottomGeometry: THREE.BufferGeometry;

  constructor(terrain: XYZ[][], lowestPoint?: XYZ, highestPoint?: XYZ) {
    this.terrain = terrain;

    if (lowestPoint && highestPoint) {
      this.lowestPoint = lowestPoint;
      this.highestPoint = highestPoint;
    } else {
      this._getMinimalAndMaximalPoints();
    }

    this.bottomGeometry = new THREE.BufferGeometry();

    this._addVertices();
  }

  getGroundBottomLevel() {
    return this.lowestPoint[2] - 10;
  }

  getBottomGeometry() {
    return this.bottomGeometry;
  }

  _addVertices() {
    const bottomVertices = [];

    for (let i = 0; i < this.terrain.length; i++) {
      if (i === 0 || i === this.terrain.length - 1) {
        // north and south sides
        for (let j = 0; j < this.terrain.length - 1; j++) {
          const v1 = this.terrain[i][j];
          const v2 = this.terrain[i][j + 1];
          bottomVertices.push(...this._getVerticesForVerticalFaces(v1, v2));
        }
        if (i === 0) this._addEastAndWestFaces(i, bottomVertices);
      } else this._addEastAndWestFaces(i, bottomVertices);
    }

    this.bottomGeometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(bottomVertices), 3));

    this.bottomGeometry = mergeVertices(this.bottomGeometry);
  }

  _addEastAndWestFaces(i: number, bottomVertices: number[]): void {
    const v1 = this.terrain[i][0];
    const v2 = this.terrain[i + 1][0];
    // east side
    bottomVertices.push(...this._getVerticesForVerticalFaces(v1, v2));

    const v11 = this.terrain[i][this.terrain.length - 1];
    const v22 = this.terrain[i + 1][this.terrain.length - 1];
    // west side
    bottomVertices.push(...this._getVerticesForVerticalFaces(v11, v22));
  }

  _getVerticesForVerticalFaces(v1: number[], v2: number[]): number[] {
    const z = this.getGroundBottomLevel();
    const v3 = [v1[0], v1[1], z];
    const v4 = [v2[0], v2[1], z];
    const firstFace = [...v1, ...v2, ...v3];
    const secondFace = [...v4, ...v3, ...v2];
    return [...firstFace, ...secondFace];
  }

  _getMinimalAndMaximalPoints() {
    let minimalHeight = 0;
    let maximalHeight = 0;

    for (let i = 0; i < this.terrain.length - 1; i++) {
      for (let j = 0; j < this.terrain.length - 1; j++) {
        const vertex = this.terrain[i][j];
        if (vertex[2] < minimalHeight) {
          minimalHeight = vertex[2];
          this.lowestPoint = vertex;
        }
        if (vertex[2] > maximalHeight) {
          maximalHeight = vertex[2];
          this.highestPoint = vertex;
        }
      }
    }
    if (!this.lowestPoint) {
      this.lowestPoint = this.terrain[0][0];
    }
    if (!this.highestPoint) {
      this.highestPoint = this.terrain[0][0];
    }
  }
}

export default TerrainHelper;
