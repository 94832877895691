import { AdaptiveEvents } from '@react-three/drei';
import { Props as FiberCanvasProps, useThree } from '@react-three/fiber';
import React, { useEffect } from 'react';
import * as THREE from 'three';
import { EApplicationMode } from 'types/applicationPath';
import { useApplicationPath, useCityViewLoadingStates } from '../../hooks';
import store from '../../store';
import { IExternalLoadingState } from '../../store/loaderStore';
import { getCameraPosition } from '../../utils/camera';
import Camera, { CameraProps } from '../Camera/Camera';
import CanvasDebugger from '../CanvasDebugger';
import Controls from '../Controls';
import Environment from '../Environment';
import { EnvironmentProps } from '../Environment/Environment';
import Loader from '../Loader';

import BaseCanvas from './BaseCanvas';
import styles from './Canvas.module.css';

const CanvasCamera = (props: CameraProps) => {
  const { isLoading } = useCityViewLoadingStates();

  const { gl, camera } = useThree();

  const { cameraAngle, cameraElevation, cameraRadius } = store.animations;

  const updateCameraPosition = () => {
    const [x, y, z] = getCameraPosition(cameraAngle.getValue(), cameraElevation.getValue(), cameraRadius.getValue());
    camera.position.set(x, y, z);
  };

  useEffect(() => {
    const canvasRef = gl.domElement;

    if (isLoading || !canvasRef) return;

    const { removeListener: removeAngleListener } = cameraAngle.onChange(() => {
      updateCameraPosition();
    });

    const { removeListener: removeElevationListener } = cameraElevation.onChange(() => {
      updateCameraPosition();
    });

    const { removeListener: removeRadiusListener } = cameraRadius.onChange(() => {
      updateCameraPosition();
    });

    updateCameraPosition();
    cameraAngle.setEffect((timeDelta, prevValue) => prevValue + timeDelta * 0.00003);

    const animationListener = () => {
      cameraAngle.stop();
      cameraElevation.stop();
      cameraRadius.stop();
    };
    canvasRef.addEventListener('mousedown', animationListener);
    canvasRef.addEventListener('wheel', animationListener);

    return () => {
      removeAngleListener();
      removeElevationListener();
      removeRadiusListener();

      if (!canvasRef) return;
      canvasRef.removeEventListener('mousedown', animationListener);
      canvasRef.removeEventListener('wheel', animationListener);
    };
  }, [isLoading]);

  useEffect(() => {
    if (store.general.applicationPath.mode === EApplicationMode.BUFFER) return;

    cameraRadius.moveTo(400, 50);
    cameraAngle.playEffect();
  }, [isLoading]);

  return <Camera {...props} />;
};

const canvasGlSettings = {
  antialias: true,
  toneMapping: THREE.NoToneMapping,
  logarithmicDepthBuffer: true,
};

export interface CanvasProps {
  children: React.ReactNode;
  CameraProps?: CameraProps;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
  CanvasProps?: FiberCanvasProps;
  EnvironmentProps?: EnvironmentProps;
  externalLoadingState?: IExternalLoadingState;
}

// Resets the coordinate system for three.js
THREE.Object3D.DEFAULT_UP = new THREE.Vector3(0, 0, 1);

const Canvas = (props: CanvasProps) => {
  const { children, CameraProps, CanvasProps, EnvironmentProps, externalLoadingState, canvasRef } = props;

  const { isLoading } = useCityViewLoadingStates();

  const applicationPath = useApplicationPath();

  return (
    <div className={styles.CanvasWrapper}>
      <BaseCanvas
        ref={canvasRef}
        shadows={true}
        externalLoadingState={externalLoadingState}
        applicationPath={applicationPath}
        {...CanvasProps}
        gl={{
          ...canvasGlSettings,
          ...CanvasProps?.gl,
        }}
        style={{
          opacity: isLoading ? 0 : 1,
          transitionProperty: 'opacity',
          transitionTimingFunction: 'ease-in-out',
          transitionDelay: isLoading ? '0ms' : '250ms',
          transitionDuration: isLoading ? '0ms' : '250ms',
          ...CanvasProps?.style,
        }}
      >
        <AdaptiveEvents />
        <CanvasDebugger />
        <Controls />
        <CanvasCamera {...CameraProps} />
        {EnvironmentProps?.coordinates && <Environment {...EnvironmentProps} />}
        {children}
      </BaseCanvas>
      <Loader
        // debug={true}
        style={{
          maxWidth: '50vw',
        }}
      />
    </div>
  );
};

export default Canvas;
