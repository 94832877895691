export enum EBuildingRestrictions {
  MIN_WALL_LENGTH = 1,
  MIN_ANGLE_BETWEEN_WALLS = 15,
  MIN_BUILDING_THICKNESS = 1,
  MIN_WALL_DRAG_DISTANCE = 0.25,
}

export enum ESnapConstant {
  POINT_SNAP_DISTANCE = 1,
  WALL_SNAP_DISTANCE = 0.25,
  NORMAL_DIFFERENCE_THRESHOLD = 0.01,
}
