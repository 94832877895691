import SunCalc from 'suncalc';
import { Vector3 } from 'three';

import { getDateTime } from './time';

export const getSunCoordinates = (month: number, minutes: number, lat: number, lng: number, r: number) => {
  const dateTime = getDateTime(month, minutes);
  const sunPosition = SunCalc.getPosition(dateTime, lat, lng);

  const coords = new Vector3().setFromSphericalCoords(r, sunPosition.altitude - Math.PI / 2, sunPosition.azimuth);

  return { x: coords.x, z: coords.z, y: coords.y };
};

export const getSunTimes = (month: number, lat: number, lng: number) => {
  const times = SunCalc.getTimes(getDateTime(month, 0), lat, lng);

  return {
    sunset: times.sunset,
    sunrise: times.sunrise,
    sunriseMinutes: times.sunrise.getHours() * 60 + times.sunrise.getMinutes(),
    sunsetMinutes: times.sunset.getHours() * 60 + times.sunset.getMinutes(),
    times,
  };
};
