import { terrainStore } from 'cityview/store/';
import { derivedTerrainStore } from 'cityview/store/terrainStore';
import { generateUUID } from 'three/src/math/MathUtils';
import { IBuilding } from 'types/building/Building';
import { EBufferType, IBufferObject } from 'types/building/BuildingBuffers';
import {
  EFaceDirection,
  IBuildingWall,
  IFacadeFace,
  IFacadeVariant,
  IFloorFacade,
  ISegmentListItem,
  ISelectedFacadeProperties,
  IWallLayoutGroup,
} from 'types/building/Facade';
import { EFaceType, TFace } from 'types/building/Face';
import {
  ESegmentOpening,
  ESegmentWallPart,
  EWallLayout,
  IFaceSegment,
  TSegmentWallPartMaterials,
} from 'types/building/FaceSegment';
import { EFloorShape, EFloorType, IFloor } from 'types/building/Floor';
import {
  EPVActivationState,
  IBuildingPvFloors,
  IBuildingPvFloorWalls,
  IBuildingPvVariants,
  IBuildingPvWall,
  IBuildingPvWallVariants,
  IPvFaceVariant,
  IPvFloorVariants,
  IPvFloorWallVariants,
  IRoofFacePvVariants,
  IRoofSegmentPvVariants,
  ISelectedRoofSegmentProperties,
} from 'types/building/Pv';
import { ILngLat, XY, XYZ } from 'types/location/coordinates';
import { TTerrain } from 'types/terrain';
import {
  calculatePolygonSurfaceNormal,
  typeVectorToNumberArray,
  typeXYZtoPoint,
} from '../../components/Building/Face/FaceSegment/geometry';
import { getSimilarFacesFromFaces } from '../../components/Building/Face/FaceSegment/interactions';
import { getBuildingFootprint, getBuildingFootprintWgs, isSegmentAboveGround } from '../../components/Building/utils';
import { findGroundHeightEstimation } from '../../components/Terrain/utils';
import { getDimensionsFromFootprint } from '../../hooks/useBuildingDimensions';
import { getCalculatedBufferDistance } from '../../utils/bufferCalculations';
import { addExtraVertices } from '../../utils/vertexModification';

import buildingStore from '../buildingStore';
import { getFloorFootprint } from './floor';
import { isAnyObjectSelected, isObjectSelected } from './general';

export const getBuilding = (buildingId: string): IBuilding | undefined =>
  buildingStore.value.projectBuildings[buildingId];

export const getBuildingZById = (buildingId: string): number | undefined => {
  const building = getBuilding(buildingId);
  return building ? getBuildingZ(building) : undefined;
};

export const getBuildingZ = (building: IBuilding): number => {
  return Object.values(building.floors).sort((a, b) => a.properties.order - b.properties.order)[0].properties.z;
};

export const getBuildingHeightById = (buildingId: string): number | undefined => {
  const building = getBuilding(buildingId);
  return building ? getBuildingHeight(building) : undefined;
};

export const getBuildingHeight = (building: IBuilding, excludeUnderground?: boolean): number =>
  Object.values(building.floors).reduce((sum, floor) => {
    if (excludeUnderground && floor.properties.type === EFloorType.UG) return sum;

    return sum + floor.properties.height;
  }, 0);

export const getBuildingBuffersById = (buildingId: string): IBufferObject[] | undefined => {
  const building = getBuilding(buildingId);
  return building ? building.buffers : undefined;
};

export const getBufferById = (buildingId: string, bufferId: string): IBufferObject | undefined => {
  const buffers = getBuildingBuffersById(buildingId);
  if (!buffers) return undefined;
  return buffers.find((buffer) => `${buildingId}-${buffer.order}` === bufferId);
};

export const getBuildingFacadeFaces = (buildingId: string): TFace[] => {
  const building = getBuilding(buildingId);
  if (!building) return [];

  const terrain = terrainStore.value.originalTerrain.terrainData;
  if (!terrain) return [];

  return Object.values(building.floors).reduce((acc, floor) => {
    if (floor.properties.shape !== EFloorShape.ROOF) {
      return acc.concat(floor.faces.filter((face) => face.type === EFaceType.WALL));
    } else {
      return acc;
    }
  }, [] as TFace[]);
};

export const getBuildingFacadeSegments = (buildingId: string, testTerrain?: boolean): IFaceSegment[] => {
  const building = getBuilding(buildingId);
  if (!building) return [];

  const { mergedModifiedTerrain } = derivedTerrainStore;
  if (!mergedModifiedTerrain) return [];

  return Object.values(building.floors).reduce((acc, floor) => {
    if (floor.properties.shape !== EFloorShape.ROOF) {
      return acc.concat(
        floor.faces
          .filter((face) => face.type === EFaceType.WALL)
          .flatMap((face) =>
            face.segments.filter((segment) =>
              testTerrain ? isSegmentAboveGround(segment, floor, mergedModifiedTerrain) : true,
            ),
          ),
      );
    } else {
      return acc;
    }
  }, [] as IFaceSegment[]);
};

export const getBuildingWallSegments = (buildingId: string): IFaceSegment[] => {
  const building = getBuilding(buildingId);
  if (!building) return [];

  return Object.values(building.floors).reduce((acc, floor) => {
    return acc.concat(floor.faces.filter((face) => face.type === EFaceType.WALL).flatMap((face) => face.segments));
  }, [] as IFaceSegment[]);
};

export const getFloorWallSegments = (floor: IFloor): IFaceSegment[] => {
  return floor.faces.filter((face) => face.type === EFaceType.WALL).flatMap((face) => face.segments);
};

export const getBuildingWallFacades = (buildingId: string): IBuildingWall[] => {
  // go through all faces on all floors and find similar faces for each of them. push the similar faces as an IBuildingWall to the array. each turn check if the face is already in one of the IBuildingWalls. if yes, skip the wall and check the next one. if no, create a new IBuildingWall with similar faces and push it to the array.
  const building = getBuilding(buildingId);
  if (!building) return [];

  const buildingWalls: IBuildingWall[] = [];
  const usedFaceIds: string[] = [];
  const buildingFaces = getBuildingFacadeFaces(buildingId);
  Object.values(building.floors)
    .filter((floor) => floor.properties.shape !== EFloorShape.ROOF)
    .forEach((floor) => {
      floor.faces.forEach((face) => {
        if (face.type === EFaceType.WALL && face.id && !usedFaceIds.includes(face.id)) {
          const similarFaces = getSimilarFacesFromFaces(buildingFaces, face);
          const { direction, directionName } = getFaceDirection(face);

          const facadeFaces = [face, ...similarFaces];

          usedFaceIds.push(...facadeFaces.map((facadeFace) => facadeFace.id));

          const facadeSegments = facadeFaces.reduce((acc, facadeFace) => {
            return [...acc, ...facadeFace.segments];
          }, [] as IFaceSegment[]);

          const selectedSegmentCount = facadeSegments.filter((segment) => isObjectSelected(segment.id)).length;

          const totalSegmentCount = facadeSegments.length;

          const segmentIds = facadeSegments.map((segment) => segment.id);

          buildingWalls.push({
            id: generateUUID(),
            direction,
            directionName,
            faces: facadeFaces,
            openingsOnWall: getOpeningsOnSegments(facadeSegments),
            selectedSegmentCount,
            totalSegmentCount,
            segmentIds,
          });
        }
      });
    });
  return buildingWalls.filter((wall) => wall.totalSegmentCount > 0);
};

export const getOpeningsInBuilding = (buildingId: string): ESegmentOpening[] => {
  const openings = getBuildingFacadeSegments(buildingId).reduce((acc, segment) => {
    return [...acc, segment.properties.opening];
  }, [] as ESegmentOpening[]);

  return [...new Set(openings)];
};

export const getOpeningsOnSegments = (segments: IFaceSegment[]): ESegmentOpening[] => {
  const openings: ESegmentOpening[] = [];

  segments.forEach((segment) => {
    openings.push(segment.properties.opening);
  });

  return [...new Set(openings)];
};

export const getTopHeightsOnSegments = (segments: IFaceSegment[]): number[] => {
  const heights: number[] = [];

  segments.forEach((segment) => {
    heights.push(segment.properties.wallProperties.top.variableHeight);
  });

  return [...new Set(heights)];
};

export const getBottomHeightsOnSegments = (segments: IFaceSegment[]): number[] => {
  const heights: number[] = [];

  segments.forEach((segment) => {
    heights.push(segment.properties.wallProperties.bottom.height);
  });

  return [...new Set(heights)];
};

export const getSideWidthsOnSegments = (segments: IFaceSegment[]): number[] => {
  const widths: number[] = [];

  segments.forEach((segment) => {
    widths.push(segment.properties.wallProperties.sides.width);
  });

  return [...new Set(widths)];
};

export const getWallMaterialsOnSegments = (segments: IFaceSegment[]) => {
  const materials: TSegmentWallPartMaterials = {
    [ESegmentWallPart.TOP]: {
      colors: [] as string[],
      isPV: [] as boolean[],
    },
    [ESegmentWallPart.SIDES]: {
      colors: [] as string[],
      isPV: [] as boolean[],
    },
    [ESegmentWallPart.BOTTOM]: {
      colors: [] as string[],
      isPV: [] as boolean[],
    },
    [ESegmentWallPart.CENTER]: {
      colors: [] as string[],
      isPV: [] as boolean[],
    },
  };

  segments.forEach((segment) => {
    Object.values(ESegmentWallPart).forEach((part) => {
      if (!materials[part].colors.includes(segment.properties.wallProperties[part].color))
        materials[part].colors.push(segment.properties.wallProperties[part].color);
      if (!materials[part].isPV.includes(segment.properties.wallProperties[part].hasPv))
        materials[part].isPV.push(segment.properties.wallProperties[part].hasPv);
    });
  });

  return materials;
};

export const getPVActivationOnSegments = (segments: IFaceSegment[]): EPVActivationState => {
  const pvActive = segments.every((segment) => segment.properties.pvActive);
  const pvInactive = segments.every((segment) => !segment.properties.pvActive);

  if (pvActive) return EPVActivationState.ACTIVE;
  if (pvInactive) return EPVActivationState.INACTIVE;
  return EPVActivationState.MIXED;
};

export const getFacadeFloorsInBuilding = (buildingId: string): IFloorFacade[] => {
  const building = getBuilding(buildingId);
  if (!building) return [];

  const terrain = terrainStore.value.originalTerrain.terrainData;
  if (!terrain) return [];

  return Object.values(building.floors)
    .filter((floor) => floor.properties.shape !== EFloorShape.ROOF)
    .map((floor) => {
      const {
        id,
        faces,
        properties: { type, order },
      } = floor;
      const facesWithFacadeSegments = faces.filter((face) => face.type === EFaceType.WALL);
      const segments = facesWithFacadeSegments.reduce((acc, face) => [...acc, ...face.segments], [] as IFaceSegment[]);
      const openingsOnFloor = getOpeningsOnSegments(segments);

      const facesWithSegmentsFiltered = facesWithFacadeSegments.map((face) => {
        return { ...face, segments: face.segments };
      });

      return {
        floorId: id,
        selectedSegmentCount: segments.filter((segment) => isObjectSelected(segment.id)).length,
        totalSegmentCount: segments.length,
        floorNumber: order,
        floorType: type,
        facadeFaces: getFacadeFaces(facesWithSegmentsFiltered),
        segmentIds: segments.map((segment) => segment.id),
        openingsOnFloor,
      };
    })
    .filter((item) => item.totalSegmentCount > 0);
};

export const getFacadeFaces = (faces: TFace[]): IFacadeFace[] => {
  return faces.map((face) => {
    const { direction, directionName } = getFaceDirection(face);

    return {
      selectedSegmentsCount: face.segments.filter((segment) => isObjectSelected(segment.id)).length,
      totalSegmentsCount: face.segments.length,
      direction,
      directionName,
      face,
      openingsOnFacade: getOpeningsOnSegments(face.segments),
      segmentIds: face.segments.map((segment) => segment.id),
    };
  });
};

export const getFacadeSegmentVariantsInBuilding = (buildingId: string): IFacadeVariant[] => {
  const building = getBuilding(buildingId);
  if (!building) return [];

  const buildingSegments = getBuildingFacadeSegments(buildingId);

  const openingGroups: Record<ESegmentOpening, IFaceSegment[]> = {
    [ESegmentOpening.WINDOW]: [],
    [ESegmentOpening.DOOR]: [],
    [ESegmentOpening.NONE]: [],
  };

  buildingSegments.forEach((buildingSegment) => {
    openingGroups[buildingSegment.properties.opening].push(buildingSegment);
  });

  const variants: IFacadeVariant[] = [];

  Object.entries(openingGroups).forEach(([opening, segments]) => {
    const selectedSegmentCount = segments.filter((segment) => isObjectSelected(segment.id)).length;
    const totalSegmentCount = segments.length;
    const segmentIds = segments.map((segment) => segment.id);

    const wallLayoutGroups = segments.reduce((acc, segment) => {
      const wallLayout = segment.properties.wallLayout;
      const wallLayoutGroupIndex = acc.findIndex((group) => group.wallLayout === wallLayout);

      if (wallLayoutGroupIndex !== -1) {
        acc[wallLayoutGroupIndex].selectedSegmentCount += isObjectSelected(segment.id) ? 1 : 0;
        acc[wallLayoutGroupIndex].totalSegmentCount += 1;
        acc[wallLayoutGroupIndex].segmentIds.push(segment.id);
      } else {
        acc.push({
          selectedSegmentCount: isObjectSelected(segment.id) ? 1 : 0,
          totalSegmentCount: 1,
          wallLayout,
          segmentIds: [segment.id],
        });
      }

      return acc;
    }, [] as IWallLayoutGroup[]);

    variants.push({
      selectedSegmentCount,
      totalSegmentCount,
      opening: opening as ESegmentOpening,
      wallLayoutGroups,
      segmentIds,
    });
  });

  return variants.filter((variant) => variant.totalSegmentCount > 0);
};

export const getFaceDirection = (face: TFace) => {
  const faceNormal = typeVectorToNumberArray(
    calculatePolygonSurfaceNormal(face.coordinates.map((xyz) => typeXYZtoPoint(xyz))),
  );
  const [x, y] = faceNormal;
  const direction = normalToDirection([x, y]);
  const directionName = getDirectionName(direction);

  return { direction, directionName };
};

export const normalToDirection = (normal: XY) => {
  // 0 to 360
  let dir = Math.PI / 2 - Math.atan2(normal[1], normal[0]);

  dir = dir % (2 * Math.PI);
  dir = dir < 0 ? dir + 2 * Math.PI : dir;
  dir = (dir * 180) / Math.PI;

  return dir;
};

const getDirectionName = (direction: number): EFaceDirection => {
  if (direction > 337.5 || direction <= 22.5) {
    return EFaceDirection.NORTH;
  } else if (direction > 22.5 && direction <= 67.5) {
    return EFaceDirection.NORTH_EAST;
  } else if (direction > 67.5 && direction <= 112.5) {
    return EFaceDirection.EAST;
  } else if (direction > 112.5 && direction <= 157.5) {
    return EFaceDirection.SOUTH_EAST;
  } else if (direction > 157.5 && direction <= 202.5) {
    return EFaceDirection.SOUTH;
  } else if (direction > 202.5 && direction <= 247.5) {
    return EFaceDirection.SOUTH_WEST;
  } else if (direction > 247.5 && direction <= 292.5) {
    return EFaceDirection.WEST;
  } else if (direction > 292.5 && direction <= 337.5) {
    return EFaceDirection.NORTH_WEST;
  } else {
    throw new Error('Invalid direction value');
  }
};

export const getCalculatedBufferDistanceById = (
  buildingId: string,
  coordinates: XY[],
  bufferType: EBufferType,
  buildingHeight: number,
): number | undefined => {
  const building = getBuilding(buildingId);
  if (!building) return undefined;

  return getCalculatedBufferDistance(coordinates, bufferType, buildingHeight);
};

export const getBuildingDimensions = (
  buildingId: string,
): {
  length: number;
  width: number;
} => {
  const building = getBuilding(buildingId);
  if (!building) return { length: 0, width: 0 };

  const footprint = getBuildingFootprint(building);

  return getDimensionsFromFootprint(footprint);
};

export const getBuildingFootprintWgsById = (buildingId: string, center: ILngLat) => {
  const building = getBuilding(buildingId);
  if (!building) return [];
  return getBuildingFootprintWgs(building, center);
};

export const getBuildingHeights = (
  buildingId: string,
  terrain: TTerrain,
  midpointElevation?: number,
): {
  totalHeight: number;
  maxHeightFromSeaLevel: number;
} => {
  const result = {
    totalHeight: 0,
    maxHeightFromSeaLevel: 0,
  };

  const building = getBuilding(buildingId);
  if (!building) return result;

  let pointsToObserve: [number, number][] = [];
  let maxPoint = 0;

  const hasFlatRoof = getBuildingHasFlatRoof(building);
  if (hasFlatRoof) {
    const lastFloor = Object.values(building.floors).sort((a, b) => b.properties.order - a.properties.order)[0];
    const footprint = getFloorFootprint(lastFloor);
    pointsToObserve = addExtraVertices(footprint, 1).map((p) => [p[0], p[1]]);
    maxPoint = lastFloor.properties.z + lastFloor.properties.height;
  } else {
    const attic: IFloor = Object.values(building.floors).find((f) => f.properties.shape === EFloorShape.ROOF)!;

    maxPoint = attic.properties.z + attic.properties.height + (attic.properties.baseHeight || 0);
    attic.faces
      .filter((f) => f.type === EFaceType.ROOF)
      .forEach((face) => {
        const ridgePoints: XYZ[] = face.coordinates.filter((p) => {
          return Math.abs(p[2] - maxPoint) < 1e-4;
        });
        if (ridgePoints.length === 2) {
          const ridgeLine: [number, number][] = ridgePoints.map((p) => [p[0], p[1]]);
          const ridgeWithExtraCorners: [number, number][] = addExtraVertices(ridgeLine, 1).map((p) => [p[0], p[1]]);
          pointsToObserve.push(...ridgeWithExtraCorners);
        }
      });
  }

  let minPoint = Infinity;
  pointsToObserve.forEach((corner) => {
    const z = findGroundHeightEstimation(corner, terrain);
    if (z < minPoint) minPoint = z;
  });

  result.totalHeight = maxPoint - minPoint;
  result.maxHeightFromSeaLevel = maxPoint + (midpointElevation || 0);

  return result;
};

const getBuildingHasFlatRoof = (building: IBuilding): boolean => {
  const hasSlopedRood = Object.values(building.floors).some(
    (f) => f.properties.type === EFloorType.DG && f.properties.shape === EFloorShape.ROOF,
  );
  return !hasSlopedRood;
};

export const getWallLayoutsOnSegments = (segments: IFaceSegment[]): EWallLayout[] => {
  const usedWallLayouts = segments.map((segment) => segment.properties.wallLayout);
  return [...new Set(usedWallLayouts)];
};

export const getSegmentsByIds = (segmentIds: string[]): IFaceSegment[] => {
  const segments: IFaceSegment[] = [];
  Object.values(buildingStore.value.projectBuildings).forEach((building) => {
    Object.values(building.floors).forEach((floor) => {
      floor.faces.forEach((face) => {
        face.segments.forEach((segment) => {
          if (segmentIds.includes(segment.id)) segments.push(segment);
        });
      });
    });
  });

  return segments;
};

export const getSegmentFacadeProperties = (segmentIds: string[]): ISelectedFacadeProperties => {
  const segments = getSegmentsByIds(segmentIds);
  if (!segments.length) return {} as ISelectedFacadeProperties;

  const openings = getOpeningsOnSegments(segments);
  const wallLayouts = getWallLayoutsOnSegments(segments);
  const wallMaterials = getWallMaterialsOnSegments(segments);
  const topHeights = getTopHeightsOnSegments(segments);
  const bottomHeights = getBottomHeightsOnSegments(segments);
  const sidesWidths = getSideWidthsOnSegments(segments);
  const pvIsActive = getPVActivationOnSegments(segments);

  const allHaveBalconies = segments.every((segment) => segment.properties.hasBalcony);
  const hasBalcony =
    segments.some((segment) => segment.properties.hasBalcony) && !allHaveBalconies ? null : allHaveBalconies;

  const balconyWidths = segments.map((segment) => segment.properties.balconyWidth);
  const allBalconyWidthsEqual = balconyWidths.every((width) => width === balconyWidths[0]);
  const balconyWidth = allBalconyWidthsEqual ? balconyWidths[0] : null;

  const balconyDividers = segments.every(
    (segment) => segment.properties.balconyDividers === segments[0].properties.balconyDividers,
  )
    ? segments[0].properties.balconyDividers
    : null;

  return {
    openings,
    wallLayouts,
    hasBalcony,
    balconyDividers,
    balconyWidth,
    wallMaterials,
    topHeights,
    bottomHeights,
    sidesWidths,
    pvIsActive,
  };
};

export const getRoofSegmentProperties = (segmentIds: string[]): ISelectedRoofSegmentProperties => {
  const segments = getSegmentsByIds(segmentIds);
  if (!segments.length) return {} as ISelectedRoofSegmentProperties;

  const roofPvPercentages = segments.reduce((acc, segment) => {
    if (segment.properties.roofPvPercentage !== undefined) {
      acc.push(segment.properties.roofPvPercentage);
    }
    return acc;
  }, [] as number[]);

  return {
    roofPvPercentages,
  };
};

export const getPvVariantsInBuilding = (buildingId: string): IBuildingPvVariants => {
  const variants = {
    totalSegmentCount: 0,
    selectedSegmentCount: 0,
    segmentIds: [] as string[],
    segmentsWithActivePv: {
      totalSegmentCount: 0,
      selectedSegmentCount: 0,
      segmentIds: [] as string[],
    },
    segmentsWithInactivePv: {
      totalSegmentCount: 0,
      selectedSegmentCount: 0,
      segmentIds: [] as string[],
    },
    segmentsWithNoPv: {
      totalSegmentCount: 0,
      selectedSegmentCount: 0,
      segmentIds: [] as string[],
    },
  };
  const building = getBuilding(buildingId);

  if (!building) return variants;

  const buildingSegments = getBuildingFacadeSegments(buildingId);

  variants.totalSegmentCount = buildingSegments.length;
  variants.selectedSegmentCount = buildingSegments.filter((segment) => isObjectSelected(segment.id)).length;
  variants.segmentIds = buildingSegments.map((segment) => segment.id);

  const { segmentsWithActivePv, segmentsWithInactivePv, segmentsWithNoPv } = getPvVariants(buildingSegments);

  variants.segmentsWithActivePv.totalSegmentCount = segmentsWithActivePv.totalSegmentCount;
  variants.segmentsWithActivePv.selectedSegmentCount = segmentsWithActivePv.selectedSegmentCount;
  variants.segmentsWithActivePv.segmentIds = segmentsWithActivePv.segmentIds;

  variants.segmentsWithInactivePv.totalSegmentCount = segmentsWithInactivePv.totalSegmentCount;
  variants.segmentsWithInactivePv.selectedSegmentCount = segmentsWithInactivePv.selectedSegmentCount;
  variants.segmentsWithInactivePv.segmentIds = segmentsWithInactivePv.segmentIds;

  variants.segmentsWithNoPv.totalSegmentCount = segmentsWithNoPv.totalSegmentCount;
  variants.segmentsWithNoPv.selectedSegmentCount = segmentsWithNoPv.selectedSegmentCount;
  variants.segmentsWithNoPv.segmentIds = segmentsWithNoPv.segmentIds;

  return variants;
};

export const getPvVariantsOnFloors = (buildingId: string): IBuildingPvFloors => {
  const variants = {
    totalSegmentCount: 0,
    selectedSegmentCount: 0,
    segmentIds: [] as string[],
    floors: [] as IPvFloorVariants[],
  };
  const building = getBuilding(buildingId);

  if (!building) return variants;

  const buildingSegments = getBuildingFacadeSegments(buildingId);
  variants.totalSegmentCount = buildingSegments.length;
  variants.selectedSegmentCount = buildingSegments.filter((segment) => isObjectSelected(segment.id)).length;
  variants.segmentIds = buildingSegments.map((segment) => segment.id);

  const buildingFloors = Object.values(building.floors).filter((floor) => floor.properties.shape !== EFloorShape.ROOF);

  buildingFloors.forEach((floor) => {
    const floorSegments = getFloorWallSegments(floor);
    const selectedSegmentCount = floorSegments.filter((segment) => isObjectSelected(segment.id)).length;
    const totalSegmentCount = floorSegments.length;
    const segmentIds = floorSegments.map((segment) => segment.id);

    const { segmentsWithActivePv, segmentsWithInactivePv, segmentsWithNoPv } = getPvVariants(floorSegments);

    variants.floors.push({
      floorId: floor.id,
      selectedSegmentCount,
      totalSegmentCount,
      segmentIds,
      segmentsWithActivePv: {
        totalSegmentCount: segmentsWithActivePv.totalSegmentCount,
        selectedSegmentCount: segmentsWithActivePv.selectedSegmentCount,
        segmentIds: segmentsWithActivePv.segmentIds,
      },
      segmentsWithInactivePv: {
        totalSegmentCount: segmentsWithInactivePv.totalSegmentCount,
        selectedSegmentCount: segmentsWithInactivePv.selectedSegmentCount,
        segmentIds: segmentsWithInactivePv.segmentIds,
      },
      segmentsWithNoPv: {
        totalSegmentCount: segmentsWithNoPv.totalSegmentCount,
        selectedSegmentCount: segmentsWithNoPv.selectedSegmentCount,
        segmentIds: segmentsWithNoPv.segmentIds,
      },
    });
  });

  return variants;
};

export const getPvVariantsOnFacadeWalls = (buildingId: string): IBuildingPvWallVariants => {
  // go through all faces on all floors and find similar faces for each of them. push the similar faces as an IBuildingWall to the array. each turn check if the face is already in one of the IBuildingWalls. if yes, skip the wall and check the next one. if no, create a new IBuildingWall with similar faces and push it to the array.
  const building = getBuilding(buildingId);
  if (!building)
    return {
      totalSegmentCount: 0,
      selectedSegmentCount: 0,
      segmentIds: [] as string[],
      walls: [],
    };

  const buildingSegments = getBuildingFacadeSegments(buildingId);

  const buildingWalls: IBuildingPvWall[] = [];
  const usedFaceIds: string[] = [];
  const buildingFaces = getBuildingFacadeFaces(buildingId);
  Object.values(building.floors)
    .filter((floor) => floor.properties.shape !== EFloorShape.ROOF)
    .forEach((floor) => {
      floor.faces.forEach((face) => {
        if (face.type === EFaceType.WALL && face.id && !usedFaceIds.includes(face.id)) {
          const similarFaces = getSimilarFacesFromFaces(buildingFaces, face);
          const { direction, directionName } = getFaceDirection(face);

          const facadeFaces = [face, ...similarFaces];

          usedFaceIds.push(...facadeFaces.map((facadeFace) => facadeFace.id));

          const facadeSegments = facadeFaces.reduce((acc, facadeFace) => {
            return [...acc, ...facadeFace.segments];
          }, [] as IFaceSegment[]);

          const selectedSegmentCount = facadeSegments.filter((segment) => isObjectSelected(segment.id)).length;

          const totalSegmentCount = facadeSegments.length;

          const segmentIds = facadeSegments.map((segment) => segment.id);

          const { segmentsWithActivePv, segmentsWithInactivePv, segmentsWithNoPv } = getPvVariants(facadeSegments);

          buildingWalls.push({
            id: 'PvWall-' + buildingWalls.length,
            direction,
            directionName,
            selectedSegmentCount,
            totalSegmentCount,
            segmentIds,
            segmentsWithActivePv: {
              totalSegmentCount: segmentsWithActivePv.totalSegmentCount,
              selectedSegmentCount: segmentsWithActivePv.selectedSegmentCount,
              segmentIds: segmentsWithActivePv.segmentIds,
            },
            segmentsWithInactivePv: {
              totalSegmentCount: segmentsWithInactivePv.totalSegmentCount,
              selectedSegmentCount: segmentsWithInactivePv.selectedSegmentCount,
              segmentIds: segmentsWithInactivePv.segmentIds,
            },
            segmentsWithNoPv: {
              totalSegmentCount: segmentsWithNoPv.totalSegmentCount,
              selectedSegmentCount: segmentsWithNoPv.selectedSegmentCount,
              segmentIds: segmentsWithNoPv.segmentIds,
            },
          });
        }
      });
    });

  return {
    totalSegmentCount: buildingSegments.length,
    selectedSegmentCount: buildingSegments.filter((segment) => isObjectSelected(segment.id)).length,
    segmentIds: buildingSegments.map((segment) => segment.id),
    walls: buildingWalls.filter((wall) => wall.totalSegmentCount > 0),
  };
};

export const getPvVariantsOnFloorWalls = (buildingId: string): IBuildingPvFloorWalls => {
  const building = getBuilding(buildingId);
  if (!building)
    return {
      totalSegmentCount: 0,
      selectedSegmentCount: 0,
      segmentIds: [] as string[],
      floors: [] as IPvFloorWallVariants[],
    };

  const buildingSegments = getBuildingFacadeSegments(buildingId);

  const floors = Object.values(building.floors)
    .filter((floor) => floor.properties.shape !== EFloorShape.ROOF)
    .map((floor) => {
      const {
        id,
        faces,
        properties: { type, order },
      } = floor;
      const facesWithFacadeSegments = faces.filter((face) => face.type === EFaceType.WALL);
      const floorSegments = facesWithFacadeSegments.reduce(
        (acc, face) => [...acc, ...face.segments],
        [] as IFaceSegment[],
      );

      const pvFaces = getPvFaces(facesWithFacadeSegments);

      return {
        floorId: id,
        selectedSegmentCount: floorSegments.filter((segment) => isObjectSelected(segment.id)).length,
        totalSegmentCount: floorSegments.length,
        segmentIds: floorSegments.map((segment) => segment.id),
        floorNumber: order,
        floorType: type,
        faces: pvFaces,
      };
    })
    .filter((item) => item.totalSegmentCount > 0);

  return {
    totalSegmentCount: buildingSegments.length,
    selectedSegmentCount: buildingSegments.filter((segment) => isObjectSelected(segment.id)).length,
    segmentIds: buildingSegments.map((segment) => segment.id),
    floors,
  };
};

const getPvFaces = (faces: TFace[]): IPvFaceVariant[] => {
  return faces.map((face) => {
    const { direction, directionName } = getFaceDirection(face);

    const { segmentsWithActivePv, segmentsWithInactivePv, segmentsWithNoPv } = getPvVariants(face.segments);

    return {
      selectedSegmentCount: face.segments.filter((segment) => isObjectSelected(segment.id)).length,
      totalSegmentCount: face.segments.length,
      direction,
      directionName,
      face,
      segmentIds: face.segments.map((segment) => segment.id),
      segmentsWithActivePv: {
        totalSegmentCount: segmentsWithActivePv.totalSegmentCount,
        selectedSegmentCount: segmentsWithActivePv.selectedSegmentCount,
        segmentIds: segmentsWithActivePv.segmentIds,
      },
      segmentsWithInactivePv: {
        totalSegmentCount: segmentsWithInactivePv.totalSegmentCount,
        selectedSegmentCount: segmentsWithInactivePv.selectedSegmentCount,
        segmentIds: segmentsWithInactivePv.segmentIds,
      },
      segmentsWithNoPv: {
        totalSegmentCount: segmentsWithNoPv.totalSegmentCount,
        selectedSegmentCount: segmentsWithNoPv.selectedSegmentCount,
        segmentIds: segmentsWithNoPv.segmentIds,
      },
    };
  });
};

const getPvVariants = (segments: IFaceSegment[]) => {
  const segmentsWithPanels = segments.filter(
    (segment) =>
      segment.properties.wallProperties.top.hasPv ||
      segment.properties.wallProperties.bottom.hasPv ||
      segment.properties.wallProperties.sides.hasPv ||
      segment.properties.wallProperties.center.hasPv,
  );

  const segmentsWithActivePv = segmentsWithPanels.filter((segment) => segment.properties.pvActive);
  const segmentsWithInactivePv = segmentsWithPanels.filter((segment) => !segment.properties.pvActive);
  const segmentsWithNoPv = segments.filter(
    (segment) =>
      !segment.properties.wallProperties.top.hasPv &&
      !segment.properties.wallProperties.bottom.hasPv &&
      !segment.properties.wallProperties.sides.hasPv &&
      !segment.properties.wallProperties.center.hasPv,
  );

  return {
    segmentsWithActivePv: {
      totalSegmentCount: segmentsWithActivePv.length,
      selectedSegmentCount: segmentsWithActivePv.filter((segment) => isObjectSelected(segment.id)).length,
      segmentIds: segmentsWithActivePv.map((segment) => segment.id),
    },
    segmentsWithInactivePv: {
      totalSegmentCount: segmentsWithInactivePv.length,
      selectedSegmentCount: segmentsWithInactivePv.filter((segment) => isObjectSelected(segment.id)).length,
      segmentIds: segmentsWithInactivePv.map((segment) => segment.id),
    },
    segmentsWithNoPv: {
      totalSegmentCount: segmentsWithNoPv.length,
      selectedSegmentCount: segmentsWithNoPv.filter((segment) => isObjectSelected(segment.id)).length,
      segmentIds: segmentsWithNoPv.map((segment) => segment.id),
    },
  };
};

export const hasSelectedRoofSegmentsInBuilding = (buildingId: string) => {
  const buildingSegments = getBuildingRoofSegments(buildingId);
  return isAnyObjectSelected(buildingSegments.map((segment) => segment.id));
};

export const getBuildingRoofSegments = (buildingId: string): IFaceSegment[] => {
  const building = getBuilding(buildingId);
  if (!building) return [] as IFaceSegment[];

  const segments: IFaceSegment[] = [];

  Object.values(building.floors).forEach((floor) => {
    floor.faces.forEach((face) => {
      if (face.type === EFaceType.ROOF) {
        segments.push(...face.segments);
      }
    });
  }, segments);

  return segments;
};

export const getRoofPvVariantsOnFaces = (buildingId: string): IRoofFacePvVariants[] => {
  const building = getBuilding(buildingId);
  if (!building) return [] as IRoofFacePvVariants[];

  const facePvVariants: IRoofFacePvVariants[] = [];

  Object.values(building.floors).forEach((floor) => {
    floor.faces.forEach((face) => {
      if (face.type === EFaceType.ROOF) {
        const segmentPvVariants: IRoofSegmentPvVariants = getRoofPvSegmentVariantsInBuilding(buildingId);

        facePvVariants.push({
          faceId: face.id,
          segmentIds: face.segments.map((segment) => segment.id),
          totalSegmentCount: face.segments.length,
          selectedSegmentCount: face.segments.filter((segment) => isObjectSelected(segment.id)).length,
          segmentPvVariants,
        });
      }
    });
  });

  return facePvVariants;
};

export const getRoofPvSegmentVariantsInBuilding = (buildingId: string): IRoofSegmentPvVariants => {
  const building = getBuilding(buildingId);
  if (!building) return {} as IRoofSegmentPvVariants;

  const roofSegments = getBuildingRoofSegments(buildingId);

  const usedPercentages = roofSegments.reduce((acc, segment) => {
    if (segment.properties.roofPvPercentage !== undefined) {
      acc.push(segment.properties.roofPvPercentage);
    }

    return acc;
  }, [] as number[]);

  const segmentPvVariants: Record<number, ISegmentListItem> = {};

  usedPercentages.forEach((percentage) => {
    const segments = roofSegments.filter((segment) => segment.properties.roofPvPercentage === percentage);
    const selectedSegmentCount = segments.filter((segment) => isObjectSelected(segment.id)).length;
    const totalSegmentCount = segments.length;
    const segmentIds = segments.map((segment) => segment.id);

    segmentPvVariants[percentage] = {
      selectedSegmentCount,
      totalSegmentCount,
      segmentIds,
    };
  });

  return {
    totalSegmentCount: roofSegments.length,
    selectedSegmentCount: roofSegments.filter((segment) => isObjectSelected(segment.id)).length,
    segmentIds: roofSegments.map((segment) => segment.id),
    segmentPvVariants,
  };
};
