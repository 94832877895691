import { ThreeEvent } from '@react-three/fiber';
import { EObjectType, IVegetation } from 'cityview';
import React, { useEffect, useRef } from 'react';
import { BufferGeometry, InstancedMesh, Material } from 'three';
import { EApplicationMode } from 'types/applicationPath';
import { ILngLat } from 'types/location/coordinates';
import { useApplicationPath } from '../../hooks';
import { updateVegetations } from './utils';

interface VegetationsInstancedMeshProps {
  visible: boolean;
  vegetations: IVegetation[];
  hiddenVegetations?: IVegetation[];
  center: ILngLat;
  geometry: BufferGeometry | undefined;
  material: Material | Material[] | undefined;
  onClick?: (e: ThreeEvent<MouseEvent>) => void;
  onPointerOver?: (e: ThreeEvent<MouseEvent>) => void;
  onPointerOut?: (e: ThreeEvent<MouseEvent>) => void;
  name?: string;
}

const VegetationsInstancedMesh = (props: VegetationsInstancedMeshProps) => {
  const {
    visible,
    vegetations,
    center,
    geometry,
    material,
    name,
    onClick,
    onPointerOver,
    onPointerOut,
    hiddenVegetations,
  } = props;

  const { mode } = useApplicationPath();
  const instancedMeshRef = useRef<InstancedMesh>(null);

  useEffect(() => {
    if (!vegetations.length || !instancedMeshRef.current) return;

    updateVegetations(
      vegetations,
      instancedMeshRef.current,
      hiddenVegetations?.map((selectedVegetation) => selectedVegetation.id),
    );
  }, [vegetations, center, hiddenVegetations]);

  const showShadows = mode !== EApplicationMode.BUFFER;

  return visible ? (
    <instancedMesh
      ref={instancedMeshRef}
      name={name}
      castShadow={showShadows}
      receiveShadow={true}
      args={[geometry, material, vegetations.length]}
      onClick={onClick}
      userData={{
        type: EObjectType.VEGETATION,
      }}
      onPointerOver={(e) => {
        e.stopPropagation();

        onPointerOver?.(e);
      }}
      onPointerOut={(e) => {
        e.stopPropagation();

        onPointerOut?.(e);
      }}
      onUpdate={(self) => {
        self.instanceMatrix.needsUpdate = true;
      }}
    />
  ) : null;
};

export default VegetationsInstancedMesh;
