import { IBuilding } from 'types/building/Building';
import { EFaceType, IWallFace, TFace } from 'types/building/Face';
import { EFloorShape, EFloorType, IFloor, TFloors } from 'types/building/Floor';
import { XY } from 'types/location/coordinates';
import buildingStore from '../buildingStore';
import { getBuilding } from './building';

export const getFloorsById = (buildingId: string): TFloors | undefined => getBuilding(buildingId)?.floors;

export const getFloorById = (buildingId: string, floorId: string): IFloor | undefined =>
  getFloorsById(buildingId)?.[floorId];

export const getRoofFloorById = (buildingId: string): IFloor | undefined => {
  const floors = getFloorsById(buildingId);
  if (!floors) return;

  return Object.values(floors).find(
    (floor) => floor.properties.type === EFloorType.DG && floor.properties.shape === EFloorShape.ROOF,
  );
};

export const getFloorFootprintByIds = (buildingId: string, floorId: string): XY[] | undefined => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  return getFloorFootprint(floor);
};

export const getFloorFootprint = (floor: IFloor, decimals = 8): XY[] => {
  const wallFaces = [...floor.faces.filter((face) => face.type === EFaceType.WALL)] as IWallFace[];
  const walls = wallFaces.sort((wallA, wallB) => wallA.order - wallB.order);

  return walls.map((wall): [number, number] => {
    return [Number(wall.coordinates[0][0].toFixed(decimals)), Number(wall.coordinates[0][1].toFixed(decimals))];
  });
};

export const getPreviousFloorId = (buildingId: string, floorId: string): string | undefined => {
  const floors = getFloorsById(buildingId);
  if (!floors) return;

  const floor = floors[floorId];
  if (!floor) return;

  return Object.keys(floors).find((floorId) => floors[floorId].properties.order === floor.properties.order - 1);
};

export const getFloorAndFaceByFaceId = (faceId: string): { floor: IFloor; face: TFace } | undefined => {
  const buildings: IBuilding[] = Object.values(buildingStore.value.projectBuildings);
  for (const building of buildings) {
    const floors = Object.values(building.floors);
    for (const floor of floors) {
      const face = floor.faces.find((face) => face.id === faceId);
      if (face) return { floor, face };
    }
  }
};
