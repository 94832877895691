import TerrainHelper from 'cityview/components/Terrain/terrainHelper';
import { emitTerrainModified } from 'cityview/events/terrain';
import { useEffect, useMemo, useState } from 'react';
import { XY, XYZ } from 'types/location/coordinates';
import { ITerrainProperties, TTerrain } from 'types/terrain';
import { ref, useSnapshot } from 'valtio';
import { getTerrainSurfaceGeometry } from '../components/Terrain/utils';
import store, { terrainStore } from '../store';

interface UseTerrainGeometryProps {
  properties: ITerrainProperties;
  terrain: TTerrain;
}

const useTerrainGeometry = (props: UseTerrainGeometryProps) => {
  const { properties, terrain } = props;
  const { id, midpointElevation } = properties;

  const [hasTriangulatedModifiedTerrain, setHasTriangulatedModifiedTerrain] = useState(false);
  const [pointIndices, setPointIndices] = useState<Map<string, number> | null>(null);

  const {
    value: {
      modifiedTerrain: { edgePoints: plotEdgePoints, innerPoints: plotInnerPoints },
    },
  } = useSnapshot(terrainStore);

  const { width } = useSnapshot(store.settings.terrain);
  const { id: storeId, bottomGeometry, overlayGeometry } = useSnapshot(terrainStore.value.originalTerrain);
  const { lng, lat } = useSnapshot(store.general.center);

  const terrainInnerPointsOutsideOfPlot: XYZ[] = [];
  const terrainPointIndices = new Map<string, number>();

  terrain.forEach((row, rowIndex) => {
    row.forEach((point, index) => {
      if (rowIndex !== 0 && rowIndex !== terrain.length - 1 && index !== 0 && index !== row.length - 1) {
        terrainInnerPointsOutsideOfPlot.push(point);
        terrainPointIndices.set(`${point[0]},${point[1]}`, terrainInnerPointsOutsideOfPlot.length - 1);
      }
    });
  });

  if (plotInnerPoints.length > 0) {
    plotInnerPoints.forEach((point) => {
      const index = terrainPointIndices.get(`${point[0]},${point[1]}`);
      if (index !== undefined && index >= 0) terrainInnerPointsOutsideOfPlot.splice(index, 1);
    });
  }

  const terrainEdgePoints = useMemo(() => {
    if (!terrain.length || terrain.length === 0) return [];

    let points: XYZ[] = [];

    points = points.concat(terrain[0]);

    for (let i = 1; i < terrain.length - 1; i++) {
      points.push(terrain[i][terrain[i].length - 1]);
    }

    points = points.concat(terrain[terrain.length - 1].toReversed());

    for (let i = terrain.length - 2; i > 0; i--) {
      points.push(terrain[i][0]);
    }

    return points;
  }, [terrain]);

  useEffect(() => {
    if (storeId && id === storeId) return;

    const terrainXYIndices = new Map<string, XY>();
    terrain.forEach((row, rowIndex) => {
      row.forEach((point, colIndex) => {
        terrainXYIndices.set(`${point[0]},${point[1]}`, [rowIndex, colIndex]);
      });
    });

    const terrainHelper = new TerrainHelper(terrain, properties?.lowestPoint, properties?.highestPoint);
    terrainStore.value.originalTerrain.bottomGeometry = ref(terrainHelper.getBottomGeometry());
    terrainStore.value.originalTerrain.id = id;
    terrainStore.value.originalTerrain.terrainData = terrain;
    terrainStore.value.originalTerrain.midpointElevation = midpointElevation;
    terrainStore.value.originalTerrain.terrainXYIndices = terrainXYIndices;

    const { geometry, pointIndexMap } = getTerrainSurfaceGeometry(
      [...terrainInnerPointsOutsideOfPlot, ...plotInnerPoints, ...plotEdgePoints],
      terrainEdgePoints,
      width,
    );

    terrainStore.value.originalTerrain.overlayGeometry = ref(geometry);
    setPointIndices(pointIndexMap);
  }, [id, lat, lng, width, terrainInnerPointsOutsideOfPlot, terrainEdgePoints]);

  useEffect(() => {
    if (plotInnerPoints.length === 0) return;

    if (hasTriangulatedModifiedTerrain) {
      // only update z coordinates of the plot inner points
      emitTerrainModified({ changedPoints: plotInnerPoints });

      return;
    }

    const { geometry, pointIndexMap } = getTerrainSurfaceGeometry(
      [...terrainInnerPointsOutsideOfPlot, ...plotInnerPoints, ...plotEdgePoints],
      terrainEdgePoints,
      width,
    );

    terrainStore.value.originalTerrain.overlayGeometry = ref(geometry);
    setPointIndices(pointIndexMap);
    setHasTriangulatedModifiedTerrain(true);
  }, [plotInnerPoints, plotEdgePoints]);

  return {
    bottomGeometry: bottomGeometry,
    overlayGeometry: overlayGeometry,
    pointIndices,
  };
};

export default useTerrainGeometry;
