import theme from 'theme';

const styles = {
  modal: {
    maxHeight: '100dvh',
    zIndex: {
      xs: theme.zIndex.drawer + 1,
      lg: theme.constants.ProjectModal.zIndex,
    },
    pt: {
      lg: `${theme.constants.ProjectTopBar.height}px`,
    },
    px: 4,
    pb: 2,
    transition: theme.transitions.create('padding'),
  },
  modalSidebarOpen: {
    pl: {
      lg: `calc(${theme.constants.Sidebar.width}px + ${theme.spacing(6)})`,
    },
  },
  container: {
    borderRadius: 2,
    maxHeight: '100%',
    overflow: 'auto',
  },
  modalContent: {
    my: {
      sm: 0,
      lg: 0,
    },
  },
  modalContentWrapper: {
    p: 0,
  },
  content: {
    p: 6,
  },
  modalPaddingX: { xs: 4, lg: 6, xl: 8 },
  modalPaddingY: { xs: 4, lg: 5, xl: 6 },
  tabs: {
    minHeight: 28,
    height: 28,
    mb: 3,
  },
  tab: {
    px: 0,
    pb: 2,
    pt: 0,
    mr: 3,
    minWidth: 0,
    minHeight: 32,
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'none',
    overflow: 'initial',
    transition: theme.transitions.create('all'),
  },
};

export default styles;
