import { EDefaultFacadePalette, emitFacadeChange, emitRoofChange, EObjectType } from 'cityview';
import cloneDeep from 'lodash/cloneDeep';
import * as THREE from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { IBuilding, TBuildings } from 'types/building/Building';
import { EBufferType } from 'types/building/BuildingBuffers';
import { EBuildingPartWithoutWallAndWindow } from 'types/building/Facade';
import {
  EBalconyDividers,
  ESegmentBottomHeight,
  ESegmentOpening,
  ESegmentSideWidth,
  ESegmentTopHeight,
  ESegmentWallPart,
  EWallLayout,
  IFaceSegment,
} from 'types/building/FaceSegment';
import { EFloorType, IFloor, TFloors } from 'types/building/Floor';
import { ESolarExposureType } from 'types/building/Pv';
import {
  elevateBuilding,
  getSegmentBuilding,
  getSegmentFloor,
  getValidWallLayout,
  isSegmentTopWide,
  regenerateFaceBalconyDividers,
  rotateBuilding,
  translateBuilding,
  updateFloorRoofFacesById,
} from '../../components/Building/utils';
import { getCalculatedBufferDistance } from '../../utils/bufferCalculations';
import { getBufferArray } from '../../utils/polygonOffset';

import buildingStore from '../buildingStore';
import dataStore from '../dataStore';
import { getBuilding, getBuildingFacadeSegments, getSegmentsByIds } from '../selectors/building';
import { getFaceById } from '../selectors/wall';
import { cloneFloorUnits, getFaceCopy } from './floor';
import { deselectType, selectObject } from './selections';

export const removeAllBuildings = () => (buildingStore.value.projectBuildings = {});

export const setBuildings = (buildings: TBuildings) => (buildingStore.value.projectBuildings = buildings);

export const randomizeBuildingPlacement = (building: IBuilding, radius = 20, rotate = false) => {
  const x = -radius + 2 * radius * Math.random();
  const y = -radius + 2 * radius * Math.random();
  translateBuilding(building, new THREE.Vector3(x, y, 0));
  if (rotate) rotateBuilding(building, new THREE.Euler(0, Math.PI * Math.random(), 0));
};

export const duplicateBuilding = (buildingId: string) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const buildingToCopy = cloneDeep(building);

  const newFloors = Object.keys(buildingToCopy.floors).reduce((floors, floorId) => {
    const floorToCopy = buildingToCopy.floors[floorId];
    const newFloorId = generateUUID();
    if (floorToCopy.usage) floorToCopy.usage.id = generateUUID();
    if (floorToCopy.units && floorToCopy.units.length > 0) floorToCopy.units = cloneFloorUnits(floorToCopy, newFloorId);

    return {
      ...floors,
      [newFloorId]: {
        ...floorToCopy,
        id: newFloorId,
        copyId: floorId,
        faces: floorToCopy.faces.map((face) => getFaceCopy(face)),
      },
    };
  }, {} as TFloors);

  const newBuildingId = generateUUID();

  const newBuilding: IBuilding = {
    ...buildingToCopy,
    id: newBuildingId,
    order: Object.keys(buildingStore.value.projectBuildings).length + 1,
    copyId: buildingId,
    floors: newFloors,
  };

  const buildings = buildingStore.value.projectBuildings;
  buildings[newBuildingId] = newBuilding;

  const addedBuilding = getBuilding(newBuildingId);
  if (!addedBuilding) return;

  randomizeBuildingPlacement(addedBuilding, 20);

  updateFloorRoofFacesById(newBuildingId);
  selectObject(newBuildingId, EObjectType.BUILDING);
};

export const refreshBuildingsOrder = () => {
  Object.values(buildingStore.value.projectBuildings)
    .sort((a, b) => a.order - b.order)
    .forEach((building, index) => {
      building.order = index + 1;
    });
};

export const removeBuildingsByIds = (buildingIds: string[]) => {
  const buildings = Object.values(buildingStore.value.projectBuildings).reduce((previousValue, currentBuilding) => {
    return buildingIds.includes(currentBuilding.id)
      ? previousValue
      : { ...previousValue, [currentBuilding.id]: currentBuilding };
  }, {} as TBuildings);

  setBuildings(buildings);
  refreshBuildingsOrder();
  deselectType(EObjectType.BUILDING);
};

export const setBuildingBufferArrayObjectValue = (
  buildingId: string,
  bufferId: string,
  type: EBufferType,
  totalHeight: number,
) => {
  const object = buildingStore.value.projectBuildings[buildingId].buffers.find(
    (buffer) => `${buildingId}-${buffer.order}` === bufferId,
  );
  if (!object) return;

  const building = getBuilding(buildingId);
  if (!building) return;

  object.bufferType = type;
  object.bufferDistance = getCalculatedBufferDistance(object.geometry, type, totalHeight);
  buildingStore.value.projectBuildings[buildingId].buffers = getBufferArray(building);
};

export const setFacadeSegmentProperties = (
  segmentIds: string[],
  properties: {
    opening?: ESegmentOpening;
    wallLayout?: EWallLayout;
    hasBalcony?: boolean;
    balconyDividers?: EBalconyDividers;
    balconyWidth?: number;
  },
) => {
  const { wallLayout, hasBalcony, balconyDividers, opening, balconyWidth } = properties;

  if (
    opening === undefined &&
    wallLayout === undefined &&
    hasBalcony === undefined &&
    balconyDividers === undefined &&
    balconyWidth === undefined
  )
    return;

  Object.keys(buildingStore.value.projectBuildings).forEach((buildingId) => {
    const building = getBuilding(buildingId);
    if (!building) return;

    const faceIdsForSegmentsWithBalconyChanges: string[] = [];

    const buildingSegments = getBuildingFacadeSegments(buildingId);
    for (const segmentId of segmentIds) {
      const segment = buildingSegments.find((segment) => segment.id === segmentId);
      if (segment) {
        if (hasBalcony === true || hasBalcony === false) {
          segment.properties.hasBalcony = hasBalcony;
          faceIdsForSegmentsWithBalconyChanges.push(segment.faceId);
        }

        if (balconyDividers !== undefined && balconyDividers !== null) {
          segment.properties.balconyDividers = balconyDividers;
        }

        [...new Set(faceIdsForSegmentsWithBalconyChanges)].forEach((faceId) => {
          const face = getFaceById(faceId);
          if (!face) return;
          regenerateFaceBalconyDividers(face);
        });

        const newOpening = hasBalcony === true && opening === undefined ? ESegmentOpening.DOOR : opening;

        if (newOpening !== undefined) {
          // Reset center material if opening is changed
          if (newOpening === ESegmentOpening.NONE && segment.properties.opening !== ESegmentOpening.NONE) {
            segment.properties.wallProperties.center.color = EDefaultFacadePalette.STANDARD_WHITE;
          } else if (newOpening === ESegmentOpening.WINDOW || newOpening === ESegmentOpening.DOOR) {
            segment.properties.wallProperties.center.color = EDefaultFacadePalette.STANDARD_GLASS;
          }

          // Reset wall layout if opening is changed
          segment.properties.wallLayout = getValidWallLayout(segment.properties.wallLayout, newOpening);

          if (newOpening === ESegmentOpening.DOOR && segment.properties.opening !== ESegmentOpening.DOOR) {
            segment.properties.wallProperties.bottom.height = ESegmentBottomHeight.ZERO;
            segment.properties.wallProperties.center.height =
              segment.properties.segmentHeight - segment.properties.wallProperties.top.height;

            segment.properties.wallProperties.sides.height = isSegmentTopWide(segment)
              ? segment.properties.wallProperties.center.height
              : segment.properties.segmentHeight;
          } else if (newOpening !== ESegmentOpening.DOOR && segment.properties.opening === ESegmentOpening.DOOR) {
            segment.properties.wallProperties.bottom.height = ESegmentBottomHeight.HUNDRED;
            segment.properties.wallProperties.center.height =
              segment.properties.segmentHeight -
              segment.properties.wallProperties.top.height -
              segment.properties.wallProperties.bottom.height;
            segment.properties.wallProperties.sides.height = isSegmentTopWide(segment)
              ? segment.properties.wallProperties.center.height
              : segment.properties.segmentHeight - segment.properties.wallProperties.bottom.height;
          }

          segment.properties.opening = newOpening;
        }

        if (wallLayout !== undefined) {
          segment.properties.wallLayout = wallLayout;
        }

        if (balconyWidth !== undefined) {
          segment.properties.balconyWidth = balconyWidth;
        }

        updateSegmentWallGeometry(segment);
      }
    }
  });

  emitFacadeChange();
};

export const setSegmentsWallPartMaterial = (segmentIds: string[], color: string, part?: ESegmentWallPart) => {
  if (!color.startsWith('#')) return;

  const segments = getSegmentsByIds(segmentIds);

  segments.forEach((segment) => {
    if (part) {
      segment.properties.wallProperties[part].color = color;
    } else {
      Object.values(ESegmentWallPart).forEach((part) => {
        if (part === ESegmentWallPart.CENTER && segment.properties.opening !== ESegmentOpening.NONE) return;
        segment.properties.wallProperties[part].color = color;
      });
    }
  });

  emitFacadeChange();
};

export const setSegmentsWallPartSize = (segmentIds: string[], size: number, part: ESegmentWallPart) => {
  const segments = getSegmentsByIds(segmentIds);

  segments.forEach((segment) => {
    const building = getSegmentBuilding(segment);
    if (!building) return;
    const floor = getSegmentFloor(building, segment);
    if (!floor) return;

    switch (part) {
      case ESegmentWallPart.TOP:
        segment.properties.wallProperties.top.variableHeight = size;
        segment.properties.wallProperties.top.height =
          size +
          (floor.properties.type === EFloorType.DG
            ? building.construction.roofThickness
            : building.construction.ceilingThickness);
        segment.properties.wallProperties.center.height =
          segment.properties.segmentHeight -
          (segment.properties.wallProperties.top.height + segment.properties.wallProperties.bottom.height);
        break;
      case ESegmentWallPart.BOTTOM:
        segment.properties.wallProperties.bottom.height = size;
        segment.properties.wallProperties.center.height =
          segment.properties.segmentHeight - size - segment.properties.wallProperties.top.height;
        break;
      case ESegmentWallPart.SIDES:
        segment.properties.wallProperties.sides.width = size;
        segment.properties.wallProperties.center.width = segment.properties.segmentWidth - size * 2;
        break;
    }
  });

  emitFacadeChange();
};

export const updateTopWidth = (segment: IFaceSegment) => {
  const {
    properties: {
      wallLayout,
      segmentWidth,
      wallProperties: {
        center: { width: centerWidth },
      },
    },
  } = segment;

  switch (wallLayout) {
    case EWallLayout.WIDE_TOP_AND_BOTTOM:
    case EWallLayout.WIDE_TOP:
    case EWallLayout.NO_BOTTOM_WIDE_TOP:
      segment.properties.wallProperties.top.width = segmentWidth;
      break;
    case EWallLayout.WIDE_BOTTOM:
    case EWallLayout.NARROW_TOP_AND_BOTTOM:
    case EWallLayout.NO_BOTTOM_NARROW_TOP:
      segment.properties.wallProperties.top.width = centerWidth;
      break;
  }
};

export const updateBottomWidth = (segment: IFaceSegment) => {
  const {
    properties: {
      wallLayout,
      segmentWidth,
      wallProperties: {
        center: { width: centerWidth },
      },
    },
  } = segment;

  switch (wallLayout) {
    case EWallLayout.WIDE_TOP_AND_BOTTOM:
    case EWallLayout.WIDE_BOTTOM:
      segment.properties.wallProperties.bottom.width = segmentWidth;
      break;
    case EWallLayout.WIDE_TOP:
    case EWallLayout.NO_BOTTOM_WIDE_TOP:
    case EWallLayout.NARROW_TOP_AND_BOTTOM:
    case EWallLayout.NO_BOTTOM_NARROW_TOP:
      segment.properties.wallProperties.bottom.width = centerWidth;
      break;
  }
};

export const updateSideHeight = (segment: IFaceSegment) => {
  const {
    properties: {
      wallLayout,
      segmentHeight,
      wallProperties: {
        top: { height: topHeight },
        bottom: { height: bottomHeight },
      },
    },
  } = segment;

  switch (wallLayout) {
    case EWallLayout.NARROW_TOP_AND_BOTTOM:
    case EWallLayout.NO_BOTTOM_NARROW_TOP:
      segment.properties.wallProperties.sides.height = segmentHeight;
      break;
    case EWallLayout.WIDE_TOP_AND_BOTTOM:
      segment.properties.wallProperties.sides.height = segmentHeight - topHeight - bottomHeight;
      break;
    case EWallLayout.WIDE_TOP:
    case EWallLayout.NO_BOTTOM_WIDE_TOP:
      segment.properties.wallProperties.sides.height = segmentHeight - topHeight;
      break;
    case EWallLayout.WIDE_BOTTOM:
      segment.properties.wallProperties.sides.height = segmentHeight - bottomHeight;
      break;
  }
};

export const updateSegmentWallGeometry = (segment: IFaceSegment) => {
  updateTopWidth(segment);
  updateBottomWidth(segment);
  updateSideHeight(segment);
};

export const setFacadeProperties = (
  segmentIds: string[],
  properties: {
    opening?: ESegmentOpening;
    wallLayout?: EWallLayout;
    hasBalcony?: boolean;
    balconyDividers?: EBalconyDividers;
    balconyWidth?: number;
  },
) => {
  const { opening, wallLayout, hasBalcony, balconyDividers, balconyWidth } = properties;

  return setFacadeSegmentProperties(segmentIds, {
    opening,
    wallLayout,
    hasBalcony,
    balconyDividers,
    balconyWidth,
  });
};

export const resetSegmentWallPartSizes = (segment: IFaceSegment, building: IBuilding, floor: IFloor) => {
  const { segmentWidth, segmentHeight } = segment.properties;

  const variableTopHeight = ESegmentTopHeight.THIRTY;
  const bottomHeight = ESegmentBottomHeight.HUNDRED;
  const sidesWidth = ESegmentSideWidth.THIRTY;

  segment.properties.wallProperties.top.height =
    variableTopHeight +
    (floor.properties.type === EFloorType.DG
      ? building.construction.roofThickness
      : building.construction.ceilingThickness);
  segment.properties.wallProperties.top.variableHeight = variableTopHeight;
  segment.properties.wallProperties.bottom.height = bottomHeight;
  segment.properties.wallProperties.sides.width = sidesWidth;

  segment.properties.wallProperties.center.height =
    segmentHeight - segment.properties.wallProperties.top.height - bottomHeight;
  segment.properties.wallProperties.center.width = segmentWidth - sidesWidth * 2;

  updateSegmentWallGeometry(segment);
};

export const setBuildingPartMaterial = (buildingId: string, part: EBuildingPartWithoutWallAndWindow, color: string) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  building.construction.materials[part] = color;
};

export const setWallPartPV = (segmentIds: string[], hasPv: boolean, part?: ESegmentWallPart) => {
  const segments = getSegmentsByIds(segmentIds);
  if (!segments.length) return;

  segments.forEach((segment) => {
    if (part) {
      if (hasPv && part === ESegmentWallPart.CENTER && segment.properties.opening !== ESegmentOpening.NONE) return;
      segment.properties.wallProperties[part].hasPv = hasPv;
    } else {
      Object.values(ESegmentWallPart).forEach((part) => {
        if (hasPv && part === ESegmentWallPart.CENTER && segment.properties.opening !== ESegmentOpening.NONE) return;
        segment.properties.wallProperties[part].hasPv = hasPv;
      });
    }

    if (hasPv) {
      segment.properties.pvActive = true;
    }
  });

  emitFacadeChange();
};

export const setSegmentsPVWithThreshold = (
  segmentIds: string[],
  thresholdValue: number,
  thresholdType: ESolarExposureType,
) => {
  const segments = getSegmentsByIds(segmentIds);
  if (!segments.length) return;

  const { solarSegments } = dataStore;
  if (!solarSegments) return;

  segments.forEach((segment) => {
    if (thresholdType === ESolarExposureType.IRRADIATION) {
      segment.properties.pvActive = solarSegments[segment.id].yearlyIrradiation >= thresholdValue;
    } else {
      segment.properties.pvActive = solarSegments[segment.id].yearlyIrradiationReduction <= thresholdValue;
    }
  });

  emitFacadeChange();
};

export const setRoofSegmentsPvPercentage = (segmentIds: string[], percentage: number) => {
  const segments = getSegmentsByIds(segmentIds);
  segments.forEach((segment) => {
    segment.properties.roofPvPercentage = percentage;
  });

  emitRoofChange();
};

export const setSegmentsPVActive = (segmentIds: string[], value: boolean) => {
  const segments = getSegmentsByIds(segmentIds);
  segments.forEach((segment) => {
    segment.properties.pvActive = value;
  });

  emitFacadeChange();
};

export const changeBuildingElevation = (buildingId: string, elevationDiff: number) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  elevateBuilding(building, elevationDiff);
};
