import { MapControls, OrbitControls } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import {
  MapControls as MapControlsImpl,
  OrbitControls as OrbitControlsImpl,
} from 'three-stdlib/controls/OrbitControls';
import { useSnapshot } from 'valtio';
import { subscribeKey } from 'valtio/utils';
import store from '../../store';
import GizmoHelper from '../GizmoHelper';
import GizmoViewport from '../GizmoViewport';
import { EApplicationMode } from 'types/applicationPath';

const vector3 = new THREE.Vector3();
const minPan = new THREE.Vector3(-250, -250, -250);
const maxPan = new THREE.Vector3(250, 250, 250);

interface ControlsProps {
  showGizmo?: boolean;
  onInit?: (controls: OrbitControlsImpl) => void;
}

const Controls = (props: ControlsProps) => {
  const { showGizmo = true, onInit } = props;

  const { mode } = useSnapshot(store.general.applicationPath);

  const { camera } = useThree();

  const orbitRef = useRef<OrbitControlsImpl>(null);
  const mapControlsRef = useRef<MapControlsImpl>(null);

  const handleMapControlsChange = () => {
    if (!mapControlsRef.current) return;

    vector3.copy(mapControlsRef.current.target);
    mapControlsRef.current.target.clamp(minPan, maxPan);
    vector3.sub(mapControlsRef.current.target);
    camera.position.sub(vector3);
  };

  useEffect(() => {
    const unsubscribe = subscribeKey(store.mouse, 'isMouseDown', (state) => {
      if (orbitRef.current) orbitRef.current.enabled = !state;
      if (mapControlsRef.current) mapControlsRef.current.enabled = !state;
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (!orbitRef.current || !onInit) return;

    onInit(orbitRef.current);
  }, [orbitRef.current]);

  const handleCameraInteractionStart = () => {
    store.mouse.isTransformingCamera = true;
  };

  const handleCameraInteractionEnd = () => {
    store.mouse.isTransformingCamera = false;
  };

  return (
    <>
      {mode === EApplicationMode.BUFFER ? (
        <MapControls ref={mapControlsRef} enableRotate={false} onChange={handleMapControlsChange} />
      ) : (
        <>
          <OrbitControls
            ref={orbitRef}
            makeDefault
            minDistance={15}
            maxDistance={1000}
            enableDamping={true}
            dampingFactor={0.15}
            enablePan={true}
            onStart={handleCameraInteractionStart}
            onEnd={handleCameraInteractionEnd}
          />
          {showGizmo && (
            <GizmoHelper>
              <GizmoViewport axisColors={['#ff5e5e', '#9D9FA0', '#6B86C4', '#9D9FA0', '#BEBFC0']} labelColor='#FFF' />
            </GizmoHelper>
          )}
        </>
      )}
    </>
  );
};

export default Controls;
