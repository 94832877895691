import { Box, Grid, LinearProgress, Tab, Tabs, Theme, Typography } from '@mui/material';
import { skipToken } from '@reduxjs/toolkit/query';
import DataOverviewHeader from 'components/modals/DataOverviewModal/components/DataOverviewHeader';
import DataOverviewRow from 'components/modals/DataOverviewModal/components/DataOverviewRow';
import useLayoutView from 'hooks/useLayout/useLayoutView';
import useLocationData from 'pages/App/hooks/useLocationData';
import ModalHeader from 'pages/App/Project/views/Modals/ModalHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { projectIdSelector } from 'state/selectors/router';
import { useGetDataOverviewQuery } from 'state/services/backend/endpoints/general/dataOverview';
import { useGetProjectQueryState } from 'state/services/backend/endpoints/project/project';
import { selectedZoneIdSelector } from 'state/slices/lawSelect';
import { FileRows } from 'theme/icons';

const styles = {
  tabs: {
    minHeight: 28,
    height: 28,
  },
  tab: {
    px: 0,
    pb: 2,
    pt: 0,
    mr: 4.5,
    minWidth: 0,
    minHeight: 32,
    fontSize: 13,
    fontWeight: 400,
    textTransform: 'none',
    overflow: 'initial',
    transition: (theme: Theme) => theme.transitions.create('all'),
  },
};

enum EDataOverviewPage {
  FUNDAMENTALS = 'fundamentals',
  LAW = 'law',
  NOISE = 'noise',
  RESTRICTIONS = 'restrictions',
}

const DataOverviewModalContent = () => {
  const { t } = useTranslation();

  const locationData = useLocationData();

  const reduxSelectedZoneId = useSelector(selectedZoneIdSelector);

  const projectId = useSelector(projectIdSelector);
  const { data: projectData } = useGetProjectQueryState(projectId ? { projectId } : skipToken);

  const selectedZoneId = projectId && projectData ? projectData.generalSettings.selectedZone : reduxSelectedZoneId;

  const { currentView, setView } = useLayoutView<EDataOverviewPage>({
    initialView: EDataOverviewPage.FUNDAMENTALS,
    path: ['modals', 'dataOverview'],
  });

  const { data, isLoading } = useGetDataOverviewQuery({
    lng: locationData?.centerLng ?? 0,
    lat: locationData?.centerLat ?? 0,
    selectedZone: selectedZoneId ?? undefined,
  });

  return (
    <Box>
      <ModalHeader title={t('project:dataOverview.title')} Icon={FileRows} />
      <Box
        sx={{
          px: { xs: 4, lg: 6, xl: 8 },
          pb: { xs: 3, lg: 5, xl: 7 },
          pt: 0,
          width: '100vw',
          minWidth: 0,
          maxWidth: 1080,
        }}
      >
        {isLoading ? (
          <Box sx={{ py: 3 }}>
            <LinearProgress color='primary' />
          </Box>
        ) : data ? (
          <>
            <Tabs value={currentView} onChange={(_, view) => setView(view)} indicatorColor='secondary' sx={styles.tabs}>
              {[
                EDataOverviewPage.FUNDAMENTALS,
                EDataOverviewPage.RESTRICTIONS,
                EDataOverviewPage.LAW,
                EDataOverviewPage.NOISE,
              ].map((page) => (
                <Tab label={t(`project:dataOverview.topics.${page}.title`)} value={page} sx={styles.tab} key={page} />
              ))}
            </Tabs>
            <Box sx={{ mt: 6 }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <DataOverviewHeader />
                </Grid>
                {currentView === EDataOverviewPage.FUNDAMENTALS && (
                  <>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.terrain} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.trees} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.satelliteImages} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.existingBuildings3D} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.municipalityGeometry} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.cadastre} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.existingBuildingsRegister} />
                    </Grid>
                  </>
                )}
                {currentView === EDataOverviewPage.RESTRICTIONS && (
                  <>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.lawZones} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.specialBuildingPlans} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.constructionLines} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.forestBorders} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.forestDistanceLines} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.waterBorders} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.waterDistanceLines} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.contamination} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.hazards} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.protectedSitesIsos} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.heritageAreas} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.heritageObjects} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.protectedLandscapeFeatures} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.archaeology} />
                    </Grid>
                  </>
                )}
                {currentView === EDataOverviewPage.LAW && (
                  <>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.federalLaw} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.federalParameters} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.cantonalLaw} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.cantonalParameters} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.municipalityLaw} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.municipalityParameters} />
                    </Grid>
                  </>
                )}
                {currentView === EDataOverviewPage.NOISE && (
                  <>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.carNoiseOverview} />
                    </Grid>
                    <Grid item xs={12}>
                      <DataOverviewRow dataStatus={data.trainNoiseOverview} />
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
          </>
        ) : (
          <Typography variant='body2'>{t('general:noDataToDisplay')}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default DataOverviewModalContent;
