import Flatten from '@flatten-js/core';
import { buildingStore } from 'cityview/index';
import round from 'lodash/round';
import { Vector3 } from 'three';
import { EFaceType } from 'types/building/Face';
import { ESegmentOpening, IFaceSegment } from 'types/building/FaceSegment';
import { ISolarLimits, ISolarSegments } from 'types/building/Pv';
import { XY, XYZ } from 'types/location/coordinates';
import { Nullable } from 'types/util';
import { proxy } from 'valtio';
import { derive } from 'valtio/utils';

export const DEFAULT_SOLAR_LIMITS: ISolarLimits = {
  yearlyReduction: [0, 100],
  yearlyIrradiation: [0, 1320],
  reductionMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  reductionMonthlyMax: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
  irradiationMonthlyMin: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  irradiationMonthlyMax: [110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110, 110],
};

interface IDataStore {
  solarSegments: Nullable<ISolarSegments>;
  solarLimits: ISolarLimits;
}

const dataStore = proxy<IDataStore>({
  solarSegments: null,
  solarLimits: DEFAULT_SOLAR_LIMITS,
});

const getPvArea = (segment: IFaceSegment, isRoof: boolean = false): number => {
  let area = 0;
  if (isRoof) {
    const pvRatio = (segment.properties.roofPvPercentage ?? 0) / 100;
    area = getRoofSegmentArea(segment) * pvRatio;
  } else {
    if (!segment.properties.pvActive) return 0;
    if (segment.properties.wallProperties.top.hasPv) area += getTopArea(segment);
    if (segment.properties.wallProperties.bottom.hasPv) area += getBottomArea(segment);
    if (segment.properties.wallProperties.sides.hasPv) area += getSidesArea(segment);
    if (segment.properties.opening === ESegmentOpening.NONE && segment.properties.wallProperties.center.hasPv)
      area += getCenterArea(segment);
  }

  return area;
};

const getTopArea = (segment: IFaceSegment): number => {
  const { top } = segment.properties.wallProperties;
  return top.width * top.height;
};

const getBottomArea = (segment: IFaceSegment): number => {
  const { bottom } = segment.properties.wallProperties;
  return bottom.width * bottom.height;
};

const getSidesArea = (segment: IFaceSegment): number => {
  const { sides } = segment.properties.wallProperties;
  return sides.width * sides.height;
};

const getCenterArea = (segment: IFaceSegment): number => {
  const { center } = segment.properties.wallProperties;
  return center.width * center.height;
};

const getRoofSegmentArea = (segment: IFaceSegment): number => {
  return polygonArea3D(segment.corners.map((corner) => corner.coordinates));
};

export const polygonArea3D = (polygon: XYZ[]): number => {
  if (polygon.length < 3) return 0;

  const normal = calculateNormal(polygon);
  const axis = normal.map(Math.abs).indexOf(Math.max(...normal.map(Math.abs)));

  const projectedPolygon = projectPolygon(polygon, axis);
  const projectedArea = new Flatten.Polygon(projectedPolygon).area();

  const normalMagnitude = vectorMagnitude(normal);
  return (projectedArea * normalMagnitude) / Math.sqrt(normal[axis] ** 2);
};

const projectPolygon = (polygon: XYZ[], axis: number): XY[] => {
  return polygon.map((point): XY => {
    return [point[(axis + 1) % 3], point[(axis + 2) % 3]];
  });
};

const vectorMagnitude = (v: XYZ): number => {
  return Math.sqrt(new Vector3(...v).dot(new Vector3(...v)));
};

const calculateNormal = (polygon: XYZ[]): XYZ => {
  const v1: XYZ = [polygon[1][0] - polygon[0][0], polygon[1][1] - polygon[0][1], polygon[1][2] - polygon[0][2]];
  const v2: XYZ = [polygon[2][0] - polygon[0][0], polygon[2][1] - polygon[0][1], polygon[2][2] - polygon[0][2]];

  return new Vector3(...v1).cross(new Vector3(...v2)).toArray();
};

export const derivedDataStore = derive({
  pvSegments: (get) => {
    const buildingStoreValue = get(buildingStore.value);
    const { projectBuildings } = buildingStoreValue;

    const segmentsResults: Record<
      string,
      {
        power: number;
        pvArea: number;
        pvEfficiency: number;
      }
    > = {};

    Object.values(projectBuildings).forEach((building) => {
      Object.values(building.floors).forEach((floor) => {
        floor.faces.forEach((face) => {
          const isRoof = face.type === EFaceType.ROOF;
          const pvEfficiency = isRoof ? building.services.roofPvEfficiency : building.services.pvEfficiency;
          face.segments.forEach((segment) => {
            const pvArea = getPvArea(segment, isRoof);
            const powerPerSqm = (pvEfficiency / 100) * 1000;
            const power = round(pvArea * powerPerSqm, 2);

            segmentsResults[segment.id] = {
              power,
              pvArea: round(pvArea, 2),
              pvEfficiency,
            };
          });
        });
      });
    });

    return segmentsResults;
  },
});

export default dataStore;
