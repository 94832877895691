// TODO: Declare typescript module for polylabel
// @ts-ignore
import polylabel from '@mapbox/polylabel';
import * as turf from '@turf/turf';
import { booleanPointInPolygon, buffer, point, pointOnFeature, polygon } from '@turf/turf';
import { Position } from 'geojson';

export const getCenter = (coordinates: Position[][]) => {
  try {
    const _polygon = polygon(coordinates);
    let [lng, lat] = polylabel(coordinates, 1);

    if (!booleanPointInPolygon(point([lng, lat]), _polygon)) {
      const plotPolygonBuffer = buffer(_polygon, -0.0005, { units: 'kilometers' });
      if (plotPolygonBuffer) {
        [lng, lat] = pointOnFeature(plotPolygonBuffer).geometry.coordinates;
      }
    }

    return { lng, lat };
  } catch (e) {
    const { lng, lat } = coordinates.reduce(
      (acc, coords) => {
        // @ts-ignore
        const feature = turf.polygon(coords);
        const [lng, lat] = polylabel(coords);
        const area = turf.area(feature);
        return area > acc.area ? { lng, lat, area } : acc;
      },
      { lng: null, lat: null, area: 0 },
    );

    return { lng, lat };
  }
};
