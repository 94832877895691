import theme from 'theme';
import { EBuildingUnitCategory, EBuildingUnitType } from 'types/building/BuildingUsage';

export const USAGE_CATEGORIES_PALETTE = {
  [EBuildingUnitCategory.CIRCULATION]: '#DD3B6E',
  [EBuildingUnitCategory.COMMERCIAL]: '#E8DB8E',
  [EBuildingUnitCategory.LIVING]: '#9DE88E',
  [EBuildingUnitCategory.SIDE_USAGE]: '#6E8387',
  [EBuildingUnitCategory.SUB_USAGE]: '#D9D9D9',
  all: '#DD3B6E',
  business: '#E8DB8E',
  businessLiving: '#E7F823',
  businessSide: '#9C51C9',
  livingSide: '#1FFFD6',
  multiFamily: '#9DE88E',
  side: '#6E8387',
  singleFamily: '#9DE88E',
};

interface IPaletteColor {
  color: string;
}

export const USAGE_UNITS_PALETTE: Record<EBuildingUnitType, IPaletteColor> = {
  [EBuildingUnitType.STUDIO]: { color: '#95EE95' },
  [EBuildingUnitType.TWOANDAHALF]: { color: '#61E561' },
  [EBuildingUnitType.THREEANDAHALF]: { color: '#2CDD2C' },
  [EBuildingUnitType.FOURANDAHALF]: { color: '#1DAF1D' },
  [EBuildingUnitType.FIVEANDAHALF]: { color: '#147B14' },
  [EBuildingUnitType.OFFICE]: { color: '#ECDE83' },
  [EBuildingUnitType.MAISONETTE]: { color: '#2CDD2C' },
  [EBuildingUnitType.SIDE_COMMON_ROOM]: { color: '#2CDD2C' },
  [EBuildingUnitType.SINGLE_FAMILY_LIVING]: { color: '#147B14' },
  [EBuildingUnitType.SINGLE_FAMILY_STUDIO]: { color: '#95EE95' },
  [EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF]: { color: '#61E561' },
  [EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF]: { color: '#2CDD2C' },
  [EBuildingUnitType.SINGLE_FAMILY_BUREAU]: { color: '#ECDE83' },
  [EBuildingUnitType.SINGLE_FAMILY_WORKSHOP]: { color: '#9F8D19' },
  [EBuildingUnitType.SHOP]: { color: '#E3CF4F' },
  [EBuildingUnitType.WORKSHOP]: { color: '#9F8D19' },
  [EBuildingUnitType.PRODUCTION]: { color: '#584E0E' },
  [EBuildingUnitType.GASTRONOMY]: { color: '#D4BC21' },
  [EBuildingUnitType.SIDE_BUILDING_SERVICE]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_LAUNDRY]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_BICYCLE]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_ENTRANCE_HALL]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_STROLLER_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_STORAGE]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_STORAGE_FACILITY]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_CLEANING_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_HOBBY_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SIDE_HOUSEKEEPING]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_STORAGE_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_HOBBY_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_WINTERGARDEN]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_TECHNICAL_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_CLEANING_ROOM]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_BIKE_STORAGE]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_LAUNDRY]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.SUB_STORAGE_FACILITY]: { color: theme.palette.teal[500] },
  [EBuildingUnitType.CIRCULATION]: { color: theme.palette.teal[500] },
};
