import * as THREE from 'three';
import { MathUtils, Plane, Vector3 } from 'three';
import { ETerrainWidth } from 'types/terrain';

const epsilon = 0.1;

export interface IClippingSides {
  bottom?: number; // Terrain bottom level
  north?: boolean;
  east?: boolean;
  south?: boolean;
  west?: boolean;
}

export const getClippingPlanes = (terrainWidth: ETerrainWidth, clippingSides?: IClippingSides) => {
  const { bottom, north = true, east = true, south = true, west = true } = clippingSides ?? {};

  const clippingPlanes = [
    north && new THREE.Plane(new THREE.Vector3(0, 1, 0), terrainWidth / 2 + epsilon),
    south && new THREE.Plane(new THREE.Vector3(0, -1, 0), terrainWidth / 2 + epsilon),
    east && new THREE.Plane(new THREE.Vector3(1, 0, 0), terrainWidth / 2 + epsilon),
    west && new THREE.Plane(new THREE.Vector3(-1, 0, 0), terrainWidth / 2 + epsilon),
    bottom !== undefined && new THREE.Plane(new THREE.Vector3(0, 0, 1), Math.abs(bottom) ?? 10),
  ];

  return clippingPlanes.filter(Boolean) as THREE.Plane[];
};

export const constructClippingPlanes = (
  active: boolean,
  constant: number,
  angle: number,
  terrainWidth: ETerrainWidth,
  clippingSides?: IClippingSides,
) => {
  const clippingPlanes = getClippingPlanes(terrainWidth, clippingSides);

  const clippingDirection = new Vector3(0, 1, 0);
  const clippingPlane = new Plane(clippingDirection, constant);

  clippingDirection.applyAxisAngle(new Vector3(0, 0, 1), MathUtils.degToRad(angle));
  clippingPlane.set(clippingDirection, constant);

  if (active) clippingPlanes.push(clippingPlane);

  return clippingPlanes;
};
