import { Box } from '@react-three/drei';
import chroma from 'chroma-js';
import { EObjectName, useApplicationPath } from 'cityview';
import React from 'react';
import * as THREE from 'three';
import { EApplicationTopic } from 'types/applicationPath';
import { EParkingLotType, IParkingLotEntrance, TParkingLotIndoor } from 'types/parking';

interface ParkingLotIndoorEntranceProps {
  entrance: IParkingLotEntrance;
  garageHeight: number;
  parkingLot: TParkingLotIndoor;
  parkingBuildingColor: string;
  opacity?: number;
}

const ParkingLotIndoorEntrance = (props: ParkingLotIndoorEntranceProps) => {
  const { entrance, garageHeight, parkingLot, parkingBuildingColor, opacity = 1 } = props;
  const { properties } = parkingLot;
  const { type, clearHeight, construction } = properties;
  const { foundationThickness } = construction;

  const { topic } = useApplicationPath();

  const angle = Math.atan2(entrance.entranceDirection[1], entrance.entranceDirection[0]);
  const zRotation = angle - Math.PI / 2;
  const entranceZ = -garageHeight / 2 - (Math.abs(garageHeight) - clearHeight - foundationThickness) / 2;
  const doorHeight = clearHeight;
  let doorWidth = 3.2;

  if (type === EParkingLotType.GARAGE) {
    doorWidth = properties.parkingSpotWidth * properties.nrOfTotalParkingSpots;
  }

  if (type === EParkingLotType.GARAGE_ROW) {
    doorWidth = properties.parkingSpotWidth;
  }

  return (
    <group
      key={entrance.id}
      position={[entrance.position[0], entrance.position[1], entranceZ]}
      rotation={[0, 0, zRotation]}
    >
      <Box args={[doorWidth, doorHeight, 0.2]} rotation={[Math.PI / 2, 0, 0]} name={EObjectName.PARKING_GARAGE_DOOR}>
        <meshStandardMaterial
          transparent={topic === EApplicationTopic.TERRAIN}
          opacity={opacity}
          color={chroma(parkingBuildingColor).darken(1).hex()}
          side={THREE.DoubleSide}
        />
      </Box>
    </group>
  );
};

export default ParkingLotIndoorEntrance;
