import {
  EAdditionalDistanceBuffer,
  EAdditionalDistances,
  EBufferDistances,
  EBufferType,
  IBufferConstructionSettings,
} from 'types/building/BuildingBuffers';
import { XY } from "types/location/coordinates";
import { distanceBetweenPoints } from '../components/Building/utils';
import { getBufferConstructionSettings } from '../store/selectors/general';

interface GetCalculatedDistanceProps {
  bufferConstructionSettings: IBufferConstructionSettings;
  ML: number;
  MH: number;
}

const getCalculatedKGDistance = (props: GetCalculatedDistanceProps) => {
  const { bufferConstructionSettings, ML, MH } = props;

  const {
    additionalDistance,
    additionalDistanceByLength,
    additionalDistanceByHeight,
    mlz,
    mhz,
    smallBuffer,
    maxKgWithDistance,
  } = bufferConstructionSettings;

  const isLengthAddedToKGByBuildingLength =
    (additionalDistance === EAdditionalDistances.MLZ || additionalDistance === EAdditionalDistances.MLZ_MHZ) &&
    (additionalDistanceByLength === EAdditionalDistanceBuffer.KG ||
      additionalDistanceByLength === EAdditionalDistanceBuffer.KGandGG);

  const isLengthAddedToKGByBuildingHeight =
    (additionalDistance === EAdditionalDistances.MHZ || additionalDistance === EAdditionalDistances.MLZ_MHZ) &&
    (additionalDistanceByHeight === EAdditionalDistanceBuffer.KG ||
      additionalDistanceByHeight === EAdditionalDistanceBuffer.KGandGG);

  const addedDistanceToKGByLength = !!mlz && isLengthAddedToKGByBuildingLength ? (ML / 100) * mlz : 0;
  const addedDistanceToKGByHeight = !!mhz && isLengthAddedToKGByBuildingHeight ? (MH / 100) * mhz : 0;

  const smallBufferValue = smallBuffer === undefined ? EBufferDistances.KG : smallBuffer;

  const newKGDistance = smallBufferValue + addedDistanceToKGByLength + addedDistanceToKGByHeight;

  return !!maxKgWithDistance && newKGDistance > maxKgWithDistance
    ? Math.round(maxKgWithDistance * 100) / 100
    : Math.round(newKGDistance * 100) / 100;
};

const getCalculatedGGDistance = (props: GetCalculatedDistanceProps) => {
  const { bufferConstructionSettings, ML, MH } = props;

  const {
    additionalDistance,
    additionalDistanceByLength,
    additionalDistanceByHeight,
    mlz,
    mhz,
    largeBuffer,
    maxGgWithDistance,
  } = bufferConstructionSettings;

  const isLengthAddedToGGByBuildingLength =
    (additionalDistance === EAdditionalDistances.MLZ || additionalDistance === EAdditionalDistances.MLZ_MHZ) &&
    (additionalDistanceByLength === EAdditionalDistanceBuffer.GG ||
      additionalDistanceByLength === EAdditionalDistanceBuffer.KGandGG);

  const isLengthAddedToGGByBuildingHeight =
    (additionalDistance === EAdditionalDistances.MHZ || additionalDistance === EAdditionalDistances.MLZ_MHZ) &&
    (additionalDistanceByHeight === EAdditionalDistanceBuffer.GG ||
      additionalDistanceByHeight === EAdditionalDistanceBuffer.KGandGG);

  const addedDistanceToGGByLength = !!mlz && isLengthAddedToGGByBuildingLength ? (ML / 100) * mlz : 0;
  const addedDistanceToGGByHeight = !!mhz && isLengthAddedToGGByBuildingHeight ? (MH / 100) * mhz : 0;

  const largeBufferValue = largeBuffer === undefined ? EBufferDistances.GG : largeBuffer;
  const newGGDistance = largeBufferValue + addedDistanceToGGByLength + addedDistanceToGGByHeight;

  return !!maxGgWithDistance && newGGDistance > maxGgWithDistance
    ? Math.round(maxGgWithDistance * 100) / 100
    : Math.round(newGGDistance * 100) / 100;
};

export const getCalculatedBufferDistance = (
  bufferCoordinates: XY[],
  bufferType: EBufferType,
  buildingHeight: number,
): number => {
  const bufferConstructionSettings = getBufferConstructionSettings();
  if (!bufferConstructionSettings) return EBufferDistances[bufferType];

  const { mhzFrom, mlzFrom, buildingBuffer, streetBuffer } = bufferConstructionSettings;

  const length = distanceBetweenPoints(bufferCoordinates[0], bufferCoordinates[1]);
  const ML = !mlzFrom || length < mlzFrom ? 0 : length - mlzFrom;
  const MH = !mhzFrom || buildingHeight < mhzFrom ? 0 : buildingHeight - mhzFrom;

  switch (bufferType) {
    case EBufferType.KG:
      return (
        getCalculatedKGDistance({
          bufferConstructionSettings,
          ML,
          MH,
        }) || EBufferDistances.KG
      );
    case EBufferType.GG:
      return (
        getCalculatedGGDistance({
          bufferConstructionSettings,
          ML,
          MH,
        }) || EBufferDistances.GG
      );
    case EBufferType.GA:
      return buildingBuffer || EBufferDistances.GA;
    case EBufferType.SA:
      return streetBuffer || EBufferDistances.SA;
    default:
      return streetBuffer || EBufferDistances.SA;
  }
};
