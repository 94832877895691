import * as THREE from 'three';
import { EObjectName, store, useSnapshot } from 'cityview';
import usePlotTerrainGeometry from 'cityview/hooks/usePlotTerrainGeometry';
import { XY } from "types/location/coordinates";

interface OffsetTerrainProps {
  plotCoordinates: XY[];
}

const OffsetTerrain = (props: OffsetTerrainProps) => {
  const { plotCoordinates } = props;
  const { geometry: plotTerrainGeometry } = usePlotTerrainGeometry({ plotCoordinates });
  const { heightFromTerrain } = useSnapshot(store.settings.offsetTerrain);

  if (heightFromTerrain === null) {
    return null;
  }

  return (
    <mesh
      renderOrder={4}
      geometry={plotTerrainGeometry}
      name={EObjectName.OFFSET_TERRAIN}
      position={[0, 0, heightFromTerrain]}
    >
      <meshStandardMaterial
        color={'#ff00ff'}
        side={THREE.DoubleSide}
        transparent={true}
        opacity={0.4}
        attach='material'
      />
    </mesh>
  );
};

export default OffsetTerrain;
