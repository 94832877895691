import { useCursor } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { canvasTheme, EObjectName, EObjectType } from 'cityview';
import useIsTerrainBuildingVisible from 'cityview/hooks/useIsTerrainBuildingVisible';
import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { EApplicationMode, EApplicationTool, EApplicationTopic } from 'types/applicationPath';
import { EParkingLotType, TParkingLotIndoor } from 'types/parking';
import { useApplicationPath, useHovered, useIsObjectSelected } from '../../../hooks';
import useDeepEffect from '../../../hooks/useDeepEffect';
import store, { parkingLotStore } from '../../../store';
import { deselectType, selectObject } from '../../../store/mutations/selections';
import { generateGeometryFromCornersAndHeight } from '../../../utils/parking-lot/util';
import { calculateCenter } from '../../Building/utils';
import ObjectSelectionIndicator from '../../ObjectSelectionIndicator';
import ParkingLotTransformer from '../ParkingGarageTransformer';
import ParkingLotIndoorEntrance from './ParkingLotIndoorEntrance';

interface ParkingLotIndoorProps {
  parkingLot: TParkingLotIndoor;
  selectionIndicator?: React.ReactNode;
}

const ParkingLotIndoor = (props: ParkingLotIndoorProps) => {
  const { parkingLot, selectionIndicator } = props;
  const { id, position, rotation, corners, entrances, properties } = parkingLot;
  const { type, clearHeight, construction } = properties;
  const { ceilingThickness, foundationThickness } = construction;

  const garageHeight =
    type === EParkingLotType.GARAGE || type === EParkingLotType.GARAGE_ROW
      ? -(clearHeight + ceilingThickness + foundationThickness)
      : clearHeight + ceilingThickness + foundationThickness + construction.ceilingTechnicalClearance;

  const parkingRef = useRef<THREE.Group | null>(null);
  const meshRef = useRef<THREE.Mesh | null>(null);

  const { topic, mode, tool } = useApplicationPath();
  const isSelected = useIsObjectSelected(id.toString());
  const isGarageVisible = useIsTerrainBuildingVisible(id.toString());

  const showTransformer =
    topic === EApplicationTopic.PARKING_LOTS &&
    mode === EApplicationMode.EDIT &&
    tool === EApplicationTool.MOVE &&
    parkingRef.current &&
    isSelected;

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    if (
      isSelected ||
      (store.general.applicationPath.topic !== EApplicationTopic.PARKING_LOTS &&
        store.general.applicationPath.topic !== EApplicationTopic.TERRAIN) ||
      (store.general.applicationPath.tool !== EApplicationTool.SELECT &&
        store.general.applicationPath.tool !== EApplicationTool.DEFAULT &&
        store.general.applicationPath.tool !== EApplicationTool.TERRAIN_ELEVATION &&
        store.general.applicationPath.tool !== EApplicationTool.MOVE)
    ) {
      return;
    }

    if (store.general.applicationPath.topic !== EApplicationTopic.TERRAIN) e.stopPropagation();

    if (
      store.general.applicationPath.topic !== EApplicationTopic.TERRAIN ||
      (store.general.applicationPath.topic === EApplicationTopic.TERRAIN &&
        meshRef.current &&
        e.intersections[0].eventObject.uuid === meshRef.current.uuid)
    ) {
      if (store.general.applicationPath.topic === EApplicationTopic.TERRAIN) {
        deselectType(EObjectType.BUILDING);
      }
      selectObject(id.toString(), EObjectType.PARKING_LOT);
    }
  };

  const { hovered, onPointerOver, onPointerOut } = useHovered();
  useCursor(hovered);

  const showHovered = hovered && topic === EApplicationTopic.PARKING_LOTS;

  const handleUpdatePosition = () => {
    if (!parkingRef.current) return;

    parkingLotStore.value.parkingLots[id].position = {
      x: parkingRef.current.position.x,
      y: parkingRef.current.position.y,
      z: parkingRef.current.position.z,
    };
  };

  const handleUpdateRotation = () => {
    if (!parkingRef.current) return;

    parkingLotStore.value.parkingLots[id].rotation = parkingRef.current.rotation.z;
  };

  useDeepEffect(() => {
    if (!meshRef.current) return;
    meshRef.current.geometry = generateGeometryFromCornersAndHeight(corners, -garageHeight);
  }, [corners, garageHeight, meshRef.current]);

  const parkingBuildingColor =
    topic === EApplicationTopic.USAGE
      ? canvasTheme.parkingLot.parkingUsageColor
      : type === EParkingLotType.UNDERGROUND_GARAGE
        ? canvasTheme.parkingLot.parkingUndergroundColor
        : canvasTheme.parkingLot.parkingOvergroundColor;

  const [centerX, centerY] = useMemo(() => calculateCenter(corners), [corners]);

  return (
    <group position={[0, 0, -foundationThickness]}>
      <group ref={parkingRef} position={[position.x, position.y, position.z]} rotation={[0, 0, rotation]}>
        <mesh
          ref={meshRef}
          name={EObjectName.PARKING_GARAGE}
          onClick={handleClick}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
        >
          <meshStandardMaterial
            color={parkingBuildingColor}
            transparent={topic === EApplicationTopic.TERRAIN}
            opacity={topic !== EApplicationTopic.TERRAIN ? 1 : isGarageVisible ? 0.6 : 0.4}
            side={THREE.DoubleSide}
            emissive={'#ffffff'}
            emissiveIntensity={showHovered ? 0.15 : isSelected ? 0.4 : 0}
            attach='material'
          />
        </mesh>
        {entrances.map((entrance) => (
          <ParkingLotIndoorEntrance
            entrance={entrance}
            garageHeight={garageHeight}
            parkingLot={parkingLot}
            parkingBuildingColor={parkingBuildingColor}
            opacity={topic !== EApplicationTopic.TERRAIN ? 1 : isGarageVisible ? 0.6 : 0.4}
            key={entrance.id}
          />
        ))}
        {isSelected && topic === EApplicationTopic.PARKING_LOTS && (
          <ObjectSelectionIndicator
            buildingId={id.toString()}
            coordinates={[centerX, centerY, garageHeight + 5]}
            showIndicator={tool !== EApplicationTool.MOVE && tool !== EApplicationTool.MODIFY}
          >
            {selectionIndicator}
          </ObjectSelectionIndicator>
        )}
      </group>
      {showTransformer && (
        <ParkingLotTransformer
          handleUpdatePosition={handleUpdatePosition}
          handleUpdateRotation={handleUpdateRotation}
          geometry={meshRef.current?.geometry as THREE.BufferGeometry}
          intersectObject={parkingRef.current}
        />
      )}
    </group>
  );
};

export default ParkingLotIndoor;
