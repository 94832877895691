import { ELoadingState, IVegetation } from 'cityview';
import React, { useEffect } from 'react';
import { EApplicationMode, EApplicationTool } from 'types/applicationPath';
import { ILngLat } from 'types/location/coordinates';
import { TTerrain } from 'types/terrain';
import { useSnapshot } from 'valtio';
import { useApplicationPath } from '../../hooks';
import useVegetationModels from '../../hooks/useVegetationModels';
import { landscapeStore, loaderStore } from '../../store';
import ActiveVegetations from './ActiveVegetations';
import RestorableVegetations from './RestorableVegetations';
import TemporaryVegetation from './TemporaryVegetation';

const groupVegetationsByVisibility = (vegetations: IVegetation[]) =>
  vegetations.reduce(
    (acc, vegetation) => {
      const activeVegetations = acc.activeVegetations;
      const restorableVegetations = acc.restorableVegetations;

      vegetation.properties.visible ? activeVegetations.push(vegetation) : restorableVegetations.push(vegetation);

      return {
        ...acc,
        activeVegetations,
        restorableVegetations,
      };
    },
    {
      activeVegetations: [] as IVegetation[],
      restorableVegetations: [] as IVegetation[],
    },
  );

interface VegetationsProps {
  center: ILngLat;
  terrain: TTerrain;
  terrainWidth: number;
  visible: boolean;
}

const Vegetations = (props: VegetationsProps) => {
  const { center, terrain, visible, terrainWidth } = props;

  const {
    value: { vegetations },
  } = useSnapshot(landscapeStore);

  const { vegetations: vegetationsExternallyLoading } = useSnapshot(loaderStore.external);

  const { activeVegetations, restorableVegetations } = groupVegetationsByVisibility(vegetations);

  const { mode, tool } = useApplicationPath();
  const selectToolActive = tool === EApplicationTool.VEGETATION_SELECT;
  const addToolActive = tool === EApplicationTool.VEGETATION_ADD;
  const restoreToolActive = tool === EApplicationTool.VEGETATION_RESTORE;

  const models = useVegetationModels();

  useEffect(() => {
    return () => {
      loaderStore.internal.vegetations.loadingState = ELoadingState.LOADING;
    };
  }, []);

  if (!models || vegetationsExternallyLoading.loadingState !== ELoadingState.SUCCESS) return null;

  return (
    <group
      scale={mode === EApplicationMode.BUFFER ? [1, 1, 0.01] : [1, 1, 1]}
      position={[0, 0, mode === EApplicationMode.BUFFER ? 0.5 : -0.5]}
    >
      <ActiveVegetations
        visible={visible}
        vegetations={activeVegetations}
        vegetationModels={models}
        center={center}
        terrainWidth={terrainWidth}
        selectToolActive={selectToolActive}
      />
      {restoreToolActive && (
        <RestorableVegetations
          visible={visible}
          vegetations={restorableVegetations}
          vegetationModels={models}
          center={center}
          terrainWidth={terrainWidth}
          restoreActive={restoreToolActive}
        />
      )}
      {addToolActive && (
        <TemporaryVegetation
          vegetationModels={models}
          terrain={terrain}
          terrainWidth={terrainWidth}
          visible={visible}
        />
      )}
    </group>
  );
};

const MemoizedVegetations = React.memo(Vegetations);

export default MemoizedVegetations;
