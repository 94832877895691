import { XY } from 'types/location/coordinates';
import { buildingStore } from '../../store';
import { getBuilding } from '../../store/selectors/building';
import { getBufferArray } from '../../utils/polygonOffset';

export const constructLinePoints = (corners: XY[]): number[] =>
  corners.reduce((previousCorners, currentValue) => {
    const [x, y] = currentValue;
    return [...previousCorners, x, y, 0];
  }, [] as number[]);

export const updateBuildingBuffersById = (buildingId: string) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const bufferArray = getBufferArray(building);
  if (!bufferArray) return;

  building.buffers = bufferArray;
};

export const updateBuildingsBuffers = () => {
  const buildings = buildingStore.value.projectBuildings;
  Object.keys(buildings).forEach((buildingId) => updateBuildingBuffersById(buildingId));
};
