import { IBuildingSettings } from 'types/building/BuildingSettings';
import { EStaircaseType } from 'types/building/BuildingUsage';
import { XY } from 'types/location/coordinates';
import { IBufferObject } from './BuildingBuffers';
import { EBuildingEnergyConsumptionProfile, EBuildingEnergySystemType } from './BuildingServices';
import { EBuildingPartWithoutWallAndWindow } from './Facade';
import { TFloors } from './Floor';

export enum EBuildingVariant {
  PROJECT = 'project',
  EXISTING = 'existing',
}

export enum EBuildingType {
  MULTI_FAMILY = 'multiFamily',
  SINGLE_FAMILY = 'singleFamily',
  INDUSTRIAL = 'industrial',
  OFFICE = 'office',
  MULTI_FAMILY_AND_OFFICE = 'multiFamilyAndOffice',
}

export enum EBuildingShape {
  I = 'I',
  L = 'L',
}

export type TFootprint = XY[];

export type TBuildings = Record<string, IBuilding>;

export interface IBuilding {
  id: string;
  name: string;
  type: EBuildingType;
  order: number;
  floors: TFloors;
  construction: IConstruction;
  services: IBuildingServices;
  buffers: IBufferObject[];
  copyId?: string;
  settings: IBuildingSettings;
  buildingFormChanged?: boolean;
  buildingPositionChanged?: boolean;
}

export interface IConstruction {
  ceilingThickness: number;
  hasLift: boolean;
  materials: Record<EBuildingPartWithoutWallAndWindow, string>;
  nrOfCores: number;
  nrOfLifts: number;
  roofThickness: number;
  staircaseType: EStaircaseType;
  wallThickness: number;
}

export enum EBuildingEnergyPvSubsidySource {
  PRONOVO = 'pronovo',
  USER = 'user',
}

export interface IBuildingServices {
  roofPvPercentage: number;
  pvIsIntegrated: boolean;
  pvEfficiency: number;
  roofPvEfficiency: number;
  pvEfficiencyError: number;
  pvSubsidySource: EBuildingEnergyPvSubsidySource;
  pvSubsidy: number;
  integratedFacadeExtraCost: number;
  integratedRoofExtraCost: number;
  appliedRoofExtraCost: number;
  energySelfConsumptionRate: number;
  energyFeedInRate: number;
  energySystemType: EBuildingEnergySystemType;
  energyConsumptionProfile: EBuildingEnergyConsumptionProfile;
  hasBattery: boolean;
}

export interface IBuildingProperties {
  name?: string;
  type: EBuildingType;
  variant: EBuildingVariant;
  nrOfFloors?: number;
  height?: number;
  buffers: IBufferObject[];
}

export interface ICoreType {
  area: number;
  length: number;
}

export interface IMeta {
  createdAt: string;
  updatedAt: string;
}

export enum EExistingBuildingVisibility {
  HIDDEN = 'HIDDEN',
  VISIBLE = 'VISIBLE',
  WIREFRAME = 'WIREFRAME',
}
