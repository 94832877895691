import Flatten from '@flatten-js/core';
import { Feature, LineString, Position } from 'geojson';
import { geom, operation } from 'jsts';
import { XY } from 'types/location/coordinates';
import { distanceBetweenPoints } from '../components/Building/utils';
import Coordinate = geom.Coordinate;
import GeometryFactory = geom.GeometryFactory;
import BufferOp = operation.buffer.BufferOp;
import BufferParameters = operation.buffer.BufferParameters;

export const getClosestPointsObject = (position: XY, points: XY[], lineStrings: Feature<LineString>[]) => {
  let closestCornerPoint: Position = [0, 0];
  let closestCornerPointDistance = Infinity;

  let closestLinePoint: Position = [0, 0];
  let closestLinePointDistance = Infinity;

  points.forEach((point) => {
    const distance = distanceBetweenPoints(position, point);

    if (distance < closestCornerPointDistance) {
      closestCornerPointDistance = distance;
      closestCornerPoint = point;
    }
  });

  lineStrings.forEach((lineString) => {
    // Find the closest corner point
    lineString.geometry.coordinates.forEach((corner) => {
      const distance = distanceBetweenPoints(position, corner as XY);

      if (distance < closestCornerPointDistance) {
        closestCornerPointDistance = distance;
        closestCornerPoint = corner;
      }
    });

    // Find the closest point on the line
    const polygon = new Flatten.Polygon();
    polygon.addFace(lineString.geometry.coordinates.map((point) => new Flatten.Point(point[0], point[1])));

    const [distance, segment] = new Flatten.Point(position[0], position[1]).distanceTo(polygon);
    if (distance < closestLinePointDistance) {
      closestLinePointDistance = distance;
      closestLinePoint = [segment.end.x, segment.end.y];
    }
  });

  return {
    closestCornerPoint,
    closestCornerPointDistance,
    closestLinePoint,
    closestLinePointDistance,
  };
};

const geometryFactory = new GeometryFactory();
const parameters = new BufferParameters(
  BufferParameters.DEFAULT_QUADRANT_SEGMENTS,
  BufferParameters.CAP_SQUARE,
  BufferParameters.JOIN_MITRE,
  5,
);

export const getBufferedPolygonCoordinates = (polygonCoordinates: XY[], buffer: number): XY[] => {
  const coordinateSequence = geometryFactory.getCoordinateSequenceFactory();

  const polygon = geometryFactory.createPolygon(
    coordinateSequence.create(polygonCoordinates.map((coordinate) => new Coordinate(coordinate[0], coordinate[1]))),
  );

  const bufferOp = new BufferOp(polygon, parameters);
  const bufferedPolygon = bufferOp.getResultGeometry(buffer);
  const bufferedCoordinates = bufferedPolygon.getCoordinates();

  return bufferedCoordinates.reverse().map((coordinate) => [coordinate.x, coordinate.y]);
};
