import { store, useApplicationPath } from 'cityview';
import CursorTooltip from 'cityview/components/CursorTooltip';
import TerrainPointTransformTooltipContent from 'cityview/components/Terrain/ModifiedTerrain/TerrainPoints/TransformerTooltipContent/TerrainPointTransformTooltipContent';
import { emitTerrainModified } from 'cityview/events/terrain';
import React, { useMemo, useRef } from 'react';
import { EApplicationTool } from 'types/applicationPath';
import { XYZ } from 'types/location/coordinates';
import { useSnapshot } from 'valtio';
import useObjectSelections from '../../../../hooks/useObjectSelections';
import { terrainStore } from '../../../../store';
import TerrainPointInstances from './TerrainPointInstances';
import TerrainPointTransformer from './TerrainPointTransformer';

const TerrainPoints = () => {
  const {
    value: {
      modifiedTerrain: { innerPoints },
    },
  } = useSnapshot(terrainStore);
  const { tool } = useApplicationPath();
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const { isMouseEditing } = useSnapshot(store.mouse);

  const { selectedTerrainPointIndices } = useObjectSelections();

  const transformerPosition: XYZ = useMemo(() => {
    if (selectedTerrainPointIndices.length > 0) {
      const points = selectedTerrainPointIndices.map((index) => innerPoints[index]);
      const sum = points.reduce((acc, point) => [acc[0] + point[0], acc[1] + point[1], acc[2] + point[2]], [0, 0, 0]);
      return [sum[0] / points.length, sum[1] / points.length, sum[2] / points.length];
    }

    return [0, 0, 0];
  }, [innerPoints, selectedTerrainPointIndices]);

  const handleElevationChange = (pointIndices: number[], elevationDifference: number, skipStore: boolean) => {
    const changedPoints: XYZ[] = [];
    pointIndices.forEach((pointIndex) => {
      const point = terrainStore.value.modifiedTerrain.innerPoints[pointIndex];
      if (point !== undefined) {
        const newValue = point[2] + elevationDifference;
        if (newValue !== point[2]) changedPoints.push([point[0], point[1], newValue]);

        if (!skipStore) point[2] = newValue;
      }
    });

    if (skipStore) emitTerrainModified({ changedPoints });
  };

  const showTransformer =
    selectedTerrainPointIndices.length > 0 &&
    (tool === EApplicationTool.TERRAIN_ELEVATION || tool === EApplicationTool.TERRAIN_RECTANGLE_SELECT);

  return (
    <>
      <TerrainPointInstances points={innerPoints} selectedIndices={selectedTerrainPointIndices} />

      {showTransformer && (
        <TerrainPointTransformer
          affectedPointIndices={selectedTerrainPointIndices}
          position={transformerPosition}
          handleElevationChange={handleElevationChange}
          updateElevationWhileDragging={(change: number) => {
            if (!tooltipRef.current) return;
            tooltipRef.current!.innerText = (change > 0 ? '+' : '') + change.toFixed(1);
          }}
        />
      )}

      {isMouseEditing && (
        <CursorTooltip>
          <TerrainPointTransformTooltipContent valueSpanRef={tooltipRef} />
        </CursorTooltip>
      )}
    </>
  );
};

export default TerrainPoints;
