// @ts-ignore
import StaticMap from '@rkaravia/static-map';
import { canvasTheme } from 'cityview';
import { terrainStore } from 'cityview/store';
import { Feature, LineString, MultiPolygon, Polygon } from 'geojson';
import { IPointLike, Point, SweepContext } from 'poly2tri';
import proj4 from 'proj4';
import * as THREE from 'three';
import { CanvasTexture } from 'three';
import { IConstructionLine } from 'types/gis/ConstructionLine';
import { ILngLat, XY, XYZ } from 'types/location/coordinates';
import { TOutdoorParkingLot } from 'types/parking';
import { ETerrainWidth, TTerrain } from 'types/terrain';
import { isObjectSelected } from '../../store/selectors/general';
import { wgsToLocal } from '../../utils/coordinates';
import { getAbsoluteParkingLotCoordinates } from '../../utils/parking-lot';

/**
 * Given the position [x, y] finds the closest point on terrain and returns its elevation
 * @param location
 * @param terrain
 */
export const findGroundHeightEstimation = (location: XY, terrain: TTerrain): number => {
  if (!location || !terrain) return 0;

  const [x, y] = location;
  const maxIdx = terrain.length - 1;
  const step = Math.abs(terrain[0][0][1] - terrain[0][1][1]);

  const rowLength = terrain[0].length;
  const width = Math.abs(terrain[0][0][1] - terrain[0][rowLength - 1][1]);
  const halfWidth = width / 2;

  const iReverse = Math.round((halfWidth - x) / step);
  const i = intoBounds(iReverse, 0, maxIdx);

  const jReverse = Math.round((halfWidth - y) / step);
  const j = intoBounds(maxIdx - jReverse, 0, maxIdx);

  const elevationPoint = terrain?.[i]?.[j];
  return elevationPoint ? elevationPoint[2] : 0;
};

/**
 * Returns the given value so that it is inside the given bounds: lower <= value <= upper
 * @param value
 * @param lower
 * @param upper
 */
const intoBounds = (value: number, lower: number, upper: number): number => {
  let correctValue;
  correctValue = value < lower ? lower : value;
  correctValue = correctValue > upper ? upper : correctValue;
  return correctValue;
};

const getMapBoxImageURL = (
  mapboxApiKey: string,
  coordinates: ILngLat,
  zoom = 18.28,
  bearing = 0,
  pitch = 0,
  width = 1200,
  height = 1200,
  retina = true,
) => {
  const camera = `${coordinates.lng},${coordinates.lat},${zoom},${bearing},${pitch}`;

  const urlPieces = [
    `https://api.mapbox.com/styles/v1/amenti/clwujdthh01cs01r0ahxk38rf/static/`,
    `${camera}/${width}x${height}${retina && '@2x'}`,
    `?attribution=false&access_token=${mapboxApiKey}`,
  ];

  return urlPieces.join('');
};

// Source: https://api3.geo.admin.ch/services/sdiservices.html#wmts
const RESOLUTIONS = [
  4000, 3750, 3500, 3250, 3000, 2750, 2500, 2250, 2000, 1750, 1500, 1250, 1000, 750, 650, 500, 250, 100, 50, 20, 10, 5,
  2.5, 2, 1.5, 1, 0.5, 0.25, 0.1,
];

const textureZoom = 27;
const textureTileSize = 256;
const tileWidth = 64;
const pixelsPerMeter = textureTileSize / tileWidth;

interface LonLatToPixelWmtsProps {
  lon: number;
  lat: number;
  zoom: number;
}

const sourceProj = 'EPSG:4326';
const destinationProj = 'EPSG:21781';
proj4.defs(
  destinationProj,
  '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.4,15.1,405.3,0,0,0,0 +units=m +no_defs',
);

function lonLatToPixelWmts(props: LonLatToPixelWmtsProps) {
  const { lon, lat, zoom } = props;
  const [xSwiss, ySwiss] = proj4(sourceProj, destinationProj, [lon, lat]);

  // Calculate the tile coordinates (x, y) based on the Swiss coordinates, zoom level, and tile size
  const resolution = RESOLUTIONS[zoom];
  const X_OFFSET = 420000;
  const Y_OFFSET = 350000;

  const x = Math.floor((xSwiss - X_OFFSET) / resolution);
  const y = Math.floor((Y_OFFSET - ySwiss) / resolution);

  return [x, y];
}

interface LoadRoadmapToCanvasProps {
  mapboxApiKey: string;
  center: ILngLat;
  terrainWidth: ETerrainWidth;
}

const loadRoadmapToCanvas = async (props: LoadRoadmapToCanvasProps): Promise<HTMLCanvasElement> => {
  const { mapboxApiKey, center, terrainWidth } = props;

  const zoom = terrainWidth === ETerrainWidth.SMALL ? 18.28 : 17;
  const mapboxMapURL = getMapBoxImageURL(mapboxApiKey, center, zoom);
  const canvas = document.createElement('canvas');
  canvas.width = 1024;
  canvas.height = 1024;
  canvas.className = 'mapbox-canvas';
  const context = canvas.getContext('2d');

  const image = new Image();
  image.crossOrigin = 'anonymous';

  return new Promise((resolve) => {
    image.addEventListener('load', () => {
      context?.drawImage(image, 0, 0, 1024, 1024);
      resolve(canvas);
    });

    image.src = mapboxMapURL;
  });
};

export const loadRoadmapImageAsTexture = async (mapboxApiKey: string, center: ILngLat, terrainWidth: number) => {
  const canvas: HTMLCanvasElement = await loadRoadmapToCanvas({
    mapboxApiKey,
    center,
    terrainWidth,
  });
  return new CanvasTexture(canvas);
};

const TERRAIN_TEXTURE_SIZE = 1024;

const drawHelpLines = (
  context: CanvasRenderingContext2D,
  helplines: Feature[],
  deviceMultiplier: number,
  centerX: number,
  centerY: number,
  scale: number,
) => {
  helplines.forEach((feature) => {
    context.beginPath();
    if (feature.geometry.type === 'Polygon') {
      const polygon = feature as Feature<Polygon>;
      polygon.geometry.coordinates.forEach((line) => {
        line.forEach((point, index) => {
          if (index === 0) {
            context.moveTo(centerX + point[0] * scale, centerY - point[1] * scale);
          } else {
            context.lineTo(centerX + point[0] * scale, centerY - point[1] * scale);
          }
        });
      });
      context.closePath();
    } else if (feature.geometry.type === 'LineString') {
      const lineString = feature as Feature<LineString>;
      lineString.geometry.coordinates.forEach((point, index) => {
        if (index === 0) {
          context.moveTo(centerX + point[0] * scale, centerY - point[1] * scale);
        } else {
          context.lineTo(centerX + point[0] * scale, centerY - point[1] * scale);
        }
      });
    }

    context.lineWidth = deviceMultiplier;
    switch (feature.properties?.type) {
      case 'zone_border':
        context.fillStyle = 'rgba(98,103,106,0.3)';
        context.strokeStyle = 'rgb(92,96,97)';
        break;
      case 'plot_border':
        context.fillStyle = 'rgba(31,198,106,0.3)';
        context.strokeStyle = 'rgb(31,198,118)';
        break;
      case 'construction_line':
        context.fillStyle = 'rgb(133, 141, 162, 0.3)';
        context.strokeStyle = 'rgb(133, 141, 162)';
        break;
      case 'construction_window':
        context.fillStyle = 'rgba(157,105,13, 0.3)';
        context.strokeStyle = 'rgb(153,100,4)';
        break;
      case 'forest_line':
        context.fillStyle = 'rgba(43,167,127,0.3)';
        context.strokeStyle = 'rgb(34,173,128)';
        break;
      case 'water_line':
        context.fillStyle = 'rgba(32,81,195,0.3)';
        context.strokeStyle = 'rgb(10,54,157)';
        break;
      case 'distance_line':
        context.fillStyle = 'rgba(98,14,160,0.3)';
        context.strokeStyle = 'rgb(93,2,160)';
        break;
      case 'helpline':
        context.fillStyle = 'rgba(172,142,25,0.3)';
        context.strokeStyle = 'rgb(193,157,21)';
        break;
      case 'accountable_plotarea':
        context.fillStyle = 'rgba(18,171,32,0.3)';
        context.strokeStyle = 'rgb(8,174,23)';
        break;
      case 'not_accountable_plotarea':
        context.fillStyle = 'rgba(26,106,8, 0.3)';
        context.strokeStyle = 'rgb(26,106,8)';
        break;
      default:
        context.fillStyle = 'rgba(255, 0, 0, 0.3)';
        context.strokeStyle = 'rgba(255, 0, 0, 1)';
    }

    if (feature.geometry.type === 'Polygon') {
      context.fill();
    }
    context.stroke();
  });
};

export const composeTerrainTextureLayers = (
  isMouseEditing: boolean,
  helplines: Feature[],
  plotCoordinates?: XY[],
  mainTexture?: THREE.CanvasTexture,
  terrainWidth?: number,
  showTexture = false,
  showGrid = false,
  gridCellWidth?: number,
  constructionLinesCoordinates?: XY[][],
  showTerrain = true,
  gridRotationAngle = 0,
  darkGrid = false,
  isDesktop = false,
  outDoorParkingLots?: TOutdoorParkingLot[],
  outDoorParkingLotsLines?: Record<string, [XY, XY][]>,
  landScapeFeatures?: Feature<MultiPolygon>[],
) => {
  if (!terrainWidth) return;
  const canvas = document.createElement('canvas');
  const deviceMultiplier = isDesktop ? 2 : 1;
  canvas.width = TERRAIN_TEXTURE_SIZE * deviceMultiplier;
  canvas.height = TERRAIN_TEXTURE_SIZE * deviceMultiplier;
  canvas.className = 'terrain-texture-compositing-canvas';
  const context = canvas.getContext('2d');
  if (!context) return;

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;
  const halfWidth = canvasWidth / 2;
  const halfHeight = canvasHeight / 2;

  // if (!showTerrain) {
  //   // Set the canvas background to transparent black
  //   const bgColor = chroma(canvasTheme.gridViewBackgroundColor).alpha(0.15).rgba();
  //   context.fillStyle = `rgba(${bgColor[0]}, ${bgColor[1]}, ${bgColor[2]}, ${bgColor[3]})`; //'rgba(34, 34, 34, 0.15)';
  //   context.fillRect(0, 0, canvasWidth, canvasHeight);
  // }

  const scale = canvasWidth / terrainWidth;
  const centerX = canvasWidth / 2;
  const centerY = canvasHeight / 2;

  if (showTerrain) {
    if (showTexture && mainTexture) {
      const image = mainTexture.image;
      context.drawImage(image, 0, 0, canvasWidth, canvasHeight);
    } else {
      context.fillStyle = showGrid ? canvasTheme.gridViewBackgroundColor : canvasTheme.terrainColor;
      context.fillRect(0, 0, canvasWidth, canvasHeight);
    }

    if (landScapeFeatures && landScapeFeatures.length > 0) {
      const filteredLandScapeFeatures = landScapeFeatures.filter((feature) => {
        const type = feature.properties?.objektart || '';
        return type.toLowerCase().includes('wald') || type.toLowerCase().includes('waesser');
      });
      filteredLandScapeFeatures.forEach((feature: Feature<MultiPolygon>) => {
        const coordinates = feature.geometry.coordinates[0][0];
        context.beginPath();

        coordinates.forEach((point, index) => {
          if (index === 0) {
            // @ts-ignore
            context.moveTo(centerX + point[0][0] * scale, centerY - point[0][1] * scale);
          } else {
            // @ts-ignore
            context.lineTo(centerX + point[0][0] * scale, centerY - point[0][1] * scale);
          }
        });
        context.closePath();
        const type = feature.properties?.objektart || '';
        if (type.toLowerCase().includes('wald')) {
          context.fillStyle = 'rgba(166,181,166,0.6)';
        } else if (type.toLowerCase().includes('waesser')) {
          context.fillStyle = 'rgba(213,230,246,0.6)';
        } else {
          context.fillStyle = 'rgba(255,255,255,0.0)';
        }
        context.fill();
      });
    }

    if (plotCoordinates && plotCoordinates.length > 0) {
      context.beginPath();
      context.moveTo(centerX + plotCoordinates[0][0] * scale, centerY - plotCoordinates[0][1] * scale);
      for (let i = 1; i < plotCoordinates.length; i++) {
        context.lineTo(centerX + plotCoordinates[i][0] * scale, centerY - plotCoordinates[i][1] * scale);
      }

      context.closePath();
      context.lineWidth = 1 * deviceMultiplier;
      context.strokeStyle = 'rgba(37, 187, 115, 1)';
      context.fillStyle = 'rgba(37, 187, 115, 0.15)';
      context.fill();
      context.strokeStyle = 'rgba(37, 187, 115, 1)';
      context.stroke();
    }

    drawHelpLines(context, helplines, deviceMultiplier, centerX, centerY, scale);
  }

  if (showGrid && gridCellWidth) {
    const scaledCellSize = Math.round(gridCellWidth * scale * 10) / 10;
    const sin = Math.sin(gridRotationAngle);
    const cos = Math.cos(gridRotationAngle);

    context.save(); // Save the current canvas state
    context.translate(halfWidth, halfHeight); // Move the origin to the center of the canvas
    context.rotate(gridRotationAngle); // Rotate the canvas
    context.translate(-halfWidth, -halfHeight); // Move the origin back to the top-left corner

    // Calculate the size of the rotated canvas
    const lineCount = Math.ceil((canvasWidth / scaledCellSize / 2) * 1.4);

    // Draw grid
    context.beginPath();

    for (let x = -lineCount * scaledCellSize; x <= lineCount * scaledCellSize; x += scaledCellSize) {
      const startY = -lineCount * scaledCellSize;
      const endY = lineCount * scaledCellSize;
      const rotatedStartX = x * cos - startY * sin;
      const rotatedStartY = x * sin + startY * cos;
      const rotatedEndX = x * cos - endY * sin;
      const rotatedEndY = x * sin + endY * cos;
      context.moveTo(rotatedStartX + halfWidth, rotatedStartY + halfHeight);
      context.lineTo(rotatedEndX + halfWidth, rotatedEndY + halfHeight);
    }

    for (let y = -lineCount * scaledCellSize; y <= lineCount * scaledCellSize; y += scaledCellSize) {
      const startX = -lineCount * scaledCellSize;
      const endX = lineCount * scaledCellSize;
      const rotatedStartX = startX * cos - y * sin;
      const rotatedStartY = startX * sin + y * cos;
      const rotatedEndX = endX * cos - y * sin;
      const rotatedEndY = endX * sin + y * cos;
      context.moveTo(rotatedStartX + halfWidth, rotatedStartY + halfHeight);
      context.lineTo(rotatedEndX + halfWidth, rotatedEndY + halfHeight);
    }

    context.strokeStyle = darkGrid ? canvasTheme.gridLineColorDark : canvasTheme.gridLineColor;
    context.lineWidth = 1 * deviceMultiplier;
    context.stroke();

    context.restore(); // Restore the canvas rotation
  }

  if (showTerrain && constructionLinesCoordinates && constructionLinesCoordinates.length > 0) {
    constructionLinesCoordinates.forEach((line) => {
      context.beginPath();

      line.forEach((point, index) => {
        if (index === 0) {
          context.moveTo(centerX + point[0] * scale, centerY - point[1] * scale);
        } else {
          context.lineTo(centerX + point[0] * scale, centerY - point[1] * scale);
        }
      });

      context.strokeStyle = canvasTheme.constructionLineColor;
      context.lineWidth = 2 * deviceMultiplier;
      context.stroke();
    });
  }

  if (outDoorParkingLots && outDoorParkingLots.length > 0) {
    outDoorParkingLots.forEach((parkingLot) => {
      const { position, rotation, corners, id } = parkingLot;
      if (isMouseEditing && isObjectSelected(id)) {
        return;
      }

      // calculate parkinglot corner coordinates as XY using the position and rotation and relative coordinates of each corner
      const parkingLotCorners = corners.map((corner): XY => {
        return getAbsoluteParkingLotCoordinates(position, rotation, corner);
      });

      // Draw filled polygon
      context.beginPath();
      context.moveTo(centerX + parkingLotCorners[0][0] * scale, centerY - parkingLotCorners[0][1] * scale);
      for (let i = 1; i < parkingLotCorners.length; i++) {
        context.lineTo(centerX + parkingLotCorners[i][0] * scale, centerY - parkingLotCorners[i][1] * scale);
      }
      context.closePath();
      context.fillStyle = canvasTheme.asphaltColor;
      context.fill();

      const lines = outDoorParkingLotsLines?.[id];

      if (lines) {
        lines.forEach((line) => {
          const startPoint = getAbsoluteParkingLotCoordinates(position, rotation, line[0]);
          const endPoint = getAbsoluteParkingLotCoordinates(position, rotation, line[1]);

          context.beginPath();
          context.moveTo(centerX + startPoint[0] * scale, centerY - startPoint[1] * scale);
          context.lineTo(centerX + endPoint[0] * scale, centerY - endPoint[1] * scale);
          context.strokeStyle = canvasTheme.parkingLot.outdoorLotLineColor;
          context.lineWidth = 1 * deviceMultiplier;
          context.stroke();
        });
      }
    });
  }

  return new CanvasTexture(canvas);
};

interface LoadSatelliteCanvasProps {
  center: ILngLat;
  zoom: number;
  tileSize: number;
  size: number;
  url: string;
}

const loadToCanvas = async (props: LoadSatelliteCanvasProps): Promise<HTMLCanvasElement> => {
  const { center, zoom, tileSize, size, url } = props;
  const staticMap = new StaticMap(url, { size: tileSize, lonLatToPixel: lonLatToPixelWmts });
  const canvas = document.createElement('canvas');
  canvas.width = size;
  canvas.height = size;

  return new Promise((resolve) => {
    staticMap.getMap(canvas, center.lng, center.lat, zoom, () => {
      resolve(canvas);
    });
  });
};

const loadImageAsTexture = async (url: string, center: ILngLat, width: number): Promise<CanvasTexture> => {
  const canvas: HTMLCanvasElement = await loadToCanvas({
    center,
    zoom: textureZoom,
    tileSize: textureTileSize,
    size: pixelsPerMeter * width,
    url,
  });
  const texture = new CanvasTexture(canvas);

  texture.minFilter = THREE.NearestFilter;
  texture.magFilter = THREE.NearestFilter;
  texture.generateMipmaps = false;

  return texture;
};

export const loadSatelliteImageAsTexture = async (center: ILngLat, width: number): Promise<CanvasTexture> => {
  const textureUrl = 'https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/2056/{z}/{x}/{y}.jpeg';

  return await loadImageAsTexture(textureUrl, center, width);
};

export const recalculateHelplines = (helplines: Feature[], center: ILngLat | undefined): Feature[] => {
  const linestringHelplines = helplines
    .filter((feature) => feature.geometry.type === 'LineString')
    .map((feature) => {
      const line = feature as Feature<LineString>;
      if (!center) return line;

      const localCoordinates = wgsToLocal(
        line.geometry.coordinates.map((point) => ({ lng: point[0], lat: point[1] })),
        center,
      );

      const coordinates: XY[] = localCoordinates.map((position) => [position[0], position[1]]);

      return {
        ...line,
        geometry: {
          ...line.geometry,
          coordinates,
        },
      };
    });

  const polygonHelplines = helplines
    .filter((feature) => feature.geometry.type === 'Polygon')
    .map((feature) => {
      const poly = feature as Feature<Polygon>;
      if (!center) return poly;

      try {
        const localCoordinates = wgsToLocal(
          poly.geometry.coordinates[0].map((point) => ({ lng: point[0], lat: point[1] })),
          center,
        );

        const coordinates: XY[] = localCoordinates.map((position) => [position[0], position[1]]);

        return {
          ...poly,
          geometry: {
            ...poly.geometry,
            coordinates: [coordinates],
          },
        };
      } catch (e) {
        console.error('Error while converting polygon helpline coordinates', e);
        return false;
      }
    })
    .filter((f) => !!f) as Feature<Polygon>[];

  return [...linestringHelplines, ...polygonHelplines];
};

export const getPlotSurfaceCoordinates = (
  plotPolygon: ILngLat[] | undefined,
  center: ILngLat | undefined,
): XY[] | undefined => {
  if (!plotPolygon || !center) return;

  const localCoordinates = wgsToLocal(plotPolygon, center);

  return localCoordinates.map((position) => [position[0], position[1]]);
};

export const getConstructionLinesCoordinates = (
  constructionLines: IConstructionLine[] | undefined,
  center: ILngLat | undefined,
): XY[][] | undefined => {
  if (!constructionLines || !center) return;

  return constructionLines.map((line) => {
    const localCoordinates = wgsToLocal(
      line.geom.coordinates[0].map((point) => ({ lng: point[0], lat: point[1] })),
      center,
    ).map((position) => [position[0], position[1]]);

    return localCoordinates.map((position) => [position[0], position[1]]);
  });
};

export const getTerrainSurfaceGeometry = (
  terrainInnerPoints: XYZ[],
  terrainEdgePoints: XYZ[],
  terrainWidth: number,
): {
  geometry: THREE.BufferGeometry;
  pointIndexMap: Map<string, number>;
} => {
  const totalPoints = [...terrainEdgePoints, ...terrainInnerPoints];

  // Convert coordinates to poly2tri.Point
  const contour = terrainEdgePoints.map((p) => new Point(p[0], p[1]));

  // Create a sweep context with the contour
  const sweepContext = new SweepContext(contour);

  // Add the rest of the points (inner points)
  terrainInnerPoints.forEach((p) => {
    sweepContext.addPoint(new Point(p[0], p[1]));
  });

  // Perform the triangulation
  sweepContext.triangulate();

  // Get the triangles
  const triangles = sweepContext.getTriangles();

  const pointRecord: Record<string, XYZ> = {};
  totalPoints.forEach((point) => {
    pointRecord[`${point[0]},${point[1]}`] = point;
  });

  // Prepare vertices and indices for Three.js geometry
  const vertices: number[] = [];
  const indices: number[] = [];
  const uvs: number[] = [];
  const vertexIndexMap = new Map<string, number>();

  const addVertex = (p: IPointLike): number => {
    const key = `${p.x},${p.y}`;
    if (!vertexIndexMap.has(key)) {
      // Find the original 3D point
      const originalPoint = pointRecord[key];
      if (originalPoint) {
        vertexIndexMap.set(key, vertices.length / 3);
        vertices.push(originalPoint[0], originalPoint[1], originalPoint[2]);

        const uvX = (p.x + terrainWidth / 2) / terrainWidth;
        const uvY = (p.y + terrainWidth / 2) / terrainWidth;
        uvs.push(uvX, uvY);
      }
    }
    return vertexIndexMap.get(key)!;
  };

  triangles.forEach((triangle) => {
    const a = addVertex(triangle.getPoint(0));
    const b = addVertex(triangle.getPoint(1));
    const c = addVertex(triangle.getPoint(2));
    indices.push(a, b, c);
  });

  // Create a BufferGeometry to use with Three.js
  const geometry = new THREE.BufferGeometry();
  geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));
  geometry.setAttribute('uv', new THREE.BufferAttribute(new Float32Array(uvs), 2));
  geometry.setIndex(indices);

  return {
    geometry,
    pointIndexMap: vertexIndexMap,
  };
};

export const getTerrainPointElevationFromAbsoluteElevation = (absoluteElevation: number): number => {
  const { midpointElevation } = terrainStore.value.originalTerrain;

  return midpointElevation !== undefined ? absoluteElevation - midpointElevation : absoluteElevation;
};

export const getTerrainPointElevationRelativeToOriginalTerrain = (terrainPoint: XYZ): number => {
  const { terrainData } = terrainStore.value.originalTerrain;

  if (!terrainData) return 0;

  const originalElevation =
    Math.round(100 * findGroundHeightEstimation([terrainPoint[0], terrainPoint[1]], terrainData)) / 100;

  let difference = Math.round(100 * (terrainPoint[2] - originalElevation)) / 100;
  // eslint-disable-next-line no-compare-neg-zero
  if (difference === -0) difference = 0;

  return difference;
};

export const getTerrainPointElevationFromSeaLevel = (elevation: number): number => {
  const { midpointElevation } = terrainStore.value.originalTerrain;

  let absoluteElevation = midpointElevation !== undefined ? elevation + midpointElevation : elevation;
  absoluteElevation = Math.round(100 * absoluteElevation) / 100;

  return absoluteElevation;
};

export const getAltitudeLevel = (altitudeDifference: number): number => {
  if (altitudeDifference <= -5) return -6;
  if (altitudeDifference >= 5) return 6;
  if (altitudeDifference === 0) return 0;

  return altitudeDifference < 0 ? Math.ceil(altitudeDifference) - 1 : Math.floor(altitudeDifference) + 1;
};

export const ALTITUDE_COLORS: string[] = [
  '#000088',
  '#0044dd',
  '#0088ff',
  '#00AAff',
  '#00ccff',
  '#00ffdd',
  '#BBBBBB',
  '#ffff00',
  '#FFCC00',
  '#FFAA00',
  '#FF8800',
  '#FF5500',
  '#FF1100',
];

export const getAltitudeColor = (altitudeDifference: number): string => {
  const level = getAltitudeLevel(altitudeDifference);

  return ALTITUDE_COLORS[level + 6];
};

export const updateVertexElevation = (
  geometry: THREE.BufferGeometry,
  changedPoints: XYZ[],
  pointIndices: Map<string, number>,
) => {
  const position = geometry.attributes.position;
  const verticesArray = position.array as Float32Array;

  changedPoints.forEach((changedPoint) => {
    const key = `${changedPoint[0]},${changedPoint[1]}`;
    const pointIndex = pointIndices.get(key);

    if (pointIndex !== undefined) {
      verticesArray[pointIndex * 3 + 2] = changedPoint[2];
    }
  });

  position.needsUpdate = true;
  geometry.computeVertexNormals();
};

export const updateWireframeVertexElevation = (
  geometry: THREE.WireframeGeometry,
  changedPoints: XYZ[],
  indices: Map<string, number[]>,
) => {
  const vertices = geometry.attributes.position.array as Float32Array;
  changedPoints.forEach((changedPoint) => {
    const key = `${changedPoint[0]},${changedPoint[1]}`;
    const zIndices = indices.get(key);
    if (!zIndices || zIndices.length === 0) return;

    zIndices.forEach((zIndex) => {
      vertices[zIndex + 2] = changedPoint[2];
    });
  });

  geometry.attributes.position.needsUpdate = true;
};
