export interface GetKeeValueSubscriptionAccessResponse {
  status: EKeeValueSubscriptionStatus;
}

export interface GetKeeValueCalculationSettingsResponse {
  general: {
    name: string;
    streetAndNumber: string;
    zipCodeAndCity: string;
  };
  quantity: {
    floorArea: number;
    buildingVolume: number;
    buildingVolumeBelowTerrain: number;
  };
  needsRecalculation: boolean;
}

export interface StartNewKeeValueSubscriptionIntegrationResponse {
  url: string;
  requestId: string;
}

export interface StartNewKeeValueCalculationResponse {
  url: string;
}

export enum EKeeValueSubscriptionStatus {
  NOT_CONNECTED = 'NOT_CONNECTED',
  VALID = 'VALID',
  INVALID = 'INVALID',
  MISSING_MODULE = 'MISSING_MODULE',
}
