import isEqual from 'lodash/isEqual';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';

export function useDeepCompareCache<T>(obj: T): T {
  const ref = useRef<T>(obj);

  // or other
  if (!isEqual(ref.current, obj)) {
    ref.current = obj;
  }

  return ref.current;
}

function useDeepEffect(effect: EffectCallback, deps?: DependencyList) {
  return useEffect(effect, useDeepCompareCache(deps));
}

export default useDeepEffect;
