import { XYZ } from 'types/location/coordinates';
import { IMeta } from './Building';

export interface IFaceSegment {
  id: string;
  faceId: string;
  properties: IFaceSegmentProperties;
  corners: ISegmentPoint[];
}

export interface ISegmentPoint {
  coordinates: XYZ;
  order?: number;
  id?: string;
  meta?: IMeta;
}

export interface IFaceSegmentProperties {
  segmentWidth: number;
  segmentHeight: number;
  wallLayout: EWallLayout;
  wallProperties: ISegmentWallProperties;
  pvActive: boolean;
  balconyDividers: EBalconyDividers;
  hasBalcony: boolean;
  balconyWidth: number;
  opening: ESegmentOpening;
  roofPvPercentage: number | undefined;
}

export enum EBalconyDividers {
  LEFT = 'left',
  RIGHT = 'right',
  BOTH = 'both',
  NONE = 'none',
}

export enum ESegmentOpening {
  WINDOW = 'window',
  DOOR = 'door',
  NONE = 'none',
}

export interface IWallPartProperties {
  width: number;
  height: number;
  color: string;
  hasPv: boolean;
}

export interface IWallTopProperties extends IWallPartProperties {
  variableHeight: number;
}

export interface ISegmentWallProperties {
  top: IWallTopProperties;
  bottom: IWallPartProperties;
  sides: IWallPartProperties;
  center: IWallPartProperties;
}

export type TSegmentWallPartMaterials = Record<
  ESegmentWallPart,
  {
    colors: string[];
    isPV: boolean[];
  }
>;

export enum ESegmentWallPart {
  TOP = 'top',
  BOTTOM = 'bottom',
  SIDES = 'sides',
  CENTER = 'center',
}

export enum ESegmentTopHeight {
  ZERO = 0,
  FIFTEEN = 0.15,
  THIRTY = 0.3,
  FIFTY = 0.5,
  EIGHTY = 0.8,
}

export enum ESegmentBottomHeight {
  ZERO = 0,
  SEVENTY = 0.7,
  EIGHTY_FIVE = 0.85,
  HUNDRED = 1,
}

export enum ESegmentSideWidth {
  ZERO = 0,
  FIFTEEN = 0.15,
  THIRTY = 0.3,
  FIFTY = 0.5,
  SEVENTY_FIVE = 0.75,
  HUNDRED = 1,
}

export enum EWallLayout {
  NARROW_TOP_AND_BOTTOM = 'narrowTopAndBottom',
  WIDE_TOP_AND_BOTTOM = 'wideTopAndBottom',
  WIDE_TOP = 'wideTop',
  WIDE_BOTTOM = 'wideBottom',
  NO_BOTTOM_NARROW_TOP = 'noBottomNarrowTop',
  NO_BOTTOM_WIDE_TOP = 'noBottomWideTop',
}
