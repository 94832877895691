import { useCursor } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import {
  ELoadingState,
  EObjectName,
  EObjectType,
  EVegetationType,
  getClippingPlanes,
  IVegetation,
  store,
} from 'cityview';
import React, { useEffect, useMemo } from 'react';
import { Color, DoubleSide, MeshStandardMaterial } from 'three';
import { EApplicationTool } from 'types/applicationPath';
import { ILngLat } from 'types/location/coordinates';
import { ETerrainWidth } from 'types/terrain';
import { useHovered, useObjectSelections } from '../../hooks';
import { TVegetationModels } from '../../hooks/useVegetationModels';
import { loaderStore } from '../../store';
import { deselectObject, selectObject } from '../../store/mutations/selections';
import { getVegetation } from '../../store/selectors/landscape';

import { getVegetationByMouseEvent, groupVegetationsByType } from './utils';
import VegetationsInstancedMesh from './VegetationsInstancedMesh';

interface ActiveVegetationsProps {
  visible: boolean;
  vegetations: IVegetation[];
  vegetationModels: TVegetationModels;
  center: ILngLat;
  selectToolActive: boolean;
  terrainWidth: ETerrainWidth;
}

const ActiveVegetations = (props: ActiveVegetationsProps) => {
  const { visible, vegetationModels, terrainWidth, selectToolActive, vegetations, center } = props;

  const vegetationsByType = groupVegetationsByType(vegetations);

  const { selectedVegetationIds } = useObjectSelections();

  const selectedVegetations = selectedVegetationIds.reduce((acc, selectedVegetationId) => {
    const vegetation = getVegetation(selectedVegetationId);

    if (!vegetation) return acc;
    if (!vegetation.properties.visible) return acc;

    return [...acc, vegetation];
  }, [] as IVegetation[]);

  const selectedVegetationsByType = groupVegetationsByType(selectedVegetations);

  const { vegetationMaterial, selectedVegetationMaterial } = useMemo(() => {
    const vegetationMaterial = vegetationModels[EVegetationType.BIRCH].material.clone() as MeshStandardMaterial;
    vegetationMaterial.side = DoubleSide;
    vegetationMaterial.clippingPlanes = getClippingPlanes(terrainWidth);
    vegetationMaterial.metalness = 0;
    vegetationMaterial.roughness = 1;

    const selectedVegetationMaterial = vegetationMaterial.clone();
    selectedVegetationMaterial.emissive = new Color('#22FFBB');
    selectedVegetationMaterial.emissiveIntensity = 0.15;

    return { vegetationMaterial, selectedVegetationMaterial };
  }, [terrainWidth]);

  const { hovered, onPointerOver, onPointerOut } = useHovered({
    disabled: !selectToolActive,
  });

  useCursor(hovered);

  useEffect(() => {
    loaderStore.internal.vegetations.loadingState = ELoadingState.SUCCESS;
  }, []);

  return (
    <>
      {Object.entries(selectedVegetationsByType).map(([type, typeVegetations]) => (
        <VegetationsInstancedMesh
          key={`selected-${type}`}
          name={EObjectName.ACTIVE_VEGETATION}
          center={center}
          geometry={vegetationModels[type as EVegetationType].geometry}
          material={selectedVegetationMaterial}
          onClick={(e: ThreeEvent<MouseEvent>) => {
            e.stopPropagation();

            if (store.general.applicationPath.tool !== EApplicationTool.VEGETATION_SELECT) return;

            const vegetation = getVegetationByMouseEvent(e, typeVegetations);
            if (!vegetation) return;

            if (e.metaKey || e.ctrlKey || selectedVegetationIds.length === 1) {
              deselectObject(vegetation.id);
            } else {
              selectObject(vegetation.id, EObjectType.VEGETATION);
            }
          }}
          onPointerOut={onPointerOut}
          onPointerOver={onPointerOver}
          vegetations={typeVegetations}
          visible={visible}
        />
      ))}

      {Object.entries(vegetationsByType).map(([type, typeVegetations]) => (
        <VegetationsInstancedMesh
          key={`other-${type}`}
          name={EObjectName.ACTIVE_VEGETATION}
          center={center}
          geometry={vegetationModels[type as EVegetationType].geometry}
          material={vegetationMaterial}
          onClick={(e: ThreeEvent<MouseEvent>) => {
            e.stopPropagation();

            if (store.general.applicationPath.tool !== EApplicationTool.VEGETATION_SELECT) return;

            const vegetation = getVegetationByMouseEvent(e, typeVegetations);
            if (!vegetation) return;

            selectObject(vegetation.id, EObjectType.VEGETATION, !e.ctrlKey && !e.metaKey);
          }}
          onPointerOut={onPointerOut}
          onPointerOver={onPointerOver}
          hiddenVegetations={selectedVegetations}
          vegetations={typeVegetations}
          visible={visible}
        />
      ))}
    </>
  );
};

export default ActiveVegetations;
