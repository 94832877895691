import { getFloorById } from '../selectors/floor';

export const removeFloorFace = (buildingId: string, floorId: string, faceId: string) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) {
    console.warn('removeFloorFace: floor not found');
    return;
  }

  floor.faces = floor.faces.filter((face) => face.id !== faceId);
};
