import throttle from 'lodash/throttle';
import { findMinBoundingRect } from 'min-bounding-rectangle';
import { EFaceType, IWallFace } from 'types/building/Face';
import { XY } from 'types/location/coordinates';
import { getBuildingFootprint, getFootprintArea, getXYFromXYZ } from '../components/Building/utils';
import { CornerDragEvent, useCornerDragEndListener, useCornerDragListener } from '../events/corners';
import { useWallDragEndListener, useWallDragListener, WallDragEvent } from '../events/walls';
import { getBuilding } from '../store/selectors/building';
import { getFloorById } from '../store/selectors/floor';

const { sqrt, pow, min, max } = Math;

interface IDimensions {
  length: number;
  width: number;
  area: number;
}

interface UseBuildingDimensionsProps {
  onDrag?: (e: IDimensions) => void;
  onEnd?: (e: IDimensions) => void;
}

const useBuildingDimensions = (props: UseBuildingDimensionsProps) => {
  const { onDrag, onEnd } = props;

  useWallDragListener(
    throttle(({ buildingId, wallsCoordinates }: WallDragEvent) => {
      if (!onDrag) return;

      const {
        floorId,
        coordinates: { previous, primary, next },
      } = wallsCoordinates[0];

      const floor = getFloorById(buildingId, floorId);

      const walls = floor?.faces.filter((face) => face.type === EFaceType.WALL) as IWallFace[];

      const newWalls =
        walls
          .sort((a, b) => a.order - b.order)
          .map((wall) => {
            if (wall.id === previous.id) {
              return previous.coordinates;
            } else if (wall.id === primary.id) {
              return primary.coordinates;
            } else if (wall.id === next.id) {
              return next.coordinates;
            } else {
              return wall.coordinates;
            }
          }) ?? [];

      const footprint = newWalls.map((coordinates) => getXYFromXYZ(coordinates[0]));

      const dimensions = getDimensionsFromFootprint(footprint);

      onDrag(dimensions);
    }, 100),
  );

  useWallDragEndListener(({ buildingId }) => {
    if (!onEnd) return;

    const building = getBuilding(buildingId);
    if (!building) return;

    const footprint = getBuildingFootprint(building);
    const dimensions = getDimensionsFromFootprint(footprint);

    onEnd(dimensions);
  });

  useCornerDragListener(
    throttle((input: CornerDragEvent) => {
      if (!onDrag) return;

      const { buildingId, floorId, position, wall: currentWall, previousWall } = input;
      const { x, y } = position;

      const floor = getFloorById(buildingId, floorId);

      const walls = floor?.faces.filter((face) => face.type === EFaceType.WALL) as IWallFace[];

      const newWalls =
        walls
          .sort((a, b) => a.order - b.order)
          .map((wall) => {
            if (wall.id === previousWall.id) {
              return [previousWall.coordinates[0], [x, y]];
            }
            if (wall.id === currentWall.id) {
              return [[x, y], wall.coordinates[1]];
            }
            return wall.coordinates;
          }) ?? [];

      const footprint: XY[] = newWalls.map((coordinates) => [coordinates[0][0], coordinates[0][1]]);

      const dimensions = getDimensionsFromFootprint(footprint);

      onDrag(dimensions);
    }, 100),
  );

  useCornerDragEndListener(({ buildingId }) => {
    if (!onEnd) return;

    const building = getBuilding(buildingId);
    if (!building) return;

    const footprint = getBuildingFootprint(building);
    const dimensions = getDimensionsFromFootprint(footprint);

    onEnd(dimensions);
  });
};

export const getDimensionsFromFootprint = (footprint: XY[]): IDimensions => {
  if (!footprint.length) return { length: 0, width: 0, area: 0 };

  const bbox: number[][] = findMinBoundingRect(footprint);

  const [v1, v2, v3] = [bbox[0], bbox[1], bbox[2]];
  const length1 = sqrt(pow(v2[0] - v1[0], 2) + pow(v2[1] - v1[1], 2));
  const length2 = sqrt(pow(v3[0] - v2[0], 2) + pow(v3[1] - v2[1], 2));

  return {
    length: max(length1, length2),
    width: min(length1, length2),
    area: getFootprintArea(footprint),
  };
};

export default useBuildingDimensions;
