import { IModifiedTerrain } from 'cityview';
import { IVegetation } from 'cityview/store/landscapeStore';
import { FeatureCollection } from 'geojson';
import { ELanguage } from 'i18n';
import { IBuilding } from 'types/building/Building';
import { TCostLibraryDataSource, TIncomeLibraryDataSource } from 'types/enums/LibraryDataSource';
import { EProjectIncomeType } from 'types/enums/Project';
import { IProjectExistingBuilding } from 'types/gis/ExistingBuilding';
import { ILngLat } from 'types/location/coordinates';
import { QueryParameters } from 'types/request';
import { ETerrainWidth } from 'types/terrain';
import { Int } from 'utils/Int';

export interface CreateProjectRequest {
  name: string;
  egrids: string[];
  location: {
    center: ILngLat;
    address: string;
    plot: IProjectPlotConstructorParams;
  };
  selectedZoneId?: number | null;
  isPlotSaveAction?: boolean;
}

export interface IProjectPlotConstructorParams {
  id?: number;
  plotNumber: string;
  egrid: string;
  polygon: number[][];
  area: number;
}

export interface ProjectsRequest {
  projectIds: string[];
}

export interface RenameProjectRequest {
  projectId: string;
  newName: string;
  currentName: string;
}

export interface ChangeProjectSettingsRequest {
  projectId: string;
  name: string;
  description: string;
}

export interface GetProjectsForDashboardRequest {
  pagination: {
    limit: number;
    page: number;
  };
  search: string;
  plotFilter: {
    plotNumber: string;
    municipality: string;
  };
  shared: boolean;
}

export interface GetProjectStaticMapRequest extends QueryParameters {
  width?: number;
  height?: number;
  retina?: number;
  bearing?: number;
  zoom?: number;
  pitch?: number;
  lineWidth?: number;
  satellite?: boolean;
}

export interface GetProjectStaticMapInputs {
  projectId: string;
  params: GetProjectStaticMapRequest;
}

export interface EditLandCostRequest {
  projectId: string;
  body: {
    landCost: number;
  };
}

export interface EditCostSettingsRequest {
  projectId: string;
  body: {
    value: number;
  };
}

export interface EditAdditionalBkpFieldRequest {
  projectId: string;
  body: IUpdateAdditionalBkpField;
}

export interface IUpdateAdditionalBkpField {
  field: EAdditionalBkpCostField;
  cost: number;
}

export enum EAdditionalBkpCostField {
  BKP3 = 'BKP3',
  BKP6 = 'BKP6',
  BKP7 = 'BKP7',
  BKP8 = 'BKP8',
  BKP9 = 'BKP9',
}

export interface EditCostTypeRequest {
  projectId: string;
  costType: string;
}

export interface EditCostDataSourceRequest {
  projectId: string;
  dataSource: TCostLibraryDataSource;
}

export interface EditIncomeDataSourceRequest {
  projectId: string;
  dataSource: TIncomeLibraryDataSource;
}

export interface EditIncomeTypeRequest {
  projectId: string;
  incomeType: string;
}

export interface EditDiscountRateRequest {
  projectId: string;
  discountRate: number;
}

export interface EditRiskCorrectionRequest {
  projectId: string;
  riskCorrection: number;
}

export interface EditExitMultipleRequest {
  projectId: string;
  exitMultiple: number;
}

export interface EditOperationalCostRequest {
  projectId: string;
  operationalCost: number;
}

export interface EditRefurbishmentCostRequest {
  projectId: string;
  refurbishmentCost: number;
}

export interface EditProjectLibraryItemRequest {
  projectId: string;
  libraryItemId: number;
}

export interface CreateNoteRequest {
  note: string;
}

export interface CreateNoteInputs {
  projectId: string;
  body: CreateNoteRequest;
}

export interface SaveProjectMapLinesRequest {
  projectId: string;
  body: FeatureCollection;
}

export interface DeleteNoteInputs {
  projectId: string;
  noteId: number;
}

export interface ImportFahrlanderRentIncomesRequest {
  projectId: string;
}

export interface ImportFahrlanderSaleIncomesRequest {
  projectId: string;
}

export type GetFahrlanderIncomesResponse = Record<string, Int>;

export interface GetFahrlanderIncomesRequest {
  projectId: string;
  incomeType: EProjectIncomeType;
}

export interface Neighbors3dRequest {
  neighbors: string[];
}

export interface Neighbors3dInputs {
  projectId: string;
  body: Neighbors3dRequest;
}

export interface GetProjectVegetationsRequest {
  projectId: string;
  terrainWidth: ETerrainWidth;
}

export interface UpdateProjectLandscapeRequest {
  projectId: string;
  body: {
    vegetations: IVegetation[];
  };
}

export interface GetProjectModifiedTerrainRequest {
  projectId: string;
}

export interface UpdateProjectModifiedTerrainRequest {
  projectId: string;
  body: {
    modifiedTerrain: IModifiedTerrain;
  };
}

export interface SetProjectArea {
  projectId: string;
  area: number;
}

export interface SetConfidenceLevelRequest {
  projectId: string;
  confidenceLevel: number;
}

export interface SetLawZoneRequest {
  projectId: string;
  zoneId: number;
}

export interface GetProjectUtilizationByDtoRequest {
  projectId: string;
  buildings: IBuilding[];
  existingBuildings: IProjectExistingBuilding[];
}

export enum EReportSection {
  PLOTS = 'plots',
  RESTRICTIONS = 'restrictions',
  MUNICIPALITY_LAW = 'municipalityLaw',
  CANTON_LAW = 'cantonLaw',
  BUILDINGS = 'buildings',
  SUMMARY = 'summary',
  SITUATION = 'situation',
  UTILIZATION = 'utilization',
  AREAS = 'areas',
  DIMENSIONS = 'dimensions',
  DIMENSION_IMAGES = 'dimensionImages',
  BUILDING_UNITS = 'buildingUnits',
  CHECKLIST = 'checklist',
  COSTS = 'costs',
  INCOMES = 'incomes',
  DATA_OVERVIEW = 'dataOverview',
}

export interface IReportSection {
  show: boolean;
}

export type TReportSections = Record<EReportSection, IReportSection>;

export interface ProjectReportSettingsInput {
  hasImages: boolean;
  language: ELanguage | null;
  sections: TReportSections;
}
