import Flatten from '@flatten-js/core';
import { XY } from 'types/location/coordinates';

export const getLineSlopeAndIntercept = (coordinates: XY[]): { slope: number; intercept: number } => {
  const [[x1, y1], [x2, y2]] = coordinates;
  if (x1 === x2) return { slope: Infinity, intercept: y1 };

  const slope = (y2 - y1) / (x2 - x1);
  const intercept = y1 - slope * x1;
  return { slope, intercept };
};

// export const findDistanceBetweenParallelLines = (coordinates1: XY[], coordinates2: XY[]): number => {
//   const { slope, intercept: intercept1 } = getLineSlopeAndIntercept(coordinates1);
//   const { intercept: intercept2 } = getLineSlopeAndIntercept(coordinates2);
//
//   if (slope === Infinity || slope === -Infinity) return Math.abs(coordinates1[0][0] - coordinates2[0][0]);
//
//   const numerator = absolute ? Math.abs(intercept2 - intercept1) : intercept2 - intercept1;
//   const denominator = Math.sqrt(slope ** 2 + 1);
//
//   return numerator / denominator;
// };

export const findDistanceAndDirectionBetweenParallelLines = (coordinates1: XY[], coordinates2: XY[]) => {
  const dy = coordinates1[1][1] - coordinates1[0][1];
  const dx = coordinates1[1][0] - coordinates1[0][0];
  const l1Normal: XY = [dy, -dx];

  const length2 = Math.sqrt(
    (coordinates2[1][0] - coordinates2[0][0]) ** 2 + (coordinates2[1][1] - coordinates2[0][1]) ** 2,
  );
  const dir2: XY = [
    (coordinates2[1][0] - coordinates2[0][0]) / length2,
    (coordinates2[1][1] - coordinates2[0][1]) / length2,
  ];
  const l2Extended: XY[] = [
    [coordinates2[0][0] - 1000 * dir2[0], coordinates2[0][1] - 1000 * dir2[1]],
    [coordinates2[0][0] + 1000 * dir2[0], coordinates2[0][1] + 1000 * dir2[1]],
  ];

  const l1Start = new Flatten.Point(coordinates1[0]);
  const l2 = new Flatten.Segment(new Flatten.Point(l2Extended[0]), new Flatten.Point(l2Extended[1]));
  const [distance, dirSegment] = l2.distanceTo(l1Start);

  const {
    ps: { x: x1, y: y1 },
    pe: { x: x2, y: y2 },
  } = dirSegment;
  const diffDir: XY = [x2 - x1, y2 - y1];

  const sameDirX = Math.sign(l1Normal[0]) === Math.sign(diffDir[0]);
  const sameDirY = Math.sign(l1Normal[1]) === Math.sign(diffDir[1]);
  if ((sameDirX && sameDirY) || distance === 0) return { distance, direction: 1 };

  return { distance, direction: -1 };
};
