import { ThreeEvent } from '@react-three/fiber';
import { EInteractionType, emitSegmentHover, EObjectType } from 'cityview';
import { deselectObject, deselectType, selectObject } from 'cityview/store/mutations/selections';
import { extrudeWall } from 'cityview/store/mutations/walls';
import { getBuilding, getBuildingFacadeSegments, getBuildingRoofSegments } from 'cityview/store/selectors/building';
import { getFloorById } from 'cityview/store/selectors/floor';
import {
  areAllObjectsSelected,
  getSelectedObjectIdsByType,
  hasSelectedObjectsOfType,
  isObjectSelected,
} from 'cityview/store/selectors/general';
import { derivedTerrainStore } from 'cityview/store/terrainStore';
import { getLineSlopeAndIntercept } from 'cityview/utils/lineCalculations';
import { isMouseOutOfBounds } from 'cityview/utils/mouse';
import { faceNormal } from 'cityview/utils/polygonOffset';
import { Vector2 } from 'three';
import {
  EApplicationMode,
  EApplicationStep,
  EApplicationTool,
  EApplicationTopic,
  ESelectionFilter,
} from 'types/applicationPath';
import { EFaceType, TFace } from 'types/building/Face';
import { IFaceSegment } from 'types/building/FaceSegment';
import { EFloorShape, IFloor } from 'types/building/Floor';
import { XY } from 'types/location/coordinates';
import store, { buildingStore } from '../../../../store';
import { isSegmentAboveGround } from '../../utils';
import { calculatePolygonSurfaceNormal, typeVectorToNumberArray, typeXYZtoPoint } from './geometry';

export interface SegmentClickHandlerArgs {
  segmentId: string;
  faceId: string;
  floorId: string;
  buildingId: string;
  disableClickInteraction: boolean;
  isFacadeSegment: boolean;
  isFacadeFace: boolean;
  faceType: EFaceType;
}

export const getSegmentInteractionHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const {
    applicationPath: { step, topic, mode, tool },
  } = store.general;

  switch (step) {
    case EApplicationStep.DESIGN:
      switch (topic) {
        case EApplicationTopic.FORM:
          switch (mode) {
            case EApplicationMode.VIEW:
              switch (tool) {
                case EApplicationTool.VEGETATION_SELECT:
                case EApplicationTool.VEGETATION_ADD:
                case EApplicationTool.VEGETATION_RESTORE:
                  return () => null;
                default:
                  return getStandardSelectClickHandler(handlerArgs);
              }
            case EApplicationMode.EDIT:
              switch (tool) {
                case EApplicationTool.SELECT:
                  return getStandardSelectClickHandler(handlerArgs);
                case EApplicationTool.MOVE:
                  return getStandardMoveClickHandler(handlerArgs);
                case EApplicationTool.MODIFY:
                  return getStandardEditClickHandler(handlerArgs);
                case EApplicationTool.VEGETATION_SELECT:
                case EApplicationTool.VEGETATION_ADD:
                case EApplicationTool.VEGETATION_RESTORE:
                  return () => null;
                default:
                  throw new Error(`Invalid interaction case: step ${step}, topic ${topic}, mode ${mode}, tool ${tool}`);
              }
            case EApplicationMode.BUFFER:
              switch (tool) {
                case EApplicationTool.SELECT:
                  return getBufferSelectClickHandler(handlerArgs);
                case EApplicationTool.MOVE:
                  return getStandardMoveClickHandler(handlerArgs);
                case EApplicationTool.VEGETATION_SELECT:
                case EApplicationTool.VEGETATION_ADD:
                case EApplicationTool.VEGETATION_RESTORE:
                  return () => null;
                default:
                  throw new Error(`Invalid interaction case: step ${step}, topic ${topic}, mode ${mode}, tool ${tool}`);
              }
            default:
              throw new Error(`Invalid interaction case: step ${step}, topic ${topic}, mode ${mode}`);
          }
        case EApplicationTopic.FACADE:
          if (isObjectSelected(handlerArgs.buildingId) && (!handlerArgs.isFacadeSegment || !handlerArgs.isFacadeFace)) {
            return () => null;
          }

          switch (tool) {
            case EApplicationTool.BUILDING:
              return getFacadeBuildingSelectClickHandler(handlerArgs);
            case EApplicationTool.FLOOR:
              return getFacadeFloorSelectClickHandler(handlerArgs);
            case EApplicationTool.WALL:
              return getFacadeWallSelectClickHandler(handlerArgs);
            case EApplicationTool.FLOOR_WALL:
              return getFacadeFaceSelectClickHandler(handlerArgs);
            case EApplicationTool.SEGMENT:
              return getFacadeSegmentSelectClickHandler(handlerArgs);
            default:
              throw new Error(`Invalid interaction case: step ${step}, topic ${topic}, mode ${mode}, tool ${tool}`);
          }
        case EApplicationTopic.PHOTOVOLTAICS:
          switch (tool) {
            case EApplicationTool.BUILDING:
              return getFacadeBuildingSelectClickHandler(handlerArgs);
            case EApplicationTool.FLOOR:
              return getFacadeFloorSelectClickHandler(handlerArgs);
            case EApplicationTool.WALL:
              return getFacadeWallSelectClickHandler(handlerArgs);
            case EApplicationTool.FLOOR_WALL:
              return getFacadeFaceSelectClickHandler(handlerArgs);
            case EApplicationTool.SEGMENT:
              return getFacadeSegmentSelectClickHandler(handlerArgs);
            default:
              throw new Error(`Invalid interaction case: step ${step}, topic ${topic}, mode ${mode}, tool ${tool}`);
          }
        case EApplicationTopic.PARKING_LOTS:
          return () => null;
        case EApplicationTopic.TERRAIN:
          switch (tool) {
            case EApplicationTool.TERRAIN_ELEVATION:
              return getTerrainEditBuildingSelectHandler(handlerArgs);
            default:
              return () => null;
          }
        case EApplicationTopic.LANDSCAPE:
          return () => null;
        default:
          return getStandardSelectClickHandler(handlerArgs);
      }
    default:
      return () => null;
  }
};

interface GetHandlerWithClickVariantsArgs {
  defaultInteraction?: (action: EInteractionType) => void;
  cmdInteraction?: (action: EInteractionType) => void;
  shiftInteraction?: (action: EInteractionType) => void;
  altInteraction?: (action: EInteractionType) => void;
  disableClickInteraction: boolean;
}

const getHandlerWithClickVariants = (args: GetHandlerWithClickVariantsArgs) => {
  const {
    defaultInteraction,
    cmdInteraction,
    shiftInteraction,
    altInteraction,
    disableClickInteraction = false,
  } = args;

  return (action: EInteractionType, mouseEvent?: ThreeEvent<MouseEvent>, key?: string) => {
    if ((mouseEvent && (isMouseOutOfBounds(mouseEvent.point) || store.mouse.isMouseDown)) || disableClickInteraction)
      return;

    if ((mouseEvent?.shiftKey || key === 'Shift') && shiftInteraction) {
      shiftInteraction(action);
    } else if ((mouseEvent?.metaKey || mouseEvent?.ctrlKey || key === 'Control' || key === 'Meta') && cmdInteraction) {
      cmdInteraction(action);
    } else if ((mouseEvent?.altKey || key === 'Alt') && altInteraction) {
      altInteraction(action);
    } else if (defaultInteraction) {
      defaultInteraction(action);
    }
  };
};

const getTerrainEditBuildingSelectHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }
    },
    disableClickInteraction,
  });
};

const getStandardSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      if (action === EInteractionType.CLICK) {
        if (isObjectSelected(floorId)) {
          deselectType(EObjectType.FLOOR);
        } else {
          selectObject(floorId, EObjectType.FLOOR);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ floorIds: [floorId] });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      if (action === EInteractionType.CLICK) {
        if (isObjectSelected(floorId)) {
          deselectType(EObjectType.FLOOR);
        } else {
          selectObject(floorId, EObjectType.FLOOR, false);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ floorIds: [floorId] });
      }
    },
    shiftInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      if (isObjectSelected(floorId)) {
        if (action === EInteractionType.CLICK) {
          deselectObject(floorId);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ floorIds: [floorId] });
        }
      } else {
        if (hasSelectedObjectsOfType(EObjectType.FLOOR)) {
          const floorIds = getFloorsBetween(getSelectedObjectIdsByType(EObjectType.FLOOR)[0], floorId, buildingId);
          if (action === EInteractionType.CLICK) {
            selectObject(floorIds, EObjectType.FLOOR);
          } else if (action === EInteractionType.HOVER) {
            emitSegmentHover({ floorIds });
          }
        } else {
          if (action === EInteractionType.CLICK) {
            selectObject(floorId, EObjectType.FLOOR);
          } else if (action === EInteractionType.HOVER) {
            emitSegmentHover({ floorIds: [floorId] });
          }
        }
      }
    },
    disableClickInteraction,
  });
};

const getStandardMoveClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (isObjectSelected(buildingId)) {
        if (getSelectedObjectIdsByType(EObjectType.BUILDING).length > 1) {
          if (action === EInteractionType.CLICK) {
            selectObject(buildingId, EObjectType.BUILDING);
          } else if (action === EInteractionType.HOVER) {
            emitSegmentHover({ buildingId });
          }
        }
      } else {
        if (action === EInteractionType.CLICK) {
          selectObject(buildingId, EObjectType.BUILDING);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ buildingId });
        }
      }
    },
    cmdInteraction: (action) => {
      if (isObjectSelected(buildingId)) {
        if (action === EInteractionType.CLICK) {
          deselectObject(buildingId);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ buildingId });
        }
      } else {
        if (action === EInteractionType.CLICK) {
          selectObject(buildingId, EObjectType.BUILDING, false);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ buildingId });
        }
      }
    },
    disableClickInteraction,
  });
};

const getStandardEditClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      const selectedTransformWalls = getSelectedObjectIdsByType(EObjectType.TRANSFORM_WALL);

      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const face = buildingStore.value.projectBuildings[buildingId].floors[floorId].faces.find(
        (face) => face.id === faceId,
      );
      if (!face || face.type !== EFaceType.WALL) return;
      if (action === EInteractionType.CLICK) {
        if (isObjectSelected(faceId)) {
          if (selectedTransformWalls.length > 1) {
            deselectType(EObjectType.TRANSFORM_CORNER);
            deselectType(EObjectType.TRANSFORM_WALL);
            selectObject(faceId, EObjectType.TRANSFORM_WALL);
          } else {
            deselectType(EObjectType.TRANSFORM_WALL);
          }
        } else {
          deselectType(EObjectType.TRANSFORM_CORNER);
          selectObject(faceId, EObjectType.TRANSFORM_WALL);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ faceIds: [faceId] });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const face = buildingStore.value.projectBuildings[buildingId].floors[floorId].faces.find(
        (face) => face.id === faceId,
      );
      if (!face || face.type !== EFaceType.WALL) return;
      if (action === EInteractionType.CLICK) {
        if (isObjectSelected(faceId)) {
          deselectObject(faceId);
        } else {
          deselectType(EObjectType.TRANSFORM_CORNER);
          selectObject(faceId, EObjectType.TRANSFORM_WALL, false);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ faceIds: [faceId] });
      }
    },
    shiftInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const face = buildingStore.value.projectBuildings[buildingId].floors[floorId].faces.find(
        (face) => face.id === faceId,
      );
      if (!face || face.type !== EFaceType.WALL) return;

      const similarWalls = getSimilarFacesFromFaces(getBuildingWalls(buildingId), face);
      const similarWallIds = similarWalls.map((face) => face.id);
      if (areAllObjectsSelected(similarWallIds)) {
        if (action === EInteractionType.CLICK) {
          deselectType(EObjectType.TRANSFORM_CORNER);
          deselectType(EObjectType.TRANSFORM_WALL);
          selectObject(faceId, EObjectType.TRANSFORM_WALL);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ faceIds: [faceId] });
        }
      } else {
        if (action === EInteractionType.CLICK) {
          deselectType(EObjectType.TRANSFORM_CORNER);
          selectObject(similarWallIds, EObjectType.TRANSFORM_WALL);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ faceIds: similarWallIds });
        }
      }
    },
    altInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      if (action === EInteractionType.CLICK) {
        extrudeWall(buildingId, floorId, faceId);
      } else if (action === EInteractionType.HOVER) {
        const face = buildingStore.value.projectBuildings[buildingId].floors[floorId].faces.find(
          (face) => face.id === faceId,
        );
        if (!face || face.type !== EFaceType.WALL) return;
        emitSegmentHover({ faceIds: [faceId] });
      }
    },
    disableClickInteraction,
  });
};

const getBufferSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId)) {
        if (action === EInteractionType.CLICK) {
          selectObject(buildingId, EObjectType.BUILDING);
        } else if (action === EInteractionType.HOVER) {
          emitSegmentHover({ buildingId });
        }
      }
    },
    cmdInteraction: (action) => {
      if (action === EInteractionType.CLICK) {
        if (isObjectSelected(buildingId)) {
          deselectObject(buildingId);
        } else {
          selectObject(buildingId, EObjectType.BUILDING, false);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ buildingId });
      }
    },
    disableClickInteraction,
  });
};

const getFacadeSegmentSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { segmentId, faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        if (isObjectSelected(segmentId) && getSelectedObjectIdsByType(selectionType).length === 1) {
          deselectType(selectionType);
        } else {
          deselectType(selectionType);
          selectObject(segmentId, selectionType);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: [segmentId] });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        if (isObjectSelected(segmentId)) {
          deselectObject(segmentId);
        } else {
          selectObject(segmentId, selectionType, false);
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: [segmentId] });
      }
    },
    disableClickInteraction,
  });
};

const getFacadeFaceSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { segmentId, faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { selectionFilter } = store.general.applicationPath;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      const segmentsToSelect =
        selectionType === EObjectType.SEGMENT
          ? face.segments
          : face.type === EFaceType.ROOF
            ? face.segments
            : ([] as IFaceSegment[]);
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected && selectedSegmentIds.length === face.segments.length) {
          deselectType(selectionType);
        } else {
          deselectType(selectionType);
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: segmentIdsToSelect });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { selectionFilter } = store.general.applicationPath;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      const segmentsToSelect =
        selectionType === EObjectType.SEGMENT
          ? face.segments
          : face.type === EFaceType.ROOF
            ? face.segments
            : ([] as IFaceSegment[]);
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected) {
          selectedSegmentIds.forEach((segmentId) => deselectObject(segmentId));
        } else {
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: face.segments.map((segment) => segment.id) });
      }
    },
    disableClickInteraction,
  });
};

const getFacadeBuildingSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { segmentId, faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { selectionFilter } = store.general.applicationPath;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      const allBuildingSegments =
        selectionType === EObjectType.ROOF_SEGMENT
          ? getBuildingRoofSegments(buildingId)
          : getBuildingFacadeSegments(buildingId, true);
      let buildingSegmentIds = allBuildingSegments.map((buildingSegment) => buildingSegment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            buildingSegmentIds = allBuildingSegments
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        if (selectedSegmentIds.length === buildingSegmentIds.length) {
          deselectType(selectionType);
        } else {
          deselectType(selectionType);
          buildingSegmentIds.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: buildingSegmentIds });
      }
    },
    disableClickInteraction,
  });
};

const getFacadeFloorSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { segmentId, faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { selectionFilter } = store.general.applicationPath;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      const segmentsToSelect =
        selectionType === EObjectType.ROOF_SEGMENT
          ? floor.faces.filter((face) => face.type === EFaceType.ROOF).flatMap((face) => face.segments)
          : floor.faces.flatMap((face) => face.segments);
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected && selectedSegmentIds.length === segmentIdsToSelect.length) {
          deselectType(selectionType);
        } else {
          deselectType(selectionType);
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: segmentIdsToSelect });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { selectionFilter } = store.general.applicationPath;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      const segmentsToSelect =
        selectionType === EObjectType.ROOF_SEGMENT
          ? floor.faces.filter((face) => face.type === EFaceType.ROOF).flatMap((face) => face.segments)
          : floor.faces.flatMap((face) => face.segments);
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected) {
          segmentIdsToSelect.forEach((segmentId) => deselectObject(segmentId));
        } else {
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({
          segmentIds: segmentIdsToSelect,
        });
      }
    },
    disableClickInteraction,
  });
};

const getFacadeWallSelectClickHandler = (handlerArgs: SegmentClickHandlerArgs) => {
  const { segmentId, faceId, floorId, buildingId, disableClickInteraction } = handlerArgs;

  return getHandlerWithClickVariants({
    defaultInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const building = getBuilding(buildingId);
      if (!building) return;

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { mergedModifiedTerrain } = derivedTerrainStore;
      if (!mergedModifiedTerrain) return;

      const { selectionFilter } = store.general.applicationPath;

      let segmentsToSelect = [] as IFaceSegment[];

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      if (selectionType === EObjectType.ROOF_SEGMENT) {
        segmentsToSelect = floor.faces.filter((face) => face.type === EFaceType.ROOF).flatMap((face) => face.segments);
      } else {
        const similarFaces = getSimilarFacesFromFaces(getBuildingFaceFaces(buildingId), face);
        segmentsToSelect = similarFaces.flatMap((face) => {
          const faceFloor = getFloorById(buildingId, face.floorId);
          return faceFloor
            ? face.segments.filter((segment) => isSegmentAboveGround(segment, faceFloor, mergedModifiedTerrain))
            : [];
        });
      }
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected && selectedSegmentIds.length === segmentIdsToSelect.length) {
          deselectType(selectionType);
        } else {
          deselectType(selectionType);
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({ segmentIds: segmentIdsToSelect });
      }
    },
    cmdInteraction: (action) => {
      if (!isObjectSelected(buildingId) && action === EInteractionType.CLICK) {
        selectObject(buildingId, EObjectType.BUILDING);
      }

      const floor = getFloorById(buildingId, floorId);
      if (!floor) return;

      const face = floor.faces.find((face) => face.id === faceId);
      if (!face) return;

      const selectionType = face.type === EFaceType.ROOF ? EObjectType.ROOF_SEGMENT : EObjectType.SEGMENT;

      const segment = face.segments.find((segment) => segment.id === segmentId);
      if (!segment) return;

      const { mergedModifiedTerrain } = derivedTerrainStore;
      if (!mergedModifiedTerrain) return;

      const { selectionFilter } = store.general.applicationPath;

      let segmentsToSelect;

      const selectedSegmentIds = getSelectedObjectIdsByType(selectionType);
      if (selectionType === EObjectType.ROOF_SEGMENT) {
        segmentsToSelect = floor.faces.filter((face) => face.type === EFaceType.ROOF).flatMap((face) => face.segments);
      } else {
        const similarFaces = getSimilarFacesFromFaces(getBuildingFaceFaces(buildingId), face);
        segmentsToSelect = similarFaces.flatMap((face) => {
          const faceFloor = getFloorById(buildingId, face.floorId);
          return faceFloor
            ? face.segments.filter((segment) => isSegmentAboveGround(segment, faceFloor, mergedModifiedTerrain))
            : [];
        });
      }
      let segmentIdsToSelect = segmentsToSelect.map((segment) => segment.id);

      if (selectionType === EObjectType.SEGMENT) {
        switch (selectionFilter) {
          case ESelectionFilter.FACADE_WALL_LAYOUT:
            segmentIdsToSelect = segmentsToSelect
              .filter((buildingSegment) => buildingSegment.properties.wallLayout === segment.properties.wallLayout)
              .map((buildingSegment) => buildingSegment.id);
            break;
        }
      }

      if (action === EInteractionType.CLICK) {
        if (face.type === EFaceType.ROOF) {
          deselectType(EObjectType.SEGMENT);
        } else if (face.type === EFaceType.WALL) {
          deselectType(EObjectType.ROOF_SEGMENT);
        }

        let allSelected = true;
        segmentIdsToSelect.forEach((segmentIdToSelect) => {
          allSelected = allSelected && selectedSegmentIds.includes(segmentIdToSelect);
        });

        if (allSelected) {
          segmentIdsToSelect.forEach((segmentId) => deselectObject(segmentId));
        } else {
          segmentIdsToSelect.forEach((segmentId) => selectObject(segmentId, selectionType, false));
        }
      } else if (action === EInteractionType.HOVER) {
        emitSegmentHover({
          segmentIds: segmentIdsToSelect,
        });
      }
    },
    disableClickInteraction,
  });
};

export const getBuildingFaceFaces = (buildingId: string): TFace[] => {
  const building = getBuilding(buildingId);
  if (!building) throw new Error(`No building found with id ${buildingId}.`);

  return Object.values(building.floors)
    .filter((floor) => floor.properties.shape !== EFloorShape.ROOF)
    .flatMap((floor) => floor.faces.filter((face) => face.type === EFaceType.WALL));
};

export const getBuildingWalls = (buildingId: string): TFace[] => {
  const building = getBuilding(buildingId);
  if (!building) throw new Error(`No building found with id ${buildingId}.`);

  return Object.values(building.floors).flatMap((floor) => floor.faces.filter((face) => face.type === EFaceType.WALL));
};

export const getSimilarFacesFromFaces = (faces: TFace[], referenceFace: TFace): TFace[] => {
  const isWall = referenceFace.type === EFaceType.WALL;
  const referenceFaceNormal = isWall
    ? faceNormal(referenceFace.coordinates[0], referenceFace.coordinates[1])
    : typeVectorToNumberArray(
        calculatePolygonSurfaceNormal(referenceFace.coordinates.map((xyz) => typeXYZtoPoint(xyz))),
      );
  const referenceFaceIntercept = isWall
    ? getLineSlopeAndIntercept(referenceFace.coordinates.map((c): XY => [c[0], c[1]])).intercept
    : undefined;

  return faces.filter((face) => {
    if (!(face.type === referenceFace.type)) return false;

    const cFaceNormal = isWall
      ? faceNormal(face.coordinates[0], face.coordinates[1])
      : typeVectorToNumberArray(calculatePolygonSurfaceNormal(face.coordinates.map((xyz) => typeXYZtoPoint(xyz))));
    const sameNormals = areVectorsSimilar(cFaceNormal, referenceFaceNormal, 1e-3);
    if (!sameNormals) return false;

    if (!isWall) return true;
    if (!referenceFaceIntercept) return false;

    // Test if faces are on the same plane
    const normalVec = new Vector2(referenceFaceNormal[0], referenceFaceNormal[1]);
    const angleToX = -normalVec.angle();
    const rotatedFaceCorner = new Vector2(face.coordinates[0][0], face.coordinates[0][1]).rotateAround(
      new Vector2(0, 0),
      angleToX,
    );
    const rotatedReferenceFaceCorner = new Vector2(
      referenceFace.coordinates[0][0],
      referenceFace.coordinates[0][1],
    ).rotateAround(new Vector2(0, 0), angleToX);
    const xDistance = Math.abs(rotatedFaceCorner.x - rotatedReferenceFaceCorner.x);

    return xDistance < 0.05;
  });
};

const getFloorsBetween = (firstSelectionFloorId: string, newSelectionFloorId: string, buildingId: string) => {
  const building = getBuilding(buildingId);
  if (!building) throw new Error(`No building found with id ${buildingId}.`);

  const floors = Object.values(building.floors).sort((a, b) => a.properties.order - b.properties.order);

  const startIndex = floors.findIndex((floor) => floor.id === firstSelectionFloorId);
  const endIndex = floors.findIndex((floor) => floor.id === newSelectionFloorId);

  let floorsBetween: IFloor[];

  if (startIndex !== -1 && endIndex !== -1) {
    if (startIndex <= endIndex) {
      floorsBetween = floors.slice(startIndex, endIndex + 1);
    } else {
      floorsBetween = floors.slice(endIndex, startIndex + 1);
    }
  } else {
    throw new Error(
      `No floor found with id ${firstSelectionFloorId} or ${newSelectionFloorId} in building with id ${buildingId}.`,
    );
  }

  return floorsBetween.map((floor) => floor.id);
};

export const areVectorsSimilar = (vector1: number[], vector2: number[], allowedDeviationInRadians: number) => {
  // Find the angle between both vectors
  const angle = Math.abs(
    Math.acos(dotProduct(vector1, vector2) / (vectorMagnitude(vector1) * vectorMagnitude(vector2))),
  );

  return isNaN(angle) || angle <= allowedDeviationInRadians;
};

export const dotProduct = (vector1: number[], vector2: number[]) => {
  return vector1.reduce((acc, value, index) => {
    return acc + value * vector2[index];
  }, 0);
};

export const vectorMagnitude = (vector: number[]) => {
  return Math.sqrt(
    vector.reduce((acc, value) => {
      return acc + value * value;
    }, 0),
  );
};
