import isEqual from 'lodash/isEqual';
import round from 'lodash/round';
import uniqWith from 'lodash/uniqWith';
import { generateUUID } from 'three/src/math/MathUtils';
import { XY } from 'types/location/coordinates';
import {
  EParkingLotEntrancePosition,
  EParkingLotLaneWidthLateral,
  EParkingLotNrOfParkingRows,
  IParkingLotEntrance,
  IUndergroundGarageParkingLotProperties,
  TUndergroundGarageParkingLot,
} from 'types/parking';

const UndergroundGarageParkingLotConstants = {
  [EParkingLotLaneWidthLateral.SIX_FIFTY]: {
    parkingSpotWidth: 2.5,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 1.5,
  },
  [EParkingLotLaneWidthLateral.SIX_TWENTY_FIVE]: {
    parkingSpotWidth: 2.55,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 1.75,
  },
  [EParkingLotLaneWidthLateral.SIX]: {
    parkingSpotWidth: 2.6,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 1.75,
  },
  [EParkingLotLaneWidthLateral.FIVE_SEVENTY_FIVE]: {
    parkingSpotWidth: 2.65,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 2,
  },
  [EParkingLotLaneWidthLateral.FIVE_FIFTY]: {
    parkingSpotWidth: 2.7,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 2,
  },
  [EParkingLotLaneWidthLateral.FIVE_TWENTY_FIVE]: {
    parkingSpotWidth: 2.75,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 2.25,
  },
  [EParkingLotLaneWidthLateral.FIVE]: {
    parkingSpotWidth: 2.8,
    parkingSpotDepth: 5,
    dw: 0.3,
    lfz: 2.25,
  },
};

// static constants
const undergroundGarageParkingSpotDepth = 5;
const undergroundGarageLateralDistanceToWall = 0.3;

const getUndergroundParkingSecondParkingSectionDepth = (nrOfParkingRows: number): number => {
  return nrOfParkingRows === 2 ? undergroundGarageParkingSpotDepth : 0;
};

const getUndergroundGarageTotalLaneWidth = (laneWidth: number, externalWallThickness: number): number => {
  return laneWidth + 2 * externalWallThickness;
};

const getLateralDistanceToSupport = (distanceToSupport: number): number => {
  return distanceToSupport === 0 ? 0.3 : 0.1;
};

const getLaneWidthIncrement = (laneWidth: EParkingLotLaneWidthLateral): number => {
  return UndergroundGarageParkingLotConstants[laneWidth].lfz;
};

const getUndergroundGarageParkingSpotWidth = (laneWidth: EParkingLotLaneWidthLateral): number => {
  return UndergroundGarageParkingLotConstants[laneWidth].parkingSpotWidth;
};

const getNrOfAddedParkingSpots = (nrOfParkingRows: number, entrancePosition: EParkingLotEntrancePosition): number => {
  if (nrOfParkingRows === 1) return 0;
  if (entrancePosition === EParkingLotEntrancePosition.EDGE) return 0;
  return 2;
};

const getLeftPocketWidth = (properties: IUndergroundGarageParkingLotProperties): number => {
  const { nrOfTotalParkingSpots, nrOfParkingRows, entrancePosition, laneWidth } = properties;
  const isEven = nrOfTotalParkingSpots % 2 === 0;
  return entrancePosition === EParkingLotEntrancePosition.MIDDLE ||
    (entrancePosition === EParkingLotEntrancePosition.SIDE && nrOfParkingRows === 2 && isEven)
    ? getLaneWidthIncrement(laneWidth)
    : 0;
};

const getRightPocketWidth = (properties: IUndergroundGarageParkingLotProperties): number => {
  const { nrOfTotalParkingSpots, nrOfParkingRows, entrancePosition, laneWidth } = properties;
  const and1 = entrancePosition === EParkingLotEntrancePosition.SIDE && nrOfParkingRows === 1;
  const and2 = entrancePosition === EParkingLotEntrancePosition.EDGE && nrOfParkingRows === 1;
  const and3 = entrancePosition === EParkingLotEntrancePosition.MIDDLE && nrOfParkingRows === 1;
  const and4 =
    entrancePosition === EParkingLotEntrancePosition.EDGE && nrOfParkingRows === 2 && nrOfTotalParkingSpots % 2 === 0;
  const and5 =
    entrancePosition === EParkingLotEntrancePosition.MIDDLE && nrOfParkingRows === 2 && nrOfTotalParkingSpots % 2 === 0;
  return and1 || and2 || and3 || and4 || and5 ? getLaneWidthIncrement(laneWidth) : 0;
};

const getUndergroundGarageTotalWidth = (properties: IUndergroundGarageParkingLotProperties): number => {
  const {
    laneWidth,
    nrOfParkingRows,
    construction: { externalWallThickness },
  } = properties;
  return round(
    getUndergroundGarageTotalLaneWidth(laneWidth, externalWallThickness) +
      getUndergroundParkingSecondParkingSectionDepth(nrOfParkingRows) +
      undergroundGarageParkingSpotDepth,
    2,
  );
};

const getInnerLength = (properties: IUndergroundGarageParkingLotProperties): number => {
  const {
    nrOfTotalParkingSpots,
    nrOfParkingRows,
    nrOfGroups,
    entrancePosition,
    laneWidth,
    distanceToSupport,
    construction: { supportColumnWidth },
  } = properties;

  const addend1 =
    Math.ceil((nrOfTotalParkingSpots + getNrOfAddedParkingSpots(nrOfParkingRows, entrancePosition)) / nrOfParkingRows) *
    getUndergroundGarageParkingSpotWidth(laneWidth);
  const addend2 =
    (Math.ceil(
      Math.ceil(
        (nrOfTotalParkingSpots + getNrOfAddedParkingSpots(nrOfParkingRows, entrancePosition)) / nrOfParkingRows,
      ) / nrOfGroups,
    ) -
      1) *
    (supportColumnWidth + 2 * getLateralDistanceToSupport(distanceToSupport));
  const addend3 = 2 * undergroundGarageLateralDistanceToWall;
  return round(addend1 + addend2 + addend3, 2);
};

const getUndergroundParkingSectionLength = (properties: IUndergroundGarageParkingLotProperties): number => {
  return round(getInnerLength(properties) + 2 * properties.construction.externalWallThickness, 2);
};

const getUndergroundGarageTotalLength = (properties: IUndergroundGarageParkingLotProperties): number => {
  return round(
    getUndergroundParkingSectionLength(properties) + getLeftPocketWidth(properties) + getRightPocketWidth(properties),
    2,
  );
};

export const setUndergroundGarageCornersAndEntrances = (parkingLot: TUndergroundGarageParkingLot): void => {
  const { properties } = parkingLot;
  const {
    nrOfParkingRows,
    entrancePosition,
    laneWidth,
    construction: { externalWallThickness },
  } = properties;

  const totalLength = getUndergroundGarageTotalLength(properties);
  const totalWidth = getUndergroundGarageTotalWidth(properties);
  const totalLaneWidth = getUndergroundGarageTotalLaneWidth(laneWidth, externalWallThickness);
  const leftPocketWidth = getLeftPocketWidth(properties);
  const rightPocketWidth = getRightPocketWidth(properties);
  const secondParkingSectionDepth = getUndergroundParkingSecondParkingSectionDepth(nrOfParkingRows);
  const halfLength = totalLength / 2;
  const halfWidth = totalWidth / 2;

  const corners = [
    [-halfLength, -halfWidth + secondParkingSectionDepth],
    [-halfLength + leftPocketWidth, -halfWidth + secondParkingSectionDepth],
    [-halfLength + leftPocketWidth, -halfWidth],
    [halfLength - rightPocketWidth, -halfWidth],
    [halfLength - rightPocketWidth, -halfWidth + secondParkingSectionDepth],
    [halfLength, -halfWidth + secondParkingSectionDepth],
    [halfLength, -halfWidth + secondParkingSectionDepth + totalLaneWidth],
    [halfLength - rightPocketWidth, -halfWidth + secondParkingSectionDepth + totalLaneWidth],
    [halfLength - rightPocketWidth, halfWidth],
    [-halfLength + leftPocketWidth, halfWidth],
    [-halfLength + leftPocketWidth, -halfWidth + secondParkingSectionDepth + totalLaneWidth],
    [-halfLength, -halfWidth + secondParkingSectionDepth + totalLaneWidth],
  ];
  const uniqueCorners = uniqWith(corners, (arr1, arr2) => isEqual(arr1, arr2)) as XY[];
  uniqueCorners.push(uniqueCorners[0]);

  const entrances: IParkingLotEntrance[] = [];
  const doorWidth = 3.2;
  if (entrancePosition === EParkingLotEntrancePosition.EDGE) {
    const doorY =
      nrOfParkingRows === EParkingLotNrOfParkingRows.ONE
        ? -halfWidth + undergroundGarageLateralDistanceToWall + doorWidth / 2
        : 0;
    entrances.push({
      id: generateUUID(),
      position: [-halfLength, doorY],
      entranceDirection: [1, 0],
    });
  } else if (entrancePosition === EParkingLotEntrancePosition.MIDDLE) {
    entrances.push({
      id: generateUUID(),
      position: [0, -halfWidth],
      entranceDirection: [0, 1],
    });
  } else if (entrancePosition === EParkingLotEntrancePosition.SIDE) {
    const doorX =
      nrOfParkingRows === EParkingLotNrOfParkingRows.ONE
        ? -halfLength + (undergroundGarageLateralDistanceToWall + doorWidth / 2)
        : halfLength - (undergroundGarageLateralDistanceToWall + doorWidth / 2);
    entrances.push({
      id: generateUUID(),
      position: [doorX, -halfWidth],
      entranceDirection: [0, 1],
    });
  }

  parkingLot.corners = corners as XY[];
  parkingLot.entrances = entrances;
};
