import Flatten from '@flatten-js/core';
import { EFaceType } from 'types/building/Face';
import { IFaceSegment, ISegmentPoint } from 'types/building/FaceSegment';
import { XYZ } from 'types/location/coordinates';
import { getBuilding, getSegmentsByIds } from 'cityview/store/selectors/building';
import { getFloorById, getFloorFootprint, getPreviousFloorId } from 'cityview/store/selectors/floor';
import { createSegment, LineSegment2D } from '../../utils';
import { getCenterPoint } from './geometry';

export const generateFaceSegments = (
  coordinates: XYZ[],
  faceType: EFaceType,
  faceId: string,
  buildingId: string,
  floorId: string,
): IFaceSegment[] => {
  // Probably should only create singular segments for use in building topic.
  // Real segments should be generated in backend

  // if (faceType === EFaceType.WALL) {
  //   return generateWallFaceSegments(coordinates, faceId);
  // } else {
  return [generateSingularFaceSegment(coordinates, faceType, faceId, buildingId, floorId)];
  // }
};

export const generateSingularFaceSegment = (
  faceCoordinates: XYZ[],
  faceType: EFaceType,
  faceId: string,
  buildingId: string,
  floorId: string,
  segmentId?: string,
) => {
  const segmentCorners: ISegmentPoint[] = faceCoordinates.map((coordinate: XYZ) => ({
    coordinates: [...coordinate],
    irradiation: -1,
  }));

  return createSegment({
    id: segmentId,
    faceId,
    corners: segmentCorners,
    faceType,
    buildingId,
    floorId,
  });
};

// const findWallSegmentLength = (wallLength: number, preferredLength = 3) => {
//   const count = wallLength / preferredLength;
//   const countFloored = Math.floor(count);
//   const remainder = count - countFloored;
//
//   return countFloored + Math.round(remainder);
// };

export const doesSegmentHaveTerrace = (buildingId: string, floorId: string, segmentId: string): boolean => {
  const building = getBuilding(buildingId);
  const floor = getFloorById(buildingId, floorId);
  const segments = getSegmentsByIds([segmentId]);
  if (!building || !floor || !segments.length) return false;

  const previousFloorId = getPreviousFloorId(buildingId, floorId);
  if (!previousFloorId) return false;
  const previousFloor = getFloorById(buildingId, previousFloorId);
  if (!previousFloor) return false;

  const segment = segments[0];

  const coordinates = segment.corners.map(({ coordinates }) => coordinates);
  const centerPoint = getCenterPoint(coordinates);

  const pointDistance = 0.5;
  const ls = new LineSegment2D([coordinates[0][0], coordinates[0][1]], [coordinates[1][0], coordinates[1][1]]);

  const pointToCheck = new Flatten.Point([
    centerPoint[0] + ls.normalVec[0] * pointDistance,
    centerPoint[1] + ls.normalVec[1] * pointDistance,
  ]);

  const previousFloorFootprint = getFloorFootprint(previousFloor);
  const previousFloorPolygon = new Flatten.Polygon(previousFloorFootprint);

  return previousFloorPolygon.contains(pointToCheck);
};
