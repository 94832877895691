import { IBuilding } from 'types/building/Building';
import { TFace } from 'types/building/Face';
import { IFaceSegment } from 'types/building/FaceSegment';
import { IFloor } from 'types/building/Floor';
import { useSnapshot } from 'valtio';
import { buildingStore } from '../store';

interface BuildingTree {
  building: IBuilding;
  floor?: IFloor;
  face?: TFace;
  segment?: IFaceSegment;
}

interface UseBuildingTreeProps {
  buildingId: string;
  floorId?: string;
  faceId?: string;
  segmentId?: string;
}

/*
 * Get the building, floor, face, and segment from the store.
 * Building is required, but other levels are optional.
 */
const useBuildingTree = (props: UseBuildingTreeProps) => {
  const { buildingId, floorId, faceId, segmentId } = props ?? {};

  const {
    value: { projectBuildings },
  } = useSnapshot(buildingStore);
  const building = projectBuildings[buildingId];

  const result: BuildingTree = {
    building: building,
    floor: undefined,
    face: undefined,
    segment: undefined,
  };

  if (floorId) {
    result.floor = building?.floors[floorId];
  }

  if (faceId) {
    if (result.floor) {
      // search through floor faces to find the face with the given id
      result.face = result.floor.faces.find((face) => face.id === faceId);
    } else {
      // search through all faces in all the floors of the building to find the face with the given id
      const floors = Object.values(building?.floors ?? {});
      result.face = floors.flatMap((floor) => floor.faces).find((face) => face.id === faceId);
    }
  }

  if (segmentId) {
    // implement segment search here. How will segments be stored?
    if (result.face) {
      // search through face segments to find the segment with the given id
      result.segment = result.face.segments.find((segment) => segment.id === segmentId);
    } else if (result.floor) {
      // search through all segments in all the faces of the floor to find the segment with the given id
      const faces = result.floor.faces;
      result.segment = faces.flatMap((face) => face.segments).find((segment) => segment.id === segmentId);
    } else {
      // search through all segments in all the faces in all the floors of the building to find the segment with the given id
      const floors = Object.values(building?.floors ?? {});
      const faces = floors.flatMap((floor) => floor.faces);
      result.segment = faces.flatMap((face) => face.segments).find((segment) => segment.id === segmentId);
    }
  }

  return result;
};

export default useBuildingTree;
