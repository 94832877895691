import isEqual from 'lodash/isEqual';
import React, { useMemo, useRef } from 'react';

function useDeepCompareMemoize(value: React.DependencyList) {
  const ref = useRef<React.DependencyList>([]);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareMemo<T>(factory: () => T, dependencies: React.DependencyList) {
  return useMemo(factory, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareMemo;
