import { createPortal, useFrame, useThree } from '@react-three/fiber';
import * as React from 'react';
import { useState } from 'react';
import * as THREE from 'three';

interface RenderHudProps {
  defaultScene: THREE.Scene;
  defaultCamera: THREE.Camera;
  renderPriority?: number;
}

const RenderHud = (props: RenderHudProps) => {
  const { defaultScene, defaultCamera, renderPriority = 1 } = props;

  const { gl, scene, camera } = useThree();
  let oldCLear;
  useFrame(() => {
    oldCLear = gl.autoClear;
    if (renderPriority === 1) {
      // Clear scene and render the default scene
      gl.autoClear = true;
      gl.render(defaultScene, defaultCamera);
    }
    // Disable cleaning and render the portal with its own camera
    gl.autoClear = false;
    gl.clearDepth();
    gl.render(scene, camera);
    // Restore default
    gl.autoClear = oldCLear;
  }, renderPriority);
  return <></>;
};

interface HudProps {
  /** Any React node */
  children: React.ReactNode;
  /** Render priority, default: 1 */
  renderPriority?: number;
}

const Hud = (props: HudProps) => {
  const { children, renderPriority = 1 } = props;

  const { scene: defaultScene, camera: defaultCamera } = useThree();
  const [hudScene] = useState(() => new THREE.Scene());

  return (
    <>
      {createPortal(
        <>
          {children}
          <RenderHud defaultScene={defaultScene} defaultCamera={defaultCamera} renderPriority={renderPriority} />
        </>,
        hudScene,
        { events: { priority: renderPriority + 1 } },
      )}
    </>
  );
};

export default Hud;
