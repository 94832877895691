import { EObjectType, store } from 'cityview';
import cloneDeep from 'lodash/cloneDeep';
import { generateUUID } from 'three/src/math/MathUtils';
import { DEFAULT_VEGETATION } from '../../components/Vegetations/utils';
import landscapeStore, {
  EVegetationSource,
  EVegetationType,
  IVegetation,
  IVegetationProperties,
} from '../landscapeStore';
import { getSelectedObjectIdsByType } from '../selectors/general';
import { deselectObject } from './selections';

export const addVegetation = (vegetation: IVegetation) => {
  landscapeStore.value.vegetations.push(vegetation);
};

export const addVegetations = (vegetations: IVegetation[]) =>
  (landscapeStore.value.vegetations = {
    ...landscapeStore.value.vegetations,
    ...vegetations,
  });

export const setVegetations = (vegetations: IVegetation[]) => {
  landscapeStore.value.vegetations = [...vegetations];

  // Deselect vegetations that don't exist anymore
  const selectedVegetations = getSelectedObjectIdsByType(EObjectType.VEGETATION);
  selectedVegetations.forEach((id) => {
    const isSelectedVegetation = vegetations.find((t) => t.id === id);

    if (!isSelectedVegetation) deselectObject(id);
  });
};

export const removeVegetation = (vegetation: IVegetation) => {
  const isUserVegetation = vegetation.properties.source === 'user';

  deselectObject(vegetation.id);

  if (isUserVegetation) {
    // Remove the vegetation from the store if it's a user vegetation
    landscapeStore.value.vegetations = landscapeStore.value.vegetations.filter((t) => t.id !== vegetation.id);
  } else {
    // Hide the vegetation if it's a Swisstopo or a vegetation from an external source that can be restored
    vegetation.properties.visible = false;
    vegetation.isModified = true;
  }
};

export const removeVegetations = (vegetations: IVegetation[]) => {
  const clonedVegetations = cloneDeep(landscapeStore.value.vegetations);

  landscapeStore.value.vegetations = clonedVegetations.reduce((acc, vegetation) => {
    const isDeletableVegetation = vegetations.some((t) => t.id === vegetation.id);
    const isExistingVegetation = vegetation.properties.source === EVegetationSource.SWISSTOPO;

    if (isDeletableVegetation) {
      deselectObject(vegetation.id);

      if (isExistingVegetation) {
        vegetation.properties.visible = false;
        vegetation.isModified = true;
      } else {
        return acc;
      }
    }

    acc.push(vegetation);

    return acc;
  }, [] as IVegetation[]);
};

export const createVegetationObject = (properties: Partial<IVegetationProperties>) => {
  const vegetation: IVegetation = {
    id: generateUUID(),
    isModified: true,
    properties: {
      ...DEFAULT_VEGETATION.properties,
      ...properties,
    },
  };

  return vegetation;
};

export const createVegetation = (properties: Partial<IVegetationProperties>) => {
  const vegetation = createVegetationObject(properties);

  addVegetation(vegetation);
};

export const updateVegetationProperties = (vegetation: IVegetation, properties: Partial<IVegetationProperties>) => {
  vegetation.isModified = true;

  vegetation.properties = {
    ...vegetation.properties,
    ...properties,
  };
};

export const setAddVegetationType = (type: EVegetationType) => {
  store.landscape.addVegetationType = type;
};
