import { useThree } from '@react-three/fiber';
import { EObjectType } from 'cityview';
import store from 'cityview/store';
import { selectTerrainPointsInRectangle } from 'cityview/store/mutations/terrain';
import { useEffect, useRef } from 'react';
import { XY } from 'types/location/coordinates';
import { EApplicationTool } from 'types/applicationPath';
import { SelectionCanvas } from 'cityview/components/Terrain/ModifiedTerrain/TerrainPoints/SelectionCanvas';
import { OrbitControls } from 'three-stdlib';
import { Vector3, Euler } from 'three';
import { deselectType } from 'cityview/store/mutations/selections';

const TerrainRectangleSelection = () => {
  const { gl, camera, controls } = useThree();
  const mouseRef = useRef<XY>([0, 0]);
  const cornersRef = useRef<XY[]>([]);
  const canvasRef = useRef<SelectionCanvas | null>(null);
  const lastCameraStateRef = useRef<{ position: Vector3; rotation: Euler }>();

  const clearRectangle = () => {
    cornersRef.current = [];
    canvasRef.current?.clear();
    lastCameraStateRef.current = undefined;
    if (controls) {
      (controls as OrbitControls).enabled = true;
    }
  };

  const checkCameraMovement = () => {
    if (!lastCameraStateRef.current) {
      lastCameraStateRef.current = {
        position: camera.position.clone(),
        rotation: camera.rotation.clone(),
      };
      return false;
    }

    const positionChanged = !camera.position.equals(lastCameraStateRef.current.position);
    const rotationChanged =
      camera.rotation.x !== lastCameraStateRef.current.rotation.x ||
      camera.rotation.y !== lastCameraStateRef.current.rotation.y ||
      camera.rotation.z !== lastCameraStateRef.current.rotation.z;

    lastCameraStateRef.current = {
      position: camera.position.clone(),
      rotation: camera.rotation.clone(),
    };

    return positionChanged || rotationChanged;
  };

  useEffect(() => {
    canvasRef.current = new SelectionCanvas(gl.domElement);

    const getScreenPoint = (e: MouseEvent): XY => {
      const rect = gl.domElement.getBoundingClientRect();
      return [e.clientX - rect.left, e.clientY - rect.top];
    };

    const handleMouseDown = (e: MouseEvent) => {
      if (store.general.applicationPath.tool !== EApplicationTool.TERRAIN_RECTANGLE_SELECT) return;
      if (e.button !== 0) return;
      mouseRef.current = getScreenPoint(e);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (store.general.applicationPath.tool !== EApplicationTool.TERRAIN_RECTANGLE_SELECT) return;
      if (cornersRef.current.length === 0) return;

      // Cancel selection if camera moved between clicks
      if (checkCameraMovement()) {
        clearRectangle();
        return;
      }

      updateRectangleShape(getScreenPoint(e));
    };

    const handleMouseUp = (e: MouseEvent) => {
      if (store.general.applicationPath.tool !== EApplicationTool.TERRAIN_RECTANGLE_SELECT) return;
      if (e.button !== 0) return;

      const currentPoint = getScreenPoint(e);
      const mouseTravel = calculateMouseTravel(currentPoint);

      if (mouseTravel <= 1) {
        handleRectangleClick(currentPoint, e.metaKey || e.ctrlKey);
      }
    };

    const handleWheel = () => {
      if (cornersRef.current.length > 0) {
        clearRectangle();
      }
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (cornersRef.current.length > 0) {
          clearRectangle();
        } else {
          deselectType(EObjectType.TERRAIN_POINT);
        }
      }
    };

    const handleContextMenu = (e: MouseEvent) => {
      if (store.general.applicationPath.tool !== EApplicationTool.TERRAIN_RECTANGLE_SELECT) return;
      e.preventDefault();
      if (cornersRef.current.length > 0) {
        clearRectangle();
      } else {
        deselectType(EObjectType.TERRAIN_POINT);
      }
    };

    gl.domElement.addEventListener('pointerdown', handleMouseDown);
    gl.domElement.addEventListener('pointerup', handleMouseUp);
    gl.domElement.addEventListener('pointermove', handleMouseMove);
    gl.domElement.addEventListener('wheel', handleWheel);
    gl.domElement.addEventListener('contextmenu', handleContextMenu);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      gl.domElement.removeEventListener('pointerdown', handleMouseDown);
      gl.domElement.removeEventListener('pointerup', handleMouseUp);
      gl.domElement.removeEventListener('pointermove', handleMouseMove);
      gl.domElement.removeEventListener('wheel', handleWheel);
      gl.domElement.removeEventListener('contextmenu', handleContextMenu);
      window.removeEventListener('keydown', handleKeyDown);
      canvasRef.current?.destroy();
      if (controls) {
        (controls as OrbitControls).enabled = true;
      }
    };
  }, [gl, camera, controls]);

  const calculateMouseTravel = (currentPoint: XY): number => {
    const dx = currentPoint[0] - mouseRef.current[0];
    const dy = currentPoint[1] - mouseRef.current[1];
    return Math.sqrt(dx * dx + dy * dy);
  };

  const handleRectangleClick = (currentPoint: XY, addToExistingSelection: boolean) => {
    if (cornersRef.current.length === 0) {
      // First click - start rectangle
      cornersRef.current = [currentPoint, [...currentPoint]];
      if (controls) {
        (controls as OrbitControls).enabled = false;
      }
    } else if (cornersRef.current.length === 2) {
      // Second click - complete rectangle
      cornersRef.current[1] = currentPoint;
      cornersRef.current.push([...currentPoint]);
      cornersRef.current.push([...cornersRef.current[0]]);
    } else if (cornersRef.current.length === 4) {
      // Third click - finish selection
      selectTerrainPointsInRectangle(cornersRef.current, camera, gl.domElement, addToExistingSelection);
      clearRectangle();
    }
    canvasRef.current?.drawRectangle(cornersRef.current);
  };

  const updateRectangleShape = (screenPoint: XY) => {
    if (cornersRef.current.length === 2) {
      cornersRef.current[1] = screenPoint;
    } else if (cornersRef.current.length === 4) {
      updateRectangleWidth(screenPoint);
    }
    canvasRef.current?.drawRectangle(cornersRef.current);
  };

  const updateRectangleWidth = (screenPoint: XY) => {
    const [x0, y0] = cornersRef.current[0];
    const [x1, y1] = cornersRef.current[1];

    const dx = x1 - x0;
    const dy = y1 - y0;
    const length = Math.sqrt(dx * dx + dy * dy);
    const perpX = -dy / length;
    const perpY = dx / length;

    const mouseToSecondX = screenPoint[0] - x1;
    const mouseToSecondY = screenPoint[1] - y1;
    const width = Math.max(Math.abs(mouseToSecondX * perpX + mouseToSecondY * perpY), 0.4);

    const adjustedWidth = width * Math.sign(mouseToSecondX * perpX + mouseToSecondY * perpY);
    cornersRef.current[2] = [x1 + perpX * adjustedWidth, y1 + perpY * adjustedWidth];
    cornersRef.current[3] = [x0 + perpX * adjustedWidth, y0 + perpY * adjustedWidth];
  };

  return null;
};

export default TerrainRectangleSelection;
