import { useCursor } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { EObjectName, EVegetationType, getClippingPlanes, IVegetation } from 'cityview';
import React from 'react';
import { DoubleSide, MeshStandardMaterial } from 'three';
import { ILngLat } from 'types/location/coordinates';
import { ETerrainWidth } from 'types/terrain';
import { useHovered } from '../../hooks';
import useDeepCompareMemo from '../../hooks/useDeepCompareMemo';
import { TVegetationModels } from '../../hooks/useVegetationModels';
import { landscapeStore } from '../../store';
import { updateVegetationProperties } from '../../store/mutations/landscape';

import { getVegetationByMouseEvent, groupVegetationsByType } from './utils';
import VegetationsInstancedMesh from './VegetationsInstancedMesh';

interface RestorableVegetationsProps {
  visible: boolean;
  vegetations: IVegetation[];
  vegetationModels: TVegetationModels;
  center: ILngLat;
  terrainWidth: ETerrainWidth;
  restoreActive: boolean;
}

const RestorableVegetations = (props: RestorableVegetationsProps) => {
  const { visible, vegetationModels, center, restoreActive, terrainWidth, vegetations } = props;

  const vegetationsByType = groupVegetationsByType(vegetations);

  const { hovered, onPointerOver, onPointerOut } = useHovered({
    disabled: !restoreActive,
  });

  useCursor(hovered);

  const { vegetationMaterial } = useDeepCompareMemo(() => {
    // We assume that it is alright to use same material for all tree types
    const vegetationMaterial = vegetationModels[EVegetationType.BIRCH].material.clone() as MeshStandardMaterial;
    vegetationMaterial.side = DoubleSide;
    vegetationMaterial.clippingPlanes = getClippingPlanes(terrainWidth);

    vegetationMaterial.transparent = true;
    vegetationMaterial.opacity = 0.25;

    return { vegetationMaterial };
  }, [terrainWidth]);

  return (
    <>
      {Object.entries(vegetationsByType).map(([type, typeVegetations]) => {
        if (!typeVegetations.length) return null;

        const handleClick = (e: ThreeEvent<MouseEvent>) => {
          if (!restoreActive) return;

          e.stopPropagation();

          const vegetation = getVegetationByMouseEvent(e, typeVegetations);
          if (!vegetation) return;

          const vegetationToChange = landscapeStore.value.vegetations.find((veg) => veg.id === vegetation.id);
          if (!vegetationToChange) return;

          updateVegetationProperties(vegetationToChange, {
            visible: true,
          });
        };

        return (
          <VegetationsInstancedMesh
            key={type}
            name={EObjectName.RESTORABLE_VEGETATION}
            center={center}
            geometry={vegetationModels[type as EVegetationType].geometry}
            material={vegetationMaterial}
            onClick={handleClick}
            onPointerOut={onPointerOut}
            onPointerOver={onPointerOver}
            vegetations={typeVegetations}
            visible={visible}
          />
        );
      })}
    </>
  );
};

export default RestorableVegetations;
