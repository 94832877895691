import { useGLTF } from '@react-three/drei';
import { EVegetationType } from 'cityview';
import { BufferGeometry, Material, Mesh } from 'three';

export type TVegetationModels = Record<EVegetationType, Mesh<BufferGeometry, Material>>;

const baseUrl = `https://storage.googleapis.com/amenti-public/models/vegetation`;
const folderName = `2024_02_15_14_35`;

const getUrl = (fileName: string) => `${baseUrl}/${folderName}/${fileName}.gltf`;

const VEGETATION_MODEL_URLS: Record<EVegetationType, string> = {
  [EVegetationType.BIRCH]: getUrl('Birch'),
  [EVegetationType.CYPRESS]: getUrl('Cypress'),
  [EVegetationType.MAPLE]: getUrl('Maple'),
  [EVegetationType.SPRUCE]: getUrl('Spruce'),
  [EVegetationType.BUSH]: getUrl('Bush'),
};

const useVegetationModels = () => {
  const birchModel = useGLTF(VEGETATION_MODEL_URLS[EVegetationType.BIRCH], true);
  const cypressModel = useGLTF(VEGETATION_MODEL_URLS[EVegetationType.CYPRESS], true);
  const mapleModel = useGLTF(VEGETATION_MODEL_URLS[EVegetationType.MAPLE], true);
  const spruceModel = useGLTF(VEGETATION_MODEL_URLS[EVegetationType.SPRUCE], true);
  const bushModel = useGLTF(VEGETATION_MODEL_URLS[EVegetationType.BUSH], true);

  const models = {
    [EVegetationType.BIRCH]: birchModel.scene.children[0],
    [EVegetationType.CYPRESS]: cypressModel.scene.children[0],
    [EVegetationType.MAPLE]: mapleModel.scene.children[0],
    [EVegetationType.SPRUCE]: spruceModel.scene.children[0],
    [EVegetationType.BUSH]: bushModel.scene.children[0],
  };

  const isSuccess = Object.values(models).every(Boolean);

  return isSuccess ? (models as TVegetationModels) : undefined;
};

export default useVegetationModels;
