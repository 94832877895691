import { useCursor } from '@react-three/drei';
import { ThreeEvent } from '@react-three/fiber';
import { canvasTheme, EObjectName, EObjectType } from 'cityview';
import React, { useMemo, useRef } from 'react';
import * as THREE from 'three';
import { EApplicationMode, EApplicationTool, EApplicationTopic } from 'types/applicationPath';
import { TParkingLotOutdoor } from 'types/parking';
import { useSnapshot } from 'valtio';
import { useApplicationPath, useHovered, useIsObjectSelected } from '../../../hooks';
import useDeepEffect from '../../../hooks/useDeepEffect';
import store, { terrainStore } from '../../../store';
import { selectObject } from '../../../store/mutations/selections';

import parkingLotStore from '../../../store/parkingLotStore';
import { generateGeometryFromCornersAndHeight } from '../../../utils/parking-lot/util';
import { calculateCenter } from '../../Building/utils';
import ObjectSelectionIndicator from '../../ObjectSelectionIndicator';
import ParkingLotTransformer from '../ParkingGarageTransformer';
import { findGroundHeightEstimation } from '../../Terrain/utils';

interface ParkingLotOutdoorProps {
  parkingLot: TParkingLotOutdoor;
  selectionIndicator?: React.ReactNode;
}

const ParkingLotOutdoor = (props: ParkingLotOutdoorProps) => {
  const {
    parkingLot: { id, corners, rotation, position },
    selectionIndicator,
  } = props;

  const parkingRef = useRef<THREE.Group | null>(null);
  const meshRef = useRef<THREE.Mesh | null>(null);

  const { topic, mode, tool } = useApplicationPath();
  const isSelected = useIsObjectSelected(id.toString());

  const { showTerrain } = useSnapshot(store.settings.terrain);

  const showTransformer =
    topic === EApplicationTopic.PARKING_LOTS &&
    mode === EApplicationMode.EDIT &&
    tool === EApplicationTool.MOVE &&
    parkingRef.current &&
    isSelected;

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (
      isSelected ||
      store.general.applicationPath.topic !== EApplicationTopic.PARKING_LOTS ||
      (store.general.applicationPath.tool !== EApplicationTool.SELECT &&
        store.general.applicationPath.tool !== EApplicationTool.DEFAULT &&
        store.general.applicationPath.tool !== EApplicationTool.MOVE)
    )
      return;

    selectObject(id.toString(), EObjectType.PARKING_LOT);
  };

  function handleUpdatePosition() {
    if (!parkingRef.current) return;

    parkingLotStore.value.parkingLots[id].position = {
      x: parkingRef.current.position.x,
      y: parkingRef.current.position.y,
      z: parkingRef.current.position.z,
    };
  }

  function handleUpdateRotation() {
    if (!parkingRef.current) return;

    parkingLotStore.value.parkingLots[id].rotation = parkingRef.current.rotation.z;
  }

  const { hovered, onPointerOver, onPointerOut } = useHovered();
  useCursor(hovered);

  const showHovered = hovered && topic === EApplicationTopic.PARKING_LOTS;

  const { terrainData } = useSnapshot(terrainStore.value.originalTerrain);

  useDeepEffect(() => {
    if (!meshRef.current || !terrainData || !parkingRef.current) return;
    parkingRef.current.position.z = findGroundHeightEstimation([position.x, position.y], terrainData);
    meshRef.current.geometry = generateGeometryFromCornersAndHeight(corners, 0.01);
  }, [corners, meshRef.current, terrainData, position, showTerrain]);

  const { isMouseEditing } = useSnapshot(store.mouse);

  const [centerX, centerY] = useMemo(() => calculateCenter(corners), [corners]);

  if (!showTerrain) return null;

  return (
    <>
      <group ref={parkingRef} position={[position.x, position.y, position.z]} rotation={[0, 0, rotation]}>
        <mesh
          ref={meshRef}
          name={EObjectName.PARKING_GARAGE}
          onClick={handleClick}
          onPointerOver={onPointerOver}
          onPointerOut={onPointerOut}
          castShadow={false}
          receiveShadow={false}
          renderOrder={5}
        >
          <meshStandardMaterial
            color={canvasTheme.asphaltColor}
            side={THREE.DoubleSide}
            roughness={1}
            emissive={0xffffff}
            emissiveIntensity={showHovered ? 0.15 : isSelected ? 0.35 : 0}
            // transparent={true}
            // opacity={topic === EApplicationTopic.USAGE ? 1 : 0.9}
            attach='material'
            visible={isMouseEditing && isSelected}
            depthWrite={false}
            depthTest={false}
          />
        </mesh>
        {isSelected && (
          <ObjectSelectionIndicator
            buildingId={id.toString()}
            coordinates={[centerX, centerY, 5]}
            showIndicator={tool !== EApplicationTool.MOVE && tool !== EApplicationTool.MODIFY}
          >
            {selectionIndicator}
          </ObjectSelectionIndicator>
        )}
      </group>
      {showTransformer && (
        <ParkingLotTransformer
          handleUpdatePosition={handleUpdatePosition}
          handleUpdateRotation={handleUpdateRotation}
          showMeshWhileDragging={false}
          color={'#ead27d'}
          geometry={meshRef.current?.geometry as THREE.BufferGeometry}
          intersectObject={parkingRef.current}
          showElevationControls={false}
        />
      )}
    </>
  );
};

export default ParkingLotOutdoor;
