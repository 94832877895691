import { XYZ } from "types/location/coordinates";

export const calculateFaceNormal3D = (face: XYZ[]): XYZ => {
  const [v1, v2, v3] = face;
  const v1v2 = [v2[0] - v1[0], v2[1] - v1[1], v2[2] - v1[2]];
  const v1v3 = [v3[0] - v1[0], v3[1] - v1[1], v3[2] - v1[2]];
  const x = v1v2[1] * v1v3[2] - v1v2[2] * v1v3[1];
  const y = v1v2[2] * v1v3[0] - v1v2[0] * v1v3[2];
  const z = v1v2[0] * v1v3[1] - v1v2[1] * v1v3[0];
  const d = Math.sqrt(x * x + y * y + z * z);
  return [x / d, y / d, z / d];
};
