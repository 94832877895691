import { ThreeEvent, useThree } from '@react-three/fiber';
import * as React from 'react';
import { CanvasTexture } from 'three';
import { useGizmoContext } from '../GizmoHelper/GizmoHelper';
import store from '../../store';

type AxisProps = {
  color: string;
  rotation: [number, number, number];
  scale?: [number, number, number];
  opacity?: number;
};

type AxisHeadProps = JSX.IntrinsicElements['sprite'] & {
  arcStyle: string;
  label?: string;
  labelColor: string;
  axisHeadScale?: number;
  disabled?: boolean;
  font: string;
  onClick?: (e: ThreeEvent<MouseEvent>) => null;
};

type GizmoViewportProps = JSX.IntrinsicElements['group'] & {
  axisColors?: [string, string, string, string, string];
  axisScale?: [number, number, number];
  axisHeadScale?: number;
  labelColor?: string;
  hideNegativeAxes?: boolean;
  hideAxisHeads?: boolean;
  hideUpDownAxis?: boolean;
  disabled?: boolean;
  font?: string;
  onClick?: (e: ThreeEvent<MouseEvent>) => null;
};

function Axis({ scale = [0.8, 0.05, 0.05], color, rotation, opacity = 1 }: AxisProps) {
  return (
    <group rotation={rotation}>
      <mesh position={[0.4, 0, 0]}>
        <boxGeometry args={scale} />
        <meshBasicMaterial color={color} toneMapped={false} transparent opacity={opacity} />
      </mesh>
    </group>
  );
}

function AxisHead({
  onClick,
  font,
  disabled,
  arcStyle,
  label,
  labelColor,
  axisHeadScale = 1,
  ...props
}: AxisHeadProps) {
  const gl = useThree((state) => state.gl);
  const texture = React.useMemo(() => {
    const canvas = document.createElement('canvas');
    canvas.width = 64;
    canvas.height = 64;

    const context = canvas.getContext('2d')!;
    context.beginPath();
    context.arc(32, 32, 16, 0, 2 * Math.PI);
    context.closePath();
    context.fillStyle = arcStyle;
    context.fill();

    if (label) {
      context.font = font;
      context.textAlign = 'center';
      context.fillStyle = labelColor;
      context.fillText(label, 32, 38);
    }
    return new CanvasTexture(canvas);
  }, [arcStyle, label, labelColor, font]);

  const [active, setActive] = React.useState(false);
  const scale = (label ? 1 : 0.75) * (active ? 1.2 : 1) * axisHeadScale;
  const handlePointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setActive(true);
  };
  const handlePointerOut = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    setActive(false);
  };
  return (
    <sprite
      scale={scale}
      onPointerOver={!disabled ? handlePointerOver : undefined}
      onPointerOut={!disabled ? onClick || handlePointerOut : undefined}
      {...props}
    >
      <spriteMaterial
        map={texture}
        map-anisotropy={gl.capabilities.getMaxAnisotropy() || 1}
        alphaTest={0.3}
        opacity={label ? 1 : 0.75}
        toneMapped={false}
      />
    </sprite>
  );
}

const GizmoViewport = (props: GizmoViewportProps) => {
  const {
    hideNegativeAxes,
    hideAxisHeads,
    hideUpDownAxis,
    disabled,
    font = '13px Inter var, Arial, sans-serif',
    axisColors = ['#ff2060', '#20df80', '#2080ff', '#ff2060', '#20df80'],
    axisHeadScale = 1,
    axisScale,
    labelColor = '#000',
    onClick,
    ...otherProps
  } = props;

  const [colorN, colorE, colorS, colorW, colorVertical] = axisColors;
  const { tweenCamera } = useGizmoContext();

  const axisHeadProps = {
    font,
    disabled,
    labelColor,
    onClick,
    axisHeadScale,
    onPointerDown: !disabled
      ? (e: ThreeEvent<PointerEvent>) => {
          tweenCamera(e.object.position);
          e.stopPropagation();
          store.mouse.isMouseOnGizmoDown = true;
        }
      : undefined,
    onPointerUp: !disabled
      ? (e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          store.mouse.isMouseOnGizmoDown = false;
        }
      : undefined,
  };
  return (
    <group scale={40} {...otherProps}>
      <Axis color={colorN} rotation={[0, 0, 0]} scale={axisScale} opacity={0.5} />
      <Axis color={colorW} rotation={[0, 0, Math.PI / 2]} scale={axisScale} opacity={0.5} />
      {!hideUpDownAxis && <Axis color={colorVertical} rotation={[0, -Math.PI / 2, 0]} scale={axisScale} />}
      {!hideAxisHeads && (
        <>
          <AxisHead name={'GizmoHead'} arcStyle={colorN} position={[0, 1, 0]} label='N' {...axisHeadProps} />
          <AxisHead name={'GizmoHead'} arcStyle={colorW} position={[-1, 0, 0]} label='W' {...axisHeadProps} />
          {!hideUpDownAxis && (
            <AxisHead name={'GizmoHead'} arcStyle={colorVertical} position={[0, 0, 1]} {...axisHeadProps} />
          )}
          {!hideNegativeAxes && (
            <>
              <AxisHead name={'GizmoHead'} arcStyle={colorS} position={[0, -1, 0]} label='S' {...axisHeadProps} />
              <AxisHead name={'GizmoHead'} arcStyle={colorE} position={[1, 0, 0]} label='E' {...axisHeadProps} />
              {!hideUpDownAxis && (
                <AxisHead name={'GizmoHead'} arcStyle={colorVertical} position={[0, 0, -1]} {...axisHeadProps} />
              )}
            </>
          )}
        </>
      )}
    </group>
  );
};

export default GizmoViewport;
