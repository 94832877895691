import { createEvent } from 'react-event-hook';
import { IFaceSegment } from 'types/building/FaceSegment';
import { EFaceType } from 'types/building/Face';

export interface ISegmentTooltipData {
  segment: IFaceSegment;
  buildingId: string;
  floorId: string;
  faceId: string;
  faceType: EFaceType;
}

export const { useSegmentTooltipListener, emitSegmentTooltip } = createEvent('segment-tooltip')<ISegmentTooltipData | null>(); 