import { EObjectType, IModifiedTerrain } from 'cityview';
import {
  findGroundHeightEstimation,
  getTerrainPointElevationFromAbsoluteElevation,
} from 'cityview/components/Terrain/utils';
import { emitTerrainModified } from 'cityview/events/terrain';
import { terrainStore } from 'cityview/store/index';
import { selectObject } from 'cityview/store/mutations/selections';
import { Camera, Vector3 } from 'three';
import { XY, XYZ } from 'types/location/coordinates';

export const setModifiedTerrain = (modifiedTerrain: IModifiedTerrain) => {
  terrainStore.value.modifiedTerrain = modifiedTerrain;
};

export const setTerrainPointsRelativeElevation = (elevationFromOriginal: number, terrainPointIndices: number[]) => {
  const { innerPoints } = terrainStore.value.modifiedTerrain;
  const { terrainData } = terrainStore.value.originalTerrain;

  if (!terrainData) {
    return;
  }
  const changedPoints: XYZ[] = [];

  terrainPointIndices.forEach((index) => {
    const originalElevation = findGroundHeightEstimation([innerPoints[index][0], innerPoints[index][1]], terrainData);
    innerPoints[index][2] = originalElevation + elevationFromOriginal;
    changedPoints.push(innerPoints[index]);
  });

  emitTerrainModified({ changedPoints });
};

export const setTerrainPointsAbsoluteElevation = (absoluteElevation: number, terrainPointIndices: number[]) => {
  const { innerPoints } = terrainStore.value.modifiedTerrain;

  const newElevation = getTerrainPointElevationFromAbsoluteElevation(absoluteElevation);
  const changedPoints: XYZ[] = [];

  terrainPointIndices.forEach((index) => {
    if (innerPoints[index]) {
      innerPoints[index][2] = newElevation;
      changedPoints.push(innerPoints[index]);
    }
  });

  emitTerrainModified({ changedPoints });
};

export const resetModifiedTerrainToOriginal = () => {
  const { innerPoints } = terrainStore.value.modifiedTerrain;
  const { terrainData } = terrainStore.value.originalTerrain;

  if (!terrainData) {
    console.warn('Tried to reset modified terrain to original, but terrain data is not available');
    return;
  }

  innerPoints.forEach((point) => {
    point[2] = findGroundHeightEstimation([point[0], point[1]], terrainData);
  });

  emitTerrainModified({ changedPoints: innerPoints });
};

export const toggleBuildingVisibility = (buildingId: string) => {
  const { buildingsVisible } = terrainStore.value;

  const index = buildingsVisible.indexOf(buildingId);

  if (index === -1) {
    buildingsVisible.push(buildingId);
  } else {
    buildingsVisible.splice(index, 1);
  }
};

function isPointInPolygon(point: { x: number; y: number }, polygon: { x: number; y: number }[]): boolean {
  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i].x;
    const yi = polygon[i].y;
    const xj = polygon[j].x;
    const yj = polygon[j].y;

    const intersect = yi > point.y !== yj > point.y && point.x < ((xj - xi) * (point.y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

function isPointInBoundingBox(
  point: { x: number; y: number },
  bounds: { minX: number; minY: number; maxX: number; maxY: number },
): boolean {
  return point.x >= bounds.minX && point.x <= bounds.maxX && point.y >= bounds.minY && point.y <= bounds.maxY;
}

export const selectTerrainPointsInRectangle = (
  screenCorners: XY[],
  camera: Camera,
  canvas: HTMLCanvasElement,
  addToExistingSelection: boolean = false,
) => {
  const { innerPoints } = terrainStore.value.modifiedTerrain;
  const selectedIndices: string[] = [];
  const rect = canvas.getBoundingClientRect();

  // Calculate bounding box for quick rejection test
  const bounds = screenCorners.reduce(
    (acc, [x, y]) => ({
      minX: Math.min(acc.minX, x),
      minY: Math.min(acc.minY, y),
      maxX: Math.max(acc.maxX, x),
      maxY: Math.max(acc.maxY, y),
    }),
    { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
  );

  innerPoints.forEach((point, index) => {
    // Project 3D point to screen space
    const point3D = new Vector3(point[0], point[1], point[2]);
    point3D.project(camera);

    // Convert normalized device coordinates to screen coordinates
    const screenPoint = {
      x: ((point3D.x + 1) / 2) * rect.width,
      y: ((-point3D.y + 1) / 2) * rect.height,
    };

    // Check if point is inside selection rectangle
    if (isPointInBoundingBox(screenPoint, bounds)) {
      if (
        isPointInPolygon(
          screenPoint,
          screenCorners.map(([x, y]) => ({ x, y })),
        )
      ) {
        selectedIndices.push(index.toString());
      }
    }
  });

  selectObject(selectedIndices, EObjectType.TERRAIN_POINT, !addToExistingSelection);
};
