import React from 'react';
import { EParkingLotType, TParkingLotIndoor, TParkingLotOutdoor } from 'types/parking';
import { useSnapshot } from 'valtio';
import { parkingLotStore } from '../../store';
import ParkingLotIndoor from './ParkingLotIndoor';
import ParkingLotOutdoor from './ParkingLotOutdoor';

const ParkingLots = () => {
  const {
    value: { parkingLots },
  } = useSnapshot(parkingLotStore);

  return (
    <>
      {Object.values(parkingLots).map((parkingLot) =>
        parkingLot.properties.type === EParkingLotType.OUTDOOR_PARKING ? (
          <ParkingLotOutdoor key={parkingLot.id} parkingLot={parkingLot as TParkingLotOutdoor} />
        ) : (
          <ParkingLotIndoor key={parkingLot.id} parkingLot={parkingLot as TParkingLotIndoor} />
        ),
      )}
    </>
  );
};

const MemoizedParkingLots = React.memo(ParkingLots);

export default MemoizedParkingLots;
