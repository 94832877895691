import {
  RequestHelpRequest,
  RequestLawRequest,
  RequestSomethingRequest,
  RequestSubscriptionRequest,
} from 'api/dtos/request/request';
import { backendApi } from 'state/services/backend/api';
import { RequestMethod } from 'types/request';

export const requestApiSlice = backendApi.injectEndpoints({
  endpoints: (builder) => ({
    requestLaw: builder.mutation<void, RequestLawRequest>({
      query: (body) => ({
        url: '/api/request/law-request',
        method: RequestMethod.POST,
        body,
      }),
    }),
    requestSubscription: builder.mutation<void, RequestSubscriptionRequest>({
      query: (body) => ({
        url: '/api/request/new-subscriber-request',
        method: RequestMethod.POST,
        body,
      }),
    }),
    requestHelp: builder.mutation<void, RequestHelpRequest>({
      query: (body) => ({
        url: '/api/request/help',
        method: RequestMethod.POST,
        body,
      }),
    }),
    requestSomething: builder.mutation<void, RequestSomethingRequest>({
      query: (body) => ({
        url: '/api/request/something',
        method: RequestMethod.POST,
        body,
      }),
    }),
    requestDemo: builder.mutation<string, void>({
      query: () => ({
        url: '/api/request/demo-request',
        method: RequestMethod.POST,
      }),
    }),
  }),
});

export const {
  useRequestLawMutation,
  useRequestSubscriptionMutation,
  useRequestHelpMutation,
  useRequestSomethingMutation,
  useRequestDemoMutation,
} = requestApiSlice;
