import Flatten from '@flatten-js/core';
import * as turf from '@turf/turf';
import { canvasTheme, EDefaultFacadePalette } from 'cityview';
import { Feature, LineString } from 'geojson';
// @ts-ignore
import ClipperLib from 'js-clipper';
import { cloneDeep, round } from 'lodash-es';
import { EdgeResult, SkeletonBuilder, Vector2d } from 'straight-skeleton';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { TransformControls as TransformControlsImpl } from 'three-stdlib/controls/TransformControls';
import { generateUUID } from 'three/src/math/MathUtils';
import { IBuilding, TFootprint } from 'types/building/Building';
import { EFaceType, IOtherFace, IWallFace, TFace } from 'types/building/Face';
import {
  EBalconyDividers,
  ESegmentOpening,
  EWallLayout,
  IFaceSegment,
  ISegmentPoint,
} from 'types/building/FaceSegment';
import { EFloorShape, EFloorType, IFloor } from 'types/building/Floor';
import { TCantonAbbr } from 'types/location/cantons';
import { ILngLat, XY, XYZ } from 'types/location/coordinates';
import { TTerrain } from 'types/terrain';
import { Nullable } from 'types/util';
import { buildingStore } from '../../store';
import { resetSegmentWallPartSizes } from '../../store/mutations/building';
import { setFloorRoofFaces, setFloorWalls } from '../../store/mutations/floor';
import { getBuilding } from '../../store/selectors/building';
import {
  getFloorById,
  getFloorFootprint,
  getFloorFootprintByIds,
  getPreviousFloorId,
  getRoofFloorById,
} from '../../store/selectors/floor';
import { getBuildingFloorWall, getFloorWalls, getNextWall, getPreviousWall } from '../../store/selectors/wall';
import { localToWgs } from '../../utils/coordinates';
import { calculateFaceNormal3D } from '../../utils/faceNormal3d';
import { areTwoFootprintsIdentical } from '../../utils/footprint';
import { isValidBuildingFootprint } from '../../utils/footprintValidation';
import { getWallGeometricParameters } from '../../utils/geometry';
import { findDistanceAndDirectionBetweenParallelLines } from '../../utils/lineCalculations';
import { offsetPolygon, removePointsOnEdges } from '../../utils/polygonOffset';
import { mergeTrianglesIntoPolygon3d } from '../../utils/trianglesToPolygon3d';
import { updateBuildingBuffersById } from '../BuildingBuffer/utils';
import { findGroundHeightEstimation } from '../Terrain/utils';
import { ISelectedWall } from '../WallTransformer/utils';
import { areVectorsSimilar } from './Face/FaceSegment/interactions';
import { generateFaceSegments, generateSingularFaceSegment } from './Face/FaceSegment/segmentation';
import { ICeilingSide } from './Floors/CeilingSide/CeilingSide';
import { ESnapConstant } from './types';

const defaultFace: [XYZ, XYZ, XYZ] = [
  [0, 0, 0],
  [0, 0, 0],
  [0, 0, 0],
];

export const translateBuilding = (building: IBuilding, position: THREE.Vector3): void => {
  const { x, y } = position;

  Object.values(building.floors).forEach((floor) => {
    const { faces } = floor;

    faces.forEach(({ coordinates, segments }) => {
      coordinates.forEach((coordinate) => {
        coordinate[0] += x;
        coordinate[1] += y;
      });

      segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[0] += x;
          corner.coordinates[1] += y;
        });
      });
    });

    if (floor.properties.shape === EFloorShape.ROOF) {
      updateFloorRoofFaces(floor, building.id);
    }
  });

  building.buffers.forEach((buffer) => {
    buffer.geometry.forEach((coordinate) => {
      coordinate[0] += x;
      coordinate[1] += y;
    });
  });
};

export const elevateBuilding = (building: IBuilding, elevationDiff: number): void => {
  Object.values(building.floors).forEach((floor) => {
    const { properties, faces } = floor;
    properties.z += elevationDiff;

    faces.forEach(({ coordinates, segments }) => {
      coordinates.forEach((coordinate) => {
        coordinate[2] += elevationDiff;
      });

      segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] += elevationDiff;
        });
      });
    });

    if (floor.properties.shape === EFloorShape.ROOF) {
      updateFloorRoofFaces(floor, building.id);
    }
  });
};

export const rotateBuilding = (building: IBuilding, rotation: THREE.Euler) => {
  const { z } = rotation;
  const center = getBuildingCenter(building);

  Object.values(building.floors).forEach((floor) => {
    const { faces } = floor;

    faces.forEach(({ coordinates, segments }) => {
      coordinates.forEach((coordinate) => {
        const newCoordinate = rotateAroundOrigin([coordinate[0], coordinate[1]], z, center);
        coordinate[0] = newCoordinate[0];
        coordinate[1] = newCoordinate[1];
      });

      segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          const newCoordinate = rotateAroundOrigin([corner.coordinates[0], corner.coordinates[1]], z, center);
          corner.coordinates[0] = newCoordinate[0];
          corner.coordinates[1] = newCoordinate[1];
        });
      });
    });

    if (floor.properties.shape === EFloorShape.ROOF) {
      updateFloorRoofFaces(floor, building.id);
    }
  });

  building.buffers.forEach((buffer) => {
    buffer.geometry.forEach((coordinate) => {
      const newCoordinate = rotateAroundOrigin(coordinate, z, center);
      coordinate[0] = newCoordinate[0];
      coordinate[1] = newCoordinate[1];
    });
  });
};

export const getBuildingCenter = (building: IBuilding, centroid?: boolean): XY => {
  const fullFloors = Object.values(building.floors).filter((floor) => floor.properties.type === EFloorType.VG);
  const firstFloor = fullFloors.length
    ? fullFloors.reduce((prev, curr) => (prev.properties.order < curr.properties.order ? prev : curr))
    : Object.values(building.floors).reduce((prev, curr) =>
        prev.properties.order < curr.properties.order ? prev : curr,
      );
  if (!firstFloor) return [0, 0];

  const footprint = getFloorFootprint(firstFloor);
  return calculateCenter(footprint, centroid);
};

export const calculateCenter = (coordinates: XY[], centroid?: boolean): XY => {
  const polygon = turf.polygon([[...coordinates, coordinates[0]]]);
  const [x, y] = centroid
    ? turf.centroid(polygon).geometry.coordinates
    : turf.centerOfMass(polygon).geometry.coordinates;
  return [x, y];
};

export const rotateAroundOrigin = (point: XY, angle: number, origin = [0, 0]): XY => {
  const offsetX = origin[0];
  const offsetY = origin[1];
  const newX = point[0] - offsetX;
  const newY = point[1] - offsetY;
  const angleCos = Math.cos(angle);
  const angleSin = Math.sin(angle);
  const x = offsetX + angleCos * newX - angleSin * newY;
  const y = offsetY + angleSin * newX + angleCos * newY;
  return [x, y];
};

export const updateFloorRoofFacesById = (buildingId: string, allowIdenticalFootprint = false) => {
  const roofFloor = getRoofFloorById(buildingId);
  if (!roofFloor) return;

  updateRoofFootprintByIds(buildingId, roofFloor.id, allowIdenticalFootprint);
  updateFloorRoofFaces(roofFloor, buildingId);
};

export const updateFloorRoofFaces = (floor: IFloor, buildingId: string) => {
  const footprint = getFloorFootprint(floor);
  if (!footprint) return;

  const roofFaceGeometries = constructRoofFaces(
    footprint,
    floor.properties.baseHeight ? floor.properties.z + floor.properties.baseHeight : floor.properties.z,
    floor.properties.height,
  );

  const roofFaces: TFace[] = roofFaceGeometries.faces.map((face) => {
    const faceId = generateUUID();
    return {
      id: faceId,
      floorId: floor.id,
      coordinates: face.coordinates,
      segments: face.segments.map((segmentCornerCoordinates) =>
        createSegment({
          corners: segmentCornerCoordinates.map((cornerCoordinate) => ({
            coordinates: cornerCoordinate,
            irradiation: -1,
          })),
          faceType: EFaceType.ROOF,
          faceId,
          buildingId,
          floorId: floor.id,
        }),
      ),
      type: EFaceType.ROOF,
    };
  });

  setFloorRoofFaces(floor, roofFaces);
};

interface IConstructedRoofFace {
  coordinates: XYZ[];
  segments: XYZ[][];
}

interface IConstructedRoofFaces {
  faces: IConstructedRoofFace[];
}

export const constructRoofFaces = (footprint: TFootprint, z: number, height: number): IConstructedRoofFaces => {
  footprint.push(footprint[0]);

  const cleanedFootprint = removePointsOnEdges(footprint);
  if (isRectangle(cleanedFootprint)) return constructRectangularBuildingRoofFaces(cleanedFootprint, z, height);

  const roofFaces: IConstructedRoofFaces = {
    faces: [],
  };

  // simplify footprint before building skeleton and construct footprint line for roof edge detection
  const polygon = turf.simplify(turf.polygon([footprint]), { tolerance: 0.1 });
  const footprintLine = turf.lineString(footprint);
  // convert turf polygon to needed format and build skeleton
  const simplifiedFootprint = polygon.geometry.coordinates[0].map((pos): XY => [pos[0], pos[1]]);
  simplifiedFootprint.pop();

  const skeleton = SkeletonBuilder.BuildFromGeoJSON([[simplifiedFootprint]]);
  skeleton.Edges.forEach((edge: EdgeResult) => {
    const vertices = edge.Polygon;
    if (vertices.length > 3) {
      const segments: XYZ[][] = [];
      const [firstVtx, lastVtx] = [vertices[0], vertices[vertices.length - 1]];
      if (areVectorsSimilar([firstVtx.X, firstVtx.Y], [lastVtx.X, lastVtx.Y], 1e-4)) vertices.pop();
      const triangles = THREE.ShapeUtils.triangulateShape(
        vertices.map((v: Vector2d): THREE.Vec2 => new THREE.Vector2(v.X, v.Y)),
        [],
      );
      triangles.forEach((triangle: number[]) => {
        const usedVertices = triangle.map((index: number) => vertices[index]);
        const face: XYZ[] = [...defaultFace];

        usedVertices.forEach((vertex: Vector2d, index) => {
          face[index] = assignZToRoofCorner(vertex, footprintLine, z, z + height);
        });

        segments.push(face);
      });

      const segmentGroups = groupSegmentsByNormal(segments);
      if (Object.keys(segmentGroups).length > 1) {
        Object.values(segmentGroups).forEach((group) => {
          if (group.length > 1) {
            const mergedCoordinates = mergeTrianglesIntoPolygon3d(group);
            mergedCoordinates.pop();
            roofFaces.faces.push({
              coordinates: mergedCoordinates,
              segments: group,
            });
          } else {
            roofFaces.faces.push({
              coordinates: group[0],
              segments: [group[0]],
            });
          }
        });
      } else {
        roofFaces.faces.push({
          coordinates: vertices.map((v: Vector2d): XYZ => assignZToRoofCorner(v, footprintLine, z, z + height)),
          segments,
        });
      }
    } else {
      const face: XYZ[] = [...defaultFace];
      vertices.forEach((v: Vector2d, index) => {
        face[index] = assignZToRoofCorner(v, footprintLine, z, z + height);
      });
      roofFaces.faces.push({
        coordinates: face,
        segments: [face],
      });
    }
  });

  return roofFaces;
};

const groupSegmentsByNormal = (segments: XYZ[][]): Record<string, XYZ[][]> => {
  const segmentGroups: Record<string, XYZ[][]> = {};

  segments.forEach((segment): void => {
    const normal = calculateFaceNormal3D(segment);
    const xKey = Math.abs(round(normal[0], 1));
    const yKey = Math.abs(round(normal[1], 1));
    const zKey = Math.abs(round(normal[2], 1));

    const keyXY = `${xKey}-${yKey}-${zKey}`;
    const keyYX = `${yKey}-${xKey}-${zKey}`;

    if (segmentGroups[keyXY]) {
      segmentGroups[keyXY].push(segment);
    } else if (segmentGroups[keyYX]) {
      segmentGroups[keyYX].push(segment.reverse());
    } else segmentGroups[keyXY] = [segment];
  });
  return segmentGroups;
};

const assignZToRoofCorner = (
  corner: Vector2d,
  footprintLine: Feature<LineString>,
  floorLevel: number,
  ceilingLevel: number,
): XYZ => {
  const p = turf.point([corner.X, corner.Y]);
  const onEdge = turf.pointToLineDistance(p, footprintLine) < 0.5;
  const zPos = onEdge ? floorLevel : ceilingLevel;
  return [corner.X, corner.Y, zPos];
};

export const constructRectangularBuildingRoofFaces = (
  footprint: TFootprint,
  z: number,
  height: number,
): IConstructedRoofFaces => {
  const roofFaces: IConstructedRoofFaces = {
    faces: [],
  };
  const roofTop1: XYZ = [(footprint[0][0] + footprint[3][0]) / 2, (footprint[0][1] + footprint[3][1]) / 2, z + height];
  const roofTop2: XYZ = [(footprint[1][0] + footprint[2][0]) / 2, (footprint[1][1] + footprint[2][1]) / 2, z + height];

  for (let i = 0; i < 4; i++) {
    if (i === 0 || i === 2) {
      const nextIndex = i + 1;
      roofFaces.faces.push({
        coordinates: [
          [footprint[i][0], footprint[i][1], z],
          [footprint[nextIndex][0], footprint[nextIndex][1], z],
          i === 0 ? [...roofTop2] : [...roofTop1],
          i === 0 ? [...roofTop1] : [...roofTop2],
        ],
        segments: [
          [
            [footprint[i][0], footprint[i][1], z],
            [footprint[nextIndex][0], footprint[nextIndex][1], z],
            i === 0 ? [...roofTop1] : [...roofTop2],
          ],
          [
            [footprint[nextIndex][0], footprint[nextIndex][1], z],
            i === 0 ? [...roofTop2] : [...roofTop1],
            i === 0 ? [...roofTop1] : [...roofTop2],
          ],
        ],
      });
    }
    if (i === 1 || i === 3) {
      const nextIndex = i + 1;
      roofFaces.faces.push({
        coordinates: [
          [footprint[i][0], footprint[i][1], z],
          [footprint[nextIndex][0], footprint[nextIndex][1], z],
          i === 1 ? [...roofTop2] : [...roofTop1],
        ],
        segments: [
          [
            [footprint[i][0], footprint[i][1], z],
            [footprint[nextIndex][0], footprint[nextIndex][1], z],
            i === 1 ? [...roofTop2] : [...roofTop1],
          ],
        ],
      });
    }
  }

  return roofFaces;
};

export const updateRoofFootprintByIds = (buildingId: string, roofId: string, allowIdentical = false) => {
  const roofFootprint = getFloorFootprintByIds(buildingId, roofId);
  if (!roofFootprint) return;

  const previousFloor = getPreviousFloorId(buildingId, roofId);
  if (!previousFloor) return;

  const floorFootprint = getFloorFootprintByIds(buildingId, previousFloor);
  if (!floorFootprint) return;

  if (!allowIdentical) {
    const areIdentical = areTwoFootprintsIdentical(roofFootprint, floorFootprint);
    if (areIdentical) return;
  }

  floorFootprint.push(floorFootprint[0]);

  const floor = getFloorById(buildingId, roofId);
  if (!floor) return;

  const z = floor.properties.z;
  const height =
    floor.properties.type === EFloorType.DG && floor.properties.shape === EFloorShape.ROOF
      ? floor.properties.baseHeight!
      : floor.properties.height;

  const newWalls = footprintToWalls(floorFootprint, z, height, buildingId, floor.id);
  setFloorWalls(buildingId, roofId, newWalls);
};

export const footprintToWalls = (
  footprint: TFootprint,
  z: number,
  height: number,
  buildingId: string,
  floorId: string,
): TFace[] => {
  const walls: TFace[] = [];

  for (let i = 0; i < footprint.length - 1; i++) {
    const coordinates: XYZ[] = [
      [footprint[i][0], footprint[i][1], z],
      [footprint[i + 1][0], footprint[i + 1][1], z],
      [footprint[i + 1][0], footprint[i + 1][1], z + height],
      [footprint[i][0], footprint[i][1], z + height],
    ];

    const faceId = generateUUID();
    walls.push({
      id: faceId,
      floorId,
      order: i + 1,
      coordinates,
      segments: generateFaceSegments(coordinates, EFaceType.WALL, faceId, buildingId, floorId),
      type: EFaceType.WALL,
    });
  }

  return walls;
};

export const getCeilingSidesFromFootprint = (footprint: TFootprint, z: number, height: number): ICeilingSide[] => {
  const sides: ICeilingSide[] = [];

  for (let i = 0; i < footprint.length - 1; i++) {
    const corners: XYZ[] = [
      [footprint[i][0], footprint[i][1], z],
      [footprint[i + 1][0], footprint[i + 1][1], z],
      [footprint[i + 1][0], footprint[i + 1][1], z + height],
      [footprint[i][0], footprint[i][1], z + height],
    ];

    const sideId = generateUUID();
    sides.push({
      id: sideId,
      corners,
    });
  }

  return sides;
};

// export const getChangedWallCoordinates = (coordinates: XYZ[], diff1: XYZ, diff2: XYZ): XYZ[] => {
//   return [
//     [coordinates[0][0] + diff1[0], coordinates[0][1] + diff1[1], coordinates[0][2]],
//     [coordinates[1][0] + diff2[0], coordinates[1][1] + diff2[1], coordinates[1][2]],
//     [coordinates[1][0] + diff2[0], coordinates[1][1] + diff2[1], coordinates[2][2]],
//     [coordinates[0][0] + diff1[0], coordinates[0][1] + diff1[1], coordinates[3][2]],
//   ];
// };

export const getChangedWallCoordinates = (coordinates: XYZ[], start: XY, end: XY): XYZ[] => {
  return [
    [start[0], start[1], coordinates[0][2]],
    [end[0], end[1], coordinates[1][2]],
    [end[0], end[1], coordinates[2][2]],
    [start[0], start[1], coordinates[3][2]],
  ];
};

export const setTransformControlsColor = (transformControls: TransformControlsImpl) => {
  transformControls.traverse((child) =>
    child.traverse((child) => {
      child.traverse((innerChild) => {
        // @ts-ignore
        if (innerChild.material) innerChild.material.color.set(canvasTheme.transformControlColor);
      });
    }),
  );
};

export const distanceBetweenPoints = (v1: XY, v2: XY) =>
  Math.sqrt(Math.pow(v2[0] - v1[0], 2) + Math.pow(v2[1] - v1[1], 2));

// export const snapPosition = (x: number, y: number, uniquePoints: XY[], threshold: number): { x: number; y: number } => {
//   if (uniquePoints.length) {
//     const closestPoint = cloneDeep(uniquePoints).sort(
//       (p1, p2) => distanceBetweenPoints(p1, [x, y]) - distanceBetweenPoints(p2, [x, y]),
//     )[0];
//     if (distanceBetweenPoints(closestPoint, [x, y]) < threshold) return { x: closestPoint[0], y: closestPoint[1] };
//   }
//   return { x, y };
// };

export const getBuildingTransformBoxSize = (building: IBuilding, center: XY): [number, number] => {
  const xLimits: [number, number] = [Infinity, -Infinity];
  const yLimits: [number, number] = [Infinity, -Infinity];

  const floorIds = Object.keys(building.floors);

  floorIds.forEach((floorId) => {
    const floor = building.floors[floorId];
    const footprint = getFloorFootprint(floor);
    footprint.forEach((position) => {
      const xDiff = center[0] - position[0];
      const yDiff = center[1] - position[1];
      if (xDiff < xLimits[0]) xLimits[0] = xDiff;
      else if (xDiff > xLimits[1]) xLimits[1] = xDiff;
      if (yDiff < yLimits[0]) yLimits[0] = yDiff;
      else if (yDiff > yLimits[1]) yLimits[1] = yDiff;
    });
  });

  return [
    Math.max(Math.abs(xLimits[1]), Math.abs(xLimits[0])) * 2,
    Math.max(Math.abs(yLimits[1]), Math.abs(yLimits[0])) * 2,
  ];
};

export const getXYFromXYZ = (coordinate: XYZ): XY => {
  return [coordinate[0], coordinate[1]];
};

export const mutateCorner = (buildingId: string, floorId: string, wallId: string, position: THREE.Vector3) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  const changedWall = getBuildingFloorWall(floor, wallId);
  if (!changedWall) return;

  const cornerIndex = changedWall.order - 1;
  const footprint = getFloorFootprint(floor);

  footprint[cornerIndex] = [position.x, position.y];

  const { isValid } = isValidBuildingFootprint(footprint);

  if (isValid) {
    setWallCoordinates(changedWall, [position.x, position.y], getXYFromXYZ(changedWall.coordinates[1]));

    const walls = getFloorWalls(buildingId, floorId) ?? [];

    const previousWall = getPreviousWall(walls, changedWall);

    setWallCoordinates(previousWall, getXYFromXYZ(previousWall.coordinates[0]), [position.x, position.y]);

    updateFloorRoofFacesById(buildingId);
    updateBuildingBuffersById(buildingId);
    setBuildingFormChanged(buildingId, true);
  }
};

export const deleteCorner = (buildingId: string, floorId: string, cornerId: string) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  const wallToDelete = getBuildingFloorWall(floor, cornerId);
  if (!wallToDelete) return;

  const cornerIndex = wallToDelete.order - 1;

  const footprint = getFloorFootprint(floor);
  footprint.splice(cornerIndex, 1);

  const { isValid } = isValidBuildingFootprint(footprint);

  if (isValid) {
    const walls = getFloorWalls(buildingId, floorId) ?? [];
    const previousWall = getPreviousWall(walls, wallToDelete);
    const nextWall = getNextWall(walls, wallToDelete);

    setWallCoordinates(previousWall, getXYFromXYZ(previousWall.coordinates[0]), getXYFromXYZ(nextWall.coordinates[0]));

    const wallsToSet = walls.filter((wall) => wall.id !== wallToDelete.id);

    wallsToSet.forEach((wall) => {
      if (wall.order > wallToDelete.order) wall.order = wall.order - 1;
    });

    setFloorWalls(buildingId, floorId, wallsToSet);
    updateFloorRoofFacesById(buildingId);
    setBuildingFormChanged(buildingId, true);
  }
};

export const setWallCoordinates = (wall: TFace, firstCoordinate: XY, secondCoordinate: XY) => {
  wall.coordinates = [
    [firstCoordinate[0], firstCoordinate[1], wall.coordinates[0][2]],
    [secondCoordinate[0], secondCoordinate[1], wall.coordinates[1][2]],
    [secondCoordinate[0], secondCoordinate[1], wall.coordinates[2][2]],
    [firstCoordinate[0], firstCoordinate[1], wall.coordinates[3][2]],
  ];
};

export const addCorner = (buildingId: string, floorId: string, cornerMesh: Nullable<THREE.Mesh>) => {
  const floor = getFloorById(buildingId, floorId);
  if (!cornerMesh || !floor) return;

  const {
    userData: { wallId },
    position: { x, y },
  } = cornerMesh;

  const currentFloor = cloneDeep(floor);
  const height =
    floor.properties.type === EFloorType.DG && floor.properties.shape === EFloorShape.ROOF
      ? floor.properties.baseHeight!
      : floor.properties.height;

  const currentWall = getBuildingFloorWall(currentFloor, wallId);
  if (!currentWall) return;

  const newWall = generateWall(
    currentWall.order + 1,
    [
      [x, y, currentFloor.properties.z],
      [currentWall.coordinates[1][0], currentWall.coordinates[1][1], currentFloor.properties.z],
      [currentWall.coordinates[1][0], currentWall.coordinates[1][1], currentFloor.properties.z + height],
      [x, y, currentFloor.properties.z + height],
    ],
    buildingId,
    floorId,
  );

  currentFloor.faces.forEach((face) => {
    if (face.type !== EFaceType.WALL) return;
    const wallFace = face as IWallFace;
    if (wallFace.order > currentWall.order) wallFace.order += 1;
  });

  currentFloor.faces.push(newWall);

  setWallCoordinates(currentWall, getXYFromXYZ(currentWall.coordinates[0]), [x, y]);

  const wallFaces = currentFloor.faces.filter((face) => face.type === EFaceType.WALL) as IWallFace[];
  setFloorWalls(buildingId, floorId, wallFaces);

  updateFloorRoofFacesById(buildingId);
  setBuildingFormChanged(buildingId, true);
};

export const generateWall = (order: number, coordinates: XYZ[], buildingId: string, floorId: string): TFace => {
  const id = generateUUID();
  return {
    id,
    floorId,
    order,
    coordinates,
    type: EFaceType.WALL,
    segments: generateFaceSegments(coordinates, EFaceType.WALL, id, buildingId, floorId),
  };
};

export const getWallSnap = (
  buildingId: string,
  floorId: string,
  wallToChange: TFace,
  diff: XYZ,
  selectedWalls: ISelectedWall[],
): XYZ => {
  const building = getBuilding(buildingId);
  if (!building) return diff;

  const floor = getFloorById(buildingId, floorId);
  if (!floor) return diff;

  const { z, height } = floor.properties;
  const { normal: wallNormal } = getWallGeometricParameters(wallToChange.coordinates, z, height);

  const distances = Object.values(building.floors).reduce((acc, floor) => {
    const floorIsSelected = selectedWalls.some((selectedWall) => {
      return selectedWall.floorId === floor.id;
    });

    const newDistances: number[] = [];
    if (!floorIsSelected) {
      const { properties } = floor;
      const walls = getFloorWalls(buildingId, floor.id) ?? [];

      walls.forEach((wall) => {
        const { normal } = getWallGeometricParameters(wall.coordinates, properties.z, properties.height);

        const hasSameNormal =
          Math.abs(normal[0] - wallNormal[0]) < ESnapConstant.NORMAL_DIFFERENCE_THRESHOLD &&
          Math.abs(normal[1] - wallNormal[1]) < ESnapConstant.NORMAL_DIFFERENCE_THRESHOLD;

        if (hasSameNormal) {
          const l1 = [getXYFromXYZ(wall.coordinates[0]), getXYFromXYZ(wall.coordinates[1])];
          const l2 = [getXYFromXYZ(wallToChange.coordinates[0]), getXYFromXYZ(wallToChange.coordinates[1])];
          const { distance, direction } = findDistanceAndDirectionBetweenParallelLines(l1, l2);
          newDistances.push(direction * distance);
        }
      });
    }

    return [...acc, ...newDistances];
  }, [] as number[]);

  const diffLength = Math.sqrt(Math.pow(diff[0], 2) + Math.pow(diff[1], 2));
  const closestWallDistance = distances.sort(
    (distanceA, distanceB) => Math.abs(diffLength - Math.abs(distanceA)) - Math.abs(diffLength - Math.abs(distanceB)),
  );
  const minDistanceToWall = Math.abs(closestWallDistance[0]) - diffLength;
  const snapDirection =
    Math.sign(diff[0]) === Math.sign(wallNormal[0]) && Math.sign(diff[1]) === Math.sign(wallNormal[1]) ? 1 : -1;
  const correctDirection = Math.sign(closestWallDistance[0]) === snapDirection;

  if (Math.abs(minDistanceToWall) > 2 * ESnapConstant.WALL_SNAP_DISTANCE) {
    return diff;
  } else if (Math.abs(minDistanceToWall) < ESnapConstant.WALL_SNAP_DISTANCE) {
    if (diffLength < ESnapConstant.WALL_SNAP_DISTANCE) {
      const diffDirection = [diffLength ? diff[0] / diffLength : 0, diffLength ? diff[1] / diffLength : 0];
      return [diff[0] + minDistanceToWall * diffDirection[0], diff[1] + minDistanceToWall * diffDirection[1], diff[2]];
    } else {
      if (correctDirection) {
        const diffDirection = [diffLength ? diff[0] / diffLength : 0, diffLength ? diff[1] / diffLength : 0];
        return [
          diff[0] + minDistanceToWall * diffDirection[0],
          diff[1] + minDistanceToWall * diffDirection[1],
          diff[2],
        ];
      }
    }
  }

  return diff;
};

export const calculateRoofFloorFootprintById = (
  buildingId: string,
  floor: IFloor,
  canton?: TCantonAbbr,
): XY[] | undefined => {
  const previousFloorId = getPreviousFloorId(buildingId, floor.id);
  if (!previousFloorId) return;
  const previousFloor = getFloorById(buildingId, previousFloorId);
  if (!previousFloor) return;

  const footprint = getFloorFootprint(previousFloor);
  footprint.push(footprint[0]);

  if (previousFloor.properties.type === EFloorType.DG || floor.properties.shape === EFloorShape.ROOF) {
    return footprint;
  } else {
    return calculateRoofFloorFootprint(previousFloor, canton);
  }
};

export const calculateRoofFloorFootprint = (floor: IFloor, canton?: TCantonAbbr): XY[] => {
  const footprint = getFloorFootprint(floor);
  footprint.push(footprint[0]);
  // Is not regular rectangle
  if (footprint.length > 5) return footprint;

  const length = distanceBetweenPoints(footprint[0], footprint[1]);
  const width = distanceBetweenPoints(footprint[1], footprint[2]);
  const MIN_ATTIC_LENGTH = 4;

  let offset = -floor.properties.height;
  let offsets = Array(footprint.length).fill(-floor.properties.height);

  if (
    length < MIN_ATTIC_LENGTH + 2 * floor.properties.height ||
    width < MIN_ATTIC_LENGTH + 2 * floor.properties.height
  ) {
    const shorterSide = length < width ? length : width;
    offset = (shorterSide - 4) / 2;
    offsets = Array(footprint.length).fill(-offset);
  }

  if (canton === 'ZH') {
    return getBoxRoofFootprintWithSwissFlag(footprint, Math.abs(offset));
  }

  const poly = offsetPolygon(footprint, offsets);

  const turfPoly = turf.polygon([poly]);
  if (!turf.kinks(turfPoly).features.length) return poly;

  const polygonWithBuffer = turf.buffer(turf.polygon([footprint]), -floor.properties.height, { units: 'degrees' });
  if (!polygonWithBuffer) return footprint;

  const simplifiedPolygon = turf.simplify(polygonWithBuffer, { tolerance: 3 });

  return simplifiedPolygon.geometry.coordinates[0].map((pos) => [pos[0] as number, pos[1] as number]);
};

const getBoxRoofFootprintWithSwissFlag = (footprint: XY[], offset: number): XY[] => {
  const footprintLines: LineSegment2D[] = [];
  for (let i = 0; i < footprint.length - 1; i++) {
    footprintLines.push(new LineSegment2D(footprint[i], footprint[i + 1]));
  }

  const l1 = distanceBetweenPoints(footprint[0], footprint[1]);
  const l2 = distanceBetweenPoints(footprint[1], footprint[2]);

  const roofFlagWidth = offset;
  const roofFlagLength = Math.max(l1, l2) / 3;

  const lineIndices: number[] = l1 > l2 ? [0, 1, 2, 3] : [1, 2, 3, 0];
  const firstLine = footprintLines[lineIndices[0]];
  const secondLine = footprintLines[lineIndices[1]];
  const thirdLine = footprintLines[lineIndices[2]];
  const fourthLine = footprintLines[lineIndices[3]];

  const roofCorners: [number, number][] = [];
  roofCorners.push(firstLine.point1);
  const point2 = firstLine.pointOnLineByDistance(roofFlagLength);
  roofCorners.push(point2);
  roofCorners.push(firstLine.pointOnNormalByPointAndDistance(point2, -roofFlagWidth));
  roofCorners.push(secondLine.pointOnLineByDistance(roofFlagWidth));

  roofCorners.push(thirdLine.point1);
  const point6 = thirdLine.pointOnLineByDistance(roofFlagLength);
  roofCorners.push(point6);
  roofCorners.push(thirdLine.pointOnNormalByPointAndDistance(point6, -roofFlagWidth));
  roofCorners.push(fourthLine.pointOnLineByDistance(roofFlagWidth));

  roofCorners.push(firstLine.point1);

  return roofCorners;
};

export class LineSegment2D {
  point1: XY;
  point2: XY;
  length: number;

  directionVec: XY;
  normalVec: XY;
  reverseNormalVec: XY;

  constructor(p1: XY, p2: XY) {
    this.point1 = p1;
    this.point2 = p2;
    this.length = distanceBetweenPoints(p1, p2);

    const vec = [p2[0] - p1[0], p2[1] - p1[1]];
    this.directionVec = [vec[0] / this.length, vec[1] / this.length];

    const normals = this.getNormals2D();
    this.normalVec = normals[0];
    this.reverseNormalVec = normals[1];
  }

  public pointOnLineByDistance(distance: number): XY {
    return [this.point1[0] + this.directionVec[0] * distance, this.point1[1] + this.directionVec[1] * distance];
  }

  public pointOnNormalByPointAndDistance(point: XY, distance: number): XY {
    return [point[0] + this.normalVec[0] * distance, point[1] + this.normalVec[1] * distance];
  }

  public pointOnReverseNormalByPointAndDistance(point: XY, distance: number): XY {
    return [point[0] + this.reverseNormalVec[0] * distance, point[1] + this.reverseNormalVec[1] * distance];
  }

  private getNormals2D(): [XY, XY] {
    const vec = [this.point2[1] - this.point1[1], -(this.point2[0] - this.point1[0])];
    return [
      [vec[0] / this.length, vec[1] / this.length],
      [-(vec[0] / this.length), -(vec[1] / this.length)],
    ];
  }
}

const FLOATS_TO_INTEGER_SCALE_FACTOR = 1e4;
export const getBuildingFootprint = (building: IBuilding): TFootprint => {
  const polygons = Object.values(building.floors)
    .filter((floor) => floor.properties.type !== EFloorType.UG)
    .map((floor) => getFloorFootprint(floor, 2));
  polygons.forEach((footprint) => footprint.push(footprint[0]));

  if (!polygons.length) return [];

  const cpr = new ClipperLib.Clipper();
  for (const polygon of polygons) {
    const newPolygon = polygon.map(
      ([x, y]) => new ClipperLib.IntPoint(x * FLOATS_TO_INTEGER_SCALE_FACTOR, y * FLOATS_TO_INTEGER_SCALE_FACTOR),
    );
    cpr.AddPath(newPolygon, ClipperLib.PolyType.ptSubject, true);
  }

  const paths = new ClipperLib.Paths();
  cpr.Execute(
    ClipperLib.ClipType.ctUnion,
    paths,
    ClipperLib.PolyFillType.pftNonZero,
    ClipperLib.PolyFillType.pftNonZero,
  );
  if (!paths.length) return [];

  const footprint = paths[0].map(({ X, Y }: { X: number; Y: number }) => [
    X / FLOATS_TO_INTEGER_SCALE_FACTOR,
    Y / FLOATS_TO_INTEGER_SCALE_FACTOR,
  ]);
  footprint.push(footprint[0]);
  return footprint.length ? footprint : [];
};

export const getEgFootprint = (building: IBuilding): TFootprint => {
  const vgFloors = Object.values(building.floors).filter((floor) => floor.properties.type === EFloorType.VG);
  if (!vgFloors.length) return getBuildingFootprint(building);

  const minOrderFloor = vgFloors.reduce((acc, floor) => (floor.properties.order < acc.properties.order ? floor : acc));
  const egFootprint = getFloorFootprint(minOrderFloor, 2);
  egFootprint.push(egFootprint[0]);
  return egFootprint;
};

export const getBuildingFootprintWgs = (building: IBuilding, center: ILngLat): TFootprint => {
  const footprint = getBuildingFootprint(building);
  return localToWgs(footprint, center);
};

export interface ICreateBaseFaceProps {
  id?: string;
  coordinates: XYZ[];
  buildingId: string;
  floorId: string;
}

export interface ICreateOtherFaceProps extends ICreateBaseFaceProps {
  type: EFaceType.FLOOR | EFaceType.ROOF | EFaceType.CEILING;
}

export interface ICreateWallFaceProps extends ICreateBaseFaceProps {
  order: number;
  type: EFaceType.WALL;
}

export type TCreateFaceProps = ICreateOtherFaceProps | ICreateWallFaceProps;

type ReturnType<T> = T extends ICreateWallFaceProps ? IWallFace : IOtherFace;

export const createFace = <T extends TCreateFaceProps>(data: T): ReturnType<T> => {
  const { id, coordinates, type, buildingId, floorId } = data;

  let faceId = id;
  if (!faceId) faceId = generateUUID();

  const segments = generateFaceSegments(coordinates, type, faceId, buildingId, floorId);

  return {
    ...data,
    id: faceId,
    segments,
  } as unknown as ReturnType<T>;
};

export const logBuildingData = (building: IBuilding) => {
  const footprint = getBuildingFootprint(building);

  alert(
    `footprintArea: ${getFootprintArea(footprint)}\ntotalFloorArea: ${getBuildingTotalFloorArea(
      building,
    )}\ntotalVolume: ${getBuildingTotalVolume(building)}\nbuildingHeight: ${getBuildingHeight(building)}\n`,
  );
};

export const getFootprintArea = (footprint: TFootprint): number => {
  const polygon = new Flatten.Polygon(footprint);
  return polygon.area();
};

const getBuildingTotalFloorArea = (building: IBuilding): number =>
  Object.keys(building.floors).reduce((sum, floorId) => sum + getFloorArea(building.floors[floorId]), 0);

const getBuildingTotalVolume = (building: IBuilding): number =>
  Object.values(building.floors).reduce((sum, floor) => sum + getFloorVolume(floor), 0);

const getBuildingHeight = (building: IBuilding): number =>
  Object.values(building.floors).reduce((sum, floor) => sum + floor.properties.height, 0);

const getFloorArea = (floor: IFloor): number => {
  const footprint = getFloorFootprint(floor);
  return footprint ? THREE.ShapeUtils.area(footprint.map((c) => new THREE.Vector2(c[0], c[1]))) : 0;
};

const getFloorVolume = (floor: IFloor): number => {
  const area = getFloorArea(floor);
  return area * floor.properties.height;
};

export const isRectangle = (polygon: XY[]): boolean => {
  if (polygon.length !== 5) {
    return false;
  }

  const distance1 = round(distanceBetweenPoints(polygon[0], polygon[2]), 1);
  const distance2 = round(distanceBetweenPoints(polygon[1], polygon[3]), 1);
  return distance1 === distance2;
};

export const generateHorizontalSurfaceFace = (
  floor: IFloor,
  faceType: EFaceType.FLOOR | EFaceType.CEILING | EFaceType.ROOF,
  buildingId: string,
): TFace => {
  const { z, height } = floor.properties;

  const faceId = generateUUID();

  const coordinates = getHorizontalFaceCoordinatesFromFootprint(getFloorFootprint(floor), z, height, faceType);
  const segment = generateSingularFaceSegment(coordinates, faceType, faceId, buildingId, floor.id);

  return {
    id: faceId,
    floorId: floor.id,
    coordinates,
    type: faceType,
    segments: [segment],
  };
};

const getHorizontalFaceCoordinatesFromFootprint = (
  floorFootprint: XY[],
  z: number,
  height: number,
  faceType: EFaceType,
): XYZ[] => {
  const shift = 0.01;
  const faceZ = faceType === EFaceType.FLOOR ? z + shift : z + height;

  return floorFootprint.map((xy: XY) => [...xy, faceZ]);
};

export interface ICreatSegmentProps {
  id?: string;
  faceId: string;
  corners: ISegmentPoint[];
  faceType: EFaceType;
  buildingId: string;
  floorId: string;
}

export const createSegment = (props: ICreatSegmentProps): IFaceSegment => {
  const { id, faceId, corners, faceType, buildingId, floorId } = props;

  const building = getBuilding(buildingId);
  if (!building) throw new Error(`Building not found while creating a segment. BuildingId: ${buildingId}`);

  let segmentId = id;
  if (!segmentId) segmentId = generateUUID();

  const floor = getFloorById(buildingId, floorId);
  if (!floor) throw new Error(`Floor not found while creating a segment. FloorId: ${floorId}`);

  const segment: IFaceSegment = {
    id: segmentId,
    faceId,
    corners,
    properties: {
      opening: faceType === EFaceType.WALL ? ESegmentOpening.WINDOW : ESegmentOpening.NONE,
      balconyDividers: EBalconyDividers.NONE,
      hasBalcony: false,
      balconyWidth: 1.6,
      pvActive: false,
      roofPvPercentage: 0,
      segmentWidth: 0,
      segmentHeight: 0,
      wallLayout: EWallLayout.WIDE_TOP_AND_BOTTOM,
      wallProperties: {
        top: {
          color: EDefaultFacadePalette.STANDARD_WHITE,
          hasPv: false,
          width: 0,
          height: 0,
          variableHeight: 0,
        },
        bottom: {
          color: EDefaultFacadePalette.STANDARD_WHITE,
          hasPv: false,
          width: 0,
          height: 0,
        },
        sides: {
          color: EDefaultFacadePalette.STANDARD_WHITE,
          hasPv: false,
          width: 0,
          height: 0,
        },
        center: {
          color:
            faceType === EFaceType.WALL ? EDefaultFacadePalette.STANDARD_WHITE : EDefaultFacadePalette.STANDARD_GLASS,
          hasPv: false,
          width: 0,
          height: 0,
        },
      },
    },
  };

  if (faceType === EFaceType.WALL) {
    const leftCorner = new Vector3(...corners[0].coordinates);
    const rightCorner = new Vector3(...corners[1].coordinates);
    segment.properties.segmentWidth = leftCorner.distanceTo(rightCorner);

    const topLeftCorner = new Vector3(...corners[3].coordinates);
    segment.properties.segmentHeight = leftCorner.distanceTo(topLeftCorner);

    resetSegmentWallPartSizes(segment, building, floor);
  }

  return segment;
};

export const setBuildingFormChanged = (buildingId: string, isChanged: boolean) => {
  buildingStore.value.projectBuildings[buildingId].buildingFormChanged = isChanged;
};

export const setBuildingPositionChanged = (buildingId: string, isChanged: boolean) => {
  buildingStore.value.projectBuildings[buildingId].buildingPositionChanged = isChanged;
};

export const isSegmentAboveGround = (segment: IFaceSegment, floor: IFloor, terrain: TTerrain) => {
  const testHeight = 0.05;
  const center: XYZ = [
    (segment.corners[0].coordinates[0] + segment.corners[1].coordinates[0]) / 2,
    (segment.corners[0].coordinates[1] + segment.corners[1].coordinates[1]) / 2,
    floor.properties.z + testHeight,
  ];

  const groundHeight = findGroundHeightEstimation([center[0], center[1]], terrain);

  return center[2] >= groundHeight;
};

export const regenerateFaceBalconyDividers = (face: TFace) => {
  const segments = face.segments;
  const balconySegmentSequences: IFaceSegment[][] = [];
  let currentBalconySegments: IFaceSegment[] = [];

  segments.forEach((segment) => {
    segment.properties.balconyDividers = EBalconyDividers.NONE;
  });

  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (segment.properties.hasBalcony) {
      currentBalconySegments.push(segment);
    } else {
      if (currentBalconySegments.length > 0) {
        balconySegmentSequences.push(currentBalconySegments);
        currentBalconySegments = [];
      }
    }
  }

  if (currentBalconySegments.length > 0) {
    balconySegmentSequences.push(currentBalconySegments);
  }

  if (balconySegmentSequences.length > 0) {
    balconySegmentSequences.forEach((sequence: IFaceSegment[]) => {
      if (sequence.length === 1) {
        sequence[0].properties.balconyDividers = EBalconyDividers.BOTH;
      } else {
        sequence[0].properties.balconyDividers = EBalconyDividers.LEFT;
        sequence[sequence.length - 1].properties.balconyDividers = EBalconyDividers.RIGHT;
      }
    });
  }
};

export const getValidWallLayout = (wallLayout: EWallLayout, opening: ESegmentOpening): EWallLayout => {
  switch (wallLayout) {
    case EWallLayout.WIDE_TOP_AND_BOTTOM:
    case EWallLayout.WIDE_TOP:
      if (opening === ESegmentOpening.DOOR) {
        return EWallLayout.NO_BOTTOM_WIDE_TOP;
      } else {
        return wallLayout;
      }
    case EWallLayout.WIDE_BOTTOM:
    case EWallLayout.NARROW_TOP_AND_BOTTOM:
      if (opening === ESegmentOpening.DOOR) {
        return EWallLayout.NO_BOTTOM_NARROW_TOP;
      } else {
        return wallLayout;
      }
    case EWallLayout.NO_BOTTOM_WIDE_TOP:
      if (opening === ESegmentOpening.DOOR) {
        return EWallLayout.NO_BOTTOM_WIDE_TOP;
      } else {
        return EWallLayout.WIDE_TOP;
      }
    case EWallLayout.NO_BOTTOM_NARROW_TOP:
      if (opening === ESegmentOpening.DOOR) {
        return EWallLayout.NO_BOTTOM_NARROW_TOP;
      } else {
        return EWallLayout.NARROW_TOP_AND_BOTTOM;
      }
    default:
      return wallLayout;
  }
};

export const isSegmentTopWide = (segment: IFaceSegment): boolean => {
  return (
    segment.properties.wallLayout === EWallLayout.WIDE_TOP ||
    segment.properties.wallLayout === EWallLayout.WIDE_TOP_AND_BOTTOM ||
    segment.properties.wallLayout === EWallLayout.NO_BOTTOM_WIDE_TOP
  );
};

export const getSegmentBuilding = (segment: IFaceSegment): IBuilding | undefined => {
  const buildings = buildingStore.value.projectBuildings;

  let foundBuilding: IBuilding | undefined;

  for (const building of Object.values(buildings)) {
    if (!foundBuilding) {
      for (const floor of Object.values(building.floors)) {
        if (!foundBuilding) {
          for (const face of floor.faces) {
            if (!foundBuilding) {
              for (const testSegment of face.segments) {
                if (segment.id === testSegment.id) {
                  foundBuilding = building;
                  break;
                }
              }
            } else {
              break;
            }
          }
        } else {
          break;
        }
      }
    } else {
      break;
    }
  }

  return foundBuilding;
};

export const getSegmentFloor = (building: IBuilding, segment: IFaceSegment): IFloor | undefined => {
  let foundFloor: IFloor | undefined;

  for (const floor of Object.values(building.floors)) {
    if (!foundFloor) {
      for (const face of floor.faces) {
        if (!foundFloor) {
          for (const testSegment of face.segments) {
            if (segment.id === testSegment.id) {
              foundFloor = floor;
              break;
            }
          }
        } else {
          break;
        }
      }
    } else {
      break;
    }
  }

  return foundFloor;
};
