import clamp from 'lodash/clamp';
import { Moment } from 'moment';
import moment from 'moment-timezone/moment-timezone';

import timezonesMoment from './timezonesMoment.json';

moment.tz.load(timezonesMoment);

export const defaultDay = 21;

export const minutesToTime = (minutes: number) => {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${numberToDateString(h)}:${numberToDateString(m)}`;
};

export const numberToDateString = (value: number) => (value < 10 ? `0${value}` : `${value}`);

export const getMomentDateTime = (month: number, minutes: number): Moment => {
  const clampedMonth = clamp(month, 1, 12);
  const clampedMinutes = clamp(minutes, 0, 1440);

  const dateTime = `2025-${numberToDateString(clampedMonth)}-${defaultDay} ${minutesToTime(clampedMinutes)}`;

  return moment.tz(dateTime, 'Europe/Zurich');
};

export const getDateTime = (month: number, minutes: number) => getMomentDateTime(month, minutes).toDate();
