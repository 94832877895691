import { useSegmentHoverEndListener, useSegmentHoverListener } from 'cityview';
import React, { useState } from 'react';
import * as THREE from 'three';
import { EApplicationMode, EApplicationTopic } from 'types/applicationPath';
import { XYZ } from 'types/location/coordinates';
import { useApplicationPath, useBuildingTree } from '../../hooks';
import { getSegmentsByIds } from '../../store/selectors/building';
import { getFaceById } from '../../store/selectors/wall';

const defaultIndicatorGeometry = new THREE.SphereGeometry(2);
const DefaultIndicator = () => (
  <mesh name={'defaultIndicator'} geometry={defaultIndicatorGeometry}>
    <meshBasicMaterial color='cyan' />
    <pointLight color='cyan' intensity={2} distance={20} decay={0.8} />
  </mesh>
);

interface ObjectSelectionIndicatorProps {
  coordinates: XYZ;
  children?: React.ReactNode;
  showIndicator?: boolean;
  buildingId: string;
}

const ObjectSelectionIndicator = (props: ObjectSelectionIndicatorProps) => {
  const { coordinates, children, showIndicator = true, buildingId } = props;
  const [x, y, z] = coordinates;

  const { topic, mode } = useApplicationPath();
  const [selectionIndicatorVisible, setSelectionIndicatorVisible] = useState(true);
  const { building } = useBuildingTree({ buildingId });

  useSegmentHoverListener(({ buildingId: hoveredBuildingId, segmentIds: hoveredSegmentIds }) => {
    if (topic === EApplicationTopic.PHOTOVOLTAICS) {
      if (buildingId && buildingId === hoveredBuildingId) {
        setSelectionIndicatorVisible(false);
      } else if (hoveredSegmentIds && hoveredSegmentIds.length > 0) {
        const segment = getSegmentsByIds([hoveredSegmentIds[0]])[0];
        const face = getFaceById(segment.faceId);
        if (face && building.floors[face.floorId]) {
          setSelectionIndicatorVisible(false);
        }
      }
    }
  });

  useSegmentHoverEndListener(() => {
    if (topic === EApplicationTopic.PHOTOVOLTAICS) setSelectionIndicatorVisible(true);
  });

  if (!showIndicator || !selectionIndicatorVisible) return null;

  return (
    <group position={[x, y, z]}>{children && mode !== EApplicationMode.BUFFER ? children : <DefaultIndicator />}</group>
  );
};

export default ObjectSelectionIndicator;
