import { EBuildingType } from 'types/building/Building';
import {
  EBuildingUnitCategory,
  EBuildingUnitSize,
  EBuildingUnitType,
  ESia416Category,
  EStaircaseType,
  IBuildingUnit,
} from 'types/building/BuildingUsage';
import { Int } from 'utils/Int';

interface IUnitSettings {
  area: {
    min: number;
    max: number;
    default: number;
  };
  buildingsAllowed: {
    [EBuildingType.MULTI_FAMILY]: boolean;
    [EBuildingType.SINGLE_FAMILY]: boolean;
    [EBuildingType.MULTI_FAMILY_AND_OFFICE]: boolean;
    [EBuildingType.OFFICE]: boolean;
    [EBuildingType.INDUSTRIAL]?: boolean;
  };
  subUnitsAllowed: EBuildingUnitType[];
  sia416Category: ESia416Category;
  unitCategory: EBuildingUnitCategory;
  usedByFpre: boolean;
  maisonetteAllowed: boolean;
  generatingIncome: boolean;
  internalWallMultiplier: number;
  FFMultiplier: number;
  waterPointsMultiplier: number;
  nrOfBathrooms: number;
  unitPriority: Int;
}

export abstract class Unit {
  private static defaultParams: IUnitSettings = {
    area: {
      min: 0,
      max: 1000,
      default: 0,
    },
    buildingsAllowed: {
      [EBuildingType.MULTI_FAMILY]: true,
      [EBuildingType.SINGLE_FAMILY]: true,
      [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
      [EBuildingType.OFFICE]: true,
    },
    subUnitsAllowed: [],
    sia416Category: ESia416Category.HNF,
    unitCategory: EBuildingUnitCategory.LIVING,
    usedByFpre: false,
    maisonetteAllowed: false,
    generatingIncome: false,
    internalWallMultiplier: 0,
    FFMultiplier: 0,
    waterPointsMultiplier: 0,
    nrOfBathrooms: 0,
    unitPriority: 5 as Int,
  };
  private readonly params: IUnitSettings;

  protected constructor(params: Partial<IUnitSettings>) {
    this.params = { ...Unit.defaultParams, ...params };
  }

  public getSettings(): IUnitSettings {
    return this.params;
  }
}

interface IConstructorParameters {
  coreType?: EStaircaseType;
}

export const UnitSettings: Record<EBuildingUnitType, new (parameters: IConstructorParameters) => Unit> = {
  studio: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.STUDIO].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.STUDIO].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.STUDIO].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        generatingIncome: true,
        internalWallMultiplier: 0.029,
        FFMultiplier: 0.025,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 1,
      });
    }
  },
  twoAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.TWOANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.TWOANDAHALF].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.TWOANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.038,
        FFMultiplier: 0.012,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 1,
      });
    }
  },
  threeAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.THREEANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.THREEANDAHALF].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.THREEANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.045,
        FFMultiplier: 0.013,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 2,
      });
    }
  },
  fourAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.FOURANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.FOURANDAHALF].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.FOURANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.049,
        FFMultiplier: 0.011,
        waterPointsMultiplier: 2,
        nrOfBathrooms: 2,
      });
    }
  },
  fiveAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.FIVEANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.FIVEANDAHALF].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.FIVEANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.05,
        FFMultiplier: 0.011,
        waterPointsMultiplier: 2,
        nrOfBathrooms: 2,
      });
    }
  },
  office: class extends Unit {
    constructor() {
      super({
        area: {
          min: 30,
          max: 10000,
          default: 50,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
          EBuildingUnitType.SUB_CLEANING_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.034,
        FFMultiplier: 0.005,
        nrOfBathrooms: 1,
        unitPriority: 4 as Int,
      });
    }
  },
  gastronomy: class extends Unit {
    constructor() {
      super({
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
          EBuildingUnitType.SUB_CLEANING_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.024,
        FFMultiplier: 1.2,
        unitPriority: 1 as Int,
      });
    }
  },
  shop: class extends Unit {
    constructor() {
      super({
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
          EBuildingUnitType.SUB_CLEANING_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.021,
        FFMultiplier: 0.01,
        unitPriority: 1 as Int,
      });
    }
  },
  workshop: class extends Unit {
    constructor() {
      super({
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
          EBuildingUnitType.SUB_CLEANING_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.005,
        FFMultiplier: 0.005,
        unitPriority: 1 as Int,
      });
    }
  },
  production: class extends Unit {
    constructor() {
      super({
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
          EBuildingUnitType.SUB_CLEANING_ROOM,
        ],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.005,
        FFMultiplier: 0.007,
        unitPriority: 2 as Int,
      });
    }
  },
  singleFamilyLiving: class extends Unit {
    constructor() {
      super({
        area: {
          min: 0,
          max: 1000,
          default: 60,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_LAUNDRY,
          EBuildingUnitType.SUB_BIKE_STORAGE,
        ],
        sia416Category: ESia416Category.HNF,
        maisonetteAllowed: true,
        generatingIncome: true,
        internalWallMultiplier: 0.05,
        FFMultiplier: 0.011,
      });
    }
  },
  singleFamilyStudio: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.SINGLE_FAMILY_STUDIO].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.SINGLE_FAMILY_STUDIO].max,
          default: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.SINGLE_FAMILY_STUDIO].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM, EBuildingUnitType.SUB_WINTERGARDEN],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        generatingIncome: true,
        internalWallMultiplier: 0.029,
        FFMultiplier: 0.025,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 1,
      });
    }
  },
  singleFamilyTwoAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF].max,
          default:
            MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM, EBuildingUnitType.SUB_WINTERGARDEN],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        generatingIncome: true,
        internalWallMultiplier: 0.038,
        FFMultiplier: 0.012,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 1,
      });
    }
  },
  singleFamilyThreeAndAHalf: class extends Unit {
    constructor() {
      super({
        area: {
          min: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.S][EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF].min,
          max: MultiFamilyLivingUnitMinMax[EBuildingUnitSize.L][EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF].max,
          default:
            MultiFamilyLivingUnitMinMax[EBuildingUnitSize.M][EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF].default,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM, EBuildingUnitType.SUB_WINTERGARDEN],
        sia416Category: ESia416Category.HNF,
        usedByFpre: true,
        generatingIncome: true,
        internalWallMultiplier: 0.045,
        FFMultiplier: 0.013,
        waterPointsMultiplier: 1,
        nrOfBathrooms: 2,
      });
    }
  },
  singleFamilyBureau: class extends Unit {
    constructor() {
      super({
        area: {
          min: 20,
          max: 150,
          default: 30,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        generatingIncome: true,
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM, EBuildingUnitType.SUB_WINTERGARDEN],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        internalWallMultiplier: 0.01,
        FFMultiplier: 0.005,
      });
    }
  },
  singleFamilyWorkshop: class extends Unit {
    constructor() {
      super({
        area: {
          min: 20,
          max: 150,
          default: 30,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        generatingIncome: true,
        subUnitsAllowed: [EBuildingUnitType.SUB_STORAGE_ROOM, EBuildingUnitType.SUB_WINTERGARDEN],
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.COMMERCIAL,
        internalWallMultiplier: 0.01,
        FFMultiplier: 0.005,
      });
    }
  },
  maisonette: class extends Unit {
    constructor() {
      super({
        area: {
          min: 5,
          max: 100,
          default: 20,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        subUnitsAllowed: [
          EBuildingUnitType.SUB_STORAGE_ROOM,
          EBuildingUnitType.SUB_WINTERGARDEN,
          EBuildingUnitType.SUB_HOBBY_ROOM,
          EBuildingUnitType.SUB_TECHNICAL_ROOM,
          EBuildingUnitType.SUB_LAUNDRY,
          EBuildingUnitType.SUB_BIKE_STORAGE,
          EBuildingUnitType.SUB_CLEANING_ROOM,
          EBuildingUnitType.SUB_STORAGE_FACILITY,
        ],
        generatingIncome: true,
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.LIVING,
        internalWallMultiplier: 0.01,
      });
    }
  },
  sideCommonRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 15,
          max: 100,
          default: 20,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        internalWallMultiplier: 0.02,
        FFMultiplier: 0.01,
      });
    }
  },
  sideBuildingService: class extends Unit {
    constructor() {
      super({
        area: {
          min: 8,
          max: 50,
          default: 20,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.FF,
        internalWallMultiplier: 0.01,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
      });
    }
  },
  sideStorage: class extends Unit {
    constructor() {
      super({
        area: {
          min: 5,
          max: 250,
          default: 8,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        internalWallMultiplier: 0.025,
      });
    }
  },
  sideLaundry: class extends Unit {
    constructor() {
      super({
        area: {
          min: 8,
          max: 1000,
          default: 20,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        internalWallMultiplier: 0.01,
        FFMultiplier: 0.005,
      });
    }
  },
  sideBicycle: class extends Unit {
    constructor() {
      super({
        area: {
          min: 15,
          max: 100,
          default: 30,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
      });
    }
  },
  sideEntranceHall: class extends Unit {
    constructor() {
      super({
        area: {
          min: 4,
          max: 25,
          default: 6,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.VF,
        unitCategory: EBuildingUnitCategory.CIRCULATION,
      });
    }
  },
  sideStrollerRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 8,
          max: 50,
          default: 15,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
      });
    }
  },
  sideHobbyRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 10,
          max: 250,
          default: 25,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        internalWallMultiplier: 0.035,
        FFMultiplier: 0.007,
      });
    }
  },
  sideHousekeeping: class extends Unit {
    constructor() {
      super({
        area: {
          min: 6,
          max: 50,
          default: 12,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        FFMultiplier: 0.005,
      });
    }
  },
  sideStorageFacility: class extends Unit {
    constructor() {
      super({
        area: {
          min: 10,
          max: 1000,
          default: 50,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        generatingIncome: true,
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
        internalWallMultiplier: 0.005,
      });
    }
  },
  sideCleaningRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 6,
          max: 15,
          default: 10,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        FFMultiplier: 0.007,
        unitCategory: EBuildingUnitCategory.SIDE_USAGE,
      });
    }
  },
  subStorageRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 2,
          max: 20,
          default: 5,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subHobbyRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 5,
          max: 50,
          default: 10,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subWintergarden: class extends Unit {
    constructor() {
      super({
        area: {
          min: 5,
          max: 50,
          default: 10,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: true,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.HNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subTechnicalRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 8,
          max: 25,
          default: 15,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.FF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subCleaningRoom: class extends Unit {
    constructor() {
      super({
        area: {
          min: 6,
          max: 25,
          default: 10,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subBikeStorage: class extends Unit {
    constructor() {
      super({
        area: {
          min: 10,
          max: 50,
          default: 15,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subLaundry: class extends Unit {
    constructor() {
      super({
        area: {
          min: 6,
          max: 25,
          default: 10,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: true,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: false,
          [EBuildingType.OFFICE]: false,
        },
        sia416Category: ESia416Category.NNF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  subStorageFacility: class extends Unit {
    constructor() {
      super({
        area: {
          min: 8,
          max: 25,
          default: 15,
        },
        buildingsAllowed: {
          [EBuildingType.MULTI_FAMILY]: false,
          [EBuildingType.SINGLE_FAMILY]: false,
          [EBuildingType.MULTI_FAMILY_AND_OFFICE]: true,
          [EBuildingType.OFFICE]: true,
        },
        sia416Category: ESia416Category.FF,
        unitCategory: EBuildingUnitCategory.SUB_USAGE,
      });
    }
  },
  circulation: class extends Unit {
    constructor({ coreType }: IConstructorParameters) {
      const staircaseType = coreType || EStaircaseType.A;
      super({
        area: {
          min: CoreMinMax[staircaseType].min,
          max: CoreMinMax[staircaseType].max,
          default: CoreMinMax[staircaseType].default,
        },
        sia416Category: ESia416Category.VF,
        unitCategory: EBuildingUnitCategory.CIRCULATION,
      });
    }
  },
};

export const createUnitSettings = (unitType: EBuildingUnitType, coreType?: EStaircaseType): Unit => {
  return new UnitSettings[unitType]({
    coreType,
  });
};

export const getBuildingUnitTypes = (
  buildingType: EBuildingType,
  unitCategory: EBuildingUnitCategory,
): EBuildingUnitType[] => {
  return Object.entries(UnitSettings)
    .filter(([unitType, UnitConstructor]) => {
      const unitInstance = new UnitConstructor({});
      const unitSettings = unitInstance.getSettings();

      return (
        unitSettings.unitCategory === unitCategory &&
        unitSettings.buildingsAllowed[buildingType] &&
        unitType !== EBuildingUnitType.MAISONETTE &&
        unitType !== EBuildingUnitType.CIRCULATION
      );
    })
    .map(([unitType]) => unitType as EBuildingUnitType);
};

export const getAllUnitTypesFromCategory = (unitCategory: EBuildingUnitCategory): EBuildingUnitType[] => {
  return Object.entries(UnitSettings)
    .filter(([_, UnitConstructor]) => {
      const unitInstance = new UnitConstructor({});
      const unitSettings = unitInstance.getSettings();
      return unitSettings.unitCategory === unitCategory;
    })
    .map(([unitType]) => unitType as EBuildingUnitType);
};

export const countsAsHNF = (unit: IBuildingUnit): boolean => {
  const unitSettings = createUnitSettings(unit.type).getSettings();
  return unitSettings.sia416Category === ESia416Category.HNF;
};

const MultiFamilyLivingUnitMinMax = {
  [EBuildingUnitSize.S]: {
    [EBuildingUnitType.STUDIO]: {
      min: 10,
      max: 35,
      default: 25,
    },
    [EBuildingUnitType.TWOANDAHALF]: {
      min: 30,
      max: 65,
      default: 45,
    },
    [EBuildingUnitType.THREEANDAHALF]: {
      min: 45,
      max: 80,
      default: 60,
    },
    [EBuildingUnitType.FOURANDAHALF]: {
      min: 55,
      max: 105,
      default: 75,
    },
    [EBuildingUnitType.FIVEANDAHALF]: {
      min: 70,
      max: 135,
      default: 95,
    },
    [EBuildingUnitType.SINGLE_FAMILY_STUDIO]: {
      min: 15,
      max: 35,
      default: 25,
    },
    [EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF]: {
      min: 30,
      max: 65,
      default: 45,
    },
    [EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF]: {
      min: 45,
      max: 80,
      default: 60,
    },
  },
  [EBuildingUnitSize.M]: {
    [EBuildingUnitType.STUDIO]: {
      min: 25,
      max: 50,
      default: 35,
    },
    [EBuildingUnitType.TWOANDAHALF]: {
      min: 45,
      max: 75,
      default: 60,
    },
    [EBuildingUnitType.THREEANDAHALF]: {
      min: 60,
      max: 110,
      default: 80,
    },
    [EBuildingUnitType.FOURANDAHALF]: {
      min: 75,
      max: 150,
      default: 105,
    },
    [EBuildingUnitType.FIVEANDAHALF]: {
      min: 100,
      max: 190,
      default: 135,
    },
    [EBuildingUnitType.SINGLE_FAMILY_STUDIO]: {
      min: 25,
      max: 50,
      default: 35,
    },
    [EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF]: {
      min: 45,
      max: 75,
      default: 60,
    },
    [EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF]: {
      min: 60,
      max: 110,
      default: 80,
    },
  },
  [EBuildingUnitSize.L]: {
    [EBuildingUnitType.STUDIO]: {
      min: 35,
      max: 65,
      default: 45,
    },
    [EBuildingUnitType.TWOANDAHALF]: {
      min: 60,
      max: 90,
      default: 75,
    },
    [EBuildingUnitType.THREEANDAHALF]: {
      min: 75,
      max: 140,
      default: 100,
    },
    [EBuildingUnitType.SINGLE_FAMILY_STUDIO]: {
      min: 35,
      max: 65,
      default: 45,
    },
    [EBuildingUnitType.SINGLE_FAMILY_TWOANDAHALF]: {
      min: 60,
      max: 90,
      default: 75,
    },
    [EBuildingUnitType.SINGLE_FAMILY_THREEANDAHALF]: {
      min: 75,
      max: 140,
      default: 100,
    },
    [EBuildingUnitType.FOURANDAHALF]: {
      min: 95,
      max: 190,
      default: 130,
    },
    [EBuildingUnitType.FIVEANDAHALF]: {
      min: 125,
      max: 250,
      default: 170,
    },
  },
};

const CoreMinMax = {
  [EStaircaseType.A]: {
    min: 17,
    max: 100,
    default: 25,
  },
  [EStaircaseType.B]: {
    min: 11,
    max: 100,
    default: 25,
  },
  [EStaircaseType.C]: {
    min: 16,
    max: 100,
    default: 25,
  },
  [EStaircaseType.NONE]: {
    min: 0,
    max: 0,
    default: 0,
  },
};
