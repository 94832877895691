import React, { useRef, useMemo, useEffect, useState } from 'react';
import {
  InstancedMesh,
  Matrix4,
  Vector3,
  SphereGeometry,
  MeshStandardMaterial,
  Color,
  InstancedBufferAttribute,
} from 'three';
import { EApplicationTool } from 'types/applicationPath';
import { XYZ } from 'types/location/coordinates';
import { getAltitudeColor, getTerrainPointElevationRelativeToOriginalTerrain } from 'cityview/components/Terrain/utils';
import { ThreeEvent } from '@react-three/fiber';
import { EObjectName, EObjectType, store } from 'cityview';
import { selectObject } from 'cityview/store/mutations/selections';
import { useTerrainModifiedListener } from 'cityview/events/terrain';

interface TerrainPointInstancesProps {
  points: XYZ[];
  selectedIndices: number[];
}

const TerrainPointInstances = ({ points, selectedIndices }: TerrainPointInstancesProps) => {
  const meshRef = useRef<InstancedMesh>(null);
  const selectedSet = useMemo(() => new Set(selectedIndices), [selectedIndices]);
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const pointIndices = useMemo(() => {
    const indices = new Map<string, number>();
    points.forEach((point, index) => {
      indices.set(`${point[0]},${point[1]}`, index);
    });
    return indices;
  }, [points]);

  const geometry = useMemo(() => {
    const geo = new SphereGeometry(0.3, 16, 16);
    geo.setAttribute('color', new InstancedBufferAttribute(new Float32Array(points.length * 3), 3));
    return geo;
  }, [points.length]);

  const material = useMemo(
    () =>
      new MeshStandardMaterial({
        vertexColors: true,
        metalness: 0.1,
        roughness: 0.5,
      }),
    [],
  );

  useEffect(() => {
    if (!meshRef.current) return;

    const matrix = new Matrix4();
    const position = new Vector3();
    const colorAttribute = geometry.getAttribute('color');

    points.forEach((point, index) => {
      position.set(point[0], point[1], point[2]);
      matrix.makeTranslation(position.x, position.y, position.z);

      if (selectedSet.has(index) || index === hoveredIndex) {
        matrix.scale(new Vector3(1.5, 1.5, 1.5));
      }

      meshRef.current!.setMatrixAt(index, matrix);

      const altitudeDifference = getTerrainPointElevationRelativeToOriginalTerrain(point);
      const altitudeColor = getAltitudeColor(altitudeDifference);
      const color = new Color(selectedSet.has(index) ? '#666666' : altitudeColor);

      colorAttribute.setXYZ(index, color.r, color.g, color.b);
    });

    meshRef.current.instanceMatrix.needsUpdate = true;
    colorAttribute.needsUpdate = true;
  }, [points, selectedSet, hoveredIndex, geometry]);

  // Create reusable instances outside the listener
  const matrix = useMemo(() => new Matrix4(), []);
  const position = useMemo(() => new Vector3(), []);

  useTerrainModifiedListener(({ changedPoints }) => {
    if (!meshRef.current) return;

    let needsUpdate = false;
    const instancedMesh = meshRef.current;

    changedPoints.forEach((changedPoint) => {
      const key = `${changedPoint[0]},${changedPoint[1]}`;
      const index = pointIndices.get(key);

      if (index !== undefined) {
        position.set(changedPoint[0], changedPoint[1], changedPoint[2]);
        matrix.makeTranslation(position.x, position.y, position.z);

        if (selectedSet.has(index) || index === hoveredIndex) {
          matrix.scale(new Vector3(1.5, 1.5, 1.5));
        }

        instancedMesh.setMatrixAt(index, matrix);
        needsUpdate = true;
      }
    });

    if (needsUpdate) {
      instancedMesh.instanceMatrix.needsUpdate = true;
    }
  });

  const handleClick = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    if (e.instanceId === undefined) return;
    if (store.general.applicationPath.tool !== EApplicationTool.TERRAIN_ELEVATION) return;

    selectObject(e.instanceId.toString(), EObjectType.TERRAIN_POINT, !(e.metaKey || e.ctrlKey));
  };

  const handlePointerOver = (e: ThreeEvent<PointerEvent>) => {
    e.stopPropagation();
    if (e.instanceId !== undefined) {
      setHoveredIndex(e.instanceId);
    }
  };

  const handlePointerOut = () => {
    setHoveredIndex(null);
  };

  return (
    <instancedMesh
      ref={meshRef}
      args={[geometry, material, points.length]}
      name={EObjectName.TERRAIN_POINT}
      onClick={handleClick}
      onPointerOver={handlePointerOver}
      onPointerOut={handlePointerOut}
      frustumCulled={false}
    />
  );
};

export default TerrainPointInstances;
