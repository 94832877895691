import { derivedParkingLotStore } from 'cityview';
import { Feature, MultiPolygon } from 'geojson';
import cloneDeep from 'lodash/cloneDeep';
import { useMemo } from 'react';
import * as THREE from 'three';
import { XY } from 'types/location/coordinates';
import { EParkingLotType, TOutdoorParkingLot } from 'types/parking';
import { ETerrainImageType } from 'types/terrain';
import { useSnapshot } from 'valtio';
import { BaseTerrainProps } from '../components/Terrain/BaseTerrain';
import {
  composeTerrainTextureLayers,
  getConstructionLinesCoordinates,
  recalculateHelplines,
} from '../components/Terrain/utils';
import store, { parkingLotStore } from '../store';
import { wgsToLocal } from '../utils/coordinates';

interface UseTerrainTextureProps extends BaseTerrainProps {
  plotCoordinates?: XY[];
}

const useTerrainTexture = (props: UseTerrainTextureProps) => {
  const {
    cadastreTexture,
    center,
    constructionLines,
    gridCellWidth,
    gridRotationAngle,
    helplines = [],
    landscapeFeatures = [],
    roadmapTexture,
    satelliteTexture,
    showGrid = false,
    showTerrain = true,
    showTexture = true,
    type = ETerrainImageType.SATELLITE,
    terrainWithProperties: {
      properties: { terrainWidth },
    },
    plotCoordinates,
  } = props;

  const { isDesktop } = useSnapshot(store.general);

  const constructionLinesCoordinates = useMemo(
    () => getConstructionLinesCoordinates(constructionLines, center),
    [constructionLines, center],
  );
  const allHelpLines = useMemo(() => recalculateHelplines(helplines, center), [helplines, center]);

  const {
    value: { parkingLots },
  } = useSnapshot(parkingLotStore);

  const { isMouseEditing } = useSnapshot(store.mouse);

  const outdoorParkingLots = useMemo(() => {
    return Object.values(parkingLots).filter(
      (parkingLot): parkingLot is TOutdoorParkingLot => parkingLot.properties.type === EParkingLotType.OUTDOOR_PARKING,
    );
  }, [parkingLots]);
  const { outdoorParkingLotLines } = useSnapshot(derivedParkingLotStore);

  const texture = useMemo(() => {
    let mainTexture: THREE.CanvasTexture | undefined;
    let darkGrid = true;

    if (showTexture) {
      switch (type) {
        case ETerrainImageType.ROADMAP:
          mainTexture = roadmapTexture;
          break;
        case ETerrainImageType.CADASTRE:
          mainTexture = cadastreTexture;
          break;
        case ETerrainImageType.SATELLITE:
          mainTexture = satelliteTexture;
          darkGrid = false;
          break;
      }
    } else if (showTerrain) {
      darkGrid = false;
    }

    let mappedLandscapeFeatures: Feature<MultiPolygon>[] = [];
    if (type !== ETerrainImageType.SATELLITE && landscapeFeatures.length > 0) {
      mappedLandscapeFeatures = cloneDeep(landscapeFeatures);
      mappedLandscapeFeatures.forEach((feature: Feature<MultiPolygon>) => {
        // @ts-ignore
        feature.geometry.coordinates[0] = feature.geometry.coordinates[0].map((geometry) => {
          return geometry.map((coord) => wgsToLocal([{ lng: coord[0], lat: coord[1] }], center));
        });
      });
    }

    return composeTerrainTextureLayers(
      isMouseEditing,
      allHelpLines,
      plotCoordinates,
      mainTexture,
      terrainWidth,
      showTexture,
      showGrid,
      gridCellWidth,
      constructionLinesCoordinates,
      showTerrain,
      gridRotationAngle,
      darkGrid,
      isDesktop,
      outdoorParkingLots,
      outdoorParkingLotLines,
      mappedLandscapeFeatures,
    );
  }, [
    allHelpLines,
    type,
    showTexture,
    roadmapTexture,
    cadastreTexture,
    satelliteTexture,
    terrainWidth,
    plotCoordinates,
    showGrid,
    gridCellWidth,
    constructionLinesCoordinates,
    showTerrain,
    gridRotationAngle,
    outdoorParkingLots,
    outdoorParkingLotLines,
    landscapeFeatures,
    isMouseEditing,
  ]);

  return texture;
};

export default useTerrainTexture;
