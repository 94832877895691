import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const ShopIntegrationsView = () => {
  const location = useLocation();
  console.log(location);

  return (
    <>
      <Outlet />
    </>
  );
};

export default ShopIntegrationsView;
