import { Sphere, TransformControls } from '@react-three/drei';
import { store } from 'cityview';
import { setIsMouseDown, setIsMouseEditing } from 'cityview/store/mutations/mouse';
import { useRef } from 'react';
import { Mesh } from 'three';
import { TransformControls as TransformControlsImpl } from 'three-stdlib/controls/TransformControls';
import { XYZ } from 'types/location/coordinates';

interface TerrainPointTransformerProps {
  affectedPointIndices: number[];
  position: XYZ;
  handleElevationChange: (affectedPointIndices: number[], elevation: number, skipStore: boolean) => void;
  updateElevationWhileDragging: (change: number) => void;
}

const TerrainPointTransformer = (props: TerrainPointTransformerProps) => {
  const { affectedPointIndices, position, handleElevationChange, updateElevationWhileDragging } = props;
  const sphereRef = useRef<Mesh>(null!);
  const elevationControlsRef = useRef<TransformControlsImpl>(null!);
  const initialElevation = useRef<number | null>(null);

  return (
    <>
      <Sphere ref={sphereRef} args={[0.3, 16, 16]} position={position} visible={false}>
        <meshStandardMaterial color={'blue'} />
      </Sphere>
      <TransformControls
        ref={elevationControlsRef}
        object={sphereRef}
        mode='translate'
        showX={false}
        showY={false}
        translationSnap={0.1}
        size={1}
        onMouseDown={() => {
          if (!sphereRef.current) return;

          initialElevation.current = sphereRef.current.position.z;
          setIsMouseDown(true);
          setIsMouseEditing(true);
        }}
        onMouseUp={() => {
          if (!sphereRef.current) return;

          const difference = sphereRef.current.position.z - initialElevation.current!;

          handleElevationChange(affectedPointIndices, Math.round(difference * 10) / 10, false);

          initialElevation.current = null;
          setIsMouseDown(false);
          setIsMouseEditing(false);
        }}
        onChange={() => {
          if (!sphereRef.current || !store.mouse.isMouseDown) return;
          const difference = sphereRef.current.position.z - initialElevation.current!;
          updateElevationWhileDragging(difference);
          handleElevationChange(affectedPointIndices, Math.round(difference * 10) / 10, true);
        }}
      >
        {/*<Sphere args={[0.5, 16, 16]}>*/}
        {/*  <meshStandardMaterial color='blue' />*/}
        {/*</Sphere>*/}
      </TransformControls>
    </>
  );
};

export default TerrainPointTransformer;
