import { ThreeEvent } from '@react-three/fiber';
import { degreesToRadians } from '@turf/turf';
import { EVegetationSource, EVegetationType, IVegetation, IVegetationProperties, VEGETATION_TYPE_SIZE } from 'cityview';
import { InstancedMesh, Object3D } from 'three';
import { generateUUID } from 'three/src/math/MathUtils';
import { XYZ } from 'types/location/coordinates';
import { convertStringToInteger } from 'utils/random';

export const DEFAULT_VEGETATION: IVegetation = {
  id: generateUUID(),
  isModified: true,
  properties: {
    height: VEGETATION_TYPE_SIZE[EVegetationType.MAPLE][1],
    diameter: VEGETATION_TYPE_SIZE[EVegetationType.MAPLE][0],
    type: EVegetationType.MAPLE,
    visible: true,
    position: [0, 0, 0],
    source: EVegetationSource.USER,
  },
};

export const getVegetationByMouseEvent = (e: ThreeEvent<MouseEvent>, vegetations: IVegetation[]) => {
  if (typeof e.instanceId !== 'number') return;

  return vegetations[e.instanceId];
};

export const getVegetationScale = (properties?: IVegetationProperties): XYZ => {
  const { height, diameter, type } = properties ?? {};

  const typeSize = VEGETATION_TYPE_SIZE[type ?? EVegetationType.MAPLE];

  return [diameter ?? typeSize[0], diameter ?? typeSize[0], height ?? typeSize[1]];
};

export const convertUuidToAngle = (uuid: string) => {
  const uuidInteger = convertStringToInteger(uuid);
  const degrees = Math.abs(uuidInteger) % 360;

  return degreesToRadians(degrees);
};

const tempObject = new Object3D();
export const updateVegetations = (vegetations: IVegetation[], ref: InstancedMesh, hiddenVegetationIds?: string[]) => {
  for (let i = 0; i < vegetations.length; i++) {
    const vegetation = vegetations[i];
    const vegetationScale = getVegetationScale(vegetation.properties);
    const rotation = convertUuidToAngle(vegetation.id);

    tempObject.position.set(
      vegetation.properties.position[0],
      vegetation.properties.position[1],
      vegetation.properties.position[2],
    );
    tempObject.rotation.set(0, 0, rotation);
    tempObject.scale.set(...vegetationScale);
    tempObject.userData = { uuid: vegetation.id };

    if (hiddenVegetationIds?.includes(vegetation.id)) {
      tempObject.scale.set(0, 0, 0);
    }

    tempObject.updateMatrix();

    ref.setMatrixAt(i, tempObject.matrix);
    ref.computeBoundingSphere();
  }

  ref.instanceMatrix.needsUpdate = true;
};

export const groupVegetationsByType = (vegetations: IVegetation[]) =>
  vegetations.reduce(
    (acc, vegetation) => {
      const type = vegetation.properties.type;

      if (!acc[type]) {
        acc[type] = [];
      }

      acc[type].push(vegetation);

      return acc;
    },
    {} as Record<EVegetationType, IVegetation[]>,
  );
