import { useSnapshot } from 'valtio';
import store from '../store';

const useIsObjectSelected = (objectId: string) => {
  const { selectedObjects } = useSnapshot(store.selections);

  return selectedObjects[objectId] !== undefined;
};

export default useIsObjectSelected;
