export enum EBackendTag {
  APARTMENTS = 'Apartments',
  APARTMENTS_FIT = 'ApartmentsFit',
  AREAS = 'Areas',
  BUILDINGS = 'Buildings',
  BUILDING_APARTMENTS = 'BuildingApartments',
  BUILDING_DEFAULTS = 'BuildingDefaults',
  BUILDINGS_USAGE = 'BuildingsUsage',
  COSTS = 'Costs',
  CREDITS = 'Credits',
  DATA_OVERVIEW = 'DataOverview',
  DIMENSION_IMAGES = 'DimensionImages',
  EBKP_COSTS = 'EbkpCosts',
  ENERGY = 'Energy',
  FAHRLANDER_BKP_COSTS = 'FahrlanderBkpCosts',
  FAHRLANDER_BUILDING_SETTINGS = 'FahrlanderBuildingSettings',
  FAHRLANDER_FUNCTIONS = 'FahrlanderFunctions',
  FAHRLANDER_INCOMES = 'FahrlanderIncomes',
  FAHRLANDER_PLOT_ACCESS = 'FahrlanderPlotAccess',
  FAHRLANDER_PROJECT_ACCESS = 'FahrlanderProjectAccess',
  FAHRLANDER_SUBSCRIPTION_ACCESS = 'FahrlanderSubscriptionAccess',
  IMAGES = 'Images',
  INCOMES = 'Incomes',
  KEE_VALUE_CALCULATION_SETTINGS = 'KeeValueCalculationSettings',
  KEE_VALUE_SUBSCRIPTION_ACCESS = 'KeeValueSubscriptionAccess',
  PARKING_LOTS = 'ParkingLots',
  PARKING_LOTS_RESULTS = 'ParkingLotsResults',
  PARKING_LOT_FINANCE = 'ParkingLotFinance',
  PROJECT = 'Project',
  PROJECTS = 'Projects',
  PROJECT_AREAS = 'ProjectAreas',
  PROJECT_COLLABORATORS = 'ProjectCollaborators',
  PROJECT_COST = 'ProjectCost',
  PROJECT_ERRORS = 'ProjectErrors',
  PROJECT_EXISTING_BUILDINGS = 'ProjectExistingBuildings',
  PROJECT_INCOME = 'ProjectIncome',
  PROJECT_LANDSCAPE = 'ProjectLandscape',
  PROJECT_MAP_FEATURES = 'ProjectMapFeatures',
  PROJECT_MODIFIED_TERRAIN = 'ProjectModifiedTerrain',
  PROJECT_NOTES = 'ProjectNotes',
  PROJECT_RULES_CHECK = 'ProjectRulesCheck',
  PROJECT_VOLUMES = 'ProjectVolumes',
  PURCHASE_INFO = 'PurchaseInfo',
  SOLAR_IRRADIATION = 'SolarIrradiation',
  USER = 'User',
  USER_PROJECT_RULES = 'UserProjectRules',
  UTILITIES = 'Utilities',
  UTILIZATION = 'Utilization',
  VISUALIZATION = 'Visualization',
  VOLUMES = 'Volumes',
  WITH_LANGUAGE = 'WithLanguage',
}

export enum EBackendMutationCacheKey {
  ADD_BUILDING = 'addBuilding',
  ADD_PARKING_LOT = 'addParkingLot',
  BUILDING_USAGE_TYPE_CHANGE = 'buildingUsageTypeChange',
  CHANGE_APARTMENT_SIZE = 'changeApartmentSize',
  DELETE_APARTMENT = 'deleteApartment',
  EDIT_BUILDING_CIRCULATION = 'editBuildingCirculation',
  EDIT_BUILDING_CORE = 'editBuildingCore',
  EDIT_COST_TYPE = 'editCostType',
  EDIT_EBKP_A2 = 'editEbkpA2',
  EDIT_FLOOR_USAGE = 'editFloorUsage',
  EDIT_INCOME_TYPE = 'editIncomeType',
  EDIT_LAND_COST = 'editLandCost',
  UNIFY_USAGE = 'unifyUsage',
  UPDATE_BUILDINGS = 'updateBuildings',
  UPDATE_FACADES = 'updateFacades',
  UPDATE_PARKING_LOTS = 'updateParkingLots',
  UPDATE_PHOTOVOLTAICS = 'updatePhotovoltaics',
  UPDATE_USER_PROJECT_RULES = 'updateUserProjectRules',
  UPDATE_VEGETATION = 'updateVegetation',
  UPDATE_BUILDINGS_USAGE = 'updateBuildingsUsage',
  UPDATE_MODIFIED_TERRAIN = 'updateModifiedTerrain',
}
