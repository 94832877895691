import { createEvent } from 'react-event-hook';
import { XYZ } from 'types/location/coordinates';

export interface IWallDragCoordinates {
  id: string;
  coordinates: XYZ[];
  skip?: boolean;
}

export interface IWallDragWallsCoordinates {
  floorId: string;
  coordinates: {
    primary: IWallDragCoordinates;
    previous: IWallDragCoordinates;
    next: IWallDragCoordinates;
  };
}

export interface WallDragEvent {
  buildingId: string;
  wallsCoordinates: IWallDragWallsCoordinates[];
}

export interface WallDragEndEvent {
  buildingId: string;
}

export const { useWallDragListener, emitWallDrag } = createEvent('wall-drag')<WallDragEvent>();

export const { useWallDragEndListener, emitWallDragEnd } = createEvent('wall-drag-end')<WallDragEndEvent>();
