import * as turf from '@turf/turf';
import { EBuildingRestrictions } from 'cityview/components/Building/types';
import { Feature, Polygon, Position } from 'geojson';
import { TFootprint } from 'types/building/Building';
import { XY } from 'types/location/coordinates';

export const isFootprintClosed = (footprint: TFootprint) =>
  footprint[0][0] !== footprint[footprint.length - 1][0] || footprint[0][1] !== footprint[footprint.length - 1][1];

const hasDuplicateCorners = (polygon: Feature<Polygon>): boolean => {
  const polygonCoordinates = polygon.geometry.coordinates[0];

  let hasDuplicates = false;
  for (let i = 1; i < polygonCoordinates.length; i++) {
    if (
      polygonCoordinates[i - 1][0] === polygonCoordinates[i][0] &&
      polygonCoordinates[i - 1][1] === polygonCoordinates[i][1]
    ) {
      hasDuplicates = true;
    }
  }
  return hasDuplicates;
};

const isSelfIntersecting = (polygon: Feature<Polygon>): boolean => {
  const unkinked = turf.unkinkPolygon(polygon);
  return unkinked.features.length > 1;
};

const isClockwise = (polygon: Feature<Polygon>): boolean =>
  turf.booleanClockwise(turf.lineString(polygon.geometry.coordinates[0]));

const hasTooSharpCorners = (polygon: Feature<Polygon>) => {
  const polygonCoordinates = polygon.geometry.coordinates[0];

  const polygonToCheck = [...polygonCoordinates];
  polygonToCheck.push(polygonCoordinates[1]);

  for (let i = 0; i < polygonToCheck.length - 2; i++) {
    const v1 = polygonToCheck[i];
    const v2 = polygonToCheck[i + 1];
    const v3 = polygonToCheck[i + 2];
    const angle = angleBetweenCorners(v1, v2, v3);
    if (angle < EBuildingRestrictions.MIN_ANGLE_BETWEEN_WALLS || isNaN(angle)) return true;
  }

  return false;
};

const hasCornersTooClose = (polygon: Feature<Polygon>): boolean => {
  const polygonCoordinates = polygon.geometry.coordinates[0];

  const wallLengths: number[] = [];
  for (let i = 0; i < polygonCoordinates.length - 2; i++) {
    wallLengths.push(distanceBetweenCorners(polygon, i));
  }
  const minLength = Math.min(...wallLengths);
  return minLength < EBuildingRestrictions.MIN_WALL_LENGTH;
};

const hasCornersTooCloseToWalls = (polygon: Feature<Polygon>): boolean => {
  const polygonCoordinates = polygon.geometry.coordinates[0];
  const cornerTooClose = false;
  for (let i = 0; i < polygonCoordinates.length; i++) {
    // copy polygon and remove last element, because it's the same as the first one
    const polygonCopy = [...polygonCoordinates];
    polygonCopy.pop();

    // shift element position so that the checked corner is the last one
    for (let shift = 0; shift < i; shift++) {
      const first = polygonCopy.shift();
      if (first) polygonCopy.push(first);
    }

    // check corner distances to other walls
    for (let i = 0; i < polygonCopy.length - 2; i++) {
      const d = distanceToLineSegment(polygonCopy[polygonCopy.length - 1], [polygonCopy[i], polygonCopy[i + 1]]);
      if (d < EBuildingRestrictions.MIN_BUILDING_THICKNESS) return true;
    }
  }
  return cornerTooClose;
};

export const distanceBetweenCorners = (polygon: Feature<Polygon>, firstCornerIndex: number): number => {
  const polygonCoordinates = polygon.geometry.coordinates[0];

  if (firstCornerIndex >= polygonCoordinates.length) return 0;
  const polygonArray: XY[] = polygonCoordinates.map((c) => [c[0], c[1]]);
  if (
    polygonArray[0][0] !== polygonArray[polygonArray.length - 1][0] ||
    polygonArray[0][1] !== polygonArray[polygonArray.length - 1][1]
  ) {
    polygonArray.push(polygonArray[0]);
  }
  const a = polygonArray[firstCornerIndex];
  const b = polygonArray[firstCornerIndex + 1];
  return Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
};

export const angleBetweenCorners = (a: Position | XY, b: Position | XY, c: Position | XY) => {
  const AB = Math.sqrt(Math.pow(b[0] - a[0], 2) + Math.pow(b[1] - a[1], 2));
  const BC = Math.sqrt(Math.pow(b[0] - c[0], 2) + Math.pow(b[1] - c[1], 2));
  const AC = Math.sqrt(Math.pow(c[0] - a[0], 2) + Math.pow(c[1] - a[1], 2));
  const cos = (BC * BC + AB * AB - AC * AC) / (2 * BC * AB);

  // safety for "flat" corner (two consecutive walls with same orientation)
  if (Math.abs(cos + 1) < 1e-8) return 180;

  const rad = Math.acos(cos);
  return (rad * 180) / Math.PI;
};

export const distanceToLineSegment = (point: Position | XY, line: (Position | XY)[]): number => {
  const [x, y] = point;
  const [[x1, y1], [x2, y2]] = line;

  const A = x - x1;
  const B = y - y1;
  const dxLine = x2 - x1;
  const dyLine = y2 - y1;

  const dot: number = A * dxLine + B * dyLine;
  const lengthSquare: number = Math.pow(dxLine, 2) + Math.pow(dyLine, 2);
  const param: number = lengthSquare === 0 ? -1 : dot / lengthSquare;

  const closestPoint: XY = [0, 0];
  if (param < 0) {
    closestPoint[0] = x1;
    closestPoint[1] = y1;
  } else if (param > 1) {
    closestPoint[0] = x2;
    closestPoint[1] = y2;
  } else {
    closestPoint[0] = x1 + param * dxLine;
    closestPoint[1] = y1 + param * dyLine;
  }

  const dx = x - closestPoint[0];
  const dy = y - closestPoint[1];
  return Math.sqrt(dx * dx + dy * dy);
};

// Names need to be specified because the function names are not readable in the minified code
const VALIDATIONS = [
  {
    name: 'hasTooSharpCorners',
    validate: hasTooSharpCorners,
  },
  {
    name: 'hasCornersTooClose',
    validate: hasCornersTooClose,
  },
  {
    name: 'hasCornersTooCloseToWalls',
    validate: hasCornersTooCloseToWalls,
  },
  {
    name: 'isSelfIntersecting',
    validate: isSelfIntersecting,
  },
  {
    name: 'isClockwise',
    validate: isClockwise,
  },
  {
    name: 'hasDuplicateCorners',
    validate: hasDuplicateCorners,
  },
] as const;

type TValidation =
  | {
      isValid: true;
      errors?: undefined;
    }
  | {
      isValid: false;
      errors: string[];
    };

const validatePolygon = (polygon: Feature<Polygon>, options?: IValidationOptions): TValidation => {
  const { debug, skip, validateAll } = options ?? {};

  const isInvalid = ({ name, validate }: (typeof VALIDATIONS)[number]) => {
    const skipValidation = skip?.includes(name);
    if (skipValidation) return false;

    const isInvalid = validate(polygon);

    if (debug && isInvalid) console.log('Invalid polygon: ', name);

    return isInvalid;
  };

  let validations: (typeof VALIDATIONS)[number][] = [];
  if (validateAll) {
    validations = VALIDATIONS.filter(isInvalid);
  } else {
    const firstInvalid = VALIDATIONS.find(isInvalid);
    if (firstInvalid) validations.push(firstInvalid);
  }

  return validations.length > 0
    ? {
        isValid: false,
        errors: validations.map((validation) => validation.name),
      }
    : {
        isValid: true,
      };
};

interface IValidationOptions {
  // If true, logs invalid polygons to the console
  debug?: boolean;
  // If true, validates all restrictions, if false, stops at the first invalid restriction
  validateAll?: boolean;
  // Skips specific validations
  skip?: (typeof VALIDATIONS)[number]['name'][];
}

export const isValidBuildingPolygon = (polygon: Feature<Polygon>, options?: IValidationOptions): TValidation => {
  return validatePolygon(polygon, options);
};

export const isValidBuildingFootprint = (footprint: TFootprint, options?: IValidationOptions): TValidation => {
  if (footprint.length < 3)
    return {
      isValid: false,
      errors: ['notEnoughPoints'],
    };

  const polygonCoordinates = [...footprint];
  if (isFootprintClosed(footprint)) {
    polygonCoordinates.push(footprint[0]);
  }

  const polygon = turf.polygon([polygonCoordinates]);

  return validatePolygon(polygon, options);
};
