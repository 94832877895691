import { XY } from 'types/location/coordinates';

export class SelectionCanvas {
  private canvas: HTMLCanvasElement;
  private ctx: CanvasRenderingContext2D;

  constructor(container: HTMLElement) {
    this.canvas = document.createElement('canvas');
    this.setupCanvas(container);
    this.ctx = this.canvas.getContext('2d')!;
    this.updateCanvasSize(container);

    window.addEventListener('resize', () => this.updateCanvasSize(container));
  }

  private setupCanvas(container: HTMLElement) {
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '0';
    this.canvas.style.left = '0';
    this.canvas.style.pointerEvents = 'none';
    this.canvas.style.zIndex = '1';
    container.parentElement?.appendChild(this.canvas);
  }

  private updateCanvasSize(container: HTMLElement) {
    const rect = container.getBoundingClientRect();
    const pixelRatio = window.devicePixelRatio || 1;

    this.canvas.width = rect.width * pixelRatio;
    this.canvas.height = rect.height * pixelRatio;
    this.canvas.style.width = `${rect.width}px`;
    this.canvas.style.height = `${rect.height}px`;
    this.ctx.scale(pixelRatio, pixelRatio);
  }

  drawRectangle(corners: XY[]) {
    this.clear();
    if (corners.length < 2) return;

    this.ctx.save();

    // Draw filled rectangle if selection is complete
    if (corners.length === 4) {
      this.drawFilledRectangle(corners);
    }

    // Draw outline
    this.drawRectangleOutline(corners);

    this.ctx.restore();
  }

  private drawFilledRectangle(corners: XY[]) {
    this.ctx.fillStyle = 'rgba(0, 200, 255, 0.2)';
    this.ctx.beginPath();
    this.ctx.moveTo(corners[0][0], corners[0][1]);
    corners.slice(1).forEach((point) => {
      this.ctx.lineTo(point[0], point[1]);
    });
    this.ctx.closePath();
    this.ctx.fill();
  }

  private drawRectangleOutline(corners: XY[]) {
    // Draw the outline
    this.ctx.strokeStyle = '#00C8FF';
    this.ctx.lineWidth = 2;
    this.ctx.beginPath();
    this.ctx.moveTo(corners[0][0], corners[0][1]);
    corners.slice(1).forEach((point) => {
      this.ctx.lineTo(point[0], point[1]);
    });
    if (corners.length === 4) {
      this.ctx.closePath();
    }
    this.ctx.stroke();

    // Draw corner points
    corners.forEach((point, index) => {
      this.ctx.beginPath();
      this.ctx.fillStyle = index === 3 ? 'rgba(0, 200, 255, 0.4)' : '#00C8FF';
      this.ctx.arc(point[0], point[1], 4, 0, Math.PI * 2);
      this.ctx.fill();
    });
  }

  clear() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  destroy() {
    window.removeEventListener('resize', () => this.updateCanvasSize(this.canvas.parentElement!));
    this.canvas.remove();
  }
}
