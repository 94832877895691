import { ThreeEvent } from '@react-three/fiber';
import { useState } from 'react';
import { useSnapshot } from 'valtio';
import store from '../store';
import { isMouseOutOfBounds } from '../utils/mouse';

interface useHoveredProps {
  disabled?: boolean;
  overHandler?: () => void;
  outHandler?: () => void;
}

const useHovered = (props?: useHoveredProps) => {
  const { disabled, overHandler, outHandler } = props ?? {};
  const [hovered, setHovered] = useState(false);

  const { disableClickInteraction } = useSnapshot(store.general);

  const onPointerOver = (e: ThreeEvent<MouseEvent>) => {
    if (isMouseOutOfBounds(e.point) || store.mouse.isMouseDown || disableClickInteraction) return;

    e.stopPropagation();
    setHovered(true);
    overHandler?.();
  };

  const onPointerOut = (e: ThreeEvent<MouseEvent>) => {
    e.stopPropagation();
    setHovered(false);
    outHandler?.();
  };

  return disabled
    ? {
        hovered: false,
        onPointerOut: undefined,
        onPointerOver: undefined,
      }
    : {
        hovered,
        onPointerOver,
        onPointerOut,
      };
};

export default useHovered;
