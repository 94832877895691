import { EObjectType } from 'cityview';
import { cloneDeep, groupBy, last } from 'lodash-es';
import * as THREE from 'three';
import { EFaceType, TFace } from 'types/building/Face';
import { IFaceSegment } from 'types/building/FaceSegment';
import { EFloorShape, EFloorType, IFloor, TFloors } from 'types/building/Floor';
import { TCantonAbbr } from 'types/location/cantons';
import { IBuildingUnit } from '../../../types/building/BuildingUsage';
import {
  calculateRoofFloorFootprint,
  calculateRoofFloorFootprintById,
  footprintToWalls,
  setBuildingFormChanged,
  updateFloorRoofFaces,
  updateFloorRoofFacesById,
} from '../../components/Building/utils';
import { updateBuildingBuffersById } from '../../components/BuildingBuffer/utils';
import { getDimensionsFromFootprint } from '../../hooks/useBuildingDimensions';
import { bufferFootprint } from '../../utils/footprint';

import { getBuilding } from '../selectors/building';
import { getFloorById, getFloorFootprint, getFloorsById } from '../selectors/floor';
import { removeBuildingsByIds } from './building';
import { deselectType } from './selections';

const { generateUUID } = THREE.MathUtils;

const DEFAULT_BASE_HEIGHT = 1;
const DEFAULT_LIVING_HEIGHT = 1.5;
const DEFAULT_HEIGHT = 3;

export const getFaceCopy = (face: TFace): TFace => {
  const clonedFace = cloneDeep(face);

  return {
    ...clonedFace,
    id: generateUUID(),
    segments: clonedFace.segments.map((segment) => getSegmentCopy(segment)),
  };
};

export const getSegmentCopy = (segment: IFaceSegment): IFaceSegment => {
  const clonedSegment = cloneDeep(segment);

  return {
    ...clonedSegment,
    id: generateUUID(),
  };
};

export const changeFloorShape = (buildingId: string, floorId: string, newShape: EFloorShape, canton?: TCantonAbbr) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const floor = floors[floorId];
  if (!floor) return;

  const maxOrder = Math.max(...Object.values(floors).map((f) => f.properties.order));
  const canHaveSlopedRoof = floor.properties.order === maxOrder;
  const actualNewShape = canHaveSlopedRoof ? newShape : EFloorShape.STANDARD;

  floor.properties.shape = actualNewShape;
  floor.properties.baseHeight = actualNewShape === EFloorShape.ROOF ? DEFAULT_BASE_HEIGHT : undefined;
  floor.properties.livingHeight = actualNewShape === EFloorShape.ROOF ? DEFAULT_LIVING_HEIGHT : undefined;
  floor.properties.height = DEFAULT_HEIGHT;
  setBuildingFormChanged(buildingId, true);

  const footprint = calculateRoofFloorFootprintById(buildingId, floor, canton);
  if (floor.properties.type === EFloorType.DG) {
    if (!footprint) return;
    const height = floor.properties.shape === EFloorShape.ROOF ? floor.properties.baseHeight! : floor.properties.height;
    const walls = footprintToWalls(footprint, floor.properties.z, height, buildingId, floorId);
    floor.faces = [...walls];
    if (floor.properties.shape === EFloorShape.ROOF) {
      updateFloorRoofFaces(floor, buildingId);
    } else {
      // floor.faces.push(generateHorizontalSurfaceFace(floor, EFaceType.FLOOR, buildingId));
      // floor.faces.push(generateHorizontalSurfaceFace(floor, EFaceType.ROOF, buildingId));
    }
  }
};

export const changeFloorType = (buildingId: string, floorId: string, newType: EFloorType) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const floor = floors[floorId];
  if (!floor) return;

  const isUGtoVG = floor.properties.type === EFloorType.UG && newType === EFloorType.VG;
  floor.properties.type = newType;

  if (isUGtoVG) {
    Object.values(floors).forEach((floor) => {
      floor.properties.order += 1;
    });
  }
};

export const setFloorRoofFaces = (floor: IFloor, roofFaces: TFace[]) => {
  const facesWithoutRoofFaces = floor.faces.filter((face) => face.type !== EFaceType.ROOF);
  floor.faces = [...facesWithoutRoofFaces, ...roofFaces];
};

const getFloorGroups = (floors: IFloor[]) => {
  const groups = groupBy(Object.values(floors), ({ properties }) => properties.type);

  return {
    roofFloors: groups[EFloorType.DG] || [],
    mainFloors: groups[EFloorType.VG] || [],
    undergroundFloors: groups[EFloorType.UG] || [],
  };
};

export const addFloor = (buildingId: string, floorType: EFloorType = EFloorType.VG) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const allFloors = Object.values(floors).sort(
    (floorOne, floorTwo) => floorTwo.properties.order - floorOne.properties.order,
  );

  const floorGroups = getFloorGroups(allFloors);
  const { roofFloors, mainFloors, undergroundFloors } = cloneDeep(floorGroups);

  let cloneFloor: IFloor;
  switch (floorType) {
    case EFloorType.DG: {
      const bottomRoofFloor = last(roofFloors);

      if (bottomRoofFloor) {
        cloneFloor = bottomRoofFloor;
      } else {
        cloneFloor = [...mainFloors, ...undergroundFloors][0];
        cloneFloor.properties.order += 1;
        const footprint = calculateRoofFloorFootprint(cloneFloor);
        const height =
          cloneFloor.properties.shape === EFloorShape.ROOF
            ? cloneFloor.properties.baseHeight!
            : cloneFloor.properties.height;

        const walls = footprintToWalls(footprint, cloneFloor.properties.z, height, buildingId, cloneFloor.id);
        cloneFloor.faces = [...cloneFloor.faces.filter((face) => face.type !== EFaceType.WALL), ...walls];
      }
      break;
    }

    case EFloorType.VG: {
      const topMainFloor = mainFloors[0];

      if (topMainFloor) {
        cloneFloor = topMainFloor;
        cloneFloor.properties.order += 1;
      } else {
        const bottomRoofFloor = last(roofFloors);

        if (bottomRoofFloor) {
          cloneFloor = bottomRoofFloor;
        } else {
          cloneFloor = undergroundFloors[0];
          cloneFloor.properties.order += 1;
        }
      }
      break;
    }

    case EFloorType.UG: {
      cloneFloor = last([...roofFloors, ...mainFloors, ...undergroundFloors]) as IFloor;
      cloneFloor.properties.order -= 1;
      break;
    }
  }

  const newFloorId = generateUUID();
  const newFloor: IFloor = {
    id: newFloorId,
    buildingId,
    faces: cloneFloor.faces.map((face) => getFaceCopy(face)),
    properties: {
      order: cloneFloor.properties.order,
      height: cloneFloor.properties.height,
      type: floorType,
      shape: EFloorShape.STANDARD,
      corridorArea: 0,
      corridorWidth: 0,
      z:
        cloneFloor.properties.type === EFloorType.DG && floorType !== EFloorType.UG
          ? cloneFloor.properties.z
          : floorType === EFloorType.UG
            ? cloneFloor.properties.z - cloneFloor.properties.height
            : cloneFloor.properties.z + cloneFloor.properties.height,
    },
    usageType: cloneFloor.usageType,
    usage: cloneFloor.usage,
    units: cloneFloorUnits(cloneFloor, newFloorId),
  };

  newFloor.faces.forEach((face) => {
    if (face.type === EFaceType.WALL) {
      face.coordinates[0][2] = newFloor.properties.z;
      face.coordinates[1][2] = newFloor.properties.z;
      face.coordinates[2][2] = newFloor.properties.z + newFloor.properties.height;
      face.coordinates[3][2] = newFloor.properties.z + newFloor.properties.height;

      face.segments.forEach((segment) => {
        segment.corners[0].coordinates[2] = newFloor.properties.z;
        segment.corners[1].coordinates[2] = newFloor.properties.z;
        segment.corners[2].coordinates[2] = newFloor.properties.z + newFloor.properties.height;
        segment.corners[3].coordinates[2] = newFloor.properties.z + newFloor.properties.height;
      });
    } else if (
      face.type === EFaceType.CEILING ||
      (face.type === EFaceType.ROOF && cloneFloor.properties.shape !== EFloorShape.ROOF)
    ) {
      const newZ = newFloor.properties.z + newFloor.properties.height;
      face.coordinates[0][2] = newZ;
      face.coordinates[1][2] = newZ;
      face.coordinates[2][2] = newZ;
      face.coordinates[3][2] = newZ;

      face.segments.forEach((segment) => {
        segment.corners[0].coordinates[2] = newZ;
        segment.corners[1].coordinates[2] = newZ;
        segment.corners[2].coordinates[2] = newZ;
        segment.corners[3].coordinates[2] = newZ;
      });
    } else if (face.type === EFaceType.ROOF && cloneFloor.properties.shape === EFloorShape.ROOF) {
      const zDIff = newFloor.properties.z - cloneFloor.properties.z;
      face.coordinates[0][2] += zDIff;
      face.coordinates[1][2] += zDIff;
      face.coordinates[2][2] += zDIff;
      face.coordinates[3][2] += zDIff;

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] += zDIff;
        });
      });
    } else if (face.type === EFaceType.FLOOR) {
      face.coordinates[0][2] = newFloor.properties.z;
      face.coordinates[1][2] = newFloor.properties.z;
      face.coordinates[2][2] = newFloor.properties.z;
      face.coordinates[3][2] = newFloor.properties.z;

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] = newFloor.properties.z;
        });
      });
    }
  });

  addFloorToBuildingFloors(floors, newFloor);

  // // Update roof geometry
  updateFloorRoofFacesById(buildingId);
};

export const duplicateFloor = (buildingId: string, floorId: string) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const floor: IFloor = floors[floorId];
  if (!floor) return;

  removeMaisonettesFromFloorUnits(floor, floors);
  const cloneFloor: IFloor = cloneDeep(floor);
  switch (floor.properties.type) {
    case EFloorType.DG:
      cloneFloor.properties.order += 1;
      break;
    case EFloorType.VG:
      cloneFloor.properties.order += 1;
      break;
    case EFloorType.UG:
      cloneFloor.properties.order -= 1;
      break;
  }

  const newFloorId = generateUUID();

  const newFloor: IFloor = {
    id: newFloorId,
    buildingId,
    faces: cloneFloor.faces.map((face) => getFaceCopy(face)),
    properties: {
      ...cloneFloor.properties,
      shape: EFloorShape.STANDARD,
      z:
        cloneFloor.properties.type === EFloorType.UG
          ? cloneFloor.properties.z - cloneFloor.properties.height
          : cloneFloor.properties.z + cloneFloor.properties.height,
    },
    usageType: cloneFloor.usageType,
    usage: cloneFloor.usage,
    units: cloneFloorUnits(cloneFloor, newFloorId),
  };

  newFloor.faces.forEach((face) => {
    if (face.type === EFaceType.WALL) {
      face.coordinates[0][2] = newFloor.properties.z;
      face.coordinates[1][2] = newFloor.properties.z;
      face.coordinates[2][2] = newFloor.properties.z + newFloor.properties.height;
      face.coordinates[3][2] = newFloor.properties.z + newFloor.properties.height;

      face.segments.forEach((segment) => {
        segment.corners[0].coordinates[2] = newFloor.properties.z;
        segment.corners[1].coordinates[2] = newFloor.properties.z;
        segment.corners[2].coordinates[2] = newFloor.properties.z + newFloor.properties.height;
        segment.corners[3].coordinates[2] = newFloor.properties.z + newFloor.properties.height;
      });
    } else if (
      face.type === EFaceType.CEILING ||
      (face.type === EFaceType.ROOF && floor.properties.shape !== EFloorShape.ROOF)
    ) {
      face.coordinates.forEach((coordinate) => {
        coordinate[2] += newFloor.properties.height;
      });

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] += newFloor.properties.height;
        });
      });
    } else if (face.type === EFaceType.ROOF && floor.properties.shape === EFloorShape.ROOF) {
      const zDIff = newFloor.properties.z - floor.properties.z;
      face.coordinates[0][2] += zDIff;
      face.coordinates[1][2] += zDIff;
      face.coordinates[2][2] += zDIff;
      face.coordinates[3][2] += zDIff;

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] += zDIff;
        });
      });
    } else if (face.type === EFaceType.FLOOR) {
      face.coordinates[0][2] = newFloor.properties.z;
      face.coordinates[1][2] = newFloor.properties.z;
      face.coordinates[2][2] = newFloor.properties.z;
      face.coordinates[3][2] = newFloor.properties.z;

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] = newFloor.properties.z;
        });
      });
    }
  });

  addFloorToBuildingFloors(floors, newFloor, floor.copyId || floor.id);

  // Update roof geometry
  updateFloorRoofFacesById(buildingId);
};

const removeMaisonettesFromFloorUnits = (floor: IFloor, floors: TFloors) => {
  if (!(floor.units && floor.units.length > 0)) return;
  if (floor.properties.type === EFloorType.DG || floor.properties.type === EFloorType.VG) {
    const unitsToRemove = floor.units.filter((unit) => {
      if (unit.floorId === floor.id) return false;
      return floors[unit.floorId].properties.order > floor.properties.order;
    });
    floor.units = floor.units.filter((unit) => !unitsToRemove.includes(unit));
  } else {
    const unitsToRemove = floor.units.filter((unit) => {
      if (unit.floorId === floor.id) return false;
      return floors[unit.floorId].properties.order < floor.properties.order;
    });
    floor.units = floor.units.filter((unit) => !unitsToRemove.includes(unit));
  }
};

export const cloneFloorUnits = (floor: IFloor, newFloorId: string) => {
  return floor.units.filter((unit) => !unit.parentId).map((unit) => cloneUnit(newFloorId, unit));
};

const cloneUnit = (floorId: string, unit: IBuildingUnit, parentId?: string): IBuildingUnit => {
  const unitId = generateUUID();
  return {
    ...unit,
    id: unitId,
    floorId: floorId,
    parentId: parentId,
    children: unit.children?.map((child) => cloneUnit(floorId, child, unitId)),
  };
};

const addFloorToBuildingFloors = (floors: TFloors, floor: IFloor, copyId: string | undefined = undefined) => {
  const {
    properties: { height },
  } = floor;

  const newFloorId = generateUUID();

  const newFloor: IFloor = {
    id: newFloorId,
    buildingId: floor.buildingId,
    faces: floor.faces.map((face) => getFaceCopy(face)),
    properties: {
      ...floor.properties,
    },
    usageType: floor.usageType,
    usage: { ...floor.usage, id: generateUUID() },
    units: cloneFloorUnits(floor, newFloorId),
    copyId: copyId,
  };

  const floorsArray = Object.values(floors);

  floorsArray.forEach((floor) => {
    const sign =
      newFloor.properties.type === EFloorType.UG && floor.properties.order <= newFloor.properties.order ? -1 : 1;

    if (
      (newFloor.properties.type === EFloorType.UG && floor.properties.order <= newFloor.properties.order) ||
      (newFloor.properties.type !== EFloorType.UG && floor.properties.order >= newFloor.properties.order)
    ) {
      floor.properties.order += sign;
      floor.properties.z += height * sign;
      floor.faces.forEach((face) => {
        face.coordinates = face.coordinates.map((coordinate) => {
          coordinate[2] += height * sign;
          return coordinate;
        });

        face.segments.forEach((segment) => {
          segment.corners.forEach((corner) => {
            corner.coordinates[2] += height * sign;
          });
        });
      });
    }
  });

  floors[newFloorId] = newFloor;

  const sortedFloors = Object.values(floors).sort(
    (floorOne, floorTwo) => floorOne.properties.order - floorTwo.properties.order,
  );
  sortedFloors.forEach((floor, index) => {
    floor.properties.order = index + 1;
    floors[floor.id] = floor;
  });
};

export const removeFloor = (buildingId: string, floorId: string) => {
  const floors = getFloorsById(buildingId);
  if (!floors) return;

  const deletedFloor = floors[floorId];
  if (!deletedFloor) return;

  const { order: deletedFloorOrder, height, type: deletedFloorType } = deletedFloor.properties;

  delete floors[floorId];

  const floorsArray = Object.values(floors);

  if (floorsArray.length) {
    floorsArray.forEach((floor) => {
      const deletedFloorHeight =
        floor.properties.order > deletedFloorOrder && deletedFloorType !== EFloorType.UG ? -height : height;

      if (
        (deletedFloorType === EFloorType.UG &&
          floor.properties.type === EFloorType.UG &&
          floor.properties.order < deletedFloorOrder) ||
        (deletedFloorType !== EFloorType.UG && floor.properties.order > deletedFloorOrder)
      ) {
        floor.properties.z += deletedFloorHeight;
        floor.faces.forEach((face) => {
          face.coordinates = face.coordinates.map((coordinate) => {
            coordinate[2] += deletedFloorHeight;
            return coordinate;
          });

          face.segments.forEach((segment) => {
            segment.corners.forEach((corner) => {
              corner.coordinates[2] += deletedFloorHeight;
            });
          });
        });
      }
    });

    const sortedFloors = floorsArray.sort(
      (floorOne, floorTwo) => floorOne.properties.order - floorTwo.properties.order,
    );
    sortedFloors.forEach((floor, index) => {
      floor.properties.order = index + 1;
      floors[floor.id] = floor;
    });

    updateFloorRoofFacesById(buildingId);
    setBuildingFormChanged(buildingId, true);
  } else {
    removeBuildingsByIds([buildingId]);
  }

  deselectType(EObjectType.FLOOR);
  deselectType(EObjectType.TRANSFORM_WALL);
};

export const setFloorHeight = (buildingId: string, floorId: string, newHeight: number) => {
  const floors = getFloorsById(buildingId);
  if (!floors) return;

  const floorToChange = floors[floorId];
  const { order, height } = floorToChange.properties;
  const heightDifference = newHeight - height;
  floorToChange.properties.height = newHeight;
  Object.values(floors).forEach((floor) => {
    if (floor.properties.order >= order) {
      if (floor.properties.order > order) floor.properties.z += heightDifference;
      if (floor.properties.shape === EFloorShape.ROOF) return;
      recalculateFloorFaceCoordinates(floor, heightDifference);
    }
  });

  // Update roof geometry
  updateFloorRoofFacesById(buildingId, true);
  setBuildingFormChanged(buildingId, true);
};

export const recalculateFloorFaceCoordinates = (floor: IFloor, zDiff: number) => {
  floor.faces.forEach((face) => {
    if (
      face.type === EFaceType.WALL &&
      floor.properties.type === EFloorType.DG &&
      floor.properties.shape === EFloorShape.ROOF
    ) {
      face.coordinates[0][2] = floor.properties.z;
      face.coordinates[1][2] = floor.properties.z;
      face.coordinates[2][2] = floor.properties.z + floor.properties.baseHeight!;
      face.coordinates[3][2] = floor.properties.z + floor.properties.baseHeight!;

      face.segments.forEach((segment) => {
        segment.corners[0].coordinates[2] = floor.properties.z;
        segment.corners[1].coordinates[2] = floor.properties.z;
        segment.corners[2].coordinates[2] = floor.properties.z + floor.properties.baseHeight!;
        segment.corners[3].coordinates[2] = floor.properties.z + floor.properties.baseHeight!;
      });
    } else if (face.type === EFaceType.WALL) {
      face.coordinates[0][2] = floor.properties.z;
      face.coordinates[1][2] = floor.properties.z;
      face.coordinates[2][2] = floor.properties.z + floor.properties.height;
      face.coordinates[3][2] = floor.properties.z + floor.properties.height;

      face.segments.forEach((segment) => {
        segment.corners[0].coordinates[2] = floor.properties.z;
        segment.corners[1].coordinates[2] = floor.properties.z;
        segment.corners[2].coordinates[2] = floor.properties.z + floor.properties.height;
        segment.corners[3].coordinates[2] = floor.properties.z + floor.properties.height;

        segment.properties.segmentHeight = floor.properties.height;
        segment.properties.wallProperties.center.height =
          floor.properties.height -
          segment.properties.wallProperties.top.height -
          segment.properties.wallProperties.bottom.height;
      });
    } else if (face.type === EFaceType.ROOF && floor.properties.shape === EFloorShape.ROOF) {
      face.coordinates.forEach((coordinate) => (coordinate[2] += zDiff));

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] += zDiff;
        });
      });
    } else if (face.type === EFaceType.CEILING || face.type === EFaceType.ROOF) {
      face.coordinates.forEach((coordinate) => (coordinate[2] = floor.properties.z + floor.properties.height));

      face.segments.forEach((segment) => {
        segment.corners.forEach((corner) => {
          corner.coordinates[2] = floor.properties.z + floor.properties.height;
        });
      });
    }
  });
};

export const setFloorBaseHeight = (buildingId: string, floorId: string, baseHeight: number) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const floor = floors[floorId];
  if (!floor) return;

  if (floor.properties.shape !== EFloorShape.ROOF) {
    floor.properties.baseHeight = undefined;
  } else {
    const heightDifference = floor.properties.baseHeight ? baseHeight - floor.properties.baseHeight : baseHeight;
    floor.properties.baseHeight = baseHeight;
    recalculateFloorFaceCoordinates(floor, heightDifference);

    // Update roof geometry
    updateFloorRoofFacesById(buildingId);
  }
};

export const setFloorLivingHeight = (buildingId: string, floorId: string, livingHeight: number) => {
  const building = getBuilding(buildingId);
  if (!building) return;

  const { floors } = building;
  if (!floors) return;

  const floor = floors[floorId];
  if (!floor) return;

  floor.properties.livingHeight = floor.properties.shape === EFloorShape.ROOF ? livingHeight : undefined;
};

export const setFloorWalls = (buildingId: string, floorId: string, walls: TFace[]) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;
  const floorFacesWithoutWallFaces = floor.faces.filter((face) => face.type !== EFaceType.WALL);
  floor.faces = [...floorFacesWithoutWallFaces, ...walls];
  deselectType(EObjectType.TRANSFORM_WALL);
  deselectType(EObjectType.TRANSFORM_CORNER);
};

export const offsetFloorFootprint = (buildingId: string, floorId: string, buffer: number) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  const floorFootprint = getFloorFootprint(floor);

  const bufferedFloorFootprint = bufferFootprint(floorFootprint, buffer, true);

  const walls = footprintToWalls(
    bufferedFloorFootprint,
    floor.properties.z,
    floor.properties.height,
    buildingId,
    floorId,
  );

  setFloorWalls(buildingId, floorId, walls);
  updateFloorRoofFacesById(buildingId);
  setBuildingFormChanged(buildingId, true);
  updateBuildingBuffersById(buildingId);
};

export const offsetFloorFootprintByPercent = (buildingId: string, floorId: string, bufferPercent: number) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) return;

  const floorFootprint = getFloorFootprint(floor);
  const { area } = getDimensionsFromFootprint(floorFootprint);
  const targetArea = area * (bufferPercent / 100);

  let buffer = -10;
  let bufferStep = 5;
  let bufferedFootprintArea = 0;
  let decreaseFlag = false;
  let increaseFlag = false;
  let errorFlag = false;
  let counter = 0;
  let bufferedFloorFootprint = undefined;

  const MAX_ITERATIONS = 50;
  const AREA_TOLERANCE = 1;

  while (Math.abs(bufferedFootprintArea - targetArea) > AREA_TOLERANCE && counter < MAX_ITERATIONS) {
    try {
      bufferedFloorFootprint = bufferFootprint(floorFootprint, buffer, true);
      bufferedFootprintArea = getDimensionsFromFootprint(bufferedFloorFootprint).area;
      if (targetArea > bufferedFootprintArea) {
        buffer = buffer + bufferStep;
        decreaseFlag = true;
      } else {
        buffer = buffer - bufferStep;
        increaseFlag = true;
      }
      if (decreaseFlag && increaseFlag) {
        bufferStep = bufferStep / 2;
        decreaseFlag = false;
        increaseFlag = false;
      }
      counter++;
      errorFlag = false;
    } catch (e) {
      buffer = buffer + bufferStep;
      bufferStep = bufferStep / 2;
      counter++;
      errorFlag = true;
    }
  }
  if (!bufferedFloorFootprint) return;

  if (errorFlag || Math.abs(bufferedFootprintArea - targetArea) > AREA_TOLERANCE)
    throw new Error('offsetFloorFootprintByPercent: Could not calculate buffer.');

  const walls = footprintToWalls(
    bufferedFloorFootprint,
    floor.properties.z,
    floor.properties.height,
    buildingId,
    floorId,
  );

  setFloorWalls(buildingId, floorId, walls);
  updateFloorRoofFacesById(buildingId);
  setBuildingFormChanged(buildingId, true);
  updateBuildingBuffersById(buildingId);
};
