import { booleanPointInPolygon, point, polygon } from '@turf/turf';
import { IPointLike, Point, SweepContext } from 'poly2tri';
import { useMemo } from 'react';
import * as THREE from 'three';
import { XY, XYZ } from 'types/location/coordinates';
import { useSnapshot } from 'valtio';
import { findGroundHeightEstimation } from '../components/Terrain/utils';
import { terrainStore } from '../store';

interface UsePlotTerrainGeometryProps {
  plotCoordinates: XY[];
}

const usePlotTerrainGeometry = (props: UsePlotTerrainGeometryProps) => {
  const { plotCoordinates } = props;
  const { terrainData } = useSnapshot(terrainStore.value.originalTerrain);

  return useMemo(() => {
    const plotPolygon = polygon([plotCoordinates]);

    const plotEdgePoints: XYZ[] = plotCoordinates.map((coords) => [
      coords[0],
      coords[1],
      terrainData ? findGroundHeightEstimation([coords[0], coords[1]], terrainData) : 0,
    ]);

    const plotInnerPoints: XYZ[] = [];
    terrainData?.forEach((row) => {
      row.forEach((coords) => {
        const rowPoint = point([coords[0], coords[1]]);
        if (booleanPointInPolygon(rowPoint, plotPolygon)) plotInnerPoints.push([coords[0], coords[1], coords[2]]);
      });
    });

    const totalPoints = [...plotEdgePoints, ...plotInnerPoints];

    // Convert points to poly2tri.Point
    const contour = plotEdgePoints.slice(1).map((p) => new Point(p[0], p[1]));

    // Create a sweep context with the contour
    const sweepContext = new SweepContext(contour);

    // Add the rest of the points (inner points)
    plotInnerPoints.forEach((p) => {
      sweepContext.addPoint(new Point(p[0], p[1]));
    });

    // Perform the triangulation
    sweepContext.triangulate();

    // Get the triangles
    const triangles = sweepContext.getTriangles();

    // Prepare vertices and indices for Three.js geometry
    const vertices: number[] = [];
    const indices: number[] = [];
    const pointIndexMap = new Map<string, number>();

    const addVertex = (p: IPointLike): number => {
      const key = `${p.x},${p.y}`;
      if (!pointIndexMap.has(key)) {
        // Find the original 3D point
        const originalPoint = totalPoints.find((pt) => pt[0] === p.x && pt[1] === p.y);
        if (originalPoint) {
          pointIndexMap.set(key, vertices.length / 3);
          vertices.push(originalPoint[0], originalPoint[1], originalPoint[2]);
        }
      }
      return pointIndexMap.get(key)!;
    };

    triangles.forEach((triangle) => {
      const a = addVertex(triangle.getPoint(0));
      const b = addVertex(triangle.getPoint(1));
      const c = addVertex(triangle.getPoint(2));
      indices.push(a, b, c);
    });

    // Create a BufferGeometry to use with Three.js
    const geometry = new THREE.BufferGeometry();
    geometry.setAttribute('position', new THREE.BufferAttribute(new Float32Array(vertices), 3));
    geometry.setIndex(indices);

    return {
      geometry,
      plotInnerPoints,
      plotEdgePoints,
    };
  }, [plotCoordinates, terrainData]);
};

export default usePlotTerrainGeometry;
