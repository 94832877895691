import { EBuildingUsageType, IBuildingUnit } from 'types/building/BuildingUsage';
import { IFloorUsage } from 'types/building/FloorUsage';
import { TFace } from './Face';

export type TFloors = Record<string, IFloor>;

export interface IFloor {
  id: string;
  buildingId: string;
  faces: TFace[];
  properties: IFloorProperties;
  usageType: EBuildingUsageType;
  units: IBuildingUnit[];
  usage: IFloorUsage;
  copyId?: string;
}

export enum EFloorShape {
  STANDARD = 'standard',
  ROOF = 'roof',
}

export enum ERoofShape {
  BOX = 'box',
  FLAT = 'flat',
  SLAB = 'slab',
}

export enum EFloorType {
  DG = 'DG',
  VG = 'VG',
  UG = 'UG',
}

export enum EBuildingSection {
  ATTIC = 'attic',
  MAIN = 'main',
  UNDERGROUND = 'underground',
}

export interface IFloorProperties {
  order: number;
  height: number;
  z: number;
  shape: EFloorShape;
  type: EFloorType;
  corridorArea: number;
  corridorWidth: number;
  color?: string;
  opacity?: number;
  baseHeight?: number;
  livingHeight?: number;
}

export type TFloorIncomes = Record<string, number>;
