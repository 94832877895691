import { XY } from 'types/location/coordinates';

export const addExtraVertices = (vertices: XY[], step: number): XY[] => {
  const newVertices: XY[] = [];
  for (let i = 0; i < vertices.length - 1; i += 1) {
    const c1 = vertices[i];
    const c2 = vertices[i + 1];
    const dist = Math.sqrt((c2[0] - c1[0]) ** 2 + (c2[1] - c1[1]) ** 2);
    const unitVec = [(c2[0] - c1[0]) / dist, (c2[1] - c1[1]) / dist];
    newVertices.push(c1);
    for (let n = 1; n < dist / step; n += 1) {
      const newCorner: XY = [c1[0] + unitVec[0] * (step * n), c1[1] + unitVec[1] * (step * n)];
      newVertices.push(newCorner);
    }
  }
  return newVertices;
};
