import { EObjectType } from 'cityview';
import { hasSelectedObjectsOfType, isOnlySelectedObjectOfType } from '../selectors/general';
import store from '../store';

enum ESelectionAction {
  SELECT,
  ADD,
  REPLACE,
  DESELECT,
  CLEAR,
}

export const selectObject = (ids: string | string[], type: EObjectType, replaceExisting = true) => {
  if (replaceExisting) deselectType(type);
  if (Array.isArray(ids)) {
    ids.forEach((id) => selectSingleObject(id, type, false));
  } else {
    selectSingleObject(ids, type, replaceExisting);
  }
};

const selectSingleObject = (id: string, type: EObjectType, replaceExisting: boolean) => {
  const isObjectSelected = !!store.selections.selectedObjects[id];
  const typeHasSelectedItems = hasSelectedObjectsOfType(type);

  if (isObjectSelected || isOnlySelectedObjectOfType(id, type)) return;

  if (replaceExisting && typeHasSelectedItems) {
    if (typeHasSelectedItems) clearObjectSelectionForType(type);
    triggerSelectionEffects(type, ESelectionAction.REPLACE);
  } else if (typeHasSelectedItems) {
    triggerSelectionEffects(type, ESelectionAction.ADD);
  } else {
    triggerSelectionEffects(type, ESelectionAction.SELECT);
  }

  store.selections.selectedObjects[id] = type;
};

const triggerBuildingSelectionEffects = (action: ESelectionAction) => {
  switch (action) {
    case ESelectionAction.SELECT:
      deselectType(EObjectType.PARKING_LOT);
      break;
    case ESelectionAction.REPLACE:
      clearBuildingChildrenSelection();
      break;
    case ESelectionAction.ADD:
      clearBuildingChildrenSelection();
      break;
    case ESelectionAction.CLEAR:
      clearBuildingChildrenSelection();
      break;
    case ESelectionAction.DESELECT:
      clearBuildingChildrenSelection();
      break;
  }
};

const triggerParkingSelectionEffects = (action: ESelectionAction) => {
  switch (action) {
    case ESelectionAction.SELECT:
      deselectType(EObjectType.BUILDING);
      break;
  }
};

const triggerSelectionEffects = (type: EObjectType, action: ESelectionAction) => {
  switch (type) {
    case EObjectType.BUILDING:
      triggerBuildingSelectionEffects(action);
      break;
    case EObjectType.PARKING_LOT:
      triggerParkingSelectionEffects(action);
      break;
    default:
      break;
  }
};

const deselectSingleObject = (id: string) => {
  if (store.selections.selectedObjects[id]) delete store.selections.selectedObjects[id];
};

export const deselectObject = (id: string | string[]) => {
  const type = Array.isArray(id) ? store.selections.selectedObjects[id[0]] : store.selections.selectedObjects[id];

  if (Array.isArray(id)) {
    id.forEach((i) => deselectSingleObject(i));
  } else {
    deselectSingleObject(id);
  }

  triggerSelectionEffects(type, ESelectionAction.DESELECT);
};

export const deselectType = (type: EObjectType) => {
  clearObjectSelectionForType(type);
  triggerSelectionEffects(type, ESelectionAction.CLEAR);
};

const clearObjectSelectionForType = (type: EObjectType) => {
  const { selectedObjects } = store.selections;
  for (const [id, objectType] of Object.entries(selectedObjects)) {
    if (objectType === type) {
      delete selectedObjects[id];
    }
  }
};

export const clearAllSelections = () => {
  for (const [id] of Object.entries(store.selections.selectedObjects)) {
    delete store.selections.selectedObjects[id];
  }
  triggerBuildingSelectionEffects(ESelectionAction.CLEAR);
  triggerParkingSelectionEffects(ESelectionAction.CLEAR);
};

export const clearBuildingChildrenSelection = () => {
  clearObjectSelectionForType(EObjectType.FLOOR);
  clearObjectSelectionForType(EObjectType.SEGMENT);
  clearObjectSelectionForType(EObjectType.TRANSFORM_CORNER);
  clearObjectSelectionForType(EObjectType.TRANSFORM_WALL);
  clearObjectSelectionForType(EObjectType.BUFFER);
};
