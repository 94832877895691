import UNITS from 'constants/units';
import React from 'react';

import styles from './TerrainPointTransformTooltip.module.css';

interface TerrainPointTransformTooltipContentProps {
  valueSpanRef: React.RefObject<HTMLSpanElement>;
}

const TerrainPointTransformTooltipContent = (props: TerrainPointTransformTooltipContentProps) => {
  const { valueSpanRef } = props;

  return (
    <div className={styles.WallTooltipContent}>
      <p className={styles.WallTooltipContentRow}>
        <span ref={valueSpanRef} style={{ marginRight: 2 }}>
          0
        </span>
        {UNITS.METER}
      </p>
    </div>
  );
};

export default TerrainPointTransformTooltipContent;
