import { EBuildingType } from 'types/building/Building';
import { EFloorType } from 'types/building/Floor';
import { Int } from 'utils/Int';

export type TBuildingsUsage = Record<string, IBuildingUsage>;

export enum ESia416Category {
  GF = 'GF',
  KF = 'KF',
  NGF = 'NGF',
  VF = 'VF',
  FF = 'FF',
  NF = 'NF',
  NNF = 'NNF',
  HNF = 'HNF',
}

export interface ISia416Areas extends IFloorAreas {
  VF: Int;
  VFInfo: IVfInfo;
  FF: Int;
  NF: Int;
  NNF: Int;
  HNF: Int;
  NNFused: Int;
  unusedArea: Int;
  unusedPercent: number;
  FFInfo: IFfInfo;
}

export interface IFloorAreas {
  GF: number;
  KF: number;
  KFInfo: IKfInfo;
  NGF: Int;
}

export interface IKfInfo {
  externalWallArea: number;
  loadBearingWallArea: number;
  unitsInternalKF: number;
  KF: number;
}

export interface IVfInfo {
  numberOfCores: Int;
  staircaseType: string;
  core: {
    VF: number;
    KF: number;
    area: number;
  };
  coreTotal: {
    VF: number;
    KF: number;
    area: number;
  };
  totalLiftArea: number;
  numberOfLiftsPerCore: number;
  liftArea: number;
  corridorArea: number;
  VF: Int;
}

export interface IFfInfo {
  numberOfBathrooms: number;
  FF: number;
}

export enum EBuildingUsageType {
  GENERAL = 'general',
  DETAILED = 'detailed',
}

export enum EChangeBuildingUsageTypeAction {
  DO_NOTHING = 'DO_NOTHING',
  UPDATE = 'UPDATE',
}

export interface IBuildingsUsage {
  totalGeneralAreas: IBuildingGeneralUsageResults;
  buildings: TBuildingsUsage;
}

export interface IBuildingUsage {
  id: string;
  type: EBuildingType;
  usageType: EBuildingUsageType;
  circulationSettings: IBuildingCirculationSettings;
  sia416Areas: Record<string, ISia416Areas>;
  [EBuildingUsageType.GENERAL]: IBuildingGeneralUsage;
  [EBuildingUsageType.DETAILED]: IBuildingDetailedUsage;
  core: IBuildingCore;
}

export interface IBuildingCore {
  type: EStaircaseType;
  nrOfCores: number;
  mainPlatformWidth: number;
  stairsWidth: number;
  handrailSpace: number;
  wallThickness: number;
  hasLift: boolean;
  liftVf: number;
  liftKf: number;
  floors: IBuildingFloorCore[];
}

export interface IBuildingFloorCore {
  id: string;
  floorOrder: number;
  floorType: EFloorType;
  clearHeight: number;
  nrOfPlatforms: number;
  nrOfSteps: number;
  stepHeight: number;
  stepWidth: number;
  internalLength: number;
  internalWidth: number;
  externalLength: number;
  externalWidth: number;
  vf: number;
  kf: number;
}

export type TFloorsGeneralUsage = Record<string, IBuildingFloorGeneralUsage>;
export type TFloorsGeneralUsageResults = Record<string, IBuildingFloorGeneralUsageResults>;

export interface IBuildingGeneralUsage {
  totalAreas: IBuildingGeneralUsageResults;
  floorsResults: TFloorsGeneralUsageResults;
  floors: TFloorsGeneralUsage;
}

export interface IBuildingDetailedUsage {
  totalHnfUnits: number;
  totalAreas: {
    nnf: number;
    hnf: number;
    ff: number;
    nf: number;
    kf: number;
    vf: number;
    vfWithoutCirculation: number;
    ngf: number;
    gnfNetArea: number;
  };
  buildingChartData: IBuildingUsageChartData;
  floorChartData: TFloorsChartData;
  floors: Record<string, IBuildingUnit[]>;
}

export type TFloorsChartData = Record<string, IBuildingUsageChartData>;

export interface IBuildingUsageChartData {
  max: number;
  unusedArea: number;
  data: IBuildingUsageChartItem[];
}

export interface IBuildingUsageChartItem {
  key: string;
  value: number;
}

export interface IBuildingUsageChartItemForGraph extends IBuildingUsageChartItem {
  title: string;
  color: string;
  unit: string;
}

export interface IBuildingFloorGeneralUsage {
  mainUsage: number;
  businessUsage: number;
  sideUsage: number;
}

export interface IBuildingGeneralUsageResults {
  mainUsageHnf: number;
  mainUsageNnf: number;
  businessUsageHnf: number;
  businessUsageNnf: number;
  sideUsageHnf: number;
  sideUsageNnf: number;
  mainUsageNf: number;
  businessUsageNf: number;
  sideUsageNf: number;
  mainUsageGnfNet: number;
  businessUsageGnfNet: number;
  sideUsageGnfNet: number;
  mainUsageShare: Int;
  businessUsageShare: Int;
  sideUsageShare: Int;
}

export interface IBuildingFloorGeneralUsageResults extends IBuildingGeneralUsageResults {
  floorTotalNnf: number;
  floorTotalHnf: number;
  floorTotalNf: number;
  floorTotalGnfNet: number;
  floorShare: Int;
}

export interface IBuildingUnit {
  id: string;
  floorId: string;
  type: EBuildingUnitType;
  area: Int;
  rentIncomePerM2: Int;
  saleIncomePerM2: Int;
  avgRentIncomePerM2: Int;
  avgSaleIncomePerM2: Int;
  fpreRentIncomePerM2: Int;
  fpreSaleIncomePerM2: Int;
  nnf: number;
  totalNnf: number;
  hnf: number;
  totalHnf: number;
  ff: number;
  totalFf: number;
  singleFloorTotalFf: number;
  nf: number;
  totalNf: number;
  totalNfOnFloor: number;
  totalNfWithSubUnitsFF: number;
  kf: number;
  totalKf: number;
  vf: number;
  ngf: number;
  totalNgf: number;
  gnfNetArea: number;
  totalGnfNetArea: number;
  corridorArea: Int;
  liftArea: Int;
  unitNumber: number;
  parentId?: string;
  children?: IBuildingUnit[];
  rentIncome: number;
  totalRentIncome: number;
  saleIncome: number;
  totalSaleIncome: number;
  hasSubContent: boolean;
  percentage: Int;
}

export interface IBuildingCirculationSettings {
  nrOfCores: Int;
  staircaseType: EStaircaseType;
  hasLift: boolean;
}

export enum EMaisonetteLocation {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export enum EBuildingUnitSize {
  S = 'S',
  M = 'M',
  L = 'L',
}

export enum EStaircaseType {
  A = 'A',
  B = 'B',
  C = 'C',
  NONE = 'None',
}

export enum EBuildingUnitCategory {
  LIVING = 'living',
  COMMERCIAL = 'commercial',
  SIDE_USAGE = 'sideUsage',
  SUB_USAGE = 'subUsage',
  CIRCULATION = 'circulation',
}

export enum ENotAccountableUsageCategory {
  NONE = 'none',
  DISTRIBUTION = 'distribution',
  TECHNICAL = 'technical',
  VEHICLE = 'vehicle',
  SIDE = 'side',
  STORAGE = 'storage',
  HOBBY = 'hobby',
  COMMON = 'common',
  WINTERGARDEN = 'wintergarden',
}

export enum EBuildingUnitType {
  // Main usage areas MFH
  STUDIO = 'studio',
  TWOANDAHALF = 'twoAndAHalf',
  THREEANDAHALF = 'threeAndAHalf',
  FOURANDAHALF = 'fourAndAHalf',
  FIVEANDAHALF = 'fiveAndAHalf',
  // Main usage areas COMMERCIAL
  OFFICE = 'office',
  GASTRONOMY = 'gastronomy', // Gastro
  SHOP = 'shop', // Verkauf
  WORKSHOP = 'workshop', // Werkstatt
  PRODUCTION = 'production', // Produktion
  //  Main usage areas EFH
  SINGLE_FAMILY_LIVING = 'singleFamilyLiving', // Wohnfläche
  SINGLE_FAMILY_STUDIO = 'singleFamilyStudio', // Einliegerwohnung
  SINGLE_FAMILY_TWOANDAHALF = 'singleFamilyTwoAndAHalf',
  SINGLE_FAMILY_THREEANDAHALF = 'singleFamilyThreeAndAHalf',
  SINGLE_FAMILY_BUREAU = 'singleFamilyBureau', // Büro
  SINGLE_FAMILY_WORKSHOP = 'singleFamilyWorkshop', // Atelier
  // Maisonette
  MAISONETTE = 'maisonette',
  // Side usage areas
  SIDE_COMMON_ROOM = 'sideCommonRoom', // Gemeinschaftsraum
  SIDE_BUILDING_SERVICE = 'sideBuildingService',
  SIDE_STORAGE = 'sideStorage', // Lagerraum
  SIDE_LAUNDRY = 'sideLaundry', //
  SIDE_BICYCLE = 'sideBicycle', //
  SIDE_ENTRANCE_HALL = 'sideEntranceHall', // Eingangsbereich
  SIDE_STROLLER_ROOM = 'sideStrollerRoom', // Kinderwagenraum
  SIDE_HOBBY_ROOM = 'sideHobbyRoom', // Hobbyraum
  SIDE_HOUSEKEEPING = 'sideHousekeeping', // Hauswartung
  SIDE_STORAGE_FACILITY = 'sideStorageFacility', // Lagerraum
  SIDE_CLEANING_ROOM = 'sideCleaningRoom', // Putzraum
  // Sub usage areas
  SUB_STORAGE_ROOM = 'subStorageRoom', // Abstellraum
  SUB_HOBBY_ROOM = 'subHobbyRoom', // Hobbyraum
  SUB_WINTERGARDEN = 'subWintergarden', // Wintergarten
  SUB_TECHNICAL_ROOM = 'subTechnicalRoom', // Technikraum
  SUB_CLEANING_ROOM = 'subCleaningRoom', // Putzraum
  SUB_BIKE_STORAGE = 'subBikeStorage', // Veloraum
  SUB_LAUNDRY = 'subLaundry', // Waschküche
  SUB_STORAGE_FACILITY = 'subStorageFacility', // Lagerraum
  // Circulation
  CIRCULATION = 'circulation',
}
