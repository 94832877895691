import cloneDeep from 'lodash/cloneDeep';
import { generateUUID } from 'three/src/math/MathUtils';
import { EFaceType, IWallFace } from 'types/building/Face';
import { createFace, setBuildingFormChanged } from '../../components/Building/utils';
import { extrudeFootprintWall } from '../../utils/footprint';
import { isValidBuildingFootprint } from '../../utils/footprintValidation';
import { getWallGeometricParameters } from '../../utils/geometry';

import { getFloorById, getFloorFootprint } from '../selectors/floor';
import { getBuildingFloorWall } from '../selectors/wall';
import { removeFloorFace } from './face';
import { setFloorWalls } from './floor';

export const extrudeWall = (buildingId: string, floorId: string, wallId: string) => {
  const floor = getFloorById(buildingId, floorId);
  if (!floor) {
    console.warn('extrudeWall: floor not found');
    return;
  }

  const walls = floor.faces.filter((face) => face.type === EFaceType.WALL);
  if (!walls.length) {
    console.warn('extrudeWall: walls not found');
    return;
  }

  const { properties } = floor;
  const { z, height } = properties;

  const wallToExtrude = getBuildingFloorWall(floor, wallId);
  if (!wallToExtrude) {
    console.warn('extrudeWall: wallToExtrude not found');
    return;
  }

  const { normal } = getWallGeometricParameters(wallToExtrude.coordinates, z, height);
  const extrusionLength = 2.5;
  const extrusion: [number, number] = [extrusionLength * normal[0], extrusionLength * normal[1]];

  const wallIndex = wallToExtrude.order - 1;
  const footprint = getFloorFootprint(floor);
  const newFootprint = extrudeFootprintWall(footprint, wallIndex, extrusion);

  const { isValid } = isValidBuildingFootprint(newFootprint);
  if (isValid) {
    const order = wallToExtrude.order;

    const oldWalls = cloneDeep(walls);
    const oldWall = cloneDeep(wallToExtrude);

    const newWalls = oldWalls.filter((wall) => wall.id !== wallToExtrude.id);
    if (!newWalls.length) {
      console.warn('extrudeWall: newWalls not found');
      return;
    }

    // increase order for walls which are after the extruded wall
    (newWalls as IWallFace[]).forEach((wall) => {
      if (wall.order > order) wall.order += 2;
    });

    // add in-between walls
    const oldZ = oldWall.coordinates[0][2];

    newWalls.push(
      createFace({
        id: generateUUID(),
        order,
        coordinates: [
          [oldWall.coordinates[0][0], oldWall.coordinates[0][1], oldZ],
          [oldWall.coordinates[0][0] + extrusion[0], oldWall.coordinates[0][1] + extrusion[1], oldZ],
          [oldWall.coordinates[0][0] + extrusion[0], oldWall.coordinates[0][1] + extrusion[1], oldZ + height],
          [oldWall.coordinates[0][0], oldWall.coordinates[0][1], oldZ + height],
        ],
        type: EFaceType.WALL,
        buildingId,
        floorId,
      }),
      createFace({
        ...oldWall,
        order: order + 1,
        coordinates: [
          [oldWall.coordinates[0][0] + extrusion[0], oldWall.coordinates[0][1] + extrusion[1], oldZ],
          [oldWall.coordinates[1][0] + extrusion[0], oldWall.coordinates[1][1] + extrusion[1], oldZ],
          [oldWall.coordinates[1][0] + extrusion[0], oldWall.coordinates[1][1] + extrusion[1], oldZ + height],
          [oldWall.coordinates[0][0] + extrusion[0], oldWall.coordinates[0][1] + extrusion[1], oldZ + height],
        ],
        buildingId,
        floorId,
      }),
      createFace({
        id: generateUUID(),
        order: order + 2,
        coordinates: [
          [oldWall.coordinates[1][0] + extrusion[0], oldWall.coordinates[1][1] + extrusion[1], oldZ],
          [oldWall.coordinates[1][0], oldWall.coordinates[1][1], oldZ],
          [oldWall.coordinates[1][0], oldWall.coordinates[1][1], oldZ + height],
          [oldWall.coordinates[1][0] + extrusion[0], oldWall.coordinates[1][1] + extrusion[1], oldZ + height],
        ],
        type: EFaceType.WALL,
        buildingId,
        floorId,
      }),
    );

    // delete original face and add new faces
    removeFloorFace(buildingId, floorId, oldWall.id);

    setFloorWalls(buildingId, floorId, newWalls);
    setBuildingFormChanged(buildingId, true);
  } else {
    console.warn('extrudeWall: resulting footprint would be invalid');
  }
};
