import { Typography, Unstable_Grid2 as Grid2 } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

const DataOverViewHeader = () => {
  const { t } = useTranslation();

  return (
    <Grid2 container spacing={3}>
      <Grid2 xs={4.5}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {t('project:dataOverview.header.type')}
        </Typography>
      </Grid2>
      <Grid2 xs={2}>
        <Typography variant='body2' fontWeight={500} noWrap>
          {t('project:dataOverview.header.source')}
        </Typography>
      </Grid2>
      <Grid2 xs={2}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.sourceDate')}
        </Typography>
      </Grid2>
      <Grid2 xs={2}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.updateDate')}
        </Typography>
      </Grid2>
      <Grid2 xs={1.5}>
        <Typography variant='body2' textAlign='right' fontWeight={500} noWrap>
          {t('project:dataOverview.header.qualityIndex')}
        </Typography>
      </Grid2>
    </Grid2>
  );
};

export default DataOverViewHeader;
